import { UserQuote } from '../UserQuotes';

const quotes: UserQuote[] = [
  {
    name: 'Shayla',
    quote: 'It’s been fantastic for creating and sharing our stories with beautiful art. What a great keepsake!',
  },
  {
    name: 'Bill',
    quote: 'My dyslexic 4th grader struggles with reading and comprehension. She is finally excited to read using Wanderly!',
  },
  {
    name: 'Sebastian',
    quote: "My son couldn't put the story down!",
  },
  {
    name: 'Matt',
    quote: "We've been using it every night for bedtime stories for our 3 year old.",
  },
  // Add more quotes as needed
];

export default quotes;
