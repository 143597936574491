import { GraphQLResult, API } from '@aws-amplify/api';
import {
  CreateGenreInput,
  CreateGenreMutation,
} from 'src/graphql/API';
import { createGenre } from 'src/graphql/mutations';

export default async function createNewGenre(
  newGenreInput: CreateGenreInput,
) {
  const imageRes = await API.graphql({
    query: createGenre,
    variables: { input: newGenreInput },
  }) as GraphQLResult<CreateGenreMutation>;

  const imageId = imageRes.data?.createGenre?.id;

  if (!imageId) {
    throw new Error('Failed to create genre');
  }

  return imageId;
}
