export const DEFAULT_META = {
  title: 'wanderly: discover your child\'s story',
  description: 'Create magical bedtime stories together. Include your child\'s friends, family and interests. Turn the stories into a beautifully printed book, made just for them.',
  images: {
    default: 'https://wander.ly/social-share.jpg', // 1200x675
    openGraph: 'https://wander.ly/social-share-og.jpg', // 1200x630
    twitter: 'https://wander.ly/social-share.jpg', // 1200x675
  },
  twitterHandle: '@wanderlystories',
  domain: 'https://wander.ly',
  type: 'website' as const,
} as const;

export const PAGE_META = {
  home: {
    title: DEFAULT_META.title,
    description: DEFAULT_META.description,
  },
  privacy: {
    title: 'Privacy Policy | Wanderly',
    description: 'Learn about how Wanderly protects and handles your personal information.',
  },
  terms: {
    title: 'Terms of Service | Wanderly',
    description: 'Read about the terms and conditions for using Wanderly\'s story creation service.',
  },
  checkout: {
    title: 'Get Started with Wanderly | Buy or Gift Packages and Books',
    description: 'Choose your package and start creating personalized stories with your child today.',
    noindex: false,
  },
} as const;
