import {
  FacebookConversionData, LineItem, PurchaseDetails, ShippingOption,
} from '@features/Checkout/types';
import {
  WANDERLY_NO_AUTH_NAME,
} from '@lib/constants';
import { API } from 'aws-amplify';

const noAuthApiName = WANDERLY_NO_AUTH_NAME;
const noAuthPath = '/stripe/create-checkout-session-noauth';

interface CheckoutSessionOptions {
  email?: string;
  lineItems: LineItem[];
  successUrl?: string;
  cancelUrl?: string;
  shippingOptions?: ShippingOption[];
  type: 'buy-package' | 'custom-book';
  purchaseDetails?: PurchaseDetails;
  fbConversionData?: FacebookConversionData;
}

export default async function getStripeCheckoutSessionNoAuth({
  email,
  lineItems,
  successUrl,
  cancelUrl,
  shippingOptions,
  type,
  purchaseDetails,
  fbConversionData,
}: CheckoutSessionOptions): Promise<string> {
  try {
    const baseUrl = window.location.origin;
    const currentPath = window.location.pathname;
    const currentParams = new URLSearchParams(window.location.search);

    // Add safe analytics parameters
    const analyticsParams = new URLSearchParams({
      success: 'true',
      type, // 'buy-package' or 'custom-book'
      items: lineItems.filter((item) => item.amount > 0).length.toString(),
      // Don't include prices or personal info in URL
    });

    // Set default success and cancel URLs
    const defaultSuccessUrl = `${baseUrl}${currentPath}?${analyticsParams.toString()}`;
    const defaultCancelUrl = `${baseUrl}${currentPath}?${currentParams.toString()}`;

    const checkoutSessionUrl = await API.post(noAuthApiName, noAuthPath, {
      body: {
        email,
        lineItems,
        baseUrl,
        successUrl: successUrl || defaultSuccessUrl,
        cancelUrl: cancelUrl || defaultCancelUrl,
        shippingOptions,
        type,
        purchaseDetails,
        fbConversionData,
      },
    });
    return checkoutSessionUrl;
  } catch (error: unknown) {
    console.error('Error in getStripeCheckoutSession:', error);
    if (error instanceof Error && 'response' in error) {
      console.error('Error response:', (error as { response: unknown }).response);
    }
    throw error;
  }
}
