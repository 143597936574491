import {
  useState,
  useEffect,
  InputHTMLAttributes,
  TextareaHTMLAttributes,
} from 'react';
import { Control, useController } from 'react-hook-form';
import { isDefined } from '../lib/utils';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'style'> {
  control: Control<any, any>;
  name: string;
  placeholder?: string;
  type?: string;
  error?: boolean;
  errorMessage?: string;
  textFieldStyle?: 'bottom-line' | 'fully-enclosed';
  multiline?: boolean; // New prop for multiline support
}

export default function AnTextField({
  control,
  name,
  placeholder = '',
  type = 'text',
  error = false,
  errorMessage = 'This field is required',
  textFieldStyle = 'bottom-line',
  multiline = false, // Default to single line
  ...inputProps
}: Props) {
  const { field } = useController({
    control,
    name,
  });

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    // Check both for error state and if errorMessage does not include "required"
    if (error && !errorMessage.toLowerCase().includes('required')) {
      timer = setTimeout(() => {
        setShowError(true);
      }, 2000); // Delay before showing error
    } else {
      setShowError(false);
    }

    return () => clearTimeout(timer); // Cleanup
  }, [error, errorMessage]); // Add errorMessage as a dependency

  const baseClassName = 'w-full text-dark text-center leading-5 py-1.5 px-4 focus:ring-0 placeholder:text-light placeholder:text-center flex-1 font-medium focus-visible:outline-0';

  const inputClassName = `${baseClassName} ${
    textFieldStyle === 'fully-enclosed'
      ? `border rounded-lg ${
        error ? 'border-red-500' : 'border-primary'
      }`
      : 'border-0 border-b'
  }`;

  return (
    <div className="flex flex-col justify-between items-center bg-white w-full">
      {multiline ? (
        <textarea
          placeholder={placeholder}
          {...field}
          {...(inputProps as TextareaHTMLAttributes<HTMLTextAreaElement>)}
          className={`${inputClassName} resize-none`}
          rows={4} // Adjust the number of rows as needed
        />
      ) : (
        <input
          type={type}
          placeholder={placeholder}
          value={isDefined(field.value) ? field.value : ''}
          onChange={field.onChange}
          className={inputClassName}
          {...inputProps}
        />
      )}
      <p
        className={`text-red-500 text-xs mb-2 transition-opacity duration-500 ${
          showError ? 'opacity-100 mt-2' : 'opacity-0'
        }`}
        style={{ height: showError ? 'auto' : 0, overflow: 'hidden' }}
      >
        {errorMessage}
      </p>
    </div>
  );
}
