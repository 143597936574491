import { imageBaseURL } from '@lib/imageUtils';
import BuyNowButton from './BuyNowButton';

interface HeroBannerProps {
  isDesktop: boolean;
}

export function HeroBanner({ isDesktop }: HeroBannerProps) {
  const imagePath = `${imageBaseURL}misc/landing/landing_page_hero`;

  return (
    <div className="relative w-full">
      <div className={`flex ${isDesktop ? 'flex-row' : 'flex-col'} items-center`}>
        {isDesktop ? (
          <>
            {/* Left Content with Background Color */}
            <div className="w-full lg:w-[40%] bg-secondary min-h-[500px] md:min-h-[600px] flex items-center">
              <div className="w-full max-w-[90%] lg:max-w-[350px] xl:max-w-[400px] mx-auto px-4 md:px-8 py-8 md:py-12 text-center md:text-left">
                <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4 max-w-[350px]">
                  Discover your child&apos;s story
                </h1>
                <p className="text-base sm:text-lg mb-8 max-w-[350px]">
                  Create magical bedtime stories together. Include your child&apos;s
                  friends, family and interests. Turn the stories into a beautifully
                  printed book, made just for them.
                </p>
                <div>
                  <BuyNowButton path="landing_page" analyticsLabel="hero_banner" />
                </div>
              </div>
            </div>

            {/* Right Image */}
            <div className="w-full md:w-[60%] md:absolute md:right-0 md:h-full">
              <picture className="block w-full h-full">
                <source
                  media="(min-width: 1280px)"
                  srcSet={`${imagePath}_1536w.webp`}
                />
                <source
                  media="(min-width: 768px)"
                  srcSet={`${imagePath}_1280w.webp`}
                />
                <img
                  src={`${imagePath}_640w.webp`}
                  alt="Children's story illustration"
                  className="w-full h-full object-cover"
                  width="1536"
                  height="1536"
                  loading="eager"
                />
              </picture>
            </div>
          </>
        ) : (
          <>
            {/* Image on Top */}
            <div className="w-full">
              <picture className="block w-full h-full">
                <source
                  media="(min-width: 1280px)"
                  srcSet={`${imagePath}_1536w.webp`}
                />
                <source
                  media="(min-width: 768px)"
                  srcSet={`${imagePath}_1280w.webp`}
                />
                <img
                  src={`${imagePath}_640w.webp`}
                  alt="Children's story illustration"
                  className="w-full h-full object-cover"
                  width="1536"
                  height="1536"
                  loading="eager"
                />
              </picture>
            </div>

            {/* Centered Content with White Background */}
            <div className="w-full px-4 py-8 bg-white text-center">
              <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4">
                Discover your child&apos;s story
              </h1>
              <p className="text-base sm:text-lg mb-8">
                Create magical bedtime stories together. Include your child&apos;s
                friends, family and interests. Turn the stories into a beautifully
                printed book, made just for them.
              </p>
              <div className="flex justify-center">
                <BuyNowButton path="landing_page" analyticsLabel="hero_banner" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default HeroBanner;
