import { Auth } from 'aws-amplify';

/**
 * Each cognito user has two ids:
 * 1. Username
 * 2. User Sub ID
 *
 * These two are the same when its an email/password auth.
 * But for federated logins, they are different.
 * And the owner field in ddb records is of the form subId::username
 *
 */
async function getCognitoUserSubId() {
  const subId = await Auth.currentAuthenticatedUser().then((user) => (user.attributes.sub));
  return subId;
}

export default getCognitoUserSubId;
