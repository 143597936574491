/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import Companion from '@lib/types/Companion';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MainCharacter from '@lib/types/MainCharacter';
import NumberedPage from '@lib/types/NumberedPage';
import {
  Child, Page, PageStatus, StoryPages, UpdateStoryPagesInput,
} from 'src/graphql/API';
import { isDefined } from '@lib/utils';
import getPageById from '@features/CustomStory/api/getPageById';
import { getExpositionImagePrefix, getPageImage, verifyCharacterSetImageExists } from '@lib/imageUtils';
import getNextPage, { getPageByIDfromDB } from '@features/CustomStory/api/getNextPage';
import { constructStoryPagesWithNextPage } from '@features/Story/lib/utils';
import putStoryPages from '@lib/api/putStoryPages';
import getStoryPages from '@lib/api/getStoryPages';
import StoryStatus from '@lib/types/StoryStatus.enum';
import startExpositionJob from '@features/CustomStory/api/startExpositionJob';
import startNextPageJob from '@features/CustomStory/api/startNextPageJob';
import { MAX_STORY_PAGES } from '@lib/constants';
import PreviousRenderedImage from '@lib/types/PreviousRenderedImage';
import StoryType from '@lib/types/StoryType';
import { StoryData } from '@lib/types/StoryData';
import { StoryLocationsData } from '@lib/types/StoryLocation';
import { RootState } from './store';

interface CustomStoryState {
    mainCharacter?: MainCharacter;
    storyType?: StoryType;
    items: string[];
    companion?: Companion;
    lastPageNumber?: number;
    jobId?: string;
    currentPage?: NumberedPage;
    retryCount: number;
    storyPages?: StoryPages;
    loading: boolean;
    error: Record<string, string | undefined>;
    isLoadingJobId: boolean;
    organicLastPageNumber?: number; // for self-ending options
    previousRenderedImage?: PreviousRenderedImage;
    previousRenderedCharacter?: string;
    resetKey: number;
    storyData?: StoryData;
  }

const initialState: CustomStoryState = {
  storyType: undefined,
  items: [],
  companion: undefined,
  lastPageNumber: undefined,
  jobId: undefined,
  currentPage: undefined,
  retryCount: 0,
  storyPages: undefined,
  loading: false,
  error: {},
  isLoadingJobId: false,
  organicLastPageNumber: undefined,
  previousRenderedImage: undefined,
  previousRenderedCharacter: undefined,
  resetKey: 0,
  storyData: undefined,
};

export const customStorySlice = createSlice({
  name: 'customStory',
  initialState,
  reducers: {
    setMainCharacter: (state, action: PayloadAction<MainCharacter>) => {
      state.mainCharacter = action.payload;

      // Check if storyData exists
      if (state.storyData) {
        // Create a new storyData object with updated mainCharacter, so that it triggers the selector
        state.storyData = {
          ...state.storyData,
          mainCharacter: action.payload,
        };
      }
    },
    setStoryType: (state, action: PayloadAction<StoryType>) => {
      state.storyType = action.payload;
      state.companion = undefined;
      state.items = [];

      // Check if storyData exists
      if (state.storyData) {
        // Create a new storyData object with updated storyType, so that it triggers the selector
        state.storyData = {
          ...state.storyData,
          storyType: action.payload,
        };
      }
    },
    addItem: (state, action: PayloadAction<string>) => {
      if (state.items.includes(action.payload)) return;
      state.items.push(action.payload);
    },
    removeItem: (state, action: PayloadAction<string>) => {
      state.items = state.items.filter((item) => item !== action.payload);
    },
    toggleItem: (state, action: PayloadAction<string>) => {
      if (state.items.includes(action.payload)) {
        state.items = state.items.filter((item) => item !== action.payload);
      } else {
        state.items.push(action.payload);
      }
    },
    setCompanion: (state, action: PayloadAction<Companion>) => {
      state.companion = action.payload;
    },
    resetCompanion: (state) => {
      state.companion = undefined;
    },
    setNextPageAsLast: (state) => {
      if (!state.currentPage) return;
      state.lastPageNumber = state.currentPage.pageNo + 1;
    },
    resetLastPageNumber: (state) => {
      state.lastPageNumber = undefined;
    },
    setOrganicLastPageNumber: (state, action: PayloadAction<number>) => {
      state.organicLastPageNumber = action.payload;
    },
    setLastPageNumber: (state, action: PayloadAction<number>) => {
      state.lastPageNumber = action.payload;
    },
    setJobId: (state, action: PayloadAction<string | undefined>) => {
      state.jobId = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<NumberedPage>) => {
      state.currentPage = action.payload;
    },
    setRetryCount: (state, action: PayloadAction<number>) => {
      state.retryCount = action.payload;
    },
    updatePageData: (state, action: PayloadAction<Page>) => {
      if (!state.currentPage) return;
      state.currentPage = { ...state.currentPage, pageData: action.payload };
    },
    setPreviousRenderedImage: (state) => {
      // If there's a new image, set that as the latest previously rendered image.
      if (state.currentPage?.pageImage) {
        state.previousRenderedImage = {
          isExposition: state.currentPage.pageNo === 1,
          name: state.currentPage.pageImage,
        };
        state.previousRenderedCharacter = undefined; // reset the character when we reset the background
        return;
      }
      // if the current page does not have its own image, use the one that was previously rendered
      if (state.previousRenderedImage) return;

      // previously we would set the previousRenderedImage to the exposition image, but now we're not doing that
      // because of the new image loading logic - as of 4/24
      state.previousRenderedImage = undefined;
    },
    setPreviousRenderedCharacter: (state) => {
      // If there is a new character, set that as the latest previously rendered character.
      if (state.currentPage?.primaryCharacter) {
        state.previousRenderedCharacter = state.currentPage.primaryCharacter;
        return;
      }

      // if the current page does not have its own image, use the one that was previously rendered
      if (state.previousRenderedCharacter) return;

      // mostly mimicing setPreviousRenderedImage; not sure when this would come up
      state.previousRenderedCharacter = undefined;
    },
    resetCustomStoryState: (state) => {
      // Reset the state, except for resetKey
      (Object.keys(state) as [keyof CustomStoryState]).forEach((key) => {
        if (key !== 'resetKey') {
          (state as any)[key] = initialState[key];
        }
      });
    },
    incrementResetKey: (state) => {
      state.resetKey += 1;
    },
    setStoryData: (state, action: PayloadAction<StoryData>) => {
      state.storyData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExistingPage.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchExistingPage.fulfilled, (state, action) => {
        state.loading = false;
        const { pageNo, pageData, primaryCharacter } = action.payload;
        if (!pageData) return;
        state.currentPage = {
          pageNo,
          pageData,
          pageImage: getPageImage(pageData),
          primaryCharacter,
        };
        state.jobId = undefined;
      })
      .addCase(fetchExistingPage.rejected, (state, action) => {
        state.loading = false;
        state.error.fetchExistingPage = action.error.message;
      })
      .addCase(fetchNewPage.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNewPage.fulfilled, (state, action) => {
        state.loading = false;
        const newPage = action.payload;
        if (state.organicLastPageNumber === newPage.pageNo) {
          state.lastPageNumber = newPage.pageNo;
        }
        state.currentPage = newPage;
        state.jobId = undefined;
        state.retryCount = 0;
      })
      .addCase(fetchNewPage.rejected, (state, action) => {
        state.loading = false;
        state.error.fetchNewPage = action.error.message;
      })
      .addCase(updateStoryPages.fulfilled, (state, action) => {
        state.storyPages = action.payload;
        state.loading = false;
        if (state.currentPage?.pageNo === 1) {
          // state.currentPage!.pageImage = action.payload.expositionImageName || undefined; // we're not setting the exposition image anymore - 4/24
          state.currentPage!.pageData.title = action.payload.title || undefined;
        }
      })
      .addCase(updateStoryPages.rejected, (state, action) => {
        state.loading = false;
        state.error.updateStoryPages = action.error.message;
      })
      .addCase(fetchStoryPages.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStoryPages.fulfilled, (state, action) => {
        state.loading = false;
        state.storyPages = action.payload;
        if (action.payload.storyType === 'dailyAdventure') {
          console.error(`${action.payload.id} is not a custom story`);
          return;
        }
        if (action.payload.storyStatus === StoryStatus.ENDED) {
          state.lastPageNumber = action.payload.pageIDs?.length || 0;
        }
      })
      .addCase(fetchStoryPages.rejected, (state, action) => {
        state.loading = false;
        state.error.fetchStoryPages = action.error.message;
      })
      .addCase(refetchCurrentPage.fulfilled, (state, action) => {
        if (!action.payload) return; // the polling was skipped due to another in-progress poll
        state.currentPage!.pageData = action.payload.pageData;
        state.currentPage!.primaryCharacter = action.payload.primaryCharacter;
        if (state.currentPage?.pageImage) return;
        state.currentPage!.pageImage = getPageImage(action.payload.pageData);
        state.currentPage!.primaryCharacter = action.payload.primaryCharacter;
      })
      .addCase(refetchCurrentPage.rejected, (state, action) => {
        state.error.refetchCurrentPage = action.error.message;
      })
      .addCase(fetchNextJobId.pending, (state) => {
        if (state.currentPage?.pageNo === MAX_STORY_PAGES - 1) {
          state.lastPageNumber = MAX_STORY_PAGES;
        }
        state.isLoadingJobId = true;
        state.jobId = undefined;
      })
      .addCase(fetchNextJobId.fulfilled, (state, action) => {
        state.isLoadingJobId = false;
        state.jobId = action.payload;
      })
      .addCase(fetchNextJobId.rejected, (state, action) => {
        state.isLoadingJobId = false;
        state.error.fetchNextJobId = action.error.message;
      });
  },
});

export const {
  setStoryType,
  addItem,
  removeItem,
  setCompanion,
  toggleItem,
  resetCompanion,
  setLastPageNumber,
  setNextPageAsLast,
  setJobId,
  setMainCharacter,
  resetCustomStoryState,
  incrementResetKey,
  setCurrentPage,
  setRetryCount,
  updatePageData,
  setOrganicLastPageNumber,
  resetLastPageNumber,
  setPreviousRenderedImage,
  setPreviousRenderedCharacter,
  setStoryData,
} = customStorySlice.actions;

export const selectIsNextPageLast = ({ customStory }: RootState) => (
  customStory.currentPage && customStory.lastPageNumber
    ? customStory.currentPage.pageNo === customStory.lastPageNumber - 1
    : false);
export const selectIsLastPage = ({ customStory }:RootState) => (customStory.currentPage ? customStory.currentPage.pageNo === customStory.lastPageNumber : false);
export const selectIsFirstPage = ({ customStory }:RootState) => (
  customStory.currentPage ? customStory.currentPage.pageNo <= 1 : false
);
export const selectMainCharacter = ({ customStory }: RootState) => customStory.mainCharacter;
export const selectPreviousRenderedImage = ({ customStory }: RootState) => customStory.previousRenderedImage;
export const selectPreviousRenderedCharacter = ({ customStory }: RootState) => customStory.previousRenderedCharacter;
export const selectStoryType = ({ customStory }: RootState) => customStory.storyType;
export const selectItems = ({ customStory }: RootState) => customStory?.items;
export const selectCompanion = ({ customStory }: RootState) => customStory?.companion;
export const selectLastPageNumber = ({ customStory }: RootState) => customStory.lastPageNumber;
export const selectOrganicLastPageNumber = ({ customStory }: RootState) => customStory.organicLastPageNumber;
export const selectJobId = ({ customStory }: RootState) => customStory.jobId;
export const selectCurrentPage = ({ customStory }: RootState) => customStory.currentPage;
export const selectCurrentPageNumber = ({ customStory }: RootState) => customStory.currentPage?.pageNo;
export const selectRetryCount = ({ customStory }: RootState) => customStory.retryCount;
export const selectStoryPages = ({ customStory }: RootState) => customStory.storyPages;
export const selectLoading = ({ customStory }: RootState) => customStory.loading;
export const selectIsLoadingJobId = ({ customStory }: RootState) => customStory.isLoadingJobId;
export const selectResetKey = ({ customStory }: RootState) => customStory.resetKey;

export const selectIsReadOnly = ({ customStory }: RootState) => {
  const { storyPages, currentPage } = customStory;
  if (storyPages?.rating) { // implies that the story has ended AND the last page has already been read
    return true;
  }

  // if this page is not the last one in an in-progress / ended story. In other words, if an option has already been selected, its no longer editable
  if ((currentPage?.pageNo || 0) < (storyPages?.pageIDs?.length || 0)) {
    return true;
  }

  return false;
};

export const selectNeedsNextJob = (appState: RootState) => {
  const isCurrentPageInProgress = selectIsCurrentPageInProgress(appState);
  const isReadOnly = selectIsReadOnly(appState);
  const isNextPageLast = selectIsNextPageLast(appState);
  const isLastPage = selectIsLastPage(appState);
  const currentPage = selectCurrentPage(appState);
  const isLoadingJobId = selectIsLoadingJobId(appState);
  const isLoadingPage = selectLoading(appState);
  const jobId = selectJobId(appState);
  if (isReadOnly) return false;
  if (isLoadingJobId) return false;
  if (isLastPage) return false;
  if (isCurrentPageInProgress) return false;
  if (jobId) return false;
  if (isLoadingPage) return false;
  if (isNextPageLast && currentPage?.pageData.followedByEnding) return false; // pages are already generated
  if (!isNextPageLast && currentPage?.pageData.followedBy) return false; // pages are already generated
  return true;
};

export const selectIsCurrentPageInProgress = ({ customStory }: RootState) => customStory.currentPage?.pageData.status === PageStatus.IN_PROGRESS;
export const selectIsCurrentPageErrored = ({ customStory }: RootState) => customStory.currentPage?.pageData.status === PageStatus.ERROR;

// This is a generic selector that returns the metadata for the current story type for scripted stories
export const selectScriptedStoryMetadata = (state: any, desiredType?: string) => {
  const metadata = state.customStory.scriptedStoryMetadata;
  if (desiredType && metadata && metadata.data && metadata.type === desiredType) {
    return metadata.data;
  }
  if (!desiredType && metadata && metadata.data) {
    return metadata.data;
  }
  return null;
};

export const selectStoryData = (state: RootState) => state.customStory.storyData;

const updateStoryPages = createAsyncThunk<StoryPages, UpdateStoryPagesInput>(
  'customStory/updateStoryPages',
  async (putData: UpdateStoryPagesInput) => {
    const res = await putStoryPages(putData);
    if (!res) {
      throw new Error('Failed to update story pages');
    }
    return res;
  },
);

const updateImageAndTitle = createAsyncThunk<void, Page>(
  'customStory/updateImageAndTitle',
  async (pageData: Page, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const storyPages = selectStoryPages(appState);

    let updatePending = false;
    let putData: UpdateStoryPagesInput = {
      id: storyPages!.id!,
    };

    if (!storyPages?.expositionImageName && pageData?.location) {
      let expositionImage = '';
      expositionImage = getExpositionImagePrefix(
        pageData.location || undefined,
        (storyPages?.storyType as keyof StoryLocationsData) || undefined,
      );
      if (expositionImage) {
        updatePending = true;
        putData = {
          ...putData,
          expositionImageName: expositionImage,
        };
      }
    }
    if (!storyPages?.title && pageData?.title) {
      updatePending = true;
      putData = {
        ...putData,
        title: pageData?.title,
      };
    }

    if (updatePending) {
      thunkAPI.dispatch(updateStoryPages(putData));
    }
  },
);

const refetchCurrentPage = createAsyncThunk<{ pageData: Page, primaryCharacter: string | undefined }, void>(
  'customStory/refetchCurrentPage',
  async (_:void, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;

    const currentPage = selectCurrentPage(appState);
    if (!currentPage) {
      throw new Error('No Page is currently open!');
    }
    const newData = await getPageByIDfromDB(currentPage.pageData.id);
    if (!newData) {
      throw new Error('Failed to re-fetch the currently-open page');
    }

    if (currentPage.pageNo === 1) {
      thunkAPI.dispatch(updateImageAndTitle(newData));
    }

    // Dispatch fetchCharacterSetImageName thunk
    let primaryCharacter = '';
    if (newData.minorImageName && newData.minorImageName !== null) {
      primaryCharacter = await thunkAPI.dispatch(
        fetchCharacterSetImageName({ characterImageData: newData.minorImageName }),
      ).unwrap();
    }

    return { pageData: newData, primaryCharacter };
  },
);

const fetchExistingPage = createAsyncThunk<{ pageNo: number, pageData: Page, primaryCharacter: string}, number>(
  'customStory/fetchExistingPage',
  async (pageNo: number, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const storyPages = selectStoryPages(appState);
    if (!storyPages) {
      throw new Error('No Story exists');
    }
    const pageId = storyPages.pageIDs![pageNo - 1];
    if (!isDefined(pageId)) {
      throw new Error('page no. does not exist');
    }
    const pageData = await getPageById(pageId);
    if (!pageData) {
      throw new Error('no page data found...');
    }

    thunkAPI.dispatch(updateImageAndTitle(pageData));

    let primaryCharacter = '';
    if (pageData.minorImageName && pageData.minorImageName !== null) {
      primaryCharacter = await thunkAPI.dispatch(
        fetchCharacterSetImageName({ characterImageData: pageData.minorImageName }),
      ).unwrap();
    }

    return { pageNo, pageData, primaryCharacter };
  },
);

const fetchNewPage = createAsyncThunk<NumberedPage, number>(
  'customStory/fetchNewPage',
  async (chosenOptionIndex: number, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const lastPageNumber = selectLastPageNumber(appState);
    const organicLastPageNumber = selectOrganicLastPageNumber(appState);
    const isNextPageLast = selectIsNextPageLast(appState);
    const currentPage = selectCurrentPage(appState);
    const storyPages = selectStoryPages(appState);
    const jobId = selectJobId(appState);
    const istryAgain = selectIsCurrentPageErrored(appState);
    let pageData: Page | undefined | null;
    if (isNextPageLast && currentPage?.pageData.followedByEnding?.length === 3) {
      pageData = await getPageByIDfromDB(currentPage.pageData.followedByEnding[chosenOptionIndex]!);
    } else if (!isNextPageLast && currentPage?.pageData.followedBy?.length === 3) {
      pageData = await getPageByIDfromDB(currentPage.pageData.followedBy[chosenOptionIndex]!);
    } else if (jobId) {
      pageData = await getNextPage(chosenOptionIndex, jobId);
    } else {
      throw new Error('no job id available...');
    }
    if (!pageData) {
      throw new Error('no page data found...');
    }

    const newPageNo = istryAgain ? currentPage!.pageNo : (currentPage?.pageNo || 0) + 1;

    let primaryCharacter = '';
    if (pageData.minorImageName && pageData.minorImageName !== null) {
      primaryCharacter = await thunkAPI.dispatch(
        fetchCharacterSetImageName({ characterImageData: pageData.minorImageName }),
      ).unwrap();
    }

    const newPage = {
      pageNo: newPageNo,
      pageData,
      pageImage: getPageImage(pageData),
      primaryCharacter, // TODO: we'll want to expand logic once we go beyond minor characters
    };

    /**
     * fetchNewPage thunk is not fulfilled until this whole thunk body completes execution. Which means:
     *
     * 1. If user selects "End story after next choice", the next page has been fetched, but the page has not turned yet. So we're still on the second-last page. But the new page is last.
     *
     * 2. If its an organic last page (an option with ⏹️ is selected), the last page number has not yet been set to the organic last page number.
     *
     * Therefore, instead of using state values, we're considering that the state has not yet updated after the last page was fetched.
     *  */

    // Dispatch fetchCharacterSetImageName thunk, no need to wait, it'll update the state when it's done via reducers
    thunkAPI.dispatch(
      fetchCharacterSetImageName({ characterImageData: pageData.minorImageName || '' }),
    );

    if (!istryAgain) {
      const putData = constructStoryPagesWithNextPage(newPage, storyPages!, newPage.pageNo === (lastPageNumber || organicLastPageNumber));
      thunkAPI.dispatch(updateStoryPages(putData));
    }

    return newPage;
  },
);

const fetchStoryPages = createAsyncThunk<StoryPages, string>(
  'customStory/fetchStoryPages',
  async (storyId: string) => {
    const res = await getStoryPages(storyId);
    if (!res) {
      throw new Error('Story Pages could not be fetched!');
    }
    return res;
  },
);

const fetchNextJobId = createAsyncThunk<string, Child>(
  'customStory/fetchNextJobId',
  async (child: Child, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const storyData = selectStoryData(appState);
    const isNextPageLast = selectIsNextPageLast(appState);
    const currentPage = selectCurrentPage(appState);
    const tryAgain = selectIsCurrentPageErrored(appState);
    const storyPages = selectStoryPages(appState);

    const isPendingExposition = !currentPage
    || currentPage.pageNo === 0
    || (currentPage.pageNo === 1 && tryAgain);
    let newJobId;
    if (isPendingExposition) {
      if (!storyData) {
        console.log('Story Data not found!');
        throw new Error('Story Data not found!');
      }
      newJobId = await startExpositionJob(storyData);
    } else {
      newJobId = await startNextPageJob(tryAgain ? storyPages!.pageIDs![currentPage!.pageNo! - 2] as string : currentPage!.pageData.id as string, isNextPageLast);
    }
    if (!newJobId) {
      throw new Error('Unable to fetch a job id!');
    }
    return newJobId;
  },
);

// an async thunk to fetch the character set image name from the characterImageData

const fetchCharacterSetImageName = createAsyncThunk<string, { characterImageData: string }>(
  'customStory/fetchCharacterSetImageName',
  async ({ characterImageData }) => {
    if (!characterImageData) {
      return '';
    }

    try {
      const parsedCharacterImageData = JSON.parse(characterImageData);
      const characterSetName = parsedCharacterImageData.character_set;
      const imageAction = parsedCharacterImageData.action;

      let imageName = '';

      const imageExists = await verifyCharacterSetImageExists(characterSetName, imageAction);
      if (imageExists) {
        imageName = `${characterSetName}/${imageAction}`;
      } else {
        imageName = `${characterSetName}/default`;
      }

      return imageName;
    } catch (error) {
      console.error('Error verifying image', error);
      throw error;
    }
  },
);

export {
  fetchExistingPage,
  fetchNewPage,
  refetchCurrentPage,
  fetchStoryPages,
  fetchNextJobId,
  updateStoryPages,
  fetchCharacterSetImageName,
};

export default customStorySlice.reducer;
