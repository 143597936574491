import pluralize from 'pluralize';
import * as timeago from 'timeago.js';
import { CommonNounType } from './types';

function isDefined<T>(a: T | null | undefined): a is Exclude<T, null | undefined> {
  return a !== undefined && a !== null;
}

const isPrimitive = (thing: any) => (thing !== Object(thing));

function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

function kebabToCamelCase(str: string): string {
  return str.replace(/-([a-z])/g, (match, p1) => p1.toUpperCase());
}

function camelToKebabCase(str: string): string {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

function kebabToTitleCase(str: string): string {
  return str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

function getFirstName(fullName?: string): string {
  if (!fullName) return '';
  return fullName.split(' ')[0];
}

function getArticle(nextWord: string): string {
  if (pluralize.isPlural(nextWord)) return '';
  if (['a', 'e', 'i', 'o', 'u'].includes(nextWord[0].toLowerCase())) {
    return 'an';
  }
  return 'a';
}

function getPronoun(commonNoun?: CommonNounType): string {
  if (!commonNoun) return '';
  if (commonNoun.toLowerCase() === 'boy') return 'he';
  if (commonNoun.toLowerCase() === 'girl') return 'she';
  return 'it';
}

function getReadingLevel(isEarlyReader: boolean) {
  if (isEarlyReader) return 'emerging';
  return 'practicing';
}

function getIsEarlyReader(readingLevel?: string) {
  if (readingLevel === 'emerging') return true;
  return false;
}

function getRelativeTime(timestampMilliseconds?: number) {
  if (!timestampMilliseconds) return '';
  const relTime = timeago.format(timestampMilliseconds);

  if (new RegExp(['now, second, minute, hour'].join('|')).test(relTime)) return relTime;
  if (relTime === '1 day ago') return 'yesterday';
  if (relTime.includes('day')) {
    const weekDayFormat = new Intl.DateTimeFormat('en-US', { weekday: 'long' });
    return weekDayFormat.format(timestampMilliseconds);
  }

  return relTime;
}

// Generate a random integer between min (inclusive) and max (inclusive)
function getRandomInt(min = 0, max = 100) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const generateTitle = (childName: string, expositionImageName: string) => `${capitalize(childName)}'s Adventure in the ${capitalize(expositionImageName.replaceAll(/[_\d]/g, ' ').trim())}`;

function generateRandomAvatarName(length: number): string {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export {
  isDefined,
  isPrimitive,
  kebabToCamelCase,
  camelToKebabCase,
  kebabToTitleCase,
  getFirstName,
  getArticle,
  getPronoun,
  capitalize,
  getReadingLevel,
  getIsEarlyReader,
  getRelativeTime,
  getRandomInt,
  generateTitle,
  generateRandomAvatarName,
};
