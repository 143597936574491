import { LineItem } from '@features/Checkout/types';
import React, { useState, useEffect } from 'react';
// import { Loader } from '@aws-amplify/ui-react';
// import colors from 'src/colors';

interface AnCheckoutImageLineItemProps {
  lineItemOptions: LineItem[];
  onChange?: (selectedItem: LineItem | null) => void;
  selectedOption?: LineItem | null;
  title?: string;
  showDescription?: boolean;
  showPrice?: boolean;
}

function AnCheckoutImageLineItem({
  lineItemOptions, onChange, selectedOption, title, showDescription = false, showPrice = true,
}: AnCheckoutImageLineItemProps) {
  const [selectedItem, setSelectedItem] = useState<LineItem>(selectedOption || lineItemOptions[0]);

  useEffect(() => {
    if (selectedOption) {
      setSelectedItem(selectedOption);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedItem) {
      onChange?.(selectedItem);
    }
  }, [selectedItem]);

  if (!lineItemOptions || lineItemOptions.length === 0) {
    return null;
    // return (
    //   <div className="mt-8 max-w-[424px] flex items-center justify-center">
    //     <div className="w-full max-w-xs flex items-center justify-center">
    //       <Loader
    //         filledColor={colors.accent1.DEFAULT}
    //         width="4em"
    //         height="4em"
    //       />
    //     </div>
    //   </div>
    // );
  }

  const handleKeyDown = (event: React.KeyboardEvent, option: LineItem) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setSelectedItem(option);
    }
  };

  return (
    <div className="mt-2 max-w-[424px]">
      <div className="flex justify-between items-center mb-2">
        <p className="text-xl truncate flex-1">{title || selectedItem?.name}</p>
        {showPrice && (
          <span className="text-lg font-medium ml-4 whitespace-nowrap">
            {selectedItem?.amount > 0 ? `+ $${selectedItem?.amount}` : '+ $0'}
          </span>
        )}
      </div>
      {showDescription && <p className="text-sm text-gray-500 mb-2">{selectedItem?.description}</p>}
      <div className="grid grid-cols-2 gap-4">
        {lineItemOptions.map((option) => (
          <div
            key={option.name}
            className={`cursor-pointer border-2 ${
              selectedItem?.name === option.name ? 'border-primary' : 'border-gray-300'
            } rounded-lg overflow-hidden aspect-square flex items-center justify-center`}
            onClick={() => setSelectedItem(option)}
            onKeyDown={(e) => handleKeyDown(e, option)}
            tabIndex={0}
            role="button"
            aria-pressed={selectedItem?.name === option.name}
          >
            <img
              src={option.imageSrc}
              alt={option.name}
              className="max-w-full max-h-full object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default AnCheckoutImageLineItem;
