import { UserQuote } from '../UserQuotes';

const quotes: UserQuote[] = [
  {
    name: 'Taryn S',
    quote: 'Kids begged me to do Wanderly at bedtime.',
  },
  {
    name: 'Eliza',
    quote: "It's so fun and I love hearing what choice he will pick.",
  },
  {
    name: 'Bill',
    quote: 'I’m very impressed with the ease with which I was able to write a fun (and colorful) story. I wrote one for my grandchild and can’t wait to share it with her. ',
  },
  {
    name: 'Simon',
    quote: "An instant hit with my 4 year old. As a parent, it's whimsical and a welcome change from the regular books in rotation.",
  },
  // Add more quotes as needed
];

export default quotes;
