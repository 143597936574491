import React from 'react';

export const PRODUCT_NAMES = {
  CUSTOM_BOOK: 'A Custom Wanderly Book',
  PREMIUM_COVER: 'Premium Cover Art',
  STANDARD_COVER: 'Standard Cover Art',
  CUSTOM_BOOK_PLUS_10_CREDITS: 'Custom Book + 10 Credits',
  // THREE_MONTH_SUBSCRIPTION: '3 Months of Stories',
  // YEAR_SUBSCRIPTION: 'A Year of Stories',
  STANDARD_PACKAGE: 'Custom Book + 3 Months',
  YEAR_WITH_BOOKS: '4 Custom Books + 1 Year',
  CLASSIC_BOOKPLATE: 'Classic Bookplate',
  MODERN_BOOKPLATE: 'Modern Bookplate',
  GENERAL_GC_STYLE: 'Yay! Enjoy some magical stories!',
  HOLIDAY_GC_STYLE: "It's a wonderful time of year for stories...",
  BIRTHDAY_GC_STYLE: 'Happy Birthday!',
} as const;

export type PackageProductName =
  // | typeof PRODUCT_NAMES.THREE_MONTH_SUBSCRIPTION
  // | typeof PRODUCT_NAMES.YEAR_SUBSCRIPTION
  | typeof PRODUCT_NAMES.CUSTOM_BOOK_PLUS_10_CREDITS
  | typeof PRODUCT_NAMES.STANDARD_PACKAGE
  | typeof PRODUCT_NAMES.YEAR_WITH_BOOKS;

export type ProductName = typeof PRODUCT_NAMES[keyof typeof PRODUCT_NAMES];

export interface LineItem {
  priceId: string;
  productId: string;
  name: ProductName;
  description: string;
  currency: string;
  amount: number; // this is the amount in cents
  imageSrc?: string; // for use within the checkout page UI
}

export interface CheckoutData {
  purchaserEmail: string;
  lineItems: LineItem[];
  successUrl: string;
  cancelUrl: string;
}

export interface CustomBookPurchase {
  title: string;
  byline: string;
  bookplateName: string;
  dedication: string;
  bookplateStyle: string;
  storyId: string;
}

export interface PackagePurchase {
  recipientFirstName: string;
  recipientLastName: string;
  recipientEmail: string;
  giftMessage: string;
  deliveryDate: Date | null;
  giftCardStyle: string;
  gifterName: string;
  gifterEmail: string;
}

export type PurchaseDetails = CustomBookPurchase | PackagePurchase;

export function isCustomBookPurchase(purchase: PurchaseDetails): purchase is CustomBookPurchase {
  return 'title' in purchase && 'byline' in purchase;
}

export interface CartItem extends LineItem {
  quantity: number;
}

export interface Cart {
  items: CartItem[];
  total: number;
}

export interface ShippingOption {
  id: string;
  displayName: string;
  deliveryEstimate: string;
  fixedAmount: number;
  type: string;
}

export interface PackageFlowType {
  buyingFor: '' | 'me' | 'someoneElse';
  whoWillCreate: '' | 'me' | 'someoneElse';
}

// export interface CheckoutStep {
//   id: string;
//   component: React.ReactElement;
//   isNextButtonVisible?: boolean;
//   onNext?: () => void | Promise<void>;
//   onNextButtonText?: string;
// }

export interface CheckoutStep {
  title: string;
  children: React.ReactNode;
  isNextDisabled?: boolean;
  onNext: () => void;
  isLastStep: boolean;
}

export interface FacebookConversionData {
  fbc: string;
  fbp: string;
}
