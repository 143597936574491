/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  id?: string | null,
  cognitoUserId: string,
  email: string,
  activeChildId?: string | null,
  settings?: string | null,
  lastInteraction?: string | null,
  emailUpdatesEnabled?: boolean | null,
  emailPromotionsEnabled?: boolean | null,
  emailStoriesFridayEnabled?: boolean | null,
  emailStoriesDailyEnabled?: boolean | null,
  trialStoriesCount?: number | null,
  bookCredits?: number | null,
  userNotificationSettingsId?: string | null,
};

export type ModelUserConditionInput = {
  cognitoUserId?: ModelStringInput | null,
  email?: ModelStringInput | null,
  activeChildId?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  lastInteraction?: ModelStringInput | null,
  emailUpdatesEnabled?: ModelBooleanInput | null,
  emailPromotionsEnabled?: ModelBooleanInput | null,
  emailStoriesFridayEnabled?: ModelBooleanInput | null,
  emailStoriesDailyEnabled?: ModelBooleanInput | null,
  trialStoriesCount?: ModelIntInput | null,
  bookCredits?: ModelIntInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userNotificationSettingsId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type User = {
  __typename: "User",
  id: string,
  cognitoUserId: string,
  email: string,
  children?: ModelChildConnection | null,
  activeChildId?: string | null,
  settings?: string | null,
  lastInteraction?: string | null,
  emailUpdatesEnabled?: boolean | null,
  emailPromotionsEnabled?: boolean | null,
  emailStoriesFridayEnabled?: boolean | null,
  emailStoriesDailyEnabled?: boolean | null,
  trialStoriesCount?: number | null,
  bookCredits?: number | null,
  notificationSettings?: NotificationSettings | null,
  deviceTokens?: ModelDeviceTokenConnection | null,
  createdAt: string,
  updatedAt: string,
  userNotificationSettingsId?: string | null,
  owner?: string | null,
};

export type ModelChildConnection = {
  __typename: "ModelChildConnection",
  items:  Array<Child | null >,
  nextToken?: string | null,
};

export type Child = {
  __typename: "Child",
  id: string,
  name: string,
  age: string,
  commonNoun: string,
  readingLevel?: string | null,
  avatar?: string | null,
  storyPages?: ModelStoryPagesConnection | null,
  createdAt: string,
  updatedAt: string,
  userChildrenId?: string | null,
  owner?: string | null,
};

export type ModelStoryPagesConnection = {
  __typename: "ModelStoryPagesConnection",
  items:  Array<StoryPages | null >,
  nextToken?: string | null,
};

export type StoryPages = {
  __typename: "StoryPages",
  id: string,
  pageIDs?: Array< string | null > | null,
  storyType?: string | null,
  storyStatus?: string | null,
  rating?: number | null,
  childStoryPagesId: string,
  lastViewed?: string | null,
  expositionImageName?: string | null,
  title?: string | null,
  initiationMethod?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type NotificationSettings = {
  __typename: "NotificationSettings",
  id: string,
  user?: User | null,
  userId: string,
  notificationsEnabled: boolean,
  storyReminders: StoryReminderSettings,
  promotionsEnabled?: boolean | null,
  updatesEnabled?: boolean | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type StoryReminderSettings = {
  __typename: "StoryReminderSettings",
  enabled: boolean,
  frequency?: string | null,
};

export type ModelDeviceTokenConnection = {
  __typename: "ModelDeviceTokenConnection",
  items:  Array<DeviceToken | null >,
  nextToken?: string | null,
};

export type DeviceToken = {
  __typename: "DeviceToken",
  id: string,
  user?: User | null,
  userId: string,
  token: string,
  platform: string,
  endpointArn?: string | null,
  createdAt: string,
  updatedAt: string,
  userDeviceTokensId?: string | null,
  owner?: string | null,
};

export type UpdateUserInput = {
  id: string,
  cognitoUserId?: string | null,
  email?: string | null,
  activeChildId?: string | null,
  settings?: string | null,
  lastInteraction?: string | null,
  emailUpdatesEnabled?: boolean | null,
  emailPromotionsEnabled?: boolean | null,
  emailStoriesFridayEnabled?: boolean | null,
  emailStoriesDailyEnabled?: boolean | null,
  trialStoriesCount?: number | null,
  bookCredits?: number | null,
  userNotificationSettingsId?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateNotificationSettingsInput = {
  id?: string | null,
  userId: string,
  notificationsEnabled: boolean,
  storyReminders: StoryReminderSettingsInput,
  promotionsEnabled?: boolean | null,
  updatesEnabled?: boolean | null,
};

export type StoryReminderSettingsInput = {
  enabled: boolean,
  frequency?: string | null,
};

export type ModelNotificationSettingsConditionInput = {
  userId?: ModelIDInput | null,
  notificationsEnabled?: ModelBooleanInput | null,
  promotionsEnabled?: ModelBooleanInput | null,
  updatesEnabled?: ModelBooleanInput | null,
  and?: Array< ModelNotificationSettingsConditionInput | null > | null,
  or?: Array< ModelNotificationSettingsConditionInput | null > | null,
  not?: ModelNotificationSettingsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateNotificationSettingsInput = {
  id: string,
  userId?: string | null,
  notificationsEnabled?: boolean | null,
  storyReminders?: StoryReminderSettingsInput | null,
  promotionsEnabled?: boolean | null,
  updatesEnabled?: boolean | null,
};

export type DeleteNotificationSettingsInput = {
  id: string,
};

export type CreateDeviceTokenInput = {
  id?: string | null,
  userId: string,
  token: string,
  platform: string,
  endpointArn?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userDeviceTokensId?: string | null,
};

export type ModelDeviceTokenConditionInput = {
  userId?: ModelIDInput | null,
  token?: ModelStringInput | null,
  platform?: ModelStringInput | null,
  endpointArn?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDeviceTokenConditionInput | null > | null,
  or?: Array< ModelDeviceTokenConditionInput | null > | null,
  not?: ModelDeviceTokenConditionInput | null,
  userDeviceTokensId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateDeviceTokenInput = {
  id: string,
  userId?: string | null,
  token?: string | null,
  platform?: string | null,
  endpointArn?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userDeviceTokensId?: string | null,
};

export type DeleteDeviceTokenInput = {
  id: string,
};

export type CreateWaitlistInput = {
  cognitoUserId: string,
  email: string,
  accessGranted: boolean,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelWaitlistConditionInput = {
  email?: ModelStringInput | null,
  accessGranted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelWaitlistConditionInput | null > | null,
  or?: Array< ModelWaitlistConditionInput | null > | null,
  not?: ModelWaitlistConditionInput | null,
  cognitoUserId?: ModelStringInput | null,
};

export type Waitlist = {
  __typename: "Waitlist",
  cognitoUserId: string,
  email: string,
  accessGranted: boolean,
  createdAt: string,
  updatedAt?: string | null,
};

export type UpdateWaitlistInput = {
  cognitoUserId: string,
  email?: string | null,
  accessGranted?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteWaitlistInput = {
  cognitoUserId: string,
};

export type CreateGiftcardInput = {
  id?: string | null,
  giftcode: string,
  recipientEmail: string,
  subscriptionProduct: string,
  storyCredits: number,
  bookCredits: number,
  giftingEmail?: string | null,
  redeemed: boolean,
  redeemedAt?: string | null,
};

export type ModelGiftcardConditionInput = {
  giftcode?: ModelStringInput | null,
  recipientEmail?: ModelStringInput | null,
  subscriptionProduct?: ModelStringInput | null,
  storyCredits?: ModelIntInput | null,
  bookCredits?: ModelIntInput | null,
  giftingEmail?: ModelStringInput | null,
  redeemed?: ModelBooleanInput | null,
  redeemedAt?: ModelStringInput | null,
  and?: Array< ModelGiftcardConditionInput | null > | null,
  or?: Array< ModelGiftcardConditionInput | null > | null,
  not?: ModelGiftcardConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Giftcard = {
  __typename: "Giftcard",
  id: string,
  giftcode: string,
  recipientEmail: string,
  subscriptionProduct: string,
  storyCredits: number,
  bookCredits: number,
  giftingEmail?: string | null,
  redeemed: boolean,
  redeemedAt?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateGiftcardInput = {
  id: string,
  giftcode?: string | null,
  recipientEmail?: string | null,
  subscriptionProduct?: string | null,
  storyCredits?: number | null,
  bookCredits?: number | null,
  giftingEmail?: string | null,
  redeemed?: boolean | null,
  redeemedAt?: string | null,
};

export type DeleteGiftcardInput = {
  id: string,
};

export type CreateMetadataInput = {
  id?: string | null,
  isUnderMaintenance: boolean,
  startingTrialStoriesCount: number,
  waitlistEnabled: boolean,
  minimumAppVersion?: string | null,
  promotionString?: string | null,
  updatedAt?: string | null,
};

export type ModelMetadataConditionInput = {
  isUnderMaintenance?: ModelBooleanInput | null,
  startingTrialStoriesCount?: ModelIntInput | null,
  waitlistEnabled?: ModelBooleanInput | null,
  minimumAppVersion?: ModelStringInput | null,
  promotionString?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMetadataConditionInput | null > | null,
  or?: Array< ModelMetadataConditionInput | null > | null,
  not?: ModelMetadataConditionInput | null,
  createdAt?: ModelStringInput | null,
};

export type Metadata = {
  __typename: "Metadata",
  id: string,
  isUnderMaintenance: boolean,
  startingTrialStoriesCount: number,
  waitlistEnabled: boolean,
  minimumAppVersion?: string | null,
  promotionString?: string | null,
  updatedAt?: string | null,
  createdAt: string,
};

export type UpdateMetadataInput = {
  id: string,
  isUnderMaintenance?: boolean | null,
  startingTrialStoriesCount?: number | null,
  waitlistEnabled?: boolean | null,
  minimumAppVersion?: string | null,
  promotionString?: string | null,
  updatedAt?: string | null,
};

export type DeleteMetadataInput = {
  id: string,
};

export type CreateUserPaymentInput = {
  stripeSubscriptionId: string,
  cognitoUserId: string,
  stripeCustomerId?: string | null,
  currentTransactionId?: string | null,
  status: SubscriptionStatus,
  currentPeriodStart?: string | null,
  currentPeriodEnd?: string | null,
  cancelAtPeriodEnd?: boolean | null,
  price: StripePriceInput,
  product: StripeProductInput,
  provider?: PaymentProvider | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum SubscriptionStatus {
  active = "active",
  past_due = "past_due",
  unpaid = "unpaid",
  canceled = "canceled",
  incomplete = "incomplete",
  incomplete_expired = "incomplete_expired",
  trialing = "trialing",
  paused = "paused",
}


export type StripePriceInput = {
  id: string,
  amount: number,
  interval?: BillingInterval | null,
  interval_count?: number | null,
  currency: string,
};

export enum BillingInterval {
  year = "year",
  quarter = "quarter",
  month = "month",
  week = "week",
  day = "day",
}


export type StripeProductInput = {
  id: string,
  name: string,
  description?: string | null,
};

export enum PaymentProvider {
  stripe = "stripe",
  android = "android",
  apple = "apple",
}


export type ModelUserPaymentConditionInput = {
  cognitoUserId?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  currentTransactionId?: ModelStringInput | null,
  status?: ModelSubscriptionStatusInput | null,
  currentPeriodStart?: ModelStringInput | null,
  currentPeriodEnd?: ModelStringInput | null,
  cancelAtPeriodEnd?: ModelBooleanInput | null,
  provider?: ModelPaymentProviderInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserPaymentConditionInput | null > | null,
  or?: Array< ModelUserPaymentConditionInput | null > | null,
  not?: ModelUserPaymentConditionInput | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionStatusInput = {
  eq?: SubscriptionStatus | null,
  ne?: SubscriptionStatus | null,
};

export type ModelPaymentProviderInput = {
  eq?: PaymentProvider | null,
  ne?: PaymentProvider | null,
};

export type UserPayment = {
  __typename: "UserPayment",
  stripeSubscriptionId: string,
  cognitoUserId: string,
  stripeCustomerId?: string | null,
  currentTransactionId?: string | null,
  status: SubscriptionStatus,
  currentPeriodStart?: string | null,
  currentPeriodEnd?: string | null,
  cancelAtPeriodEnd?: boolean | null,
  price: StripePrice,
  product: StripeProduct,
  provider?: PaymentProvider | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type StripePrice = {
  __typename: "StripePrice",
  id: string,
  amount: number,
  interval?: BillingInterval | null,
  interval_count?: number | null,
  currency: string,
};

export type StripeProduct = {
  __typename: "StripeProduct",
  id: string,
  name: string,
  description?: string | null,
};

export type UpdateUserPaymentInput = {
  stripeSubscriptionId: string,
  cognitoUserId?: string | null,
  stripeCustomerId?: string | null,
  currentTransactionId?: string | null,
  status?: SubscriptionStatus | null,
  currentPeriodStart?: string | null,
  currentPeriodEnd?: string | null,
  cancelAtPeriodEnd?: boolean | null,
  price?: StripePriceInput | null,
  product?: StripeProductInput | null,
  provider?: PaymentProvider | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteUserPaymentInput = {
  stripeSubscriptionId: string,
};

export type CreateTransactionsInput = {
  id?: string | null,
  cognitoUserId: string,
  transactionId: string,
  originalTransactionId?: string | null,
  product: StripeProductInput,
  price: StripePriceInput,
  receipt?: string | null,
  provider?: PaymentProvider | null,
  discounts?: string | null,
  transactionDate?: string | null,
  errorCode?: string | null,
  errorMessage?: string | null,
  createdAt?: string | null,
};

export type ModelTransactionsConditionInput = {
  cognitoUserId?: ModelStringInput | null,
  transactionId?: ModelStringInput | null,
  originalTransactionId?: ModelStringInput | null,
  receipt?: ModelStringInput | null,
  provider?: ModelPaymentProviderInput | null,
  discounts?: ModelStringInput | null,
  transactionDate?: ModelStringInput | null,
  errorCode?: ModelStringInput | null,
  errorMessage?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTransactionsConditionInput | null > | null,
  or?: Array< ModelTransactionsConditionInput | null > | null,
  not?: ModelTransactionsConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type Transactions = {
  __typename: "Transactions",
  id: string,
  cognitoUserId: string,
  transactionId: string,
  originalTransactionId?: string | null,
  product: StripeProduct,
  price: StripePrice,
  receipt?: string | null,
  provider?: PaymentProvider | null,
  discounts?: string | null,
  transactionDate?: string | null,
  errorCode?: string | null,
  errorMessage?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateTransactionsInput = {
  id: string,
  cognitoUserId?: string | null,
  transactionId?: string | null,
  originalTransactionId?: string | null,
  product?: StripeProductInput | null,
  price?: StripePriceInput | null,
  receipt?: string | null,
  provider?: PaymentProvider | null,
  discounts?: string | null,
  transactionDate?: string | null,
  errorCode?: string | null,
  errorMessage?: string | null,
  createdAt?: string | null,
};

export type DeleteTransactionsInput = {
  id: string,
};

export type CreateChildInput = {
  id?: string | null,
  name: string,
  age: string,
  commonNoun: string,
  readingLevel?: string | null,
  avatar?: string | null,
  userChildrenId?: string | null,
};

export type ModelChildConditionInput = {
  name?: ModelStringInput | null,
  age?: ModelStringInput | null,
  commonNoun?: ModelStringInput | null,
  readingLevel?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  and?: Array< ModelChildConditionInput | null > | null,
  or?: Array< ModelChildConditionInput | null > | null,
  not?: ModelChildConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userChildrenId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateChildInput = {
  id: string,
  name?: string | null,
  age?: string | null,
  commonNoun?: string | null,
  readingLevel?: string | null,
  avatar?: string | null,
  userChildrenId?: string | null,
};

export type DeleteChildInput = {
  id: string,
};

export type CreatePageInput = {
  id?: string | null,
  cognitoUserId: string,
  pageText: string,
  pageOptions: Array< string | null >,
  imageName?: string | null,
  minorImageName?: string | null,
  characterImageNames?: string | null,
  primaryImage?: string | null,
  protagonist?: string | null,
  storyType?: string | null,
  location?: string | null,
  readingLevel?: string | null,
  preceededBy?: string | null,
  followedBy?: Array< string | null > | null,
  followedByEnding?: Array< string | null > | null,
  isExposition?: boolean | null,
  isEnding?: boolean | null,
  isAnonymized?: boolean | null,
  status?: PageStatus | null,
  isAccepted?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  title?: string | null,
};

export enum PageStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
}


export type ModelPageConditionInput = {
  cognitoUserId?: ModelStringInput | null,
  pageText?: ModelStringInput | null,
  pageOptions?: ModelStringInput | null,
  imageName?: ModelStringInput | null,
  minorImageName?: ModelStringInput | null,
  characterImageNames?: ModelStringInput | null,
  primaryImage?: ModelStringInput | null,
  protagonist?: ModelStringInput | null,
  storyType?: ModelStringInput | null,
  location?: ModelStringInput | null,
  readingLevel?: ModelStringInput | null,
  preceededBy?: ModelStringInput | null,
  followedBy?: ModelStringInput | null,
  followedByEnding?: ModelStringInput | null,
  isExposition?: ModelBooleanInput | null,
  isEnding?: ModelBooleanInput | null,
  isAnonymized?: ModelBooleanInput | null,
  status?: ModelPageStatusInput | null,
  isAccepted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  title?: ModelStringInput | null,
  and?: Array< ModelPageConditionInput | null > | null,
  or?: Array< ModelPageConditionInput | null > | null,
  not?: ModelPageConditionInput | null,
};

export type ModelPageStatusInput = {
  eq?: PageStatus | null,
  ne?: PageStatus | null,
};

export type Page = {
  __typename: "Page",
  id: string,
  cognitoUserId: string,
  pageText: string,
  pageOptions: Array< string | null >,
  imageName?: string | null,
  minorImageName?: string | null,
  characterImageNames?: string | null,
  primaryImage?: string | null,
  protagonist?: string | null,
  storyType?: string | null,
  location?: string | null,
  readingLevel?: string | null,
  preceededBy?: string | null,
  followedBy?: Array< string | null > | null,
  followedByEnding?: Array< string | null > | null,
  isExposition?: boolean | null,
  isEnding?: boolean | null,
  isAnonymized?: boolean | null,
  status?: PageStatus | null,
  isAccepted?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  title?: string | null,
};

export type UpdatePageInput = {
  id: string,
  cognitoUserId?: string | null,
  pageText?: string | null,
  pageOptions?: Array< string | null > | null,
  imageName?: string | null,
  minorImageName?: string | null,
  characterImageNames?: string | null,
  primaryImage?: string | null,
  protagonist?: string | null,
  storyType?: string | null,
  location?: string | null,
  readingLevel?: string | null,
  preceededBy?: string | null,
  followedBy?: Array< string | null > | null,
  followedByEnding?: Array< string | null > | null,
  isExposition?: boolean | null,
  isEnding?: boolean | null,
  isAnonymized?: boolean | null,
  status?: PageStatus | null,
  isAccepted?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  title?: string | null,
};

export type DeletePageInput = {
  id: string,
};

export type CreateSharedPageInput = {
  id?: string | null,
  pageText: string,
  pageOptions?: Array< string | null > | null,
  imageName?: string | null,
  protagonist?: string | null,
  storyType?: string | null,
  location?: string | null,
  readingLevel?: string | null,
  preceededBy?: string | null,
  followedBy?: Array< string | null > | null,
  followedByEnding?: Array< string | null > | null,
  status?: PageStatus | null,
  isExposition?: boolean | null,
  isEnding?: boolean | null,
  isAnonymized?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  title?: string | null,
};

export type ModelSharedPageConditionInput = {
  pageText?: ModelStringInput | null,
  pageOptions?: ModelStringInput | null,
  imageName?: ModelStringInput | null,
  protagonist?: ModelStringInput | null,
  storyType?: ModelStringInput | null,
  location?: ModelStringInput | null,
  readingLevel?: ModelStringInput | null,
  preceededBy?: ModelStringInput | null,
  followedBy?: ModelStringInput | null,
  followedByEnding?: ModelStringInput | null,
  status?: ModelPageStatusInput | null,
  isExposition?: ModelBooleanInput | null,
  isEnding?: ModelBooleanInput | null,
  isAnonymized?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  title?: ModelStringInput | null,
  and?: Array< ModelSharedPageConditionInput | null > | null,
  or?: Array< ModelSharedPageConditionInput | null > | null,
  not?: ModelSharedPageConditionInput | null,
};

export type SharedPage = {
  __typename: "SharedPage",
  id: string,
  pageText: string,
  pageOptions?: Array< string | null > | null,
  imageName?: string | null,
  protagonist?: string | null,
  storyType?: string | null,
  location?: string | null,
  readingLevel?: string | null,
  preceededBy?: string | null,
  followedBy?: Array< string | null > | null,
  followedByEnding?: Array< string | null > | null,
  status?: PageStatus | null,
  isExposition?: boolean | null,
  isEnding?: boolean | null,
  isAnonymized?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  title?: string | null,
};

export type UpdateSharedPageInput = {
  id: string,
  pageText?: string | null,
  pageOptions?: Array< string | null > | null,
  imageName?: string | null,
  protagonist?: string | null,
  storyType?: string | null,
  location?: string | null,
  readingLevel?: string | null,
  preceededBy?: string | null,
  followedBy?: Array< string | null > | null,
  followedByEnding?: Array< string | null > | null,
  status?: PageStatus | null,
  isExposition?: boolean | null,
  isEnding?: boolean | null,
  isAnonymized?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  title?: string | null,
};

export type DeleteSharedPageInput = {
  id: string,
};

export type CreateStoryPagesInput = {
  id?: string | null,
  pageIDs?: Array< string | null > | null,
  storyType?: string | null,
  storyStatus?: string | null,
  rating?: number | null,
  childStoryPagesId: string,
  lastViewed?: string | null,
  expositionImageName?: string | null,
  title?: string | null,
  initiationMethod?: string | null,
};

export type ModelStoryPagesConditionInput = {
  pageIDs?: ModelStringInput | null,
  storyType?: ModelStringInput | null,
  storyStatus?: ModelStringInput | null,
  rating?: ModelIntInput | null,
  childStoryPagesId?: ModelIDInput | null,
  lastViewed?: ModelStringInput | null,
  expositionImageName?: ModelStringInput | null,
  title?: ModelStringInput | null,
  initiationMethod?: ModelStringInput | null,
  and?: Array< ModelStoryPagesConditionInput | null > | null,
  or?: Array< ModelStoryPagesConditionInput | null > | null,
  not?: ModelStoryPagesConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateStoryPagesInput = {
  id: string,
  pageIDs?: Array< string | null > | null,
  storyType?: string | null,
  storyStatus?: string | null,
  rating?: number | null,
  childStoryPagesId?: string | null,
  lastViewed?: string | null,
  expositionImageName?: string | null,
  title?: string | null,
  initiationMethod?: string | null,
};

export type DeleteStoryPagesInput = {
  id: string,
};

export type CreateDailyStoryInput = {
  id?: string | null,
  expositionPageID: string,
  storyType?: string | null,
  pageDepth?: string | null,
  status?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  approvedAt?: string | null,
  storyTitle?: string | null,
  displayStart?: string | null,
};

export type ModelDailyStoryConditionInput = {
  expositionPageID?: ModelStringInput | null,
  storyType?: ModelStringInput | null,
  pageDepth?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  approvedAt?: ModelStringInput | null,
  storyTitle?: ModelStringInput | null,
  displayStart?: ModelStringInput | null,
  and?: Array< ModelDailyStoryConditionInput | null > | null,
  or?: Array< ModelDailyStoryConditionInput | null > | null,
  not?: ModelDailyStoryConditionInput | null,
};

export type DailyStory = {
  __typename: "DailyStory",
  id: string,
  expositionPageID: string,
  storyType?: string | null,
  pageDepth?: string | null,
  status?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  approvedAt?: string | null,
  storyTitle?: string | null,
  displayStart?: string | null,
};

export type UpdateDailyStoryInput = {
  id: string,
  expositionPageID?: string | null,
  storyType?: string | null,
  pageDepth?: string | null,
  status?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  approvedAt?: string | null,
  storyTitle?: string | null,
  displayStart?: string | null,
};

export type DeleteDailyStoryInput = {
  id: string,
};

export type CreateImageSubjectsLogInput = {
  id?: string | null,
  subjects?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelImageSubjectsLogConditionInput = {
  subjects?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelImageSubjectsLogConditionInput | null > | null,
  or?: Array< ModelImageSubjectsLogConditionInput | null > | null,
  not?: ModelImageSubjectsLogConditionInput | null,
  owner?: ModelStringInput | null,
};

export type imageSubjectsLog = {
  __typename: "imageSubjectsLog",
  id: string,
  subjects?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type UpdateImageSubjectsLogInput = {
  id: string,
  subjects?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteImageSubjectsLogInput = {
  id: string,
};

export type CreateJobStatusInput = {
  id?: string | null,
  jobStatus: string,
  jobResult?: JobResultInput | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type JobResultInput = {
  success?: boolean | null,
  pageIDs?: Array< string | null > | null,
};

export type ModelJobStatusConditionInput = {
  jobStatus?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelJobStatusConditionInput | null > | null,
  or?: Array< ModelJobStatusConditionInput | null > | null,
  not?: ModelJobStatusConditionInput | null,
  cognitoUserId?: ModelStringInput | null,
};

export type JobStatus = {
  __typename: "JobStatus",
  id: string,
  jobStatus: string,
  jobResult?: JobResult | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  cognitoUserId?: string | null,
};

export type JobResult = {
  __typename: "JobResult",
  success?: boolean | null,
  pageIDs?: Array< string | null > | null,
};

export type UpdateJobStatusInput = {
  id: string,
  jobStatus?: string | null,
  jobResult?: JobResultInput | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteJobStatusInput = {
  id: string,
};

export type CreateImageInput = {
  id?: string | null,
  name: string,
  type: ImageType,
  traits?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  characterSet?: string | null,
  characterSetName?: string | null,
  location?: string | null,
};

export enum ImageType {
  BACKGROUND = "BACKGROUND",
  AVATAR = "AVATAR",
  MINOR_CHARACTER = "MINOR_CHARACTER",
}


export type ModelImageConditionInput = {
  name?: ModelStringInput | null,
  type?: ModelImageTypeInput | null,
  traits?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  characterSet?: ModelStringInput | null,
  characterSetName?: ModelStringInput | null,
  location?: ModelStringInput | null,
  and?: Array< ModelImageConditionInput | null > | null,
  or?: Array< ModelImageConditionInput | null > | null,
  not?: ModelImageConditionInput | null,
};

export type ModelImageTypeInput = {
  eq?: ImageType | null,
  ne?: ImageType | null,
};

export type Image = {
  __typename: "Image",
  id: string,
  name: string,
  type: ImageType,
  genres?: ModelImageGenreRelationConnection | null,
  expressions?: ModelExpressionConnection | null,
  traits?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  categories?: ModelImageCategoryRelationConnection | null,
  characterSet?: string | null,
  characterSetName?: string | null,
  location?: string | null,
};

export type ModelImageGenreRelationConnection = {
  __typename: "ModelImageGenreRelationConnection",
  items:  Array<ImageGenreRelation | null >,
  nextToken?: string | null,
};

export type ImageGenreRelation = {
  __typename: "ImageGenreRelation",
  id: string,
  imageId: string,
  genreId: string,
  image: Image,
  genre: Genre,
  createdAt: string,
  updatedAt: string,
};

export type Genre = {
  __typename: "Genre",
  id: string,
  name: string,
  images?: ModelImageGenreRelationConnection | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelExpressionConnection = {
  __typename: "ModelExpressionConnection",
  items:  Array<Expression | null >,
  nextToken?: string | null,
};

export type Expression = {
  __typename: "Expression",
  id: string,
  image: Image,
  name: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  imageExpressionsId?: string | null,
};

export type ModelImageCategoryRelationConnection = {
  __typename: "ModelImageCategoryRelationConnection",
  items:  Array<ImageCategoryRelation | null >,
  nextToken?: string | null,
};

export type ImageCategoryRelation = {
  __typename: "ImageCategoryRelation",
  id: string,
  imageId: string,
  imageCategoryId: string,
  image: Image,
  imageCategory: ImageCategory,
  createdAt: string,
  updatedAt: string,
};

export type ImageCategory = {
  __typename: "ImageCategory",
  id: string,
  category: string,
  images?: ModelImageCategoryRelationConnection | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateImageInput = {
  id: string,
  name?: string | null,
  type?: ImageType | null,
  traits?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  characterSet?: string | null,
  characterSetName?: string | null,
  location?: string | null,
};

export type DeleteImageInput = {
  id: string,
};

export type CreateExpressionInput = {
  id?: string | null,
  name: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  imageExpressionsId?: string | null,
};

export type ModelExpressionConditionInput = {
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelExpressionConditionInput | null > | null,
  or?: Array< ModelExpressionConditionInput | null > | null,
  not?: ModelExpressionConditionInput | null,
  imageExpressionsId?: ModelIDInput | null,
};

export type UpdateExpressionInput = {
  id: string,
  name?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  imageExpressionsId?: string | null,
};

export type DeleteExpressionInput = {
  id: string,
};

export type CreateGenreInput = {
  id?: string | null,
  name: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelGenreConditionInput = {
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGenreConditionInput | null > | null,
  or?: Array< ModelGenreConditionInput | null > | null,
  not?: ModelGenreConditionInput | null,
};

export type UpdateGenreInput = {
  id: string,
  name?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteGenreInput = {
  id: string,
};

export type CreateImageCategoryInput = {
  id?: string | null,
  category: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelImageCategoryConditionInput = {
  category?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelImageCategoryConditionInput | null > | null,
  or?: Array< ModelImageCategoryConditionInput | null > | null,
  not?: ModelImageCategoryConditionInput | null,
};

export type UpdateImageCategoryInput = {
  id: string,
  category?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteImageCategoryInput = {
  id: string,
};

export type CreateBookOrderInput = {
  id?: string | null,
  checkoutId: string,
  storyId: string,
  title: string,
  bookplateStyle?: string | null,
  bookplateName?: string | null,
  byline?: string | null,
  dedication?: string | null,
  lineItems: Array< OrderLineItemInput >,
  shippingName: string,
  shippingAddressLine1: string,
  shippingAddressLine2?: string | null,
  shippingCity: string,
  shippingState: string,
  shippingPostalCode: string,
  shippingCountry: string,
  status?: string | null,
  statusTimeline?: string | null,
  customerEmail: string,
  totalAmount: number,
  currency: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type OrderLineItemInput = {
  description: string,
  quantity: number,
  amount: number,
  priceId?: string | null,
};

export type ModelBookOrderConditionInput = {
  checkoutId?: ModelStringInput | null,
  storyId?: ModelStringInput | null,
  title?: ModelStringInput | null,
  bookplateStyle?: ModelStringInput | null,
  bookplateName?: ModelStringInput | null,
  byline?: ModelStringInput | null,
  dedication?: ModelStringInput | null,
  shippingName?: ModelStringInput | null,
  shippingAddressLine1?: ModelStringInput | null,
  shippingAddressLine2?: ModelStringInput | null,
  shippingCity?: ModelStringInput | null,
  shippingState?: ModelStringInput | null,
  shippingPostalCode?: ModelStringInput | null,
  shippingCountry?: ModelStringInput | null,
  status?: ModelStringInput | null,
  statusTimeline?: ModelStringInput | null,
  customerEmail?: ModelStringInput | null,
  totalAmount?: ModelFloatInput | null,
  currency?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBookOrderConditionInput | null > | null,
  or?: Array< ModelBookOrderConditionInput | null > | null,
  not?: ModelBookOrderConditionInput | null,
  owner?: ModelStringInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type BookOrder = {
  __typename: "BookOrder",
  id: string,
  checkoutId: string,
  storyId: string,
  title: string,
  bookplateStyle?: string | null,
  bookplateName?: string | null,
  byline?: string | null,
  dedication?: string | null,
  lineItems:  Array<OrderLineItem >,
  shippingName: string,
  shippingAddressLine1: string,
  shippingAddressLine2?: string | null,
  shippingCity: string,
  shippingState: string,
  shippingPostalCode: string,
  shippingCountry: string,
  status?: string | null,
  statusTimeline?: string | null,
  customerEmail: string,
  totalAmount: number,
  currency: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type OrderLineItem = {
  __typename: "OrderLineItem",
  description: string,
  quantity: number,
  amount: number,
  priceId?: string | null,
};

export type UpdateBookOrderInput = {
  id: string,
  checkoutId?: string | null,
  storyId?: string | null,
  title?: string | null,
  bookplateStyle?: string | null,
  bookplateName?: string | null,
  byline?: string | null,
  dedication?: string | null,
  lineItems?: Array< OrderLineItemInput > | null,
  shippingName?: string | null,
  shippingAddressLine1?: string | null,
  shippingAddressLine2?: string | null,
  shippingCity?: string | null,
  shippingState?: string | null,
  shippingPostalCode?: string | null,
  shippingCountry?: string | null,
  status?: string | null,
  statusTimeline?: string | null,
  customerEmail?: string | null,
  totalAmount?: number | null,
  currency?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteBookOrderInput = {
  id: string,
};

export type CreateCheckoutMetadataInput = {
  id?: string | null,
  stripeSessionId: string,
  customerEmail: string,
  purchaseType: string,
  lineItems: Array< OrderLineItemInput >,
  totalAmount: number,
  currency: string,
  discountTotal?: number | null,
  discounts?: Array< DiscountInput > | null,
  recipientName?: string | null,
  recipientEmail?: string | null,
  gifterName?: string | null,
  gifterEmail?: string | null,
  giftMessage?: string | null,
  deliveryDate?: string | null,
  giftCardStyle?: string | null,
  bookOrderId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DiscountInput = {
  amount: number,
  promoCode?: string | null,
  couponId?: string | null,
  couponName?: string | null,
};

export type ModelCheckoutMetadataConditionInput = {
  stripeSessionId?: ModelStringInput | null,
  customerEmail?: ModelStringInput | null,
  purchaseType?: ModelStringInput | null,
  totalAmount?: ModelFloatInput | null,
  currency?: ModelStringInput | null,
  discountTotal?: ModelFloatInput | null,
  recipientName?: ModelStringInput | null,
  recipientEmail?: ModelStringInput | null,
  gifterName?: ModelStringInput | null,
  gifterEmail?: ModelStringInput | null,
  giftMessage?: ModelStringInput | null,
  deliveryDate?: ModelStringInput | null,
  giftCardStyle?: ModelStringInput | null,
  bookOrderId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCheckoutMetadataConditionInput | null > | null,
  or?: Array< ModelCheckoutMetadataConditionInput | null > | null,
  not?: ModelCheckoutMetadataConditionInput | null,
  owner?: ModelStringInput | null,
};

export type CheckoutMetadata = {
  __typename: "CheckoutMetadata",
  id: string,
  stripeSessionId: string,
  customerEmail: string,
  purchaseType: string,
  lineItems:  Array<OrderLineItem >,
  totalAmount: number,
  currency: string,
  discountTotal?: number | null,
  discounts?:  Array<Discount > | null,
  recipientName?: string | null,
  recipientEmail?: string | null,
  gifterName?: string | null,
  gifterEmail?: string | null,
  giftMessage?: string | null,
  deliveryDate?: string | null,
  giftCardStyle?: string | null,
  bookOrderId?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type Discount = {
  __typename: "Discount",
  amount: number,
  promoCode?: string | null,
  couponId?: string | null,
  couponName?: string | null,
};

export type UpdateCheckoutMetadataInput = {
  id: string,
  stripeSessionId?: string | null,
  customerEmail?: string | null,
  purchaseType?: string | null,
  lineItems?: Array< OrderLineItemInput > | null,
  totalAmount?: number | null,
  currency?: string | null,
  discountTotal?: number | null,
  discounts?: Array< DiscountInput > | null,
  recipientName?: string | null,
  recipientEmail?: string | null,
  gifterName?: string | null,
  gifterEmail?: string | null,
  giftMessage?: string | null,
  deliveryDate?: string | null,
  giftCardStyle?: string | null,
  bookOrderId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteCheckoutMetadataInput = {
  id: string,
};

export type CreateImageGenreRelationInput = {
  id?: string | null,
  imageId: string,
  genreId: string,
};

export type ModelImageGenreRelationConditionInput = {
  imageId?: ModelIDInput | null,
  genreId?: ModelIDInput | null,
  and?: Array< ModelImageGenreRelationConditionInput | null > | null,
  or?: Array< ModelImageGenreRelationConditionInput | null > | null,
  not?: ModelImageGenreRelationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateImageGenreRelationInput = {
  id: string,
  imageId?: string | null,
  genreId?: string | null,
};

export type DeleteImageGenreRelationInput = {
  id: string,
};

export type CreateImageCategoryRelationInput = {
  id?: string | null,
  imageId: string,
  imageCategoryId: string,
};

export type ModelImageCategoryRelationConditionInput = {
  imageId?: ModelIDInput | null,
  imageCategoryId?: ModelIDInput | null,
  and?: Array< ModelImageCategoryRelationConditionInput | null > | null,
  or?: Array< ModelImageCategoryRelationConditionInput | null > | null,
  not?: ModelImageCategoryRelationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateImageCategoryRelationInput = {
  id: string,
  imageId?: string | null,
  imageCategoryId?: string | null,
};

export type DeleteImageCategoryRelationInput = {
  id: string,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  cognitoUserId?: ModelStringInput | null,
  email?: ModelStringInput | null,
  activeChildId?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  lastInteraction?: ModelStringInput | null,
  emailUpdatesEnabled?: ModelBooleanInput | null,
  emailPromotionsEnabled?: ModelBooleanInput | null,
  emailStoriesFridayEnabled?: ModelBooleanInput | null,
  emailStoriesDailyEnabled?: ModelBooleanInput | null,
  trialStoriesCount?: ModelIntInput | null,
  bookCredits?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  userNotificationSettingsId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelNotificationSettingsFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  notificationsEnabled?: ModelBooleanInput | null,
  promotionsEnabled?: ModelBooleanInput | null,
  updatesEnabled?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNotificationSettingsFilterInput | null > | null,
  or?: Array< ModelNotificationSettingsFilterInput | null > | null,
  not?: ModelNotificationSettingsFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelNotificationSettingsConnection = {
  __typename: "ModelNotificationSettingsConnection",
  items:  Array<NotificationSettings | null >,
  nextToken?: string | null,
};

export type ModelDeviceTokenFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  token?: ModelStringInput | null,
  platform?: ModelStringInput | null,
  endpointArn?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDeviceTokenFilterInput | null > | null,
  or?: Array< ModelDeviceTokenFilterInput | null > | null,
  not?: ModelDeviceTokenFilterInput | null,
  userDeviceTokensId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelWaitlistFilterInput = {
  cognitoUserId?: ModelStringInput | null,
  email?: ModelStringInput | null,
  accessGranted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  and?: Array< ModelWaitlistFilterInput | null > | null,
  or?: Array< ModelWaitlistFilterInput | null > | null,
  not?: ModelWaitlistFilterInput | null,
};

export type ModelWaitlistConnection = {
  __typename: "ModelWaitlistConnection",
  items:  Array<Waitlist | null >,
  nextToken?: string | null,
};

export type ModelGiftcardFilterInput = {
  id?: ModelIDInput | null,
  giftcode?: ModelStringInput | null,
  recipientEmail?: ModelStringInput | null,
  subscriptionProduct?: ModelStringInput | null,
  storyCredits?: ModelIntInput | null,
  bookCredits?: ModelIntInput | null,
  giftingEmail?: ModelStringInput | null,
  redeemed?: ModelBooleanInput | null,
  redeemedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGiftcardFilterInput | null > | null,
  or?: Array< ModelGiftcardFilterInput | null > | null,
  not?: ModelGiftcardFilterInput | null,
};

export type ModelGiftcardConnection = {
  __typename: "ModelGiftcardConnection",
  items:  Array<Giftcard | null >,
  nextToken?: string | null,
};

export type ModelMetadataFilterInput = {
  id?: ModelIDInput | null,
  isUnderMaintenance?: ModelBooleanInput | null,
  startingTrialStoriesCount?: ModelIntInput | null,
  waitlistEnabled?: ModelBooleanInput | null,
  minimumAppVersion?: ModelStringInput | null,
  promotionString?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMetadataFilterInput | null > | null,
  or?: Array< ModelMetadataFilterInput | null > | null,
  not?: ModelMetadataFilterInput | null,
};

export type ModelMetadataConnection = {
  __typename: "ModelMetadataConnection",
  items:  Array<Metadata | null >,
  nextToken?: string | null,
};

export type ModelUserPaymentFilterInput = {
  stripeSubscriptionId?: ModelStringInput | null,
  cognitoUserId?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  currentTransactionId?: ModelStringInput | null,
  status?: ModelSubscriptionStatusInput | null,
  currentPeriodStart?: ModelStringInput | null,
  currentPeriodEnd?: ModelStringInput | null,
  cancelAtPeriodEnd?: ModelBooleanInput | null,
  provider?: ModelPaymentProviderInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  and?: Array< ModelUserPaymentFilterInput | null > | null,
  or?: Array< ModelUserPaymentFilterInput | null > | null,
  not?: ModelUserPaymentFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUserPaymentConnection = {
  __typename: "ModelUserPaymentConnection",
  items:  Array<UserPayment | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelTransactionsFilterInput = {
  id?: ModelIDInput | null,
  cognitoUserId?: ModelStringInput | null,
  transactionId?: ModelStringInput | null,
  originalTransactionId?: ModelStringInput | null,
  receipt?: ModelStringInput | null,
  provider?: ModelPaymentProviderInput | null,
  discounts?: ModelStringInput | null,
  transactionDate?: ModelStringInput | null,
  errorCode?: ModelStringInput | null,
  errorMessage?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTransactionsFilterInput | null > | null,
  or?: Array< ModelTransactionsFilterInput | null > | null,
  not?: ModelTransactionsFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelTransactionsConnection = {
  __typename: "ModelTransactionsConnection",
  items:  Array<Transactions | null >,
  nextToken?: string | null,
};

export type ModelChildFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  age?: ModelStringInput | null,
  commonNoun?: ModelStringInput | null,
  readingLevel?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChildFilterInput | null > | null,
  or?: Array< ModelChildFilterInput | null > | null,
  not?: ModelChildFilterInput | null,
  userChildrenId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelPageFilterInput = {
  id?: ModelIDInput | null,
  cognitoUserId?: ModelStringInput | null,
  pageText?: ModelStringInput | null,
  pageOptions?: ModelStringInput | null,
  imageName?: ModelStringInput | null,
  minorImageName?: ModelStringInput | null,
  characterImageNames?: ModelStringInput | null,
  primaryImage?: ModelStringInput | null,
  protagonist?: ModelStringInput | null,
  storyType?: ModelStringInput | null,
  location?: ModelStringInput | null,
  readingLevel?: ModelStringInput | null,
  preceededBy?: ModelStringInput | null,
  followedBy?: ModelStringInput | null,
  followedByEnding?: ModelStringInput | null,
  isExposition?: ModelBooleanInput | null,
  isEnding?: ModelBooleanInput | null,
  isAnonymized?: ModelBooleanInput | null,
  status?: ModelPageStatusInput | null,
  isAccepted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  title?: ModelStringInput | null,
  and?: Array< ModelPageFilterInput | null > | null,
  or?: Array< ModelPageFilterInput | null > | null,
  not?: ModelPageFilterInput | null,
};

export type ModelPageConnection = {
  __typename: "ModelPageConnection",
  items:  Array<Page | null >,
  nextToken?: string | null,
};

export type ModelSharedPageFilterInput = {
  id?: ModelIDInput | null,
  pageText?: ModelStringInput | null,
  pageOptions?: ModelStringInput | null,
  imageName?: ModelStringInput | null,
  protagonist?: ModelStringInput | null,
  storyType?: ModelStringInput | null,
  location?: ModelStringInput | null,
  readingLevel?: ModelStringInput | null,
  preceededBy?: ModelStringInput | null,
  followedBy?: ModelStringInput | null,
  followedByEnding?: ModelStringInput | null,
  status?: ModelPageStatusInput | null,
  isExposition?: ModelBooleanInput | null,
  isEnding?: ModelBooleanInput | null,
  isAnonymized?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  title?: ModelStringInput | null,
  and?: Array< ModelSharedPageFilterInput | null > | null,
  or?: Array< ModelSharedPageFilterInput | null > | null,
  not?: ModelSharedPageFilterInput | null,
};

export type ModelSharedPageConnection = {
  __typename: "ModelSharedPageConnection",
  items:  Array<SharedPage | null >,
  nextToken?: string | null,
};

export type ModelStoryPagesFilterInput = {
  id?: ModelIDInput | null,
  pageIDs?: ModelStringInput | null,
  storyType?: ModelStringInput | null,
  storyStatus?: ModelStringInput | null,
  rating?: ModelIntInput | null,
  childStoryPagesId?: ModelIDInput | null,
  lastViewed?: ModelStringInput | null,
  expositionImageName?: ModelStringInput | null,
  title?: ModelStringInput | null,
  initiationMethod?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelStoryPagesFilterInput | null > | null,
  or?: Array< ModelStoryPagesFilterInput | null > | null,
  not?: ModelStoryPagesFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelDailyStoryFilterInput = {
  id?: ModelIDInput | null,
  expositionPageID?: ModelStringInput | null,
  storyType?: ModelStringInput | null,
  pageDepth?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  approvedAt?: ModelStringInput | null,
  storyTitle?: ModelStringInput | null,
  displayStart?: ModelStringInput | null,
  and?: Array< ModelDailyStoryFilterInput | null > | null,
  or?: Array< ModelDailyStoryFilterInput | null > | null,
  not?: ModelDailyStoryFilterInput | null,
};

export type ModelDailyStoryConnection = {
  __typename: "ModelDailyStoryConnection",
  items:  Array<DailyStory | null >,
  nextToken?: string | null,
};

export type ModelImageSubjectsLogFilterInput = {
  id?: ModelIDInput | null,
  subjects?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelImageSubjectsLogFilterInput | null > | null,
  or?: Array< ModelImageSubjectsLogFilterInput | null > | null,
  not?: ModelImageSubjectsLogFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelImageSubjectsLogConnection = {
  __typename: "ModelImageSubjectsLogConnection",
  items:  Array<imageSubjectsLog | null >,
  nextToken?: string | null,
};

export type ModelJobStatusFilterInput = {
  id?: ModelIDInput | null,
  jobStatus?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelJobStatusFilterInput | null > | null,
  or?: Array< ModelJobStatusFilterInput | null > | null,
  not?: ModelJobStatusFilterInput | null,
  cognitoUserId?: ModelStringInput | null,
};

export type ModelJobStatusConnection = {
  __typename: "ModelJobStatusConnection",
  items:  Array<JobStatus | null >,
  nextToken?: string | null,
};

export type ModelImageFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  type?: ModelImageTypeInput | null,
  traits?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  characterSet?: ModelStringInput | null,
  characterSetName?: ModelStringInput | null,
  location?: ModelStringInput | null,
  and?: Array< ModelImageFilterInput | null > | null,
  or?: Array< ModelImageFilterInput | null > | null,
  not?: ModelImageFilterInput | null,
};

export type ModelImageConnection = {
  __typename: "ModelImageConnection",
  items:  Array<Image | null >,
  nextToken?: string | null,
};

export type ModelExpressionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelExpressionFilterInput | null > | null,
  or?: Array< ModelExpressionFilterInput | null > | null,
  not?: ModelExpressionFilterInput | null,
  imageExpressionsId?: ModelIDInput | null,
};

export type ModelGenreFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGenreFilterInput | null > | null,
  or?: Array< ModelGenreFilterInput | null > | null,
  not?: ModelGenreFilterInput | null,
};

export type ModelGenreConnection = {
  __typename: "ModelGenreConnection",
  items:  Array<Genre | null >,
  nextToken?: string | null,
};

export type ModelImageCategoryFilterInput = {
  id?: ModelIDInput | null,
  category?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelImageCategoryFilterInput | null > | null,
  or?: Array< ModelImageCategoryFilterInput | null > | null,
  not?: ModelImageCategoryFilterInput | null,
};

export type ModelImageCategoryConnection = {
  __typename: "ModelImageCategoryConnection",
  items:  Array<ImageCategory | null >,
  nextToken?: string | null,
};

export type ModelBookOrderFilterInput = {
  id?: ModelIDInput | null,
  checkoutId?: ModelStringInput | null,
  storyId?: ModelStringInput | null,
  title?: ModelStringInput | null,
  bookplateStyle?: ModelStringInput | null,
  bookplateName?: ModelStringInput | null,
  byline?: ModelStringInput | null,
  dedication?: ModelStringInput | null,
  shippingName?: ModelStringInput | null,
  shippingAddressLine1?: ModelStringInput | null,
  shippingAddressLine2?: ModelStringInput | null,
  shippingCity?: ModelStringInput | null,
  shippingState?: ModelStringInput | null,
  shippingPostalCode?: ModelStringInput | null,
  shippingCountry?: ModelStringInput | null,
  status?: ModelStringInput | null,
  statusTimeline?: ModelStringInput | null,
  customerEmail?: ModelStringInput | null,
  totalAmount?: ModelFloatInput | null,
  currency?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBookOrderFilterInput | null > | null,
  or?: Array< ModelBookOrderFilterInput | null > | null,
  not?: ModelBookOrderFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelBookOrderConnection = {
  __typename: "ModelBookOrderConnection",
  items:  Array<BookOrder | null >,
  nextToken?: string | null,
};

export type ModelCheckoutMetadataFilterInput = {
  id?: ModelIDInput | null,
  stripeSessionId?: ModelStringInput | null,
  customerEmail?: ModelStringInput | null,
  purchaseType?: ModelStringInput | null,
  totalAmount?: ModelFloatInput | null,
  currency?: ModelStringInput | null,
  discountTotal?: ModelFloatInput | null,
  recipientName?: ModelStringInput | null,
  recipientEmail?: ModelStringInput | null,
  gifterName?: ModelStringInput | null,
  gifterEmail?: ModelStringInput | null,
  giftMessage?: ModelStringInput | null,
  deliveryDate?: ModelStringInput | null,
  giftCardStyle?: ModelStringInput | null,
  bookOrderId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCheckoutMetadataFilterInput | null > | null,
  or?: Array< ModelCheckoutMetadataFilterInput | null > | null,
  not?: ModelCheckoutMetadataFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelCheckoutMetadataConnection = {
  __typename: "ModelCheckoutMetadataConnection",
  items:  Array<CheckoutMetadata | null >,
  nextToken?: string | null,
};

export type ModelImageGenreRelationFilterInput = {
  id?: ModelIDInput | null,
  imageId?: ModelIDInput | null,
  genreId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelImageGenreRelationFilterInput | null > | null,
  or?: Array< ModelImageGenreRelationFilterInput | null > | null,
  not?: ModelImageGenreRelationFilterInput | null,
};

export type ModelImageCategoryRelationFilterInput = {
  id?: ModelIDInput | null,
  imageId?: ModelIDInput | null,
  imageCategoryId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelImageCategoryRelationFilterInput | null > | null,
  or?: Array< ModelImageCategoryRelationFilterInput | null > | null,
  not?: ModelImageCategoryRelationFilterInput | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  cognitoUserId?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  activeChildId?: ModelSubscriptionStringInput | null,
  settings?: ModelSubscriptionStringInput | null,
  lastInteraction?: ModelSubscriptionStringInput | null,
  emailUpdatesEnabled?: ModelSubscriptionBooleanInput | null,
  emailPromotionsEnabled?: ModelSubscriptionBooleanInput | null,
  emailStoriesFridayEnabled?: ModelSubscriptionBooleanInput | null,
  emailStoriesDailyEnabled?: ModelSubscriptionBooleanInput | null,
  trialStoriesCount?: ModelSubscriptionIntInput | null,
  bookCredits?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  userChildrenId?: ModelSubscriptionIDInput | null,
  userDeviceTokensId?: ModelSubscriptionIDInput | null,
  userNotificationSettingsId?: ModelSubscriptionIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionNotificationSettingsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  notificationsEnabled?: ModelSubscriptionBooleanInput | null,
  promotionsEnabled?: ModelSubscriptionBooleanInput | null,
  updatesEnabled?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNotificationSettingsFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotificationSettingsFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionDeviceTokenFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  token?: ModelSubscriptionStringInput | null,
  platform?: ModelSubscriptionStringInput | null,
  endpointArn?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDeviceTokenFilterInput | null > | null,
  or?: Array< ModelSubscriptionDeviceTokenFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionWaitlistFilterInput = {
  email?: ModelSubscriptionStringInput | null,
  accessGranted?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionWaitlistFilterInput | null > | null,
  or?: Array< ModelSubscriptionWaitlistFilterInput | null > | null,
  cognitoUserId?: ModelStringInput | null,
};

export type ModelSubscriptionGiftcardFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  giftcode?: ModelSubscriptionStringInput | null,
  subscriptionProduct?: ModelSubscriptionStringInput | null,
  storyCredits?: ModelSubscriptionIntInput | null,
  bookCredits?: ModelSubscriptionIntInput | null,
  giftingEmail?: ModelSubscriptionStringInput | null,
  redeemed?: ModelSubscriptionBooleanInput | null,
  redeemedAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGiftcardFilterInput | null > | null,
  or?: Array< ModelSubscriptionGiftcardFilterInput | null > | null,
  recipientEmail?: ModelStringInput | null,
};

export type ModelSubscriptionMetadataFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  isUnderMaintenance?: ModelSubscriptionBooleanInput | null,
  startingTrialStoriesCount?: ModelSubscriptionIntInput | null,
  waitlistEnabled?: ModelSubscriptionBooleanInput | null,
  minimumAppVersion?: ModelSubscriptionStringInput | null,
  promotionString?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMetadataFilterInput | null > | null,
  or?: Array< ModelSubscriptionMetadataFilterInput | null > | null,
};

export type ModelSubscriptionUserPaymentFilterInput = {
  stripeSubscriptionId?: ModelSubscriptionStringInput | null,
  cognitoUserId?: ModelSubscriptionStringInput | null,
  stripeCustomerId?: ModelSubscriptionStringInput | null,
  currentTransactionId?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  currentPeriodStart?: ModelSubscriptionStringInput | null,
  currentPeriodEnd?: ModelSubscriptionStringInput | null,
  cancelAtPeriodEnd?: ModelSubscriptionBooleanInput | null,
  provider?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionUserPaymentFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserPaymentFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionTransactionsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  cognitoUserId?: ModelSubscriptionStringInput | null,
  transactionId?: ModelSubscriptionStringInput | null,
  originalTransactionId?: ModelSubscriptionStringInput | null,
  receipt?: ModelSubscriptionStringInput | null,
  provider?: ModelSubscriptionStringInput | null,
  discounts?: ModelSubscriptionStringInput | null,
  transactionDate?: ModelSubscriptionStringInput | null,
  errorCode?: ModelSubscriptionStringInput | null,
  errorMessage?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTransactionsFilterInput | null > | null,
  or?: Array< ModelSubscriptionTransactionsFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionChildFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  age?: ModelSubscriptionStringInput | null,
  commonNoun?: ModelSubscriptionStringInput | null,
  readingLevel?: ModelSubscriptionStringInput | null,
  avatar?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChildFilterInput | null > | null,
  or?: Array< ModelSubscriptionChildFilterInput | null > | null,
  childStoryPagesId?: ModelSubscriptionIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionPageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  pageText?: ModelSubscriptionStringInput | null,
  pageOptions?: ModelSubscriptionStringInput | null,
  imageName?: ModelSubscriptionStringInput | null,
  minorImageName?: ModelSubscriptionStringInput | null,
  characterImageNames?: ModelSubscriptionStringInput | null,
  primaryImage?: ModelSubscriptionStringInput | null,
  protagonist?: ModelSubscriptionStringInput | null,
  storyType?: ModelSubscriptionStringInput | null,
  location?: ModelSubscriptionStringInput | null,
  readingLevel?: ModelSubscriptionStringInput | null,
  preceededBy?: ModelSubscriptionStringInput | null,
  followedBy?: ModelSubscriptionStringInput | null,
  followedByEnding?: ModelSubscriptionStringInput | null,
  isExposition?: ModelSubscriptionBooleanInput | null,
  isEnding?: ModelSubscriptionBooleanInput | null,
  isAnonymized?: ModelSubscriptionBooleanInput | null,
  status?: ModelSubscriptionStringInput | null,
  isAccepted?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPageFilterInput | null > | null,
  or?: Array< ModelSubscriptionPageFilterInput | null > | null,
  cognitoUserId?: ModelStringInput | null,
};

export type ModelSubscriptionSharedPageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  pageText?: ModelSubscriptionStringInput | null,
  pageOptions?: ModelSubscriptionStringInput | null,
  imageName?: ModelSubscriptionStringInput | null,
  protagonist?: ModelSubscriptionStringInput | null,
  storyType?: ModelSubscriptionStringInput | null,
  location?: ModelSubscriptionStringInput | null,
  readingLevel?: ModelSubscriptionStringInput | null,
  preceededBy?: ModelSubscriptionStringInput | null,
  followedBy?: ModelSubscriptionStringInput | null,
  followedByEnding?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  isExposition?: ModelSubscriptionBooleanInput | null,
  isEnding?: ModelSubscriptionBooleanInput | null,
  isAnonymized?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSharedPageFilterInput | null > | null,
  or?: Array< ModelSubscriptionSharedPageFilterInput | null > | null,
};

export type ModelSubscriptionStoryPagesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  pageIDs?: ModelSubscriptionStringInput | null,
  storyType?: ModelSubscriptionStringInput | null,
  storyStatus?: ModelSubscriptionStringInput | null,
  rating?: ModelSubscriptionIntInput | null,
  childStoryPagesId?: ModelSubscriptionIDInput | null,
  lastViewed?: ModelSubscriptionStringInput | null,
  expositionImageName?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  initiationMethod?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionStoryPagesFilterInput | null > | null,
  or?: Array< ModelSubscriptionStoryPagesFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionDailyStoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  expositionPageID?: ModelSubscriptionStringInput | null,
  storyType?: ModelSubscriptionStringInput | null,
  pageDepth?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  approvedAt?: ModelSubscriptionStringInput | null,
  storyTitle?: ModelSubscriptionStringInput | null,
  displayStart?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDailyStoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionDailyStoryFilterInput | null > | null,
};

export type ModelSubscriptionImageSubjectsLogFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  subjects?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionImageSubjectsLogFilterInput | null > | null,
  or?: Array< ModelSubscriptionImageSubjectsLogFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionJobStatusFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  jobStatus?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionJobStatusFilterInput | null > | null,
  or?: Array< ModelSubscriptionJobStatusFilterInput | null > | null,
  cognitoUserId?: ModelStringInput | null,
};

export type ModelSubscriptionImageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  traits?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  characterSet?: ModelSubscriptionStringInput | null,
  characterSetName?: ModelSubscriptionStringInput | null,
  location?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionImageFilterInput | null > | null,
  or?: Array< ModelSubscriptionImageFilterInput | null > | null,
  imageExpressionsId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionExpressionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionExpressionFilterInput | null > | null,
  or?: Array< ModelSubscriptionExpressionFilterInput | null > | null,
};

export type ModelSubscriptionGenreFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGenreFilterInput | null > | null,
  or?: Array< ModelSubscriptionGenreFilterInput | null > | null,
};

export type ModelSubscriptionImageCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  category?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionImageCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionImageCategoryFilterInput | null > | null,
};

export type ModelSubscriptionBookOrderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  checkoutId?: ModelSubscriptionStringInput | null,
  storyId?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  bookplateStyle?: ModelSubscriptionStringInput | null,
  bookplateName?: ModelSubscriptionStringInput | null,
  byline?: ModelSubscriptionStringInput | null,
  dedication?: ModelSubscriptionStringInput | null,
  shippingName?: ModelSubscriptionStringInput | null,
  shippingAddressLine1?: ModelSubscriptionStringInput | null,
  shippingAddressLine2?: ModelSubscriptionStringInput | null,
  shippingCity?: ModelSubscriptionStringInput | null,
  shippingState?: ModelSubscriptionStringInput | null,
  shippingPostalCode?: ModelSubscriptionStringInput | null,
  shippingCountry?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  statusTimeline?: ModelSubscriptionStringInput | null,
  customerEmail?: ModelSubscriptionStringInput | null,
  totalAmount?: ModelSubscriptionFloatInput | null,
  currency?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBookOrderFilterInput | null > | null,
  or?: Array< ModelSubscriptionBookOrderFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionCheckoutMetadataFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  stripeSessionId?: ModelSubscriptionStringInput | null,
  customerEmail?: ModelSubscriptionStringInput | null,
  purchaseType?: ModelSubscriptionStringInput | null,
  totalAmount?: ModelSubscriptionFloatInput | null,
  currency?: ModelSubscriptionStringInput | null,
  discountTotal?: ModelSubscriptionFloatInput | null,
  recipientName?: ModelSubscriptionStringInput | null,
  recipientEmail?: ModelSubscriptionStringInput | null,
  gifterName?: ModelSubscriptionStringInput | null,
  gifterEmail?: ModelSubscriptionStringInput | null,
  giftMessage?: ModelSubscriptionStringInput | null,
  deliveryDate?: ModelSubscriptionStringInput | null,
  giftCardStyle?: ModelSubscriptionStringInput | null,
  bookOrderId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCheckoutMetadataFilterInput | null > | null,
  or?: Array< ModelSubscriptionCheckoutMetadataFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionImageGenreRelationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  imageId?: ModelSubscriptionIDInput | null,
  genreId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionImageGenreRelationFilterInput | null > | null,
  or?: Array< ModelSubscriptionImageGenreRelationFilterInput | null > | null,
};

export type ModelSubscriptionImageCategoryRelationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  imageId?: ModelSubscriptionIDInput | null,
  imageCategoryId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionImageCategoryRelationFilterInput | null > | null,
  or?: Array< ModelSubscriptionImageCategoryRelationFilterInput | null > | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    cognitoUserId: string,
    email: string,
    children?:  {
      __typename: "ModelChildConnection",
      items:  Array< {
        __typename: "Child",
        id: string,
        name: string,
        age: string,
        commonNoun: string,
        readingLevel?: string | null,
        avatar?: string | null,
        storyPages?:  {
          __typename: "ModelStoryPagesConnection",
          items:  Array< {
            __typename: "StoryPages",
            id: string,
            pageIDs?: Array< string | null > | null,
            storyType?: string | null,
            storyStatus?: string | null,
            rating?: number | null,
            childStoryPagesId: string,
            lastViewed?: string | null,
            expositionImageName?: string | null,
            title?: string | null,
            initiationMethod?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userChildrenId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activeChildId?: string | null,
    settings?: string | null,
    lastInteraction?: string | null,
    emailUpdatesEnabled?: boolean | null,
    emailPromotionsEnabled?: boolean | null,
    emailStoriesFridayEnabled?: boolean | null,
    emailStoriesDailyEnabled?: boolean | null,
    trialStoriesCount?: number | null,
    bookCredits?: number | null,
    notificationSettings?:  {
      __typename: "NotificationSettings",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUserId: string,
        email: string,
        children?:  {
          __typename: "ModelChildConnection",
          items:  Array< {
            __typename: "Child",
            id: string,
            name: string,
            age: string,
            commonNoun: string,
            readingLevel?: string | null,
            avatar?: string | null,
            storyPages?:  {
              __typename: "ModelStoryPagesConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userChildrenId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        activeChildId?: string | null,
        settings?: string | null,
        lastInteraction?: string | null,
        emailUpdatesEnabled?: boolean | null,
        emailPromotionsEnabled?: boolean | null,
        emailStoriesFridayEnabled?: boolean | null,
        emailStoriesDailyEnabled?: boolean | null,
        trialStoriesCount?: number | null,
        bookCredits?: number | null,
        notificationSettings?:  {
          __typename: "NotificationSettings",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          notificationsEnabled: boolean,
          storyReminders:  {
            __typename: "StoryReminderSettings",
            enabled: boolean,
            frequency?: string | null,
          },
          promotionsEnabled?: boolean | null,
          updatesEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        deviceTokens?:  {
          __typename: "ModelDeviceTokenConnection",
          items:  Array< {
            __typename: "DeviceToken",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            token: string,
            platform: string,
            endpointArn?: string | null,
            createdAt: string,
            updatedAt: string,
            userDeviceTokensId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userNotificationSettingsId?: string | null,
        owner?: string | null,
      } | null,
      userId: string,
      notificationsEnabled: boolean,
      storyReminders:  {
        __typename: "StoryReminderSettings",
        enabled: boolean,
        frequency?: string | null,
      },
      promotionsEnabled?: boolean | null,
      updatesEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    deviceTokens?:  {
      __typename: "ModelDeviceTokenConnection",
      items:  Array< {
        __typename: "DeviceToken",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        token: string,
        platform: string,
        endpointArn?: string | null,
        createdAt: string,
        updatedAt: string,
        userDeviceTokensId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userNotificationSettingsId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    cognitoUserId: string,
    email: string,
    children?:  {
      __typename: "ModelChildConnection",
      items:  Array< {
        __typename: "Child",
        id: string,
        name: string,
        age: string,
        commonNoun: string,
        readingLevel?: string | null,
        avatar?: string | null,
        storyPages?:  {
          __typename: "ModelStoryPagesConnection",
          items:  Array< {
            __typename: "StoryPages",
            id: string,
            pageIDs?: Array< string | null > | null,
            storyType?: string | null,
            storyStatus?: string | null,
            rating?: number | null,
            childStoryPagesId: string,
            lastViewed?: string | null,
            expositionImageName?: string | null,
            title?: string | null,
            initiationMethod?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userChildrenId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activeChildId?: string | null,
    settings?: string | null,
    lastInteraction?: string | null,
    emailUpdatesEnabled?: boolean | null,
    emailPromotionsEnabled?: boolean | null,
    emailStoriesFridayEnabled?: boolean | null,
    emailStoriesDailyEnabled?: boolean | null,
    trialStoriesCount?: number | null,
    bookCredits?: number | null,
    notificationSettings?:  {
      __typename: "NotificationSettings",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUserId: string,
        email: string,
        children?:  {
          __typename: "ModelChildConnection",
          items:  Array< {
            __typename: "Child",
            id: string,
            name: string,
            age: string,
            commonNoun: string,
            readingLevel?: string | null,
            avatar?: string | null,
            storyPages?:  {
              __typename: "ModelStoryPagesConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userChildrenId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        activeChildId?: string | null,
        settings?: string | null,
        lastInteraction?: string | null,
        emailUpdatesEnabled?: boolean | null,
        emailPromotionsEnabled?: boolean | null,
        emailStoriesFridayEnabled?: boolean | null,
        emailStoriesDailyEnabled?: boolean | null,
        trialStoriesCount?: number | null,
        bookCredits?: number | null,
        notificationSettings?:  {
          __typename: "NotificationSettings",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          notificationsEnabled: boolean,
          storyReminders:  {
            __typename: "StoryReminderSettings",
            enabled: boolean,
            frequency?: string | null,
          },
          promotionsEnabled?: boolean | null,
          updatesEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        deviceTokens?:  {
          __typename: "ModelDeviceTokenConnection",
          items:  Array< {
            __typename: "DeviceToken",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            token: string,
            platform: string,
            endpointArn?: string | null,
            createdAt: string,
            updatedAt: string,
            userDeviceTokensId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userNotificationSettingsId?: string | null,
        owner?: string | null,
      } | null,
      userId: string,
      notificationsEnabled: boolean,
      storyReminders:  {
        __typename: "StoryReminderSettings",
        enabled: boolean,
        frequency?: string | null,
      },
      promotionsEnabled?: boolean | null,
      updatesEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    deviceTokens?:  {
      __typename: "ModelDeviceTokenConnection",
      items:  Array< {
        __typename: "DeviceToken",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        token: string,
        platform: string,
        endpointArn?: string | null,
        createdAt: string,
        updatedAt: string,
        userDeviceTokensId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userNotificationSettingsId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    cognitoUserId: string,
    email: string,
    children?:  {
      __typename: "ModelChildConnection",
      items:  Array< {
        __typename: "Child",
        id: string,
        name: string,
        age: string,
        commonNoun: string,
        readingLevel?: string | null,
        avatar?: string | null,
        storyPages?:  {
          __typename: "ModelStoryPagesConnection",
          items:  Array< {
            __typename: "StoryPages",
            id: string,
            pageIDs?: Array< string | null > | null,
            storyType?: string | null,
            storyStatus?: string | null,
            rating?: number | null,
            childStoryPagesId: string,
            lastViewed?: string | null,
            expositionImageName?: string | null,
            title?: string | null,
            initiationMethod?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userChildrenId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activeChildId?: string | null,
    settings?: string | null,
    lastInteraction?: string | null,
    emailUpdatesEnabled?: boolean | null,
    emailPromotionsEnabled?: boolean | null,
    emailStoriesFridayEnabled?: boolean | null,
    emailStoriesDailyEnabled?: boolean | null,
    trialStoriesCount?: number | null,
    bookCredits?: number | null,
    notificationSettings?:  {
      __typename: "NotificationSettings",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUserId: string,
        email: string,
        children?:  {
          __typename: "ModelChildConnection",
          items:  Array< {
            __typename: "Child",
            id: string,
            name: string,
            age: string,
            commonNoun: string,
            readingLevel?: string | null,
            avatar?: string | null,
            storyPages?:  {
              __typename: "ModelStoryPagesConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userChildrenId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        activeChildId?: string | null,
        settings?: string | null,
        lastInteraction?: string | null,
        emailUpdatesEnabled?: boolean | null,
        emailPromotionsEnabled?: boolean | null,
        emailStoriesFridayEnabled?: boolean | null,
        emailStoriesDailyEnabled?: boolean | null,
        trialStoriesCount?: number | null,
        bookCredits?: number | null,
        notificationSettings?:  {
          __typename: "NotificationSettings",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          notificationsEnabled: boolean,
          storyReminders:  {
            __typename: "StoryReminderSettings",
            enabled: boolean,
            frequency?: string | null,
          },
          promotionsEnabled?: boolean | null,
          updatesEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        deviceTokens?:  {
          __typename: "ModelDeviceTokenConnection",
          items:  Array< {
            __typename: "DeviceToken",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            token: string,
            platform: string,
            endpointArn?: string | null,
            createdAt: string,
            updatedAt: string,
            userDeviceTokensId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userNotificationSettingsId?: string | null,
        owner?: string | null,
      } | null,
      userId: string,
      notificationsEnabled: boolean,
      storyReminders:  {
        __typename: "StoryReminderSettings",
        enabled: boolean,
        frequency?: string | null,
      },
      promotionsEnabled?: boolean | null,
      updatesEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    deviceTokens?:  {
      __typename: "ModelDeviceTokenConnection",
      items:  Array< {
        __typename: "DeviceToken",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        token: string,
        platform: string,
        endpointArn?: string | null,
        createdAt: string,
        updatedAt: string,
        userDeviceTokensId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userNotificationSettingsId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateNotificationSettingsMutationVariables = {
  input: CreateNotificationSettingsInput,
  condition?: ModelNotificationSettingsConditionInput | null,
};

export type CreateNotificationSettingsMutation = {
  createNotificationSettings?:  {
    __typename: "NotificationSettings",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId: string,
      email: string,
      children?:  {
        __typename: "ModelChildConnection",
        items:  Array< {
          __typename: "Child",
          id: string,
          name: string,
          age: string,
          commonNoun: string,
          readingLevel?: string | null,
          avatar?: string | null,
          storyPages?:  {
            __typename: "ModelStoryPagesConnection",
            items:  Array< {
              __typename: "StoryPages",
              id: string,
              pageIDs?: Array< string | null > | null,
              storyType?: string | null,
              storyStatus?: string | null,
              rating?: number | null,
              childStoryPagesId: string,
              lastViewed?: string | null,
              expositionImageName?: string | null,
              title?: string | null,
              initiationMethod?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userChildrenId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activeChildId?: string | null,
      settings?: string | null,
      lastInteraction?: string | null,
      emailUpdatesEnabled?: boolean | null,
      emailPromotionsEnabled?: boolean | null,
      emailStoriesFridayEnabled?: boolean | null,
      emailStoriesDailyEnabled?: boolean | null,
      trialStoriesCount?: number | null,
      bookCredits?: number | null,
      notificationSettings?:  {
        __typename: "NotificationSettings",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        notificationsEnabled: boolean,
        storyReminders:  {
          __typename: "StoryReminderSettings",
          enabled: boolean,
          frequency?: string | null,
        },
        promotionsEnabled?: boolean | null,
        updatesEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      deviceTokens?:  {
        __typename: "ModelDeviceTokenConnection",
        items:  Array< {
          __typename: "DeviceToken",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          token: string,
          platform: string,
          endpointArn?: string | null,
          createdAt: string,
          updatedAt: string,
          userDeviceTokensId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userNotificationSettingsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    notificationsEnabled: boolean,
    storyReminders:  {
      __typename: "StoryReminderSettings",
      enabled: boolean,
      frequency?: string | null,
    },
    promotionsEnabled?: boolean | null,
    updatesEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateNotificationSettingsMutationVariables = {
  input: UpdateNotificationSettingsInput,
  condition?: ModelNotificationSettingsConditionInput | null,
};

export type UpdateNotificationSettingsMutation = {
  updateNotificationSettings?:  {
    __typename: "NotificationSettings",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId: string,
      email: string,
      children?:  {
        __typename: "ModelChildConnection",
        items:  Array< {
          __typename: "Child",
          id: string,
          name: string,
          age: string,
          commonNoun: string,
          readingLevel?: string | null,
          avatar?: string | null,
          storyPages?:  {
            __typename: "ModelStoryPagesConnection",
            items:  Array< {
              __typename: "StoryPages",
              id: string,
              pageIDs?: Array< string | null > | null,
              storyType?: string | null,
              storyStatus?: string | null,
              rating?: number | null,
              childStoryPagesId: string,
              lastViewed?: string | null,
              expositionImageName?: string | null,
              title?: string | null,
              initiationMethod?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userChildrenId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activeChildId?: string | null,
      settings?: string | null,
      lastInteraction?: string | null,
      emailUpdatesEnabled?: boolean | null,
      emailPromotionsEnabled?: boolean | null,
      emailStoriesFridayEnabled?: boolean | null,
      emailStoriesDailyEnabled?: boolean | null,
      trialStoriesCount?: number | null,
      bookCredits?: number | null,
      notificationSettings?:  {
        __typename: "NotificationSettings",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        notificationsEnabled: boolean,
        storyReminders:  {
          __typename: "StoryReminderSettings",
          enabled: boolean,
          frequency?: string | null,
        },
        promotionsEnabled?: boolean | null,
        updatesEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      deviceTokens?:  {
        __typename: "ModelDeviceTokenConnection",
        items:  Array< {
          __typename: "DeviceToken",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          token: string,
          platform: string,
          endpointArn?: string | null,
          createdAt: string,
          updatedAt: string,
          userDeviceTokensId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userNotificationSettingsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    notificationsEnabled: boolean,
    storyReminders:  {
      __typename: "StoryReminderSettings",
      enabled: boolean,
      frequency?: string | null,
    },
    promotionsEnabled?: boolean | null,
    updatesEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteNotificationSettingsMutationVariables = {
  input: DeleteNotificationSettingsInput,
  condition?: ModelNotificationSettingsConditionInput | null,
};

export type DeleteNotificationSettingsMutation = {
  deleteNotificationSettings?:  {
    __typename: "NotificationSettings",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId: string,
      email: string,
      children?:  {
        __typename: "ModelChildConnection",
        items:  Array< {
          __typename: "Child",
          id: string,
          name: string,
          age: string,
          commonNoun: string,
          readingLevel?: string | null,
          avatar?: string | null,
          storyPages?:  {
            __typename: "ModelStoryPagesConnection",
            items:  Array< {
              __typename: "StoryPages",
              id: string,
              pageIDs?: Array< string | null > | null,
              storyType?: string | null,
              storyStatus?: string | null,
              rating?: number | null,
              childStoryPagesId: string,
              lastViewed?: string | null,
              expositionImageName?: string | null,
              title?: string | null,
              initiationMethod?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userChildrenId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activeChildId?: string | null,
      settings?: string | null,
      lastInteraction?: string | null,
      emailUpdatesEnabled?: boolean | null,
      emailPromotionsEnabled?: boolean | null,
      emailStoriesFridayEnabled?: boolean | null,
      emailStoriesDailyEnabled?: boolean | null,
      trialStoriesCount?: number | null,
      bookCredits?: number | null,
      notificationSettings?:  {
        __typename: "NotificationSettings",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        notificationsEnabled: boolean,
        storyReminders:  {
          __typename: "StoryReminderSettings",
          enabled: boolean,
          frequency?: string | null,
        },
        promotionsEnabled?: boolean | null,
        updatesEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      deviceTokens?:  {
        __typename: "ModelDeviceTokenConnection",
        items:  Array< {
          __typename: "DeviceToken",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          token: string,
          platform: string,
          endpointArn?: string | null,
          createdAt: string,
          updatedAt: string,
          userDeviceTokensId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userNotificationSettingsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    notificationsEnabled: boolean,
    storyReminders:  {
      __typename: "StoryReminderSettings",
      enabled: boolean,
      frequency?: string | null,
    },
    promotionsEnabled?: boolean | null,
    updatesEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateDeviceTokenMutationVariables = {
  input: CreateDeviceTokenInput,
  condition?: ModelDeviceTokenConditionInput | null,
};

export type CreateDeviceTokenMutation = {
  createDeviceToken?:  {
    __typename: "DeviceToken",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId: string,
      email: string,
      children?:  {
        __typename: "ModelChildConnection",
        items:  Array< {
          __typename: "Child",
          id: string,
          name: string,
          age: string,
          commonNoun: string,
          readingLevel?: string | null,
          avatar?: string | null,
          storyPages?:  {
            __typename: "ModelStoryPagesConnection",
            items:  Array< {
              __typename: "StoryPages",
              id: string,
              pageIDs?: Array< string | null > | null,
              storyType?: string | null,
              storyStatus?: string | null,
              rating?: number | null,
              childStoryPagesId: string,
              lastViewed?: string | null,
              expositionImageName?: string | null,
              title?: string | null,
              initiationMethod?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userChildrenId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activeChildId?: string | null,
      settings?: string | null,
      lastInteraction?: string | null,
      emailUpdatesEnabled?: boolean | null,
      emailPromotionsEnabled?: boolean | null,
      emailStoriesFridayEnabled?: boolean | null,
      emailStoriesDailyEnabled?: boolean | null,
      trialStoriesCount?: number | null,
      bookCredits?: number | null,
      notificationSettings?:  {
        __typename: "NotificationSettings",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        notificationsEnabled: boolean,
        storyReminders:  {
          __typename: "StoryReminderSettings",
          enabled: boolean,
          frequency?: string | null,
        },
        promotionsEnabled?: boolean | null,
        updatesEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      deviceTokens?:  {
        __typename: "ModelDeviceTokenConnection",
        items:  Array< {
          __typename: "DeviceToken",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          token: string,
          platform: string,
          endpointArn?: string | null,
          createdAt: string,
          updatedAt: string,
          userDeviceTokensId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userNotificationSettingsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    token: string,
    platform: string,
    endpointArn?: string | null,
    createdAt: string,
    updatedAt: string,
    userDeviceTokensId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateDeviceTokenMutationVariables = {
  input: UpdateDeviceTokenInput,
  condition?: ModelDeviceTokenConditionInput | null,
};

export type UpdateDeviceTokenMutation = {
  updateDeviceToken?:  {
    __typename: "DeviceToken",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId: string,
      email: string,
      children?:  {
        __typename: "ModelChildConnection",
        items:  Array< {
          __typename: "Child",
          id: string,
          name: string,
          age: string,
          commonNoun: string,
          readingLevel?: string | null,
          avatar?: string | null,
          storyPages?:  {
            __typename: "ModelStoryPagesConnection",
            items:  Array< {
              __typename: "StoryPages",
              id: string,
              pageIDs?: Array< string | null > | null,
              storyType?: string | null,
              storyStatus?: string | null,
              rating?: number | null,
              childStoryPagesId: string,
              lastViewed?: string | null,
              expositionImageName?: string | null,
              title?: string | null,
              initiationMethod?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userChildrenId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activeChildId?: string | null,
      settings?: string | null,
      lastInteraction?: string | null,
      emailUpdatesEnabled?: boolean | null,
      emailPromotionsEnabled?: boolean | null,
      emailStoriesFridayEnabled?: boolean | null,
      emailStoriesDailyEnabled?: boolean | null,
      trialStoriesCount?: number | null,
      bookCredits?: number | null,
      notificationSettings?:  {
        __typename: "NotificationSettings",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        notificationsEnabled: boolean,
        storyReminders:  {
          __typename: "StoryReminderSettings",
          enabled: boolean,
          frequency?: string | null,
        },
        promotionsEnabled?: boolean | null,
        updatesEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      deviceTokens?:  {
        __typename: "ModelDeviceTokenConnection",
        items:  Array< {
          __typename: "DeviceToken",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          token: string,
          platform: string,
          endpointArn?: string | null,
          createdAt: string,
          updatedAt: string,
          userDeviceTokensId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userNotificationSettingsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    token: string,
    platform: string,
    endpointArn?: string | null,
    createdAt: string,
    updatedAt: string,
    userDeviceTokensId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteDeviceTokenMutationVariables = {
  input: DeleteDeviceTokenInput,
  condition?: ModelDeviceTokenConditionInput | null,
};

export type DeleteDeviceTokenMutation = {
  deleteDeviceToken?:  {
    __typename: "DeviceToken",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId: string,
      email: string,
      children?:  {
        __typename: "ModelChildConnection",
        items:  Array< {
          __typename: "Child",
          id: string,
          name: string,
          age: string,
          commonNoun: string,
          readingLevel?: string | null,
          avatar?: string | null,
          storyPages?:  {
            __typename: "ModelStoryPagesConnection",
            items:  Array< {
              __typename: "StoryPages",
              id: string,
              pageIDs?: Array< string | null > | null,
              storyType?: string | null,
              storyStatus?: string | null,
              rating?: number | null,
              childStoryPagesId: string,
              lastViewed?: string | null,
              expositionImageName?: string | null,
              title?: string | null,
              initiationMethod?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userChildrenId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activeChildId?: string | null,
      settings?: string | null,
      lastInteraction?: string | null,
      emailUpdatesEnabled?: boolean | null,
      emailPromotionsEnabled?: boolean | null,
      emailStoriesFridayEnabled?: boolean | null,
      emailStoriesDailyEnabled?: boolean | null,
      trialStoriesCount?: number | null,
      bookCredits?: number | null,
      notificationSettings?:  {
        __typename: "NotificationSettings",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        notificationsEnabled: boolean,
        storyReminders:  {
          __typename: "StoryReminderSettings",
          enabled: boolean,
          frequency?: string | null,
        },
        promotionsEnabled?: boolean | null,
        updatesEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      deviceTokens?:  {
        __typename: "ModelDeviceTokenConnection",
        items:  Array< {
          __typename: "DeviceToken",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          token: string,
          platform: string,
          endpointArn?: string | null,
          createdAt: string,
          updatedAt: string,
          userDeviceTokensId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userNotificationSettingsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    token: string,
    platform: string,
    endpointArn?: string | null,
    createdAt: string,
    updatedAt: string,
    userDeviceTokensId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateWaitlistMutationVariables = {
  input: CreateWaitlistInput,
  condition?: ModelWaitlistConditionInput | null,
};

export type CreateWaitlistMutation = {
  createWaitlist?:  {
    __typename: "Waitlist",
    cognitoUserId: string,
    email: string,
    accessGranted: boolean,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type UpdateWaitlistMutationVariables = {
  input: UpdateWaitlistInput,
  condition?: ModelWaitlistConditionInput | null,
};

export type UpdateWaitlistMutation = {
  updateWaitlist?:  {
    __typename: "Waitlist",
    cognitoUserId: string,
    email: string,
    accessGranted: boolean,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type DeleteWaitlistMutationVariables = {
  input: DeleteWaitlistInput,
  condition?: ModelWaitlistConditionInput | null,
};

export type DeleteWaitlistMutation = {
  deleteWaitlist?:  {
    __typename: "Waitlist",
    cognitoUserId: string,
    email: string,
    accessGranted: boolean,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type CreateGiftcardMutationVariables = {
  input: CreateGiftcardInput,
  condition?: ModelGiftcardConditionInput | null,
};

export type CreateGiftcardMutation = {
  createGiftcard?:  {
    __typename: "Giftcard",
    id: string,
    giftcode: string,
    recipientEmail: string,
    subscriptionProduct: string,
    storyCredits: number,
    bookCredits: number,
    giftingEmail?: string | null,
    redeemed: boolean,
    redeemedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGiftcardMutationVariables = {
  input: UpdateGiftcardInput,
  condition?: ModelGiftcardConditionInput | null,
};

export type UpdateGiftcardMutation = {
  updateGiftcard?:  {
    __typename: "Giftcard",
    id: string,
    giftcode: string,
    recipientEmail: string,
    subscriptionProduct: string,
    storyCredits: number,
    bookCredits: number,
    giftingEmail?: string | null,
    redeemed: boolean,
    redeemedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGiftcardMutationVariables = {
  input: DeleteGiftcardInput,
  condition?: ModelGiftcardConditionInput | null,
};

export type DeleteGiftcardMutation = {
  deleteGiftcard?:  {
    __typename: "Giftcard",
    id: string,
    giftcode: string,
    recipientEmail: string,
    subscriptionProduct: string,
    storyCredits: number,
    bookCredits: number,
    giftingEmail?: string | null,
    redeemed: boolean,
    redeemedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMetadataMutationVariables = {
  input: CreateMetadataInput,
  condition?: ModelMetadataConditionInput | null,
};

export type CreateMetadataMutation = {
  createMetadata?:  {
    __typename: "Metadata",
    id: string,
    isUnderMaintenance: boolean,
    startingTrialStoriesCount: number,
    waitlistEnabled: boolean,
    minimumAppVersion?: string | null,
    promotionString?: string | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type UpdateMetadataMutationVariables = {
  input: UpdateMetadataInput,
  condition?: ModelMetadataConditionInput | null,
};

export type UpdateMetadataMutation = {
  updateMetadata?:  {
    __typename: "Metadata",
    id: string,
    isUnderMaintenance: boolean,
    startingTrialStoriesCount: number,
    waitlistEnabled: boolean,
    minimumAppVersion?: string | null,
    promotionString?: string | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type DeleteMetadataMutationVariables = {
  input: DeleteMetadataInput,
  condition?: ModelMetadataConditionInput | null,
};

export type DeleteMetadataMutation = {
  deleteMetadata?:  {
    __typename: "Metadata",
    id: string,
    isUnderMaintenance: boolean,
    startingTrialStoriesCount: number,
    waitlistEnabled: boolean,
    minimumAppVersion?: string | null,
    promotionString?: string | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type CreateUserPaymentMutationVariables = {
  input: CreateUserPaymentInput,
  condition?: ModelUserPaymentConditionInput | null,
};

export type CreateUserPaymentMutation = {
  createUserPayment?:  {
    __typename: "UserPayment",
    stripeSubscriptionId: string,
    cognitoUserId: string,
    stripeCustomerId?: string | null,
    currentTransactionId?: string | null,
    status: SubscriptionStatus,
    currentPeriodStart?: string | null,
    currentPeriodEnd?: string | null,
    cancelAtPeriodEnd?: boolean | null,
    price:  {
      __typename: "StripePrice",
      id: string,
      amount: number,
      interval?: BillingInterval | null,
      interval_count?: number | null,
      currency: string,
    },
    product:  {
      __typename: "StripeProduct",
      id: string,
      name: string,
      description?: string | null,
    },
    provider?: PaymentProvider | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserPaymentMutationVariables = {
  input: UpdateUserPaymentInput,
  condition?: ModelUserPaymentConditionInput | null,
};

export type UpdateUserPaymentMutation = {
  updateUserPayment?:  {
    __typename: "UserPayment",
    stripeSubscriptionId: string,
    cognitoUserId: string,
    stripeCustomerId?: string | null,
    currentTransactionId?: string | null,
    status: SubscriptionStatus,
    currentPeriodStart?: string | null,
    currentPeriodEnd?: string | null,
    cancelAtPeriodEnd?: boolean | null,
    price:  {
      __typename: "StripePrice",
      id: string,
      amount: number,
      interval?: BillingInterval | null,
      interval_count?: number | null,
      currency: string,
    },
    product:  {
      __typename: "StripeProduct",
      id: string,
      name: string,
      description?: string | null,
    },
    provider?: PaymentProvider | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserPaymentMutationVariables = {
  input: DeleteUserPaymentInput,
  condition?: ModelUserPaymentConditionInput | null,
};

export type DeleteUserPaymentMutation = {
  deleteUserPayment?:  {
    __typename: "UserPayment",
    stripeSubscriptionId: string,
    cognitoUserId: string,
    stripeCustomerId?: string | null,
    currentTransactionId?: string | null,
    status: SubscriptionStatus,
    currentPeriodStart?: string | null,
    currentPeriodEnd?: string | null,
    cancelAtPeriodEnd?: boolean | null,
    price:  {
      __typename: "StripePrice",
      id: string,
      amount: number,
      interval?: BillingInterval | null,
      interval_count?: number | null,
      currency: string,
    },
    product:  {
      __typename: "StripeProduct",
      id: string,
      name: string,
      description?: string | null,
    },
    provider?: PaymentProvider | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateTransactionsMutationVariables = {
  input: CreateTransactionsInput,
  condition?: ModelTransactionsConditionInput | null,
};

export type CreateTransactionsMutation = {
  createTransactions?:  {
    __typename: "Transactions",
    id: string,
    cognitoUserId: string,
    transactionId: string,
    originalTransactionId?: string | null,
    product:  {
      __typename: "StripeProduct",
      id: string,
      name: string,
      description?: string | null,
    },
    price:  {
      __typename: "StripePrice",
      id: string,
      amount: number,
      interval?: BillingInterval | null,
      interval_count?: number | null,
      currency: string,
    },
    receipt?: string | null,
    provider?: PaymentProvider | null,
    discounts?: string | null,
    transactionDate?: string | null,
    errorCode?: string | null,
    errorMessage?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateTransactionsMutationVariables = {
  input: UpdateTransactionsInput,
  condition?: ModelTransactionsConditionInput | null,
};

export type UpdateTransactionsMutation = {
  updateTransactions?:  {
    __typename: "Transactions",
    id: string,
    cognitoUserId: string,
    transactionId: string,
    originalTransactionId?: string | null,
    product:  {
      __typename: "StripeProduct",
      id: string,
      name: string,
      description?: string | null,
    },
    price:  {
      __typename: "StripePrice",
      id: string,
      amount: number,
      interval?: BillingInterval | null,
      interval_count?: number | null,
      currency: string,
    },
    receipt?: string | null,
    provider?: PaymentProvider | null,
    discounts?: string | null,
    transactionDate?: string | null,
    errorCode?: string | null,
    errorMessage?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteTransactionsMutationVariables = {
  input: DeleteTransactionsInput,
  condition?: ModelTransactionsConditionInput | null,
};

export type DeleteTransactionsMutation = {
  deleteTransactions?:  {
    __typename: "Transactions",
    id: string,
    cognitoUserId: string,
    transactionId: string,
    originalTransactionId?: string | null,
    product:  {
      __typename: "StripeProduct",
      id: string,
      name: string,
      description?: string | null,
    },
    price:  {
      __typename: "StripePrice",
      id: string,
      amount: number,
      interval?: BillingInterval | null,
      interval_count?: number | null,
      currency: string,
    },
    receipt?: string | null,
    provider?: PaymentProvider | null,
    discounts?: string | null,
    transactionDate?: string | null,
    errorCode?: string | null,
    errorMessage?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateChildMutationVariables = {
  input: CreateChildInput,
  condition?: ModelChildConditionInput | null,
};

export type CreateChildMutation = {
  createChild?:  {
    __typename: "Child",
    id: string,
    name: string,
    age: string,
    commonNoun: string,
    readingLevel?: string | null,
    avatar?: string | null,
    storyPages?:  {
      __typename: "ModelStoryPagesConnection",
      items:  Array< {
        __typename: "StoryPages",
        id: string,
        pageIDs?: Array< string | null > | null,
        storyType?: string | null,
        storyStatus?: string | null,
        rating?: number | null,
        childStoryPagesId: string,
        lastViewed?: string | null,
        expositionImageName?: string | null,
        title?: string | null,
        initiationMethod?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userChildrenId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateChildMutationVariables = {
  input: UpdateChildInput,
  condition?: ModelChildConditionInput | null,
};

export type UpdateChildMutation = {
  updateChild?:  {
    __typename: "Child",
    id: string,
    name: string,
    age: string,
    commonNoun: string,
    readingLevel?: string | null,
    avatar?: string | null,
    storyPages?:  {
      __typename: "ModelStoryPagesConnection",
      items:  Array< {
        __typename: "StoryPages",
        id: string,
        pageIDs?: Array< string | null > | null,
        storyType?: string | null,
        storyStatus?: string | null,
        rating?: number | null,
        childStoryPagesId: string,
        lastViewed?: string | null,
        expositionImageName?: string | null,
        title?: string | null,
        initiationMethod?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userChildrenId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteChildMutationVariables = {
  input: DeleteChildInput,
  condition?: ModelChildConditionInput | null,
};

export type DeleteChildMutation = {
  deleteChild?:  {
    __typename: "Child",
    id: string,
    name: string,
    age: string,
    commonNoun: string,
    readingLevel?: string | null,
    avatar?: string | null,
    storyPages?:  {
      __typename: "ModelStoryPagesConnection",
      items:  Array< {
        __typename: "StoryPages",
        id: string,
        pageIDs?: Array< string | null > | null,
        storyType?: string | null,
        storyStatus?: string | null,
        rating?: number | null,
        childStoryPagesId: string,
        lastViewed?: string | null,
        expositionImageName?: string | null,
        title?: string | null,
        initiationMethod?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userChildrenId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreatePageMutationVariables = {
  input: CreatePageInput,
  condition?: ModelPageConditionInput | null,
};

export type CreatePageMutation = {
  createPage?:  {
    __typename: "Page",
    id: string,
    cognitoUserId: string,
    pageText: string,
    pageOptions: Array< string | null >,
    imageName?: string | null,
    minorImageName?: string | null,
    characterImageNames?: string | null,
    primaryImage?: string | null,
    protagonist?: string | null,
    storyType?: string | null,
    location?: string | null,
    readingLevel?: string | null,
    preceededBy?: string | null,
    followedBy?: Array< string | null > | null,
    followedByEnding?: Array< string | null > | null,
    isExposition?: boolean | null,
    isEnding?: boolean | null,
    isAnonymized?: boolean | null,
    status?: PageStatus | null,
    isAccepted?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title?: string | null,
  } | null,
};

export type UpdatePageMutationVariables = {
  input: UpdatePageInput,
  condition?: ModelPageConditionInput | null,
};

export type UpdatePageMutation = {
  updatePage?:  {
    __typename: "Page",
    id: string,
    cognitoUserId: string,
    pageText: string,
    pageOptions: Array< string | null >,
    imageName?: string | null,
    minorImageName?: string | null,
    characterImageNames?: string | null,
    primaryImage?: string | null,
    protagonist?: string | null,
    storyType?: string | null,
    location?: string | null,
    readingLevel?: string | null,
    preceededBy?: string | null,
    followedBy?: Array< string | null > | null,
    followedByEnding?: Array< string | null > | null,
    isExposition?: boolean | null,
    isEnding?: boolean | null,
    isAnonymized?: boolean | null,
    status?: PageStatus | null,
    isAccepted?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title?: string | null,
  } | null,
};

export type DeletePageMutationVariables = {
  input: DeletePageInput,
  condition?: ModelPageConditionInput | null,
};

export type DeletePageMutation = {
  deletePage?:  {
    __typename: "Page",
    id: string,
    cognitoUserId: string,
    pageText: string,
    pageOptions: Array< string | null >,
    imageName?: string | null,
    minorImageName?: string | null,
    characterImageNames?: string | null,
    primaryImage?: string | null,
    protagonist?: string | null,
    storyType?: string | null,
    location?: string | null,
    readingLevel?: string | null,
    preceededBy?: string | null,
    followedBy?: Array< string | null > | null,
    followedByEnding?: Array< string | null > | null,
    isExposition?: boolean | null,
    isEnding?: boolean | null,
    isAnonymized?: boolean | null,
    status?: PageStatus | null,
    isAccepted?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title?: string | null,
  } | null,
};

export type CreateSharedPageMutationVariables = {
  input: CreateSharedPageInput,
  condition?: ModelSharedPageConditionInput | null,
};

export type CreateSharedPageMutation = {
  createSharedPage?:  {
    __typename: "SharedPage",
    id: string,
    pageText: string,
    pageOptions?: Array< string | null > | null,
    imageName?: string | null,
    protagonist?: string | null,
    storyType?: string | null,
    location?: string | null,
    readingLevel?: string | null,
    preceededBy?: string | null,
    followedBy?: Array< string | null > | null,
    followedByEnding?: Array< string | null > | null,
    status?: PageStatus | null,
    isExposition?: boolean | null,
    isEnding?: boolean | null,
    isAnonymized?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title?: string | null,
  } | null,
};

export type UpdateSharedPageMutationVariables = {
  input: UpdateSharedPageInput,
  condition?: ModelSharedPageConditionInput | null,
};

export type UpdateSharedPageMutation = {
  updateSharedPage?:  {
    __typename: "SharedPage",
    id: string,
    pageText: string,
    pageOptions?: Array< string | null > | null,
    imageName?: string | null,
    protagonist?: string | null,
    storyType?: string | null,
    location?: string | null,
    readingLevel?: string | null,
    preceededBy?: string | null,
    followedBy?: Array< string | null > | null,
    followedByEnding?: Array< string | null > | null,
    status?: PageStatus | null,
    isExposition?: boolean | null,
    isEnding?: boolean | null,
    isAnonymized?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title?: string | null,
  } | null,
};

export type DeleteSharedPageMutationVariables = {
  input: DeleteSharedPageInput,
  condition?: ModelSharedPageConditionInput | null,
};

export type DeleteSharedPageMutation = {
  deleteSharedPage?:  {
    __typename: "SharedPage",
    id: string,
    pageText: string,
    pageOptions?: Array< string | null > | null,
    imageName?: string | null,
    protagonist?: string | null,
    storyType?: string | null,
    location?: string | null,
    readingLevel?: string | null,
    preceededBy?: string | null,
    followedBy?: Array< string | null > | null,
    followedByEnding?: Array< string | null > | null,
    status?: PageStatus | null,
    isExposition?: boolean | null,
    isEnding?: boolean | null,
    isAnonymized?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title?: string | null,
  } | null,
};

export type CreateStoryPagesMutationVariables = {
  input: CreateStoryPagesInput,
  condition?: ModelStoryPagesConditionInput | null,
};

export type CreateStoryPagesMutation = {
  createStoryPages?:  {
    __typename: "StoryPages",
    id: string,
    pageIDs?: Array< string | null > | null,
    storyType?: string | null,
    storyStatus?: string | null,
    rating?: number | null,
    childStoryPagesId: string,
    lastViewed?: string | null,
    expositionImageName?: string | null,
    title?: string | null,
    initiationMethod?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateStoryPagesMutationVariables = {
  input: UpdateStoryPagesInput,
  condition?: ModelStoryPagesConditionInput | null,
};

export type UpdateStoryPagesMutation = {
  updateStoryPages?:  {
    __typename: "StoryPages",
    id: string,
    pageIDs?: Array< string | null > | null,
    storyType?: string | null,
    storyStatus?: string | null,
    rating?: number | null,
    childStoryPagesId: string,
    lastViewed?: string | null,
    expositionImageName?: string | null,
    title?: string | null,
    initiationMethod?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteStoryPagesMutationVariables = {
  input: DeleteStoryPagesInput,
  condition?: ModelStoryPagesConditionInput | null,
};

export type DeleteStoryPagesMutation = {
  deleteStoryPages?:  {
    __typename: "StoryPages",
    id: string,
    pageIDs?: Array< string | null > | null,
    storyType?: string | null,
    storyStatus?: string | null,
    rating?: number | null,
    childStoryPagesId: string,
    lastViewed?: string | null,
    expositionImageName?: string | null,
    title?: string | null,
    initiationMethod?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateDailyStoryMutationVariables = {
  input: CreateDailyStoryInput,
  condition?: ModelDailyStoryConditionInput | null,
};

export type CreateDailyStoryMutation = {
  createDailyStory?:  {
    __typename: "DailyStory",
    id: string,
    expositionPageID: string,
    storyType?: string | null,
    pageDepth?: string | null,
    status?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    approvedAt?: string | null,
    storyTitle?: string | null,
    displayStart?: string | null,
  } | null,
};

export type UpdateDailyStoryMutationVariables = {
  input: UpdateDailyStoryInput,
  condition?: ModelDailyStoryConditionInput | null,
};

export type UpdateDailyStoryMutation = {
  updateDailyStory?:  {
    __typename: "DailyStory",
    id: string,
    expositionPageID: string,
    storyType?: string | null,
    pageDepth?: string | null,
    status?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    approvedAt?: string | null,
    storyTitle?: string | null,
    displayStart?: string | null,
  } | null,
};

export type DeleteDailyStoryMutationVariables = {
  input: DeleteDailyStoryInput,
  condition?: ModelDailyStoryConditionInput | null,
};

export type DeleteDailyStoryMutation = {
  deleteDailyStory?:  {
    __typename: "DailyStory",
    id: string,
    expositionPageID: string,
    storyType?: string | null,
    pageDepth?: string | null,
    status?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    approvedAt?: string | null,
    storyTitle?: string | null,
    displayStart?: string | null,
  } | null,
};

export type CreateImageSubjectsLogMutationVariables = {
  input: CreateImageSubjectsLogInput,
  condition?: ModelImageSubjectsLogConditionInput | null,
};

export type CreateImageSubjectsLogMutation = {
  createImageSubjectsLog?:  {
    __typename: "imageSubjectsLog",
    id: string,
    subjects?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateImageSubjectsLogMutationVariables = {
  input: UpdateImageSubjectsLogInput,
  condition?: ModelImageSubjectsLogConditionInput | null,
};

export type UpdateImageSubjectsLogMutation = {
  updateImageSubjectsLog?:  {
    __typename: "imageSubjectsLog",
    id: string,
    subjects?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteImageSubjectsLogMutationVariables = {
  input: DeleteImageSubjectsLogInput,
  condition?: ModelImageSubjectsLogConditionInput | null,
};

export type DeleteImageSubjectsLogMutation = {
  deleteImageSubjectsLog?:  {
    __typename: "imageSubjectsLog",
    id: string,
    subjects?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateJobStatusMutationVariables = {
  input: CreateJobStatusInput,
  condition?: ModelJobStatusConditionInput | null,
};

export type CreateJobStatusMutation = {
  createJobStatus?:  {
    __typename: "JobStatus",
    id: string,
    jobStatus: string,
    jobResult?:  {
      __typename: "JobResult",
      success?: boolean | null,
      pageIDs?: Array< string | null > | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    cognitoUserId?: string | null,
  } | null,
};

export type UpdateJobStatusMutationVariables = {
  input: UpdateJobStatusInput,
  condition?: ModelJobStatusConditionInput | null,
};

export type UpdateJobStatusMutation = {
  updateJobStatus?:  {
    __typename: "JobStatus",
    id: string,
    jobStatus: string,
    jobResult?:  {
      __typename: "JobResult",
      success?: boolean | null,
      pageIDs?: Array< string | null > | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    cognitoUserId?: string | null,
  } | null,
};

export type DeleteJobStatusMutationVariables = {
  input: DeleteJobStatusInput,
  condition?: ModelJobStatusConditionInput | null,
};

export type DeleteJobStatusMutation = {
  deleteJobStatus?:  {
    __typename: "JobStatus",
    id: string,
    jobStatus: string,
    jobResult?:  {
      __typename: "JobResult",
      success?: boolean | null,
      pageIDs?: Array< string | null > | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    cognitoUserId?: string | null,
  } | null,
};

export type CreateImageMutationVariables = {
  input: CreateImageInput,
  condition?: ModelImageConditionInput | null,
};

export type CreateImageMutation = {
  createImage?:  {
    __typename: "Image",
    id: string,
    name: string,
    type: ImageType,
    genres?:  {
      __typename: "ModelImageGenreRelationConnection",
      items:  Array< {
        __typename: "ImageGenreRelation",
        id: string,
        imageId: string,
        genreId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        genre:  {
          __typename: "Genre",
          id: string,
          name: string,
          images?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    expressions?:  {
      __typename: "ModelExpressionConnection",
      items:  Array< {
        __typename: "Expression",
        id: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        name: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        imageExpressionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    traits?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    categories?:  {
      __typename: "ModelImageCategoryRelationConnection",
      items:  Array< {
        __typename: "ImageCategoryRelation",
        id: string,
        imageId: string,
        imageCategoryId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        imageCategory:  {
          __typename: "ImageCategory",
          id: string,
          category: string,
          images?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    characterSet?: string | null,
    characterSetName?: string | null,
    location?: string | null,
  } | null,
};

export type UpdateImageMutationVariables = {
  input: UpdateImageInput,
  condition?: ModelImageConditionInput | null,
};

export type UpdateImageMutation = {
  updateImage?:  {
    __typename: "Image",
    id: string,
    name: string,
    type: ImageType,
    genres?:  {
      __typename: "ModelImageGenreRelationConnection",
      items:  Array< {
        __typename: "ImageGenreRelation",
        id: string,
        imageId: string,
        genreId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        genre:  {
          __typename: "Genre",
          id: string,
          name: string,
          images?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    expressions?:  {
      __typename: "ModelExpressionConnection",
      items:  Array< {
        __typename: "Expression",
        id: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        name: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        imageExpressionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    traits?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    categories?:  {
      __typename: "ModelImageCategoryRelationConnection",
      items:  Array< {
        __typename: "ImageCategoryRelation",
        id: string,
        imageId: string,
        imageCategoryId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        imageCategory:  {
          __typename: "ImageCategory",
          id: string,
          category: string,
          images?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    characterSet?: string | null,
    characterSetName?: string | null,
    location?: string | null,
  } | null,
};

export type DeleteImageMutationVariables = {
  input: DeleteImageInput,
  condition?: ModelImageConditionInput | null,
};

export type DeleteImageMutation = {
  deleteImage?:  {
    __typename: "Image",
    id: string,
    name: string,
    type: ImageType,
    genres?:  {
      __typename: "ModelImageGenreRelationConnection",
      items:  Array< {
        __typename: "ImageGenreRelation",
        id: string,
        imageId: string,
        genreId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        genre:  {
          __typename: "Genre",
          id: string,
          name: string,
          images?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    expressions?:  {
      __typename: "ModelExpressionConnection",
      items:  Array< {
        __typename: "Expression",
        id: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        name: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        imageExpressionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    traits?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    categories?:  {
      __typename: "ModelImageCategoryRelationConnection",
      items:  Array< {
        __typename: "ImageCategoryRelation",
        id: string,
        imageId: string,
        imageCategoryId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        imageCategory:  {
          __typename: "ImageCategory",
          id: string,
          category: string,
          images?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    characterSet?: string | null,
    characterSetName?: string | null,
    location?: string | null,
  } | null,
};

export type CreateExpressionMutationVariables = {
  input: CreateExpressionInput,
  condition?: ModelExpressionConditionInput | null,
};

export type CreateExpressionMutation = {
  createExpression?:  {
    __typename: "Expression",
    id: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    name: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    imageExpressionsId?: string | null,
  } | null,
};

export type UpdateExpressionMutationVariables = {
  input: UpdateExpressionInput,
  condition?: ModelExpressionConditionInput | null,
};

export type UpdateExpressionMutation = {
  updateExpression?:  {
    __typename: "Expression",
    id: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    name: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    imageExpressionsId?: string | null,
  } | null,
};

export type DeleteExpressionMutationVariables = {
  input: DeleteExpressionInput,
  condition?: ModelExpressionConditionInput | null,
};

export type DeleteExpressionMutation = {
  deleteExpression?:  {
    __typename: "Expression",
    id: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    name: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    imageExpressionsId?: string | null,
  } | null,
};

export type CreateGenreMutationVariables = {
  input: CreateGenreInput,
  condition?: ModelGenreConditionInput | null,
};

export type CreateGenreMutation = {
  createGenre?:  {
    __typename: "Genre",
    id: string,
    name: string,
    images?:  {
      __typename: "ModelImageGenreRelationConnection",
      items:  Array< {
        __typename: "ImageGenreRelation",
        id: string,
        imageId: string,
        genreId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        genre:  {
          __typename: "Genre",
          id: string,
          name: string,
          images?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateGenreMutationVariables = {
  input: UpdateGenreInput,
  condition?: ModelGenreConditionInput | null,
};

export type UpdateGenreMutation = {
  updateGenre?:  {
    __typename: "Genre",
    id: string,
    name: string,
    images?:  {
      __typename: "ModelImageGenreRelationConnection",
      items:  Array< {
        __typename: "ImageGenreRelation",
        id: string,
        imageId: string,
        genreId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        genre:  {
          __typename: "Genre",
          id: string,
          name: string,
          images?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteGenreMutationVariables = {
  input: DeleteGenreInput,
  condition?: ModelGenreConditionInput | null,
};

export type DeleteGenreMutation = {
  deleteGenre?:  {
    __typename: "Genre",
    id: string,
    name: string,
    images?:  {
      __typename: "ModelImageGenreRelationConnection",
      items:  Array< {
        __typename: "ImageGenreRelation",
        id: string,
        imageId: string,
        genreId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        genre:  {
          __typename: "Genre",
          id: string,
          name: string,
          images?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateImageCategoryMutationVariables = {
  input: CreateImageCategoryInput,
  condition?: ModelImageCategoryConditionInput | null,
};

export type CreateImageCategoryMutation = {
  createImageCategory?:  {
    __typename: "ImageCategory",
    id: string,
    category: string,
    images?:  {
      __typename: "ModelImageCategoryRelationConnection",
      items:  Array< {
        __typename: "ImageCategoryRelation",
        id: string,
        imageId: string,
        imageCategoryId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        imageCategory:  {
          __typename: "ImageCategory",
          id: string,
          category: string,
          images?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateImageCategoryMutationVariables = {
  input: UpdateImageCategoryInput,
  condition?: ModelImageCategoryConditionInput | null,
};

export type UpdateImageCategoryMutation = {
  updateImageCategory?:  {
    __typename: "ImageCategory",
    id: string,
    category: string,
    images?:  {
      __typename: "ModelImageCategoryRelationConnection",
      items:  Array< {
        __typename: "ImageCategoryRelation",
        id: string,
        imageId: string,
        imageCategoryId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        imageCategory:  {
          __typename: "ImageCategory",
          id: string,
          category: string,
          images?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteImageCategoryMutationVariables = {
  input: DeleteImageCategoryInput,
  condition?: ModelImageCategoryConditionInput | null,
};

export type DeleteImageCategoryMutation = {
  deleteImageCategory?:  {
    __typename: "ImageCategory",
    id: string,
    category: string,
    images?:  {
      __typename: "ModelImageCategoryRelationConnection",
      items:  Array< {
        __typename: "ImageCategoryRelation",
        id: string,
        imageId: string,
        imageCategoryId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        imageCategory:  {
          __typename: "ImageCategory",
          id: string,
          category: string,
          images?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateBookOrderMutationVariables = {
  input: CreateBookOrderInput,
  condition?: ModelBookOrderConditionInput | null,
};

export type CreateBookOrderMutation = {
  createBookOrder?:  {
    __typename: "BookOrder",
    id: string,
    checkoutId: string,
    storyId: string,
    title: string,
    bookplateStyle?: string | null,
    bookplateName?: string | null,
    byline?: string | null,
    dedication?: string | null,
    lineItems:  Array< {
      __typename: "OrderLineItem",
      description: string,
      quantity: number,
      amount: number,
      priceId?: string | null,
    } >,
    shippingName: string,
    shippingAddressLine1: string,
    shippingAddressLine2?: string | null,
    shippingCity: string,
    shippingState: string,
    shippingPostalCode: string,
    shippingCountry: string,
    status?: string | null,
    statusTimeline?: string | null,
    customerEmail: string,
    totalAmount: number,
    currency: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateBookOrderMutationVariables = {
  input: UpdateBookOrderInput,
  condition?: ModelBookOrderConditionInput | null,
};

export type UpdateBookOrderMutation = {
  updateBookOrder?:  {
    __typename: "BookOrder",
    id: string,
    checkoutId: string,
    storyId: string,
    title: string,
    bookplateStyle?: string | null,
    bookplateName?: string | null,
    byline?: string | null,
    dedication?: string | null,
    lineItems:  Array< {
      __typename: "OrderLineItem",
      description: string,
      quantity: number,
      amount: number,
      priceId?: string | null,
    } >,
    shippingName: string,
    shippingAddressLine1: string,
    shippingAddressLine2?: string | null,
    shippingCity: string,
    shippingState: string,
    shippingPostalCode: string,
    shippingCountry: string,
    status?: string | null,
    statusTimeline?: string | null,
    customerEmail: string,
    totalAmount: number,
    currency: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteBookOrderMutationVariables = {
  input: DeleteBookOrderInput,
  condition?: ModelBookOrderConditionInput | null,
};

export type DeleteBookOrderMutation = {
  deleteBookOrder?:  {
    __typename: "BookOrder",
    id: string,
    checkoutId: string,
    storyId: string,
    title: string,
    bookplateStyle?: string | null,
    bookplateName?: string | null,
    byline?: string | null,
    dedication?: string | null,
    lineItems:  Array< {
      __typename: "OrderLineItem",
      description: string,
      quantity: number,
      amount: number,
      priceId?: string | null,
    } >,
    shippingName: string,
    shippingAddressLine1: string,
    shippingAddressLine2?: string | null,
    shippingCity: string,
    shippingState: string,
    shippingPostalCode: string,
    shippingCountry: string,
    status?: string | null,
    statusTimeline?: string | null,
    customerEmail: string,
    totalAmount: number,
    currency: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCheckoutMetadataMutationVariables = {
  input: CreateCheckoutMetadataInput,
  condition?: ModelCheckoutMetadataConditionInput | null,
};

export type CreateCheckoutMetadataMutation = {
  createCheckoutMetadata?:  {
    __typename: "CheckoutMetadata",
    id: string,
    stripeSessionId: string,
    customerEmail: string,
    purchaseType: string,
    lineItems:  Array< {
      __typename: "OrderLineItem",
      description: string,
      quantity: number,
      amount: number,
      priceId?: string | null,
    } >,
    totalAmount: number,
    currency: string,
    discountTotal?: number | null,
    discounts?:  Array< {
      __typename: "Discount",
      amount: number,
      promoCode?: string | null,
      couponId?: string | null,
      couponName?: string | null,
    } > | null,
    recipientName?: string | null,
    recipientEmail?: string | null,
    gifterName?: string | null,
    gifterEmail?: string | null,
    giftMessage?: string | null,
    deliveryDate?: string | null,
    giftCardStyle?: string | null,
    bookOrderId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCheckoutMetadataMutationVariables = {
  input: UpdateCheckoutMetadataInput,
  condition?: ModelCheckoutMetadataConditionInput | null,
};

export type UpdateCheckoutMetadataMutation = {
  updateCheckoutMetadata?:  {
    __typename: "CheckoutMetadata",
    id: string,
    stripeSessionId: string,
    customerEmail: string,
    purchaseType: string,
    lineItems:  Array< {
      __typename: "OrderLineItem",
      description: string,
      quantity: number,
      amount: number,
      priceId?: string | null,
    } >,
    totalAmount: number,
    currency: string,
    discountTotal?: number | null,
    discounts?:  Array< {
      __typename: "Discount",
      amount: number,
      promoCode?: string | null,
      couponId?: string | null,
      couponName?: string | null,
    } > | null,
    recipientName?: string | null,
    recipientEmail?: string | null,
    gifterName?: string | null,
    gifterEmail?: string | null,
    giftMessage?: string | null,
    deliveryDate?: string | null,
    giftCardStyle?: string | null,
    bookOrderId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCheckoutMetadataMutationVariables = {
  input: DeleteCheckoutMetadataInput,
  condition?: ModelCheckoutMetadataConditionInput | null,
};

export type DeleteCheckoutMetadataMutation = {
  deleteCheckoutMetadata?:  {
    __typename: "CheckoutMetadata",
    id: string,
    stripeSessionId: string,
    customerEmail: string,
    purchaseType: string,
    lineItems:  Array< {
      __typename: "OrderLineItem",
      description: string,
      quantity: number,
      amount: number,
      priceId?: string | null,
    } >,
    totalAmount: number,
    currency: string,
    discountTotal?: number | null,
    discounts?:  Array< {
      __typename: "Discount",
      amount: number,
      promoCode?: string | null,
      couponId?: string | null,
      couponName?: string | null,
    } > | null,
    recipientName?: string | null,
    recipientEmail?: string | null,
    gifterName?: string | null,
    gifterEmail?: string | null,
    giftMessage?: string | null,
    deliveryDate?: string | null,
    giftCardStyle?: string | null,
    bookOrderId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateImageGenreRelationMutationVariables = {
  input: CreateImageGenreRelationInput,
  condition?: ModelImageGenreRelationConditionInput | null,
};

export type CreateImageGenreRelationMutation = {
  createImageGenreRelation?:  {
    __typename: "ImageGenreRelation",
    id: string,
    imageId: string,
    genreId: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    genre:  {
      __typename: "Genre",
      id: string,
      name: string,
      images?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateImageGenreRelationMutationVariables = {
  input: UpdateImageGenreRelationInput,
  condition?: ModelImageGenreRelationConditionInput | null,
};

export type UpdateImageGenreRelationMutation = {
  updateImageGenreRelation?:  {
    __typename: "ImageGenreRelation",
    id: string,
    imageId: string,
    genreId: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    genre:  {
      __typename: "Genre",
      id: string,
      name: string,
      images?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteImageGenreRelationMutationVariables = {
  input: DeleteImageGenreRelationInput,
  condition?: ModelImageGenreRelationConditionInput | null,
};

export type DeleteImageGenreRelationMutation = {
  deleteImageGenreRelation?:  {
    __typename: "ImageGenreRelation",
    id: string,
    imageId: string,
    genreId: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    genre:  {
      __typename: "Genre",
      id: string,
      name: string,
      images?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateImageCategoryRelationMutationVariables = {
  input: CreateImageCategoryRelationInput,
  condition?: ModelImageCategoryRelationConditionInput | null,
};

export type CreateImageCategoryRelationMutation = {
  createImageCategoryRelation?:  {
    __typename: "ImageCategoryRelation",
    id: string,
    imageId: string,
    imageCategoryId: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    imageCategory:  {
      __typename: "ImageCategory",
      id: string,
      category: string,
      images?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateImageCategoryRelationMutationVariables = {
  input: UpdateImageCategoryRelationInput,
  condition?: ModelImageCategoryRelationConditionInput | null,
};

export type UpdateImageCategoryRelationMutation = {
  updateImageCategoryRelation?:  {
    __typename: "ImageCategoryRelation",
    id: string,
    imageId: string,
    imageCategoryId: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    imageCategory:  {
      __typename: "ImageCategory",
      id: string,
      category: string,
      images?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteImageCategoryRelationMutationVariables = {
  input: DeleteImageCategoryRelationInput,
  condition?: ModelImageCategoryRelationConditionInput | null,
};

export type DeleteImageCategoryRelationMutation = {
  deleteImageCategoryRelation?:  {
    __typename: "ImageCategoryRelation",
    id: string,
    imageId: string,
    imageCategoryId: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    imageCategory:  {
      __typename: "ImageCategory",
      id: string,
      category: string,
      images?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    cognitoUserId: string,
    email: string,
    children?:  {
      __typename: "ModelChildConnection",
      items:  Array< {
        __typename: "Child",
        id: string,
        name: string,
        age: string,
        commonNoun: string,
        readingLevel?: string | null,
        avatar?: string | null,
        storyPages?:  {
          __typename: "ModelStoryPagesConnection",
          items:  Array< {
            __typename: "StoryPages",
            id: string,
            pageIDs?: Array< string | null > | null,
            storyType?: string | null,
            storyStatus?: string | null,
            rating?: number | null,
            childStoryPagesId: string,
            lastViewed?: string | null,
            expositionImageName?: string | null,
            title?: string | null,
            initiationMethod?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userChildrenId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activeChildId?: string | null,
    settings?: string | null,
    lastInteraction?: string | null,
    emailUpdatesEnabled?: boolean | null,
    emailPromotionsEnabled?: boolean | null,
    emailStoriesFridayEnabled?: boolean | null,
    emailStoriesDailyEnabled?: boolean | null,
    trialStoriesCount?: number | null,
    bookCredits?: number | null,
    notificationSettings?:  {
      __typename: "NotificationSettings",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUserId: string,
        email: string,
        children?:  {
          __typename: "ModelChildConnection",
          items:  Array< {
            __typename: "Child",
            id: string,
            name: string,
            age: string,
            commonNoun: string,
            readingLevel?: string | null,
            avatar?: string | null,
            storyPages?:  {
              __typename: "ModelStoryPagesConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userChildrenId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        activeChildId?: string | null,
        settings?: string | null,
        lastInteraction?: string | null,
        emailUpdatesEnabled?: boolean | null,
        emailPromotionsEnabled?: boolean | null,
        emailStoriesFridayEnabled?: boolean | null,
        emailStoriesDailyEnabled?: boolean | null,
        trialStoriesCount?: number | null,
        bookCredits?: number | null,
        notificationSettings?:  {
          __typename: "NotificationSettings",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          notificationsEnabled: boolean,
          storyReminders:  {
            __typename: "StoryReminderSettings",
            enabled: boolean,
            frequency?: string | null,
          },
          promotionsEnabled?: boolean | null,
          updatesEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        deviceTokens?:  {
          __typename: "ModelDeviceTokenConnection",
          items:  Array< {
            __typename: "DeviceToken",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            token: string,
            platform: string,
            endpointArn?: string | null,
            createdAt: string,
            updatedAt: string,
            userDeviceTokensId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userNotificationSettingsId?: string | null,
        owner?: string | null,
      } | null,
      userId: string,
      notificationsEnabled: boolean,
      storyReminders:  {
        __typename: "StoryReminderSettings",
        enabled: boolean,
        frequency?: string | null,
      },
      promotionsEnabled?: boolean | null,
      updatesEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    deviceTokens?:  {
      __typename: "ModelDeviceTokenConnection",
      items:  Array< {
        __typename: "DeviceToken",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        token: string,
        platform: string,
        endpointArn?: string | null,
        createdAt: string,
        updatedAt: string,
        userDeviceTokensId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userNotificationSettingsId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoUserId: string,
      email: string,
      children?:  {
        __typename: "ModelChildConnection",
        items:  Array< {
          __typename: "Child",
          id: string,
          name: string,
          age: string,
          commonNoun: string,
          readingLevel?: string | null,
          avatar?: string | null,
          storyPages?:  {
            __typename: "ModelStoryPagesConnection",
            items:  Array< {
              __typename: "StoryPages",
              id: string,
              pageIDs?: Array< string | null > | null,
              storyType?: string | null,
              storyStatus?: string | null,
              rating?: number | null,
              childStoryPagesId: string,
              lastViewed?: string | null,
              expositionImageName?: string | null,
              title?: string | null,
              initiationMethod?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userChildrenId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activeChildId?: string | null,
      settings?: string | null,
      lastInteraction?: string | null,
      emailUpdatesEnabled?: boolean | null,
      emailPromotionsEnabled?: boolean | null,
      emailStoriesFridayEnabled?: boolean | null,
      emailStoriesDailyEnabled?: boolean | null,
      trialStoriesCount?: number | null,
      bookCredits?: number | null,
      notificationSettings?:  {
        __typename: "NotificationSettings",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        notificationsEnabled: boolean,
        storyReminders:  {
          __typename: "StoryReminderSettings",
          enabled: boolean,
          frequency?: string | null,
        },
        promotionsEnabled?: boolean | null,
        updatesEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      deviceTokens?:  {
        __typename: "ModelDeviceTokenConnection",
        items:  Array< {
          __typename: "DeviceToken",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          token: string,
          platform: string,
          endpointArn?: string | null,
          createdAt: string,
          updatedAt: string,
          userDeviceTokensId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userNotificationSettingsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByCognitoUserIdQueryVariables = {
  cognitoUserId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByCognitoUserIdQuery = {
  userByCognitoUserId?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoUserId: string,
      email: string,
      children?:  {
        __typename: "ModelChildConnection",
        items:  Array< {
          __typename: "Child",
          id: string,
          name: string,
          age: string,
          commonNoun: string,
          readingLevel?: string | null,
          avatar?: string | null,
          storyPages?:  {
            __typename: "ModelStoryPagesConnection",
            items:  Array< {
              __typename: "StoryPages",
              id: string,
              pageIDs?: Array< string | null > | null,
              storyType?: string | null,
              storyStatus?: string | null,
              rating?: number | null,
              childStoryPagesId: string,
              lastViewed?: string | null,
              expositionImageName?: string | null,
              title?: string | null,
              initiationMethod?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userChildrenId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activeChildId?: string | null,
      settings?: string | null,
      lastInteraction?: string | null,
      emailUpdatesEnabled?: boolean | null,
      emailPromotionsEnabled?: boolean | null,
      emailStoriesFridayEnabled?: boolean | null,
      emailStoriesDailyEnabled?: boolean | null,
      trialStoriesCount?: number | null,
      bookCredits?: number | null,
      notificationSettings?:  {
        __typename: "NotificationSettings",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        notificationsEnabled: boolean,
        storyReminders:  {
          __typename: "StoryReminderSettings",
          enabled: boolean,
          frequency?: string | null,
        },
        promotionsEnabled?: boolean | null,
        updatesEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      deviceTokens?:  {
        __typename: "ModelDeviceTokenConnection",
        items:  Array< {
          __typename: "DeviceToken",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          token: string,
          platform: string,
          endpointArn?: string | null,
          createdAt: string,
          updatedAt: string,
          userDeviceTokensId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userNotificationSettingsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByEmailQueryVariables = {
  email: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByEmailQuery = {
  userByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoUserId: string,
      email: string,
      children?:  {
        __typename: "ModelChildConnection",
        items:  Array< {
          __typename: "Child",
          id: string,
          name: string,
          age: string,
          commonNoun: string,
          readingLevel?: string | null,
          avatar?: string | null,
          storyPages?:  {
            __typename: "ModelStoryPagesConnection",
            items:  Array< {
              __typename: "StoryPages",
              id: string,
              pageIDs?: Array< string | null > | null,
              storyType?: string | null,
              storyStatus?: string | null,
              rating?: number | null,
              childStoryPagesId: string,
              lastViewed?: string | null,
              expositionImageName?: string | null,
              title?: string | null,
              initiationMethod?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userChildrenId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activeChildId?: string | null,
      settings?: string | null,
      lastInteraction?: string | null,
      emailUpdatesEnabled?: boolean | null,
      emailPromotionsEnabled?: boolean | null,
      emailStoriesFridayEnabled?: boolean | null,
      emailStoriesDailyEnabled?: boolean | null,
      trialStoriesCount?: number | null,
      bookCredits?: number | null,
      notificationSettings?:  {
        __typename: "NotificationSettings",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        notificationsEnabled: boolean,
        storyReminders:  {
          __typename: "StoryReminderSettings",
          enabled: boolean,
          frequency?: string | null,
        },
        promotionsEnabled?: boolean | null,
        updatesEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      deviceTokens?:  {
        __typename: "ModelDeviceTokenConnection",
        items:  Array< {
          __typename: "DeviceToken",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          token: string,
          platform: string,
          endpointArn?: string | null,
          createdAt: string,
          updatedAt: string,
          userDeviceTokensId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userNotificationSettingsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationSettingsQueryVariables = {
  id: string,
};

export type GetNotificationSettingsQuery = {
  getNotificationSettings?:  {
    __typename: "NotificationSettings",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId: string,
      email: string,
      children?:  {
        __typename: "ModelChildConnection",
        items:  Array< {
          __typename: "Child",
          id: string,
          name: string,
          age: string,
          commonNoun: string,
          readingLevel?: string | null,
          avatar?: string | null,
          storyPages?:  {
            __typename: "ModelStoryPagesConnection",
            items:  Array< {
              __typename: "StoryPages",
              id: string,
              pageIDs?: Array< string | null > | null,
              storyType?: string | null,
              storyStatus?: string | null,
              rating?: number | null,
              childStoryPagesId: string,
              lastViewed?: string | null,
              expositionImageName?: string | null,
              title?: string | null,
              initiationMethod?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userChildrenId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activeChildId?: string | null,
      settings?: string | null,
      lastInteraction?: string | null,
      emailUpdatesEnabled?: boolean | null,
      emailPromotionsEnabled?: boolean | null,
      emailStoriesFridayEnabled?: boolean | null,
      emailStoriesDailyEnabled?: boolean | null,
      trialStoriesCount?: number | null,
      bookCredits?: number | null,
      notificationSettings?:  {
        __typename: "NotificationSettings",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        notificationsEnabled: boolean,
        storyReminders:  {
          __typename: "StoryReminderSettings",
          enabled: boolean,
          frequency?: string | null,
        },
        promotionsEnabled?: boolean | null,
        updatesEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      deviceTokens?:  {
        __typename: "ModelDeviceTokenConnection",
        items:  Array< {
          __typename: "DeviceToken",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          token: string,
          platform: string,
          endpointArn?: string | null,
          createdAt: string,
          updatedAt: string,
          userDeviceTokensId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userNotificationSettingsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    notificationsEnabled: boolean,
    storyReminders:  {
      __typename: "StoryReminderSettings",
      enabled: boolean,
      frequency?: string | null,
    },
    promotionsEnabled?: boolean | null,
    updatesEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListNotificationSettingsQueryVariables = {
  id?: string | null,
  filter?: ModelNotificationSettingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNotificationSettingsQuery = {
  listNotificationSettings?:  {
    __typename: "ModelNotificationSettingsConnection",
    items:  Array< {
      __typename: "NotificationSettings",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUserId: string,
        email: string,
        children?:  {
          __typename: "ModelChildConnection",
          items:  Array< {
            __typename: "Child",
            id: string,
            name: string,
            age: string,
            commonNoun: string,
            readingLevel?: string | null,
            avatar?: string | null,
            storyPages?:  {
              __typename: "ModelStoryPagesConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userChildrenId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        activeChildId?: string | null,
        settings?: string | null,
        lastInteraction?: string | null,
        emailUpdatesEnabled?: boolean | null,
        emailPromotionsEnabled?: boolean | null,
        emailStoriesFridayEnabled?: boolean | null,
        emailStoriesDailyEnabled?: boolean | null,
        trialStoriesCount?: number | null,
        bookCredits?: number | null,
        notificationSettings?:  {
          __typename: "NotificationSettings",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          notificationsEnabled: boolean,
          storyReminders:  {
            __typename: "StoryReminderSettings",
            enabled: boolean,
            frequency?: string | null,
          },
          promotionsEnabled?: boolean | null,
          updatesEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        deviceTokens?:  {
          __typename: "ModelDeviceTokenConnection",
          items:  Array< {
            __typename: "DeviceToken",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            token: string,
            platform: string,
            endpointArn?: string | null,
            createdAt: string,
            updatedAt: string,
            userDeviceTokensId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userNotificationSettingsId?: string | null,
        owner?: string | null,
      } | null,
      userId: string,
      notificationsEnabled: boolean,
      storyReminders:  {
        __typename: "StoryReminderSettings",
        enabled: boolean,
        frequency?: string | null,
      },
      promotionsEnabled?: boolean | null,
      updatesEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationSettingsByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationSettingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationSettingsByUserIdQuery = {
  notificationSettingsByUserId?:  {
    __typename: "ModelNotificationSettingsConnection",
    items:  Array< {
      __typename: "NotificationSettings",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUserId: string,
        email: string,
        children?:  {
          __typename: "ModelChildConnection",
          items:  Array< {
            __typename: "Child",
            id: string,
            name: string,
            age: string,
            commonNoun: string,
            readingLevel?: string | null,
            avatar?: string | null,
            storyPages?:  {
              __typename: "ModelStoryPagesConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userChildrenId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        activeChildId?: string | null,
        settings?: string | null,
        lastInteraction?: string | null,
        emailUpdatesEnabled?: boolean | null,
        emailPromotionsEnabled?: boolean | null,
        emailStoriesFridayEnabled?: boolean | null,
        emailStoriesDailyEnabled?: boolean | null,
        trialStoriesCount?: number | null,
        bookCredits?: number | null,
        notificationSettings?:  {
          __typename: "NotificationSettings",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          notificationsEnabled: boolean,
          storyReminders:  {
            __typename: "StoryReminderSettings",
            enabled: boolean,
            frequency?: string | null,
          },
          promotionsEnabled?: boolean | null,
          updatesEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        deviceTokens?:  {
          __typename: "ModelDeviceTokenConnection",
          items:  Array< {
            __typename: "DeviceToken",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            token: string,
            platform: string,
            endpointArn?: string | null,
            createdAt: string,
            updatedAt: string,
            userDeviceTokensId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userNotificationSettingsId?: string | null,
        owner?: string | null,
      } | null,
      userId: string,
      notificationsEnabled: boolean,
      storyReminders:  {
        __typename: "StoryReminderSettings",
        enabled: boolean,
        frequency?: string | null,
      },
      promotionsEnabled?: boolean | null,
      updatesEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDeviceTokenQueryVariables = {
  id: string,
};

export type GetDeviceTokenQuery = {
  getDeviceToken?:  {
    __typename: "DeviceToken",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId: string,
      email: string,
      children?:  {
        __typename: "ModelChildConnection",
        items:  Array< {
          __typename: "Child",
          id: string,
          name: string,
          age: string,
          commonNoun: string,
          readingLevel?: string | null,
          avatar?: string | null,
          storyPages?:  {
            __typename: "ModelStoryPagesConnection",
            items:  Array< {
              __typename: "StoryPages",
              id: string,
              pageIDs?: Array< string | null > | null,
              storyType?: string | null,
              storyStatus?: string | null,
              rating?: number | null,
              childStoryPagesId: string,
              lastViewed?: string | null,
              expositionImageName?: string | null,
              title?: string | null,
              initiationMethod?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userChildrenId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activeChildId?: string | null,
      settings?: string | null,
      lastInteraction?: string | null,
      emailUpdatesEnabled?: boolean | null,
      emailPromotionsEnabled?: boolean | null,
      emailStoriesFridayEnabled?: boolean | null,
      emailStoriesDailyEnabled?: boolean | null,
      trialStoriesCount?: number | null,
      bookCredits?: number | null,
      notificationSettings?:  {
        __typename: "NotificationSettings",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        notificationsEnabled: boolean,
        storyReminders:  {
          __typename: "StoryReminderSettings",
          enabled: boolean,
          frequency?: string | null,
        },
        promotionsEnabled?: boolean | null,
        updatesEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      deviceTokens?:  {
        __typename: "ModelDeviceTokenConnection",
        items:  Array< {
          __typename: "DeviceToken",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          token: string,
          platform: string,
          endpointArn?: string | null,
          createdAt: string,
          updatedAt: string,
          userDeviceTokensId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userNotificationSettingsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    token: string,
    platform: string,
    endpointArn?: string | null,
    createdAt: string,
    updatedAt: string,
    userDeviceTokensId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListDeviceTokensQueryVariables = {
  id?: string | null,
  filter?: ModelDeviceTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDeviceTokensQuery = {
  listDeviceTokens?:  {
    __typename: "ModelDeviceTokenConnection",
    items:  Array< {
      __typename: "DeviceToken",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUserId: string,
        email: string,
        children?:  {
          __typename: "ModelChildConnection",
          items:  Array< {
            __typename: "Child",
            id: string,
            name: string,
            age: string,
            commonNoun: string,
            readingLevel?: string | null,
            avatar?: string | null,
            storyPages?:  {
              __typename: "ModelStoryPagesConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userChildrenId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        activeChildId?: string | null,
        settings?: string | null,
        lastInteraction?: string | null,
        emailUpdatesEnabled?: boolean | null,
        emailPromotionsEnabled?: boolean | null,
        emailStoriesFridayEnabled?: boolean | null,
        emailStoriesDailyEnabled?: boolean | null,
        trialStoriesCount?: number | null,
        bookCredits?: number | null,
        notificationSettings?:  {
          __typename: "NotificationSettings",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          notificationsEnabled: boolean,
          storyReminders:  {
            __typename: "StoryReminderSettings",
            enabled: boolean,
            frequency?: string | null,
          },
          promotionsEnabled?: boolean | null,
          updatesEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        deviceTokens?:  {
          __typename: "ModelDeviceTokenConnection",
          items:  Array< {
            __typename: "DeviceToken",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            token: string,
            platform: string,
            endpointArn?: string | null,
            createdAt: string,
            updatedAt: string,
            userDeviceTokensId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userNotificationSettingsId?: string | null,
        owner?: string | null,
      } | null,
      userId: string,
      token: string,
      platform: string,
      endpointArn?: string | null,
      createdAt: string,
      updatedAt: string,
      userDeviceTokensId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DeviceTokensByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDeviceTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DeviceTokensByUserIdQuery = {
  deviceTokensByUserId?:  {
    __typename: "ModelDeviceTokenConnection",
    items:  Array< {
      __typename: "DeviceToken",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUserId: string,
        email: string,
        children?:  {
          __typename: "ModelChildConnection",
          items:  Array< {
            __typename: "Child",
            id: string,
            name: string,
            age: string,
            commonNoun: string,
            readingLevel?: string | null,
            avatar?: string | null,
            storyPages?:  {
              __typename: "ModelStoryPagesConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userChildrenId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        activeChildId?: string | null,
        settings?: string | null,
        lastInteraction?: string | null,
        emailUpdatesEnabled?: boolean | null,
        emailPromotionsEnabled?: boolean | null,
        emailStoriesFridayEnabled?: boolean | null,
        emailStoriesDailyEnabled?: boolean | null,
        trialStoriesCount?: number | null,
        bookCredits?: number | null,
        notificationSettings?:  {
          __typename: "NotificationSettings",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          notificationsEnabled: boolean,
          storyReminders:  {
            __typename: "StoryReminderSettings",
            enabled: boolean,
            frequency?: string | null,
          },
          promotionsEnabled?: boolean | null,
          updatesEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        deviceTokens?:  {
          __typename: "ModelDeviceTokenConnection",
          items:  Array< {
            __typename: "DeviceToken",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            token: string,
            platform: string,
            endpointArn?: string | null,
            createdAt: string,
            updatedAt: string,
            userDeviceTokensId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userNotificationSettingsId?: string | null,
        owner?: string | null,
      } | null,
      userId: string,
      token: string,
      platform: string,
      endpointArn?: string | null,
      createdAt: string,
      updatedAt: string,
      userDeviceTokensId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DeviceTokenByUserIdAndTokenQueryVariables = {
  token: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDeviceTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DeviceTokenByUserIdAndTokenQuery = {
  deviceTokenByUserIdAndToken?:  {
    __typename: "ModelDeviceTokenConnection",
    items:  Array< {
      __typename: "DeviceToken",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUserId: string,
        email: string,
        children?:  {
          __typename: "ModelChildConnection",
          items:  Array< {
            __typename: "Child",
            id: string,
            name: string,
            age: string,
            commonNoun: string,
            readingLevel?: string | null,
            avatar?: string | null,
            storyPages?:  {
              __typename: "ModelStoryPagesConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userChildrenId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        activeChildId?: string | null,
        settings?: string | null,
        lastInteraction?: string | null,
        emailUpdatesEnabled?: boolean | null,
        emailPromotionsEnabled?: boolean | null,
        emailStoriesFridayEnabled?: boolean | null,
        emailStoriesDailyEnabled?: boolean | null,
        trialStoriesCount?: number | null,
        bookCredits?: number | null,
        notificationSettings?:  {
          __typename: "NotificationSettings",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          notificationsEnabled: boolean,
          storyReminders:  {
            __typename: "StoryReminderSettings",
            enabled: boolean,
            frequency?: string | null,
          },
          promotionsEnabled?: boolean | null,
          updatesEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        deviceTokens?:  {
          __typename: "ModelDeviceTokenConnection",
          items:  Array< {
            __typename: "DeviceToken",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            token: string,
            platform: string,
            endpointArn?: string | null,
            createdAt: string,
            updatedAt: string,
            userDeviceTokensId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userNotificationSettingsId?: string | null,
        owner?: string | null,
      } | null,
      userId: string,
      token: string,
      platform: string,
      endpointArn?: string | null,
      createdAt: string,
      updatedAt: string,
      userDeviceTokensId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWaitlistQueryVariables = {
  cognitoUserId: string,
};

export type GetWaitlistQuery = {
  getWaitlist?:  {
    __typename: "Waitlist",
    cognitoUserId: string,
    email: string,
    accessGranted: boolean,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type ListWaitlistsQueryVariables = {
  cognitoUserId?: string | null,
  filter?: ModelWaitlistFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListWaitlistsQuery = {
  listWaitlists?:  {
    __typename: "ModelWaitlistConnection",
    items:  Array< {
      __typename: "Waitlist",
      cognitoUserId: string,
      email: string,
      accessGranted: boolean,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGiftcardQueryVariables = {
  id: string,
};

export type GetGiftcardQuery = {
  getGiftcard?:  {
    __typename: "Giftcard",
    id: string,
    giftcode: string,
    recipientEmail: string,
    subscriptionProduct: string,
    storyCredits: number,
    bookCredits: number,
    giftingEmail?: string | null,
    redeemed: boolean,
    redeemedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGiftcardsQueryVariables = {
  filter?: ModelGiftcardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGiftcardsQuery = {
  listGiftcards?:  {
    __typename: "ModelGiftcardConnection",
    items:  Array< {
      __typename: "Giftcard",
      id: string,
      giftcode: string,
      recipientEmail: string,
      subscriptionProduct: string,
      storyCredits: number,
      bookCredits: number,
      giftingEmail?: string | null,
      redeemed: boolean,
      redeemedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GiftcardByGiftcodeQueryVariables = {
  giftcode: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGiftcardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GiftcardByGiftcodeQuery = {
  giftcardByGiftcode?:  {
    __typename: "ModelGiftcardConnection",
    items:  Array< {
      __typename: "Giftcard",
      id: string,
      giftcode: string,
      recipientEmail: string,
      subscriptionProduct: string,
      storyCredits: number,
      bookCredits: number,
      giftingEmail?: string | null,
      redeemed: boolean,
      redeemedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GiftcardsByRecipientEmailQueryVariables = {
  recipientEmail: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGiftcardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GiftcardsByRecipientEmailQuery = {
  giftcardsByRecipientEmail?:  {
    __typename: "ModelGiftcardConnection",
    items:  Array< {
      __typename: "Giftcard",
      id: string,
      giftcode: string,
      recipientEmail: string,
      subscriptionProduct: string,
      storyCredits: number,
      bookCredits: number,
      giftingEmail?: string | null,
      redeemed: boolean,
      redeemedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMetadataQueryVariables = {
  id: string,
};

export type GetMetadataQuery = {
  getMetadata?:  {
    __typename: "Metadata",
    id: string,
    isUnderMaintenance: boolean,
    startingTrialStoriesCount: number,
    waitlistEnabled: boolean,
    minimumAppVersion?: string | null,
    promotionString?: string | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type ListMetadataQueryVariables = {
  filter?: ModelMetadataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMetadataQuery = {
  listMetadata?:  {
    __typename: "ModelMetadataConnection",
    items:  Array< {
      __typename: "Metadata",
      id: string,
      isUnderMaintenance: boolean,
      startingTrialStoriesCount: number,
      waitlistEnabled: boolean,
      minimumAppVersion?: string | null,
      promotionString?: string | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserPaymentQueryVariables = {
  stripeSubscriptionId: string,
};

export type GetUserPaymentQuery = {
  getUserPayment?:  {
    __typename: "UserPayment",
    stripeSubscriptionId: string,
    cognitoUserId: string,
    stripeCustomerId?: string | null,
    currentTransactionId?: string | null,
    status: SubscriptionStatus,
    currentPeriodStart?: string | null,
    currentPeriodEnd?: string | null,
    cancelAtPeriodEnd?: boolean | null,
    price:  {
      __typename: "StripePrice",
      id: string,
      amount: number,
      interval?: BillingInterval | null,
      interval_count?: number | null,
      currency: string,
    },
    product:  {
      __typename: "StripeProduct",
      id: string,
      name: string,
      description?: string | null,
    },
    provider?: PaymentProvider | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListUserPaymentsQueryVariables = {
  stripeSubscriptionId?: string | null,
  filter?: ModelUserPaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserPaymentsQuery = {
  listUserPayments?:  {
    __typename: "ModelUserPaymentConnection",
    items:  Array< {
      __typename: "UserPayment",
      stripeSubscriptionId: string,
      cognitoUserId: string,
      stripeCustomerId?: string | null,
      currentTransactionId?: string | null,
      status: SubscriptionStatus,
      currentPeriodStart?: string | null,
      currentPeriodEnd?: string | null,
      cancelAtPeriodEnd?: boolean | null,
      price:  {
        __typename: "StripePrice",
        id: string,
        amount: number,
        interval?: BillingInterval | null,
        interval_count?: number | null,
        currency: string,
      },
      product:  {
        __typename: "StripeProduct",
        id: string,
        name: string,
        description?: string | null,
      },
      provider?: PaymentProvider | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserPaymentByCognitoUserIdQueryVariables = {
  cognitoUserId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserPaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserPaymentByCognitoUserIdQuery = {
  userPaymentByCognitoUserId?:  {
    __typename: "ModelUserPaymentConnection",
    items:  Array< {
      __typename: "UserPayment",
      stripeSubscriptionId: string,
      cognitoUserId: string,
      stripeCustomerId?: string | null,
      currentTransactionId?: string | null,
      status: SubscriptionStatus,
      currentPeriodStart?: string | null,
      currentPeriodEnd?: string | null,
      cancelAtPeriodEnd?: boolean | null,
      price:  {
        __typename: "StripePrice",
        id: string,
        amount: number,
        interval?: BillingInterval | null,
        interval_count?: number | null,
        currency: string,
      },
      product:  {
        __typename: "StripeProduct",
        id: string,
        name: string,
        description?: string | null,
      },
      provider?: PaymentProvider | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTransactionsQueryVariables = {
  id: string,
};

export type GetTransactionsQuery = {
  getTransactions?:  {
    __typename: "Transactions",
    id: string,
    cognitoUserId: string,
    transactionId: string,
    originalTransactionId?: string | null,
    product:  {
      __typename: "StripeProduct",
      id: string,
      name: string,
      description?: string | null,
    },
    price:  {
      __typename: "StripePrice",
      id: string,
      amount: number,
      interval?: BillingInterval | null,
      interval_count?: number | null,
      currency: string,
    },
    receipt?: string | null,
    provider?: PaymentProvider | null,
    discounts?: string | null,
    transactionDate?: string | null,
    errorCode?: string | null,
    errorMessage?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListTransactionsQueryVariables = {
  filter?: ModelTransactionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTransactionsQuery = {
  listTransactions?:  {
    __typename: "ModelTransactionsConnection",
    items:  Array< {
      __typename: "Transactions",
      id: string,
      cognitoUserId: string,
      transactionId: string,
      originalTransactionId?: string | null,
      product:  {
        __typename: "StripeProduct",
        id: string,
        name: string,
        description?: string | null,
      },
      price:  {
        __typename: "StripePrice",
        id: string,
        amount: number,
        interval?: BillingInterval | null,
        interval_count?: number | null,
        currency: string,
      },
      receipt?: string | null,
      provider?: PaymentProvider | null,
      discounts?: string | null,
      transactionDate?: string | null,
      errorCode?: string | null,
      errorMessage?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TransactionsByCognitoUserIdQueryVariables = {
  cognitoUserId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TransactionsByCognitoUserIdQuery = {
  transactionsByCognitoUserId?:  {
    __typename: "ModelTransactionsConnection",
    items:  Array< {
      __typename: "Transactions",
      id: string,
      cognitoUserId: string,
      transactionId: string,
      originalTransactionId?: string | null,
      product:  {
        __typename: "StripeProduct",
        id: string,
        name: string,
        description?: string | null,
      },
      price:  {
        __typename: "StripePrice",
        id: string,
        amount: number,
        interval?: BillingInterval | null,
        interval_count?: number | null,
        currency: string,
      },
      receipt?: string | null,
      provider?: PaymentProvider | null,
      discounts?: string | null,
      transactionDate?: string | null,
      errorCode?: string | null,
      errorMessage?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TransactionsByTransactionIDQueryVariables = {
  transactionId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TransactionsByTransactionIDQuery = {
  transactionsByTransactionID?:  {
    __typename: "ModelTransactionsConnection",
    items:  Array< {
      __typename: "Transactions",
      id: string,
      cognitoUserId: string,
      transactionId: string,
      originalTransactionId?: string | null,
      product:  {
        __typename: "StripeProduct",
        id: string,
        name: string,
        description?: string | null,
      },
      price:  {
        __typename: "StripePrice",
        id: string,
        amount: number,
        interval?: BillingInterval | null,
        interval_count?: number | null,
        currency: string,
      },
      receipt?: string | null,
      provider?: PaymentProvider | null,
      discounts?: string | null,
      transactionDate?: string | null,
      errorCode?: string | null,
      errorMessage?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TransactionsByOriginalTransactionIdQueryVariables = {
  originalTransactionId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TransactionsByOriginalTransactionIdQuery = {
  transactionsByOriginalTransactionId?:  {
    __typename: "ModelTransactionsConnection",
    items:  Array< {
      __typename: "Transactions",
      id: string,
      cognitoUserId: string,
      transactionId: string,
      originalTransactionId?: string | null,
      product:  {
        __typename: "StripeProduct",
        id: string,
        name: string,
        description?: string | null,
      },
      price:  {
        __typename: "StripePrice",
        id: string,
        amount: number,
        interval?: BillingInterval | null,
        interval_count?: number | null,
        currency: string,
      },
      receipt?: string | null,
      provider?: PaymentProvider | null,
      discounts?: string | null,
      transactionDate?: string | null,
      errorCode?: string | null,
      errorMessage?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetChildQueryVariables = {
  id: string,
};

export type GetChildQuery = {
  getChild?:  {
    __typename: "Child",
    id: string,
    name: string,
    age: string,
    commonNoun: string,
    readingLevel?: string | null,
    avatar?: string | null,
    storyPages?:  {
      __typename: "ModelStoryPagesConnection",
      items:  Array< {
        __typename: "StoryPages",
        id: string,
        pageIDs?: Array< string | null > | null,
        storyType?: string | null,
        storyStatus?: string | null,
        rating?: number | null,
        childStoryPagesId: string,
        lastViewed?: string | null,
        expositionImageName?: string | null,
        title?: string | null,
        initiationMethod?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userChildrenId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListChildrenQueryVariables = {
  filter?: ModelChildFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChildrenQuery = {
  listChildren?:  {
    __typename: "ModelChildConnection",
    items:  Array< {
      __typename: "Child",
      id: string,
      name: string,
      age: string,
      commonNoun: string,
      readingLevel?: string | null,
      avatar?: string | null,
      storyPages?:  {
        __typename: "ModelStoryPagesConnection",
        items:  Array< {
          __typename: "StoryPages",
          id: string,
          pageIDs?: Array< string | null > | null,
          storyType?: string | null,
          storyStatus?: string | null,
          rating?: number | null,
          childStoryPagesId: string,
          lastViewed?: string | null,
          expositionImageName?: string | null,
          title?: string | null,
          initiationMethod?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userChildrenId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPageQueryVariables = {
  id: string,
};

export type GetPageQuery = {
  getPage?:  {
    __typename: "Page",
    id: string,
    cognitoUserId: string,
    pageText: string,
    pageOptions: Array< string | null >,
    imageName?: string | null,
    minorImageName?: string | null,
    characterImageNames?: string | null,
    primaryImage?: string | null,
    protagonist?: string | null,
    storyType?: string | null,
    location?: string | null,
    readingLevel?: string | null,
    preceededBy?: string | null,
    followedBy?: Array< string | null > | null,
    followedByEnding?: Array< string | null > | null,
    isExposition?: boolean | null,
    isEnding?: boolean | null,
    isAnonymized?: boolean | null,
    status?: PageStatus | null,
    isAccepted?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title?: string | null,
  } | null,
};

export type ListPagesQueryVariables = {
  filter?: ModelPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPagesQuery = {
  listPages?:  {
    __typename: "ModelPageConnection",
    items:  Array< {
      __typename: "Page",
      id: string,
      cognitoUserId: string,
      pageText: string,
      pageOptions: Array< string | null >,
      imageName?: string | null,
      minorImageName?: string | null,
      characterImageNames?: string | null,
      primaryImage?: string | null,
      protagonist?: string | null,
      storyType?: string | null,
      location?: string | null,
      readingLevel?: string | null,
      preceededBy?: string | null,
      followedBy?: Array< string | null > | null,
      followedByEnding?: Array< string | null > | null,
      isExposition?: boolean | null,
      isEnding?: boolean | null,
      isAnonymized?: boolean | null,
      status?: PageStatus | null,
      isAccepted?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSharedPageQueryVariables = {
  id: string,
};

export type GetSharedPageQuery = {
  getSharedPage?:  {
    __typename: "SharedPage",
    id: string,
    pageText: string,
    pageOptions?: Array< string | null > | null,
    imageName?: string | null,
    protagonist?: string | null,
    storyType?: string | null,
    location?: string | null,
    readingLevel?: string | null,
    preceededBy?: string | null,
    followedBy?: Array< string | null > | null,
    followedByEnding?: Array< string | null > | null,
    status?: PageStatus | null,
    isExposition?: boolean | null,
    isEnding?: boolean | null,
    isAnonymized?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title?: string | null,
  } | null,
};

export type ListSharedPagesQueryVariables = {
  filter?: ModelSharedPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSharedPagesQuery = {
  listSharedPages?:  {
    __typename: "ModelSharedPageConnection",
    items:  Array< {
      __typename: "SharedPage",
      id: string,
      pageText: string,
      pageOptions?: Array< string | null > | null,
      imageName?: string | null,
      protagonist?: string | null,
      storyType?: string | null,
      location?: string | null,
      readingLevel?: string | null,
      preceededBy?: string | null,
      followedBy?: Array< string | null > | null,
      followedByEnding?: Array< string | null > | null,
      status?: PageStatus | null,
      isExposition?: boolean | null,
      isEnding?: boolean | null,
      isAnonymized?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStoryPagesQueryVariables = {
  id: string,
};

export type GetStoryPagesQuery = {
  getStoryPages?:  {
    __typename: "StoryPages",
    id: string,
    pageIDs?: Array< string | null > | null,
    storyType?: string | null,
    storyStatus?: string | null,
    rating?: number | null,
    childStoryPagesId: string,
    lastViewed?: string | null,
    expositionImageName?: string | null,
    title?: string | null,
    initiationMethod?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListStoryPagesQueryVariables = {
  filter?: ModelStoryPagesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStoryPagesQuery = {
  listStoryPages?:  {
    __typename: "ModelStoryPagesConnection",
    items:  Array< {
      __typename: "StoryPages",
      id: string,
      pageIDs?: Array< string | null > | null,
      storyType?: string | null,
      storyStatus?: string | null,
      rating?: number | null,
      childStoryPagesId: string,
      lastViewed?: string | null,
      expositionImageName?: string | null,
      title?: string | null,
      initiationMethod?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type StoryPagesByChildIdQueryVariables = {
  childStoryPagesId: string,
  lastViewed?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStoryPagesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StoryPagesByChildIdQuery = {
  storyPagesByChildId?:  {
    __typename: "ModelStoryPagesConnection",
    items:  Array< {
      __typename: "StoryPages",
      id: string,
      pageIDs?: Array< string | null > | null,
      storyType?: string | null,
      storyStatus?: string | null,
      rating?: number | null,
      childStoryPagesId: string,
      lastViewed?: string | null,
      expositionImageName?: string | null,
      title?: string | null,
      initiationMethod?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDailyStoryQueryVariables = {
  id: string,
};

export type GetDailyStoryQuery = {
  getDailyStory?:  {
    __typename: "DailyStory",
    id: string,
    expositionPageID: string,
    storyType?: string | null,
    pageDepth?: string | null,
    status?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    approvedAt?: string | null,
    storyTitle?: string | null,
    displayStart?: string | null,
  } | null,
};

export type ListDailyStoriesQueryVariables = {
  filter?: ModelDailyStoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDailyStoriesQuery = {
  listDailyStories?:  {
    __typename: "ModelDailyStoryConnection",
    items:  Array< {
      __typename: "DailyStory",
      id: string,
      expositionPageID: string,
      storyType?: string | null,
      pageDepth?: string | null,
      status?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      approvedAt?: string | null,
      storyTitle?: string | null,
      displayStart?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DailyStoryByDisplayStartQueryVariables = {
  displayStart: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDailyStoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DailyStoryByDisplayStartQuery = {
  dailyStoryByDisplayStart?:  {
    __typename: "ModelDailyStoryConnection",
    items:  Array< {
      __typename: "DailyStory",
      id: string,
      expositionPageID: string,
      storyType?: string | null,
      pageDepth?: string | null,
      status?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      approvedAt?: string | null,
      storyTitle?: string | null,
      displayStart?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetImageSubjectsLogQueryVariables = {
  id: string,
};

export type GetImageSubjectsLogQuery = {
  getImageSubjectsLog?:  {
    __typename: "imageSubjectsLog",
    id: string,
    subjects?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListImageSubjectsLogsQueryVariables = {
  filter?: ModelImageSubjectsLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListImageSubjectsLogsQuery = {
  listImageSubjectsLogs?:  {
    __typename: "ModelImageSubjectsLogConnection",
    items:  Array< {
      __typename: "imageSubjectsLog",
      id: string,
      subjects?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetJobStatusQueryVariables = {
  id: string,
};

export type GetJobStatusQuery = {
  getJobStatus?:  {
    __typename: "JobStatus",
    id: string,
    jobStatus: string,
    jobResult?:  {
      __typename: "JobResult",
      success?: boolean | null,
      pageIDs?: Array< string | null > | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    cognitoUserId?: string | null,
  } | null,
};

export type ListJobStatusesQueryVariables = {
  filter?: ModelJobStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobStatusesQuery = {
  listJobStatuses?:  {
    __typename: "ModelJobStatusConnection",
    items:  Array< {
      __typename: "JobStatus",
      id: string,
      jobStatus: string,
      jobResult?:  {
        __typename: "JobResult",
        success?: boolean | null,
        pageIDs?: Array< string | null > | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      cognitoUserId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetImageQueryVariables = {
  id: string,
};

export type GetImageQuery = {
  getImage?:  {
    __typename: "Image",
    id: string,
    name: string,
    type: ImageType,
    genres?:  {
      __typename: "ModelImageGenreRelationConnection",
      items:  Array< {
        __typename: "ImageGenreRelation",
        id: string,
        imageId: string,
        genreId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        genre:  {
          __typename: "Genre",
          id: string,
          name: string,
          images?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    expressions?:  {
      __typename: "ModelExpressionConnection",
      items:  Array< {
        __typename: "Expression",
        id: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        name: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        imageExpressionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    traits?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    categories?:  {
      __typename: "ModelImageCategoryRelationConnection",
      items:  Array< {
        __typename: "ImageCategoryRelation",
        id: string,
        imageId: string,
        imageCategoryId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        imageCategory:  {
          __typename: "ImageCategory",
          id: string,
          category: string,
          images?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    characterSet?: string | null,
    characterSetName?: string | null,
    location?: string | null,
  } | null,
};

export type ListImagesQueryVariables = {
  filter?: ModelImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListImagesQuery = {
  listImages?:  {
    __typename: "ModelImageConnection",
    items:  Array< {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ImageByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ImageByNameQuery = {
  imageByName?:  {
    __typename: "ModelImageConnection",
    items:  Array< {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ImagesByTypeQueryVariables = {
  type: ImageType,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ImagesByTypeQuery = {
  imagesByType?:  {
    __typename: "ModelImageConnection",
    items:  Array< {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ImagesByCharacterSetAndNameQueryVariables = {
  characterSetName: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ImagesByCharacterSetAndNameQuery = {
  imagesByCharacterSetAndName?:  {
    __typename: "ModelImageConnection",
    items:  Array< {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetExpressionQueryVariables = {
  id: string,
};

export type GetExpressionQuery = {
  getExpression?:  {
    __typename: "Expression",
    id: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    name: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    imageExpressionsId?: string | null,
  } | null,
};

export type ListExpressionsQueryVariables = {
  filter?: ModelExpressionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExpressionsQuery = {
  listExpressions?:  {
    __typename: "ModelExpressionConnection",
    items:  Array< {
      __typename: "Expression",
      id: string,
      image:  {
        __typename: "Image",
        id: string,
        name: string,
        type: ImageType,
        genres?:  {
          __typename: "ModelImageGenreRelationConnection",
          items:  Array< {
            __typename: "ImageGenreRelation",
            id: string,
            imageId: string,
            genreId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            genre:  {
              __typename: "Genre",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        expressions?:  {
          __typename: "ModelExpressionConnection",
          items:  Array< {
            __typename: "Expression",
            id: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            name: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            imageExpressionsId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        traits?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        categories?:  {
          __typename: "ModelImageCategoryRelationConnection",
          items:  Array< {
            __typename: "ImageCategoryRelation",
            id: string,
            imageId: string,
            imageCategoryId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            imageCategory:  {
              __typename: "ImageCategory",
              id: string,
              category: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        characterSet?: string | null,
        characterSetName?: string | null,
        location?: string | null,
      },
      name: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      imageExpressionsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGenreQueryVariables = {
  id: string,
};

export type GetGenreQuery = {
  getGenre?:  {
    __typename: "Genre",
    id: string,
    name: string,
    images?:  {
      __typename: "ModelImageGenreRelationConnection",
      items:  Array< {
        __typename: "ImageGenreRelation",
        id: string,
        imageId: string,
        genreId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        genre:  {
          __typename: "Genre",
          id: string,
          name: string,
          images?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListGenresQueryVariables = {
  filter?: ModelGenreFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGenresQuery = {
  listGenres?:  {
    __typename: "ModelGenreConnection",
    items:  Array< {
      __typename: "Genre",
      id: string,
      name: string,
      images?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GenreByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGenreFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GenreByNameQuery = {
  genreByName?:  {
    __typename: "ModelGenreConnection",
    items:  Array< {
      __typename: "Genre",
      id: string,
      name: string,
      images?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetImageCategoryQueryVariables = {
  id: string,
};

export type GetImageCategoryQuery = {
  getImageCategory?:  {
    __typename: "ImageCategory",
    id: string,
    category: string,
    images?:  {
      __typename: "ModelImageCategoryRelationConnection",
      items:  Array< {
        __typename: "ImageCategoryRelation",
        id: string,
        imageId: string,
        imageCategoryId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        imageCategory:  {
          __typename: "ImageCategory",
          id: string,
          category: string,
          images?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListImageCategoriesQueryVariables = {
  filter?: ModelImageCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListImageCategoriesQuery = {
  listImageCategories?:  {
    __typename: "ModelImageCategoryConnection",
    items:  Array< {
      __typename: "ImageCategory",
      id: string,
      category: string,
      images?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBookOrderQueryVariables = {
  id: string,
};

export type GetBookOrderQuery = {
  getBookOrder?:  {
    __typename: "BookOrder",
    id: string,
    checkoutId: string,
    storyId: string,
    title: string,
    bookplateStyle?: string | null,
    bookplateName?: string | null,
    byline?: string | null,
    dedication?: string | null,
    lineItems:  Array< {
      __typename: "OrderLineItem",
      description: string,
      quantity: number,
      amount: number,
      priceId?: string | null,
    } >,
    shippingName: string,
    shippingAddressLine1: string,
    shippingAddressLine2?: string | null,
    shippingCity: string,
    shippingState: string,
    shippingPostalCode: string,
    shippingCountry: string,
    status?: string | null,
    statusTimeline?: string | null,
    customerEmail: string,
    totalAmount: number,
    currency: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListBookOrdersQueryVariables = {
  filter?: ModelBookOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBookOrdersQuery = {
  listBookOrders?:  {
    __typename: "ModelBookOrderConnection",
    items:  Array< {
      __typename: "BookOrder",
      id: string,
      checkoutId: string,
      storyId: string,
      title: string,
      bookplateStyle?: string | null,
      bookplateName?: string | null,
      byline?: string | null,
      dedication?: string | null,
      lineItems:  Array< {
        __typename: "OrderLineItem",
        description: string,
        quantity: number,
        amount: number,
        priceId?: string | null,
      } >,
      shippingName: string,
      shippingAddressLine1: string,
      shippingAddressLine2?: string | null,
      shippingCity: string,
      shippingState: string,
      shippingPostalCode: string,
      shippingCountry: string,
      status?: string | null,
      statusTimeline?: string | null,
      customerEmail: string,
      totalAmount: number,
      currency: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BookOrdersByCheckoutIdQueryVariables = {
  checkoutId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookOrdersByCheckoutIdQuery = {
  bookOrdersByCheckoutId?:  {
    __typename: "ModelBookOrderConnection",
    items:  Array< {
      __typename: "BookOrder",
      id: string,
      checkoutId: string,
      storyId: string,
      title: string,
      bookplateStyle?: string | null,
      bookplateName?: string | null,
      byline?: string | null,
      dedication?: string | null,
      lineItems:  Array< {
        __typename: "OrderLineItem",
        description: string,
        quantity: number,
        amount: number,
        priceId?: string | null,
      } >,
      shippingName: string,
      shippingAddressLine1: string,
      shippingAddressLine2?: string | null,
      shippingCity: string,
      shippingState: string,
      shippingPostalCode: string,
      shippingCountry: string,
      status?: string | null,
      statusTimeline?: string | null,
      customerEmail: string,
      totalAmount: number,
      currency: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCheckoutMetadataQueryVariables = {
  id: string,
};

export type GetCheckoutMetadataQuery = {
  getCheckoutMetadata?:  {
    __typename: "CheckoutMetadata",
    id: string,
    stripeSessionId: string,
    customerEmail: string,
    purchaseType: string,
    lineItems:  Array< {
      __typename: "OrderLineItem",
      description: string,
      quantity: number,
      amount: number,
      priceId?: string | null,
    } >,
    totalAmount: number,
    currency: string,
    discountTotal?: number | null,
    discounts?:  Array< {
      __typename: "Discount",
      amount: number,
      promoCode?: string | null,
      couponId?: string | null,
      couponName?: string | null,
    } > | null,
    recipientName?: string | null,
    recipientEmail?: string | null,
    gifterName?: string | null,
    gifterEmail?: string | null,
    giftMessage?: string | null,
    deliveryDate?: string | null,
    giftCardStyle?: string | null,
    bookOrderId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCheckoutMetadataQueryVariables = {
  filter?: ModelCheckoutMetadataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCheckoutMetadataQuery = {
  listCheckoutMetadata?:  {
    __typename: "ModelCheckoutMetadataConnection",
    items:  Array< {
      __typename: "CheckoutMetadata",
      id: string,
      stripeSessionId: string,
      customerEmail: string,
      purchaseType: string,
      lineItems:  Array< {
        __typename: "OrderLineItem",
        description: string,
        quantity: number,
        amount: number,
        priceId?: string | null,
      } >,
      totalAmount: number,
      currency: string,
      discountTotal?: number | null,
      discounts?:  Array< {
        __typename: "Discount",
        amount: number,
        promoCode?: string | null,
        couponId?: string | null,
        couponName?: string | null,
      } > | null,
      recipientName?: string | null,
      recipientEmail?: string | null,
      gifterName?: string | null,
      gifterEmail?: string | null,
      giftMessage?: string | null,
      deliveryDate?: string | null,
      giftCardStyle?: string | null,
      bookOrderId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CheckoutMetadataByStripeSessionIdQueryVariables = {
  stripeSessionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCheckoutMetadataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CheckoutMetadataByStripeSessionIdQuery = {
  checkoutMetadataByStripeSessionId?:  {
    __typename: "ModelCheckoutMetadataConnection",
    items:  Array< {
      __typename: "CheckoutMetadata",
      id: string,
      stripeSessionId: string,
      customerEmail: string,
      purchaseType: string,
      lineItems:  Array< {
        __typename: "OrderLineItem",
        description: string,
        quantity: number,
        amount: number,
        priceId?: string | null,
      } >,
      totalAmount: number,
      currency: string,
      discountTotal?: number | null,
      discounts?:  Array< {
        __typename: "Discount",
        amount: number,
        promoCode?: string | null,
        couponId?: string | null,
        couponName?: string | null,
      } > | null,
      recipientName?: string | null,
      recipientEmail?: string | null,
      gifterName?: string | null,
      gifterEmail?: string | null,
      giftMessage?: string | null,
      deliveryDate?: string | null,
      giftCardStyle?: string | null,
      bookOrderId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetImageGenreRelationQueryVariables = {
  id: string,
};

export type GetImageGenreRelationQuery = {
  getImageGenreRelation?:  {
    __typename: "ImageGenreRelation",
    id: string,
    imageId: string,
    genreId: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    genre:  {
      __typename: "Genre",
      id: string,
      name: string,
      images?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListImageGenreRelationsQueryVariables = {
  filter?: ModelImageGenreRelationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListImageGenreRelationsQuery = {
  listImageGenreRelations?:  {
    __typename: "ModelImageGenreRelationConnection",
    items:  Array< {
      __typename: "ImageGenreRelation",
      id: string,
      imageId: string,
      genreId: string,
      image:  {
        __typename: "Image",
        id: string,
        name: string,
        type: ImageType,
        genres?:  {
          __typename: "ModelImageGenreRelationConnection",
          items:  Array< {
            __typename: "ImageGenreRelation",
            id: string,
            imageId: string,
            genreId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            genre:  {
              __typename: "Genre",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        expressions?:  {
          __typename: "ModelExpressionConnection",
          items:  Array< {
            __typename: "Expression",
            id: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            name: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            imageExpressionsId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        traits?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        categories?:  {
          __typename: "ModelImageCategoryRelationConnection",
          items:  Array< {
            __typename: "ImageCategoryRelation",
            id: string,
            imageId: string,
            imageCategoryId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            imageCategory:  {
              __typename: "ImageCategory",
              id: string,
              category: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        characterSet?: string | null,
        characterSetName?: string | null,
        location?: string | null,
      },
      genre:  {
        __typename: "Genre",
        id: string,
        name: string,
        images?:  {
          __typename: "ModelImageGenreRelationConnection",
          items:  Array< {
            __typename: "ImageGenreRelation",
            id: string,
            imageId: string,
            genreId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            genre:  {
              __typename: "Genre",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ImageGenreRelationsByImageIdQueryVariables = {
  imageId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImageGenreRelationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ImageGenreRelationsByImageIdQuery = {
  imageGenreRelationsByImageId?:  {
    __typename: "ModelImageGenreRelationConnection",
    items:  Array< {
      __typename: "ImageGenreRelation",
      id: string,
      imageId: string,
      genreId: string,
      image:  {
        __typename: "Image",
        id: string,
        name: string,
        type: ImageType,
        genres?:  {
          __typename: "ModelImageGenreRelationConnection",
          items:  Array< {
            __typename: "ImageGenreRelation",
            id: string,
            imageId: string,
            genreId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            genre:  {
              __typename: "Genre",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        expressions?:  {
          __typename: "ModelExpressionConnection",
          items:  Array< {
            __typename: "Expression",
            id: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            name: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            imageExpressionsId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        traits?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        categories?:  {
          __typename: "ModelImageCategoryRelationConnection",
          items:  Array< {
            __typename: "ImageCategoryRelation",
            id: string,
            imageId: string,
            imageCategoryId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            imageCategory:  {
              __typename: "ImageCategory",
              id: string,
              category: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        characterSet?: string | null,
        characterSetName?: string | null,
        location?: string | null,
      },
      genre:  {
        __typename: "Genre",
        id: string,
        name: string,
        images?:  {
          __typename: "ModelImageGenreRelationConnection",
          items:  Array< {
            __typename: "ImageGenreRelation",
            id: string,
            imageId: string,
            genreId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            genre:  {
              __typename: "Genre",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ImageGenreRelationsByGenreIdQueryVariables = {
  genreId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImageGenreRelationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ImageGenreRelationsByGenreIdQuery = {
  imageGenreRelationsByGenreId?:  {
    __typename: "ModelImageGenreRelationConnection",
    items:  Array< {
      __typename: "ImageGenreRelation",
      id: string,
      imageId: string,
      genreId: string,
      image:  {
        __typename: "Image",
        id: string,
        name: string,
        type: ImageType,
        genres?:  {
          __typename: "ModelImageGenreRelationConnection",
          items:  Array< {
            __typename: "ImageGenreRelation",
            id: string,
            imageId: string,
            genreId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            genre:  {
              __typename: "Genre",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        expressions?:  {
          __typename: "ModelExpressionConnection",
          items:  Array< {
            __typename: "Expression",
            id: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            name: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            imageExpressionsId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        traits?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        categories?:  {
          __typename: "ModelImageCategoryRelationConnection",
          items:  Array< {
            __typename: "ImageCategoryRelation",
            id: string,
            imageId: string,
            imageCategoryId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            imageCategory:  {
              __typename: "ImageCategory",
              id: string,
              category: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        characterSet?: string | null,
        characterSetName?: string | null,
        location?: string | null,
      },
      genre:  {
        __typename: "Genre",
        id: string,
        name: string,
        images?:  {
          __typename: "ModelImageGenreRelationConnection",
          items:  Array< {
            __typename: "ImageGenreRelation",
            id: string,
            imageId: string,
            genreId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            genre:  {
              __typename: "Genre",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetImageCategoryRelationQueryVariables = {
  id: string,
};

export type GetImageCategoryRelationQuery = {
  getImageCategoryRelation?:  {
    __typename: "ImageCategoryRelation",
    id: string,
    imageId: string,
    imageCategoryId: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    imageCategory:  {
      __typename: "ImageCategory",
      id: string,
      category: string,
      images?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListImageCategoryRelationsQueryVariables = {
  filter?: ModelImageCategoryRelationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListImageCategoryRelationsQuery = {
  listImageCategoryRelations?:  {
    __typename: "ModelImageCategoryRelationConnection",
    items:  Array< {
      __typename: "ImageCategoryRelation",
      id: string,
      imageId: string,
      imageCategoryId: string,
      image:  {
        __typename: "Image",
        id: string,
        name: string,
        type: ImageType,
        genres?:  {
          __typename: "ModelImageGenreRelationConnection",
          items:  Array< {
            __typename: "ImageGenreRelation",
            id: string,
            imageId: string,
            genreId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            genre:  {
              __typename: "Genre",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        expressions?:  {
          __typename: "ModelExpressionConnection",
          items:  Array< {
            __typename: "Expression",
            id: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            name: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            imageExpressionsId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        traits?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        categories?:  {
          __typename: "ModelImageCategoryRelationConnection",
          items:  Array< {
            __typename: "ImageCategoryRelation",
            id: string,
            imageId: string,
            imageCategoryId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            imageCategory:  {
              __typename: "ImageCategory",
              id: string,
              category: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        characterSet?: string | null,
        characterSetName?: string | null,
        location?: string | null,
      },
      imageCategory:  {
        __typename: "ImageCategory",
        id: string,
        category: string,
        images?:  {
          __typename: "ModelImageCategoryRelationConnection",
          items:  Array< {
            __typename: "ImageCategoryRelation",
            id: string,
            imageId: string,
            imageCategoryId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            imageCategory:  {
              __typename: "ImageCategory",
              id: string,
              category: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ImageCategoryRelationsByImageIdQueryVariables = {
  imageId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImageCategoryRelationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ImageCategoryRelationsByImageIdQuery = {
  imageCategoryRelationsByImageId?:  {
    __typename: "ModelImageCategoryRelationConnection",
    items:  Array< {
      __typename: "ImageCategoryRelation",
      id: string,
      imageId: string,
      imageCategoryId: string,
      image:  {
        __typename: "Image",
        id: string,
        name: string,
        type: ImageType,
        genres?:  {
          __typename: "ModelImageGenreRelationConnection",
          items:  Array< {
            __typename: "ImageGenreRelation",
            id: string,
            imageId: string,
            genreId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            genre:  {
              __typename: "Genre",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        expressions?:  {
          __typename: "ModelExpressionConnection",
          items:  Array< {
            __typename: "Expression",
            id: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            name: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            imageExpressionsId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        traits?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        categories?:  {
          __typename: "ModelImageCategoryRelationConnection",
          items:  Array< {
            __typename: "ImageCategoryRelation",
            id: string,
            imageId: string,
            imageCategoryId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            imageCategory:  {
              __typename: "ImageCategory",
              id: string,
              category: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        characterSet?: string | null,
        characterSetName?: string | null,
        location?: string | null,
      },
      imageCategory:  {
        __typename: "ImageCategory",
        id: string,
        category: string,
        images?:  {
          __typename: "ModelImageCategoryRelationConnection",
          items:  Array< {
            __typename: "ImageCategoryRelation",
            id: string,
            imageId: string,
            imageCategoryId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            imageCategory:  {
              __typename: "ImageCategory",
              id: string,
              category: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ImageCategoryRelationsByImageCategoryIdQueryVariables = {
  imageCategoryId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImageCategoryRelationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ImageCategoryRelationsByImageCategoryIdQuery = {
  imageCategoryRelationsByImageCategoryId?:  {
    __typename: "ModelImageCategoryRelationConnection",
    items:  Array< {
      __typename: "ImageCategoryRelation",
      id: string,
      imageId: string,
      imageCategoryId: string,
      image:  {
        __typename: "Image",
        id: string,
        name: string,
        type: ImageType,
        genres?:  {
          __typename: "ModelImageGenreRelationConnection",
          items:  Array< {
            __typename: "ImageGenreRelation",
            id: string,
            imageId: string,
            genreId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            genre:  {
              __typename: "Genre",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        expressions?:  {
          __typename: "ModelExpressionConnection",
          items:  Array< {
            __typename: "Expression",
            id: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            name: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            imageExpressionsId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        traits?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        categories?:  {
          __typename: "ModelImageCategoryRelationConnection",
          items:  Array< {
            __typename: "ImageCategoryRelation",
            id: string,
            imageId: string,
            imageCategoryId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            imageCategory:  {
              __typename: "ImageCategory",
              id: string,
              category: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        characterSet?: string | null,
        characterSetName?: string | null,
        location?: string | null,
      },
      imageCategory:  {
        __typename: "ImageCategory",
        id: string,
        category: string,
        images?:  {
          __typename: "ModelImageCategoryRelationConnection",
          items:  Array< {
            __typename: "ImageCategoryRelation",
            id: string,
            imageId: string,
            imageCategoryId: string,
            image:  {
              __typename: "Image",
              id: string,
              name: string,
              type: ImageType,
              traits?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              characterSet?: string | null,
              characterSetName?: string | null,
              location?: string | null,
            },
            imageCategory:  {
              __typename: "ImageCategory",
              id: string,
              category: string,
              createdAt?: string | null,
              updatedAt?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    cognitoUserId: string,
    email: string,
    children?:  {
      __typename: "ModelChildConnection",
      items:  Array< {
        __typename: "Child",
        id: string,
        name: string,
        age: string,
        commonNoun: string,
        readingLevel?: string | null,
        avatar?: string | null,
        storyPages?:  {
          __typename: "ModelStoryPagesConnection",
          items:  Array< {
            __typename: "StoryPages",
            id: string,
            pageIDs?: Array< string | null > | null,
            storyType?: string | null,
            storyStatus?: string | null,
            rating?: number | null,
            childStoryPagesId: string,
            lastViewed?: string | null,
            expositionImageName?: string | null,
            title?: string | null,
            initiationMethod?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userChildrenId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activeChildId?: string | null,
    settings?: string | null,
    lastInteraction?: string | null,
    emailUpdatesEnabled?: boolean | null,
    emailPromotionsEnabled?: boolean | null,
    emailStoriesFridayEnabled?: boolean | null,
    emailStoriesDailyEnabled?: boolean | null,
    trialStoriesCount?: number | null,
    bookCredits?: number | null,
    notificationSettings?:  {
      __typename: "NotificationSettings",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUserId: string,
        email: string,
        children?:  {
          __typename: "ModelChildConnection",
          items:  Array< {
            __typename: "Child",
            id: string,
            name: string,
            age: string,
            commonNoun: string,
            readingLevel?: string | null,
            avatar?: string | null,
            storyPages?:  {
              __typename: "ModelStoryPagesConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userChildrenId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        activeChildId?: string | null,
        settings?: string | null,
        lastInteraction?: string | null,
        emailUpdatesEnabled?: boolean | null,
        emailPromotionsEnabled?: boolean | null,
        emailStoriesFridayEnabled?: boolean | null,
        emailStoriesDailyEnabled?: boolean | null,
        trialStoriesCount?: number | null,
        bookCredits?: number | null,
        notificationSettings?:  {
          __typename: "NotificationSettings",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          notificationsEnabled: boolean,
          storyReminders:  {
            __typename: "StoryReminderSettings",
            enabled: boolean,
            frequency?: string | null,
          },
          promotionsEnabled?: boolean | null,
          updatesEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        deviceTokens?:  {
          __typename: "ModelDeviceTokenConnection",
          items:  Array< {
            __typename: "DeviceToken",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            token: string,
            platform: string,
            endpointArn?: string | null,
            createdAt: string,
            updatedAt: string,
            userDeviceTokensId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userNotificationSettingsId?: string | null,
        owner?: string | null,
      } | null,
      userId: string,
      notificationsEnabled: boolean,
      storyReminders:  {
        __typename: "StoryReminderSettings",
        enabled: boolean,
        frequency?: string | null,
      },
      promotionsEnabled?: boolean | null,
      updatesEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    deviceTokens?:  {
      __typename: "ModelDeviceTokenConnection",
      items:  Array< {
        __typename: "DeviceToken",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        token: string,
        platform: string,
        endpointArn?: string | null,
        createdAt: string,
        updatedAt: string,
        userDeviceTokensId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userNotificationSettingsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    cognitoUserId: string,
    email: string,
    children?:  {
      __typename: "ModelChildConnection",
      items:  Array< {
        __typename: "Child",
        id: string,
        name: string,
        age: string,
        commonNoun: string,
        readingLevel?: string | null,
        avatar?: string | null,
        storyPages?:  {
          __typename: "ModelStoryPagesConnection",
          items:  Array< {
            __typename: "StoryPages",
            id: string,
            pageIDs?: Array< string | null > | null,
            storyType?: string | null,
            storyStatus?: string | null,
            rating?: number | null,
            childStoryPagesId: string,
            lastViewed?: string | null,
            expositionImageName?: string | null,
            title?: string | null,
            initiationMethod?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userChildrenId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activeChildId?: string | null,
    settings?: string | null,
    lastInteraction?: string | null,
    emailUpdatesEnabled?: boolean | null,
    emailPromotionsEnabled?: boolean | null,
    emailStoriesFridayEnabled?: boolean | null,
    emailStoriesDailyEnabled?: boolean | null,
    trialStoriesCount?: number | null,
    bookCredits?: number | null,
    notificationSettings?:  {
      __typename: "NotificationSettings",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUserId: string,
        email: string,
        children?:  {
          __typename: "ModelChildConnection",
          items:  Array< {
            __typename: "Child",
            id: string,
            name: string,
            age: string,
            commonNoun: string,
            readingLevel?: string | null,
            avatar?: string | null,
            storyPages?:  {
              __typename: "ModelStoryPagesConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userChildrenId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        activeChildId?: string | null,
        settings?: string | null,
        lastInteraction?: string | null,
        emailUpdatesEnabled?: boolean | null,
        emailPromotionsEnabled?: boolean | null,
        emailStoriesFridayEnabled?: boolean | null,
        emailStoriesDailyEnabled?: boolean | null,
        trialStoriesCount?: number | null,
        bookCredits?: number | null,
        notificationSettings?:  {
          __typename: "NotificationSettings",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          notificationsEnabled: boolean,
          storyReminders:  {
            __typename: "StoryReminderSettings",
            enabled: boolean,
            frequency?: string | null,
          },
          promotionsEnabled?: boolean | null,
          updatesEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        deviceTokens?:  {
          __typename: "ModelDeviceTokenConnection",
          items:  Array< {
            __typename: "DeviceToken",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            token: string,
            platform: string,
            endpointArn?: string | null,
            createdAt: string,
            updatedAt: string,
            userDeviceTokensId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userNotificationSettingsId?: string | null,
        owner?: string | null,
      } | null,
      userId: string,
      notificationsEnabled: boolean,
      storyReminders:  {
        __typename: "StoryReminderSettings",
        enabled: boolean,
        frequency?: string | null,
      },
      promotionsEnabled?: boolean | null,
      updatesEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    deviceTokens?:  {
      __typename: "ModelDeviceTokenConnection",
      items:  Array< {
        __typename: "DeviceToken",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        token: string,
        platform: string,
        endpointArn?: string | null,
        createdAt: string,
        updatedAt: string,
        userDeviceTokensId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userNotificationSettingsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    cognitoUserId: string,
    email: string,
    children?:  {
      __typename: "ModelChildConnection",
      items:  Array< {
        __typename: "Child",
        id: string,
        name: string,
        age: string,
        commonNoun: string,
        readingLevel?: string | null,
        avatar?: string | null,
        storyPages?:  {
          __typename: "ModelStoryPagesConnection",
          items:  Array< {
            __typename: "StoryPages",
            id: string,
            pageIDs?: Array< string | null > | null,
            storyType?: string | null,
            storyStatus?: string | null,
            rating?: number | null,
            childStoryPagesId: string,
            lastViewed?: string | null,
            expositionImageName?: string | null,
            title?: string | null,
            initiationMethod?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userChildrenId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activeChildId?: string | null,
    settings?: string | null,
    lastInteraction?: string | null,
    emailUpdatesEnabled?: boolean | null,
    emailPromotionsEnabled?: boolean | null,
    emailStoriesFridayEnabled?: boolean | null,
    emailStoriesDailyEnabled?: boolean | null,
    trialStoriesCount?: number | null,
    bookCredits?: number | null,
    notificationSettings?:  {
      __typename: "NotificationSettings",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUserId: string,
        email: string,
        children?:  {
          __typename: "ModelChildConnection",
          items:  Array< {
            __typename: "Child",
            id: string,
            name: string,
            age: string,
            commonNoun: string,
            readingLevel?: string | null,
            avatar?: string | null,
            storyPages?:  {
              __typename: "ModelStoryPagesConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userChildrenId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        activeChildId?: string | null,
        settings?: string | null,
        lastInteraction?: string | null,
        emailUpdatesEnabled?: boolean | null,
        emailPromotionsEnabled?: boolean | null,
        emailStoriesFridayEnabled?: boolean | null,
        emailStoriesDailyEnabled?: boolean | null,
        trialStoriesCount?: number | null,
        bookCredits?: number | null,
        notificationSettings?:  {
          __typename: "NotificationSettings",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          notificationsEnabled: boolean,
          storyReminders:  {
            __typename: "StoryReminderSettings",
            enabled: boolean,
            frequency?: string | null,
          },
          promotionsEnabled?: boolean | null,
          updatesEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        deviceTokens?:  {
          __typename: "ModelDeviceTokenConnection",
          items:  Array< {
            __typename: "DeviceToken",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            token: string,
            platform: string,
            endpointArn?: string | null,
            createdAt: string,
            updatedAt: string,
            userDeviceTokensId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userNotificationSettingsId?: string | null,
        owner?: string | null,
      } | null,
      userId: string,
      notificationsEnabled: boolean,
      storyReminders:  {
        __typename: "StoryReminderSettings",
        enabled: boolean,
        frequency?: string | null,
      },
      promotionsEnabled?: boolean | null,
      updatesEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    deviceTokens?:  {
      __typename: "ModelDeviceTokenConnection",
      items:  Array< {
        __typename: "DeviceToken",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        token: string,
        platform: string,
        endpointArn?: string | null,
        createdAt: string,
        updatedAt: string,
        userDeviceTokensId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userNotificationSettingsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateNotificationSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationSettingsFilterInput | null,
  owner?: string | null,
};

export type OnCreateNotificationSettingsSubscription = {
  onCreateNotificationSettings?:  {
    __typename: "NotificationSettings",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId: string,
      email: string,
      children?:  {
        __typename: "ModelChildConnection",
        items:  Array< {
          __typename: "Child",
          id: string,
          name: string,
          age: string,
          commonNoun: string,
          readingLevel?: string | null,
          avatar?: string | null,
          storyPages?:  {
            __typename: "ModelStoryPagesConnection",
            items:  Array< {
              __typename: "StoryPages",
              id: string,
              pageIDs?: Array< string | null > | null,
              storyType?: string | null,
              storyStatus?: string | null,
              rating?: number | null,
              childStoryPagesId: string,
              lastViewed?: string | null,
              expositionImageName?: string | null,
              title?: string | null,
              initiationMethod?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userChildrenId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activeChildId?: string | null,
      settings?: string | null,
      lastInteraction?: string | null,
      emailUpdatesEnabled?: boolean | null,
      emailPromotionsEnabled?: boolean | null,
      emailStoriesFridayEnabled?: boolean | null,
      emailStoriesDailyEnabled?: boolean | null,
      trialStoriesCount?: number | null,
      bookCredits?: number | null,
      notificationSettings?:  {
        __typename: "NotificationSettings",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        notificationsEnabled: boolean,
        storyReminders:  {
          __typename: "StoryReminderSettings",
          enabled: boolean,
          frequency?: string | null,
        },
        promotionsEnabled?: boolean | null,
        updatesEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      deviceTokens?:  {
        __typename: "ModelDeviceTokenConnection",
        items:  Array< {
          __typename: "DeviceToken",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          token: string,
          platform: string,
          endpointArn?: string | null,
          createdAt: string,
          updatedAt: string,
          userDeviceTokensId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userNotificationSettingsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    notificationsEnabled: boolean,
    storyReminders:  {
      __typename: "StoryReminderSettings",
      enabled: boolean,
      frequency?: string | null,
    },
    promotionsEnabled?: boolean | null,
    updatesEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateNotificationSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationSettingsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateNotificationSettingsSubscription = {
  onUpdateNotificationSettings?:  {
    __typename: "NotificationSettings",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId: string,
      email: string,
      children?:  {
        __typename: "ModelChildConnection",
        items:  Array< {
          __typename: "Child",
          id: string,
          name: string,
          age: string,
          commonNoun: string,
          readingLevel?: string | null,
          avatar?: string | null,
          storyPages?:  {
            __typename: "ModelStoryPagesConnection",
            items:  Array< {
              __typename: "StoryPages",
              id: string,
              pageIDs?: Array< string | null > | null,
              storyType?: string | null,
              storyStatus?: string | null,
              rating?: number | null,
              childStoryPagesId: string,
              lastViewed?: string | null,
              expositionImageName?: string | null,
              title?: string | null,
              initiationMethod?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userChildrenId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activeChildId?: string | null,
      settings?: string | null,
      lastInteraction?: string | null,
      emailUpdatesEnabled?: boolean | null,
      emailPromotionsEnabled?: boolean | null,
      emailStoriesFridayEnabled?: boolean | null,
      emailStoriesDailyEnabled?: boolean | null,
      trialStoriesCount?: number | null,
      bookCredits?: number | null,
      notificationSettings?:  {
        __typename: "NotificationSettings",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        notificationsEnabled: boolean,
        storyReminders:  {
          __typename: "StoryReminderSettings",
          enabled: boolean,
          frequency?: string | null,
        },
        promotionsEnabled?: boolean | null,
        updatesEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      deviceTokens?:  {
        __typename: "ModelDeviceTokenConnection",
        items:  Array< {
          __typename: "DeviceToken",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          token: string,
          platform: string,
          endpointArn?: string | null,
          createdAt: string,
          updatedAt: string,
          userDeviceTokensId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userNotificationSettingsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    notificationsEnabled: boolean,
    storyReminders:  {
      __typename: "StoryReminderSettings",
      enabled: boolean,
      frequency?: string | null,
    },
    promotionsEnabled?: boolean | null,
    updatesEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteNotificationSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationSettingsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteNotificationSettingsSubscription = {
  onDeleteNotificationSettings?:  {
    __typename: "NotificationSettings",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId: string,
      email: string,
      children?:  {
        __typename: "ModelChildConnection",
        items:  Array< {
          __typename: "Child",
          id: string,
          name: string,
          age: string,
          commonNoun: string,
          readingLevel?: string | null,
          avatar?: string | null,
          storyPages?:  {
            __typename: "ModelStoryPagesConnection",
            items:  Array< {
              __typename: "StoryPages",
              id: string,
              pageIDs?: Array< string | null > | null,
              storyType?: string | null,
              storyStatus?: string | null,
              rating?: number | null,
              childStoryPagesId: string,
              lastViewed?: string | null,
              expositionImageName?: string | null,
              title?: string | null,
              initiationMethod?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userChildrenId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activeChildId?: string | null,
      settings?: string | null,
      lastInteraction?: string | null,
      emailUpdatesEnabled?: boolean | null,
      emailPromotionsEnabled?: boolean | null,
      emailStoriesFridayEnabled?: boolean | null,
      emailStoriesDailyEnabled?: boolean | null,
      trialStoriesCount?: number | null,
      bookCredits?: number | null,
      notificationSettings?:  {
        __typename: "NotificationSettings",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        notificationsEnabled: boolean,
        storyReminders:  {
          __typename: "StoryReminderSettings",
          enabled: boolean,
          frequency?: string | null,
        },
        promotionsEnabled?: boolean | null,
        updatesEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      deviceTokens?:  {
        __typename: "ModelDeviceTokenConnection",
        items:  Array< {
          __typename: "DeviceToken",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          token: string,
          platform: string,
          endpointArn?: string | null,
          createdAt: string,
          updatedAt: string,
          userDeviceTokensId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userNotificationSettingsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    notificationsEnabled: boolean,
    storyReminders:  {
      __typename: "StoryReminderSettings",
      enabled: boolean,
      frequency?: string | null,
    },
    promotionsEnabled?: boolean | null,
    updatesEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateDeviceTokenSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceTokenFilterInput | null,
  owner?: string | null,
};

export type OnCreateDeviceTokenSubscription = {
  onCreateDeviceToken?:  {
    __typename: "DeviceToken",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId: string,
      email: string,
      children?:  {
        __typename: "ModelChildConnection",
        items:  Array< {
          __typename: "Child",
          id: string,
          name: string,
          age: string,
          commonNoun: string,
          readingLevel?: string | null,
          avatar?: string | null,
          storyPages?:  {
            __typename: "ModelStoryPagesConnection",
            items:  Array< {
              __typename: "StoryPages",
              id: string,
              pageIDs?: Array< string | null > | null,
              storyType?: string | null,
              storyStatus?: string | null,
              rating?: number | null,
              childStoryPagesId: string,
              lastViewed?: string | null,
              expositionImageName?: string | null,
              title?: string | null,
              initiationMethod?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userChildrenId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activeChildId?: string | null,
      settings?: string | null,
      lastInteraction?: string | null,
      emailUpdatesEnabled?: boolean | null,
      emailPromotionsEnabled?: boolean | null,
      emailStoriesFridayEnabled?: boolean | null,
      emailStoriesDailyEnabled?: boolean | null,
      trialStoriesCount?: number | null,
      bookCredits?: number | null,
      notificationSettings?:  {
        __typename: "NotificationSettings",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        notificationsEnabled: boolean,
        storyReminders:  {
          __typename: "StoryReminderSettings",
          enabled: boolean,
          frequency?: string | null,
        },
        promotionsEnabled?: boolean | null,
        updatesEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      deviceTokens?:  {
        __typename: "ModelDeviceTokenConnection",
        items:  Array< {
          __typename: "DeviceToken",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          token: string,
          platform: string,
          endpointArn?: string | null,
          createdAt: string,
          updatedAt: string,
          userDeviceTokensId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userNotificationSettingsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    token: string,
    platform: string,
    endpointArn?: string | null,
    createdAt: string,
    updatedAt: string,
    userDeviceTokensId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateDeviceTokenSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceTokenFilterInput | null,
  owner?: string | null,
};

export type OnUpdateDeviceTokenSubscription = {
  onUpdateDeviceToken?:  {
    __typename: "DeviceToken",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId: string,
      email: string,
      children?:  {
        __typename: "ModelChildConnection",
        items:  Array< {
          __typename: "Child",
          id: string,
          name: string,
          age: string,
          commonNoun: string,
          readingLevel?: string | null,
          avatar?: string | null,
          storyPages?:  {
            __typename: "ModelStoryPagesConnection",
            items:  Array< {
              __typename: "StoryPages",
              id: string,
              pageIDs?: Array< string | null > | null,
              storyType?: string | null,
              storyStatus?: string | null,
              rating?: number | null,
              childStoryPagesId: string,
              lastViewed?: string | null,
              expositionImageName?: string | null,
              title?: string | null,
              initiationMethod?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userChildrenId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activeChildId?: string | null,
      settings?: string | null,
      lastInteraction?: string | null,
      emailUpdatesEnabled?: boolean | null,
      emailPromotionsEnabled?: boolean | null,
      emailStoriesFridayEnabled?: boolean | null,
      emailStoriesDailyEnabled?: boolean | null,
      trialStoriesCount?: number | null,
      bookCredits?: number | null,
      notificationSettings?:  {
        __typename: "NotificationSettings",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        notificationsEnabled: boolean,
        storyReminders:  {
          __typename: "StoryReminderSettings",
          enabled: boolean,
          frequency?: string | null,
        },
        promotionsEnabled?: boolean | null,
        updatesEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      deviceTokens?:  {
        __typename: "ModelDeviceTokenConnection",
        items:  Array< {
          __typename: "DeviceToken",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          token: string,
          platform: string,
          endpointArn?: string | null,
          createdAt: string,
          updatedAt: string,
          userDeviceTokensId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userNotificationSettingsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    token: string,
    platform: string,
    endpointArn?: string | null,
    createdAt: string,
    updatedAt: string,
    userDeviceTokensId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteDeviceTokenSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceTokenFilterInput | null,
  owner?: string | null,
};

export type OnDeleteDeviceTokenSubscription = {
  onDeleteDeviceToken?:  {
    __typename: "DeviceToken",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId: string,
      email: string,
      children?:  {
        __typename: "ModelChildConnection",
        items:  Array< {
          __typename: "Child",
          id: string,
          name: string,
          age: string,
          commonNoun: string,
          readingLevel?: string | null,
          avatar?: string | null,
          storyPages?:  {
            __typename: "ModelStoryPagesConnection",
            items:  Array< {
              __typename: "StoryPages",
              id: string,
              pageIDs?: Array< string | null > | null,
              storyType?: string | null,
              storyStatus?: string | null,
              rating?: number | null,
              childStoryPagesId: string,
              lastViewed?: string | null,
              expositionImageName?: string | null,
              title?: string | null,
              initiationMethod?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userChildrenId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activeChildId?: string | null,
      settings?: string | null,
      lastInteraction?: string | null,
      emailUpdatesEnabled?: boolean | null,
      emailPromotionsEnabled?: boolean | null,
      emailStoriesFridayEnabled?: boolean | null,
      emailStoriesDailyEnabled?: boolean | null,
      trialStoriesCount?: number | null,
      bookCredits?: number | null,
      notificationSettings?:  {
        __typename: "NotificationSettings",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          cognitoUserId: string,
          email: string,
          children?:  {
            __typename: "ModelChildConnection",
            items:  Array< {
              __typename: "Child",
              id: string,
              name: string,
              age: string,
              commonNoun: string,
              readingLevel?: string | null,
              avatar?: string | null,
              createdAt: string,
              updatedAt: string,
              userChildrenId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          activeChildId?: string | null,
          settings?: string | null,
          lastInteraction?: string | null,
          emailUpdatesEnabled?: boolean | null,
          emailPromotionsEnabled?: boolean | null,
          emailStoriesFridayEnabled?: boolean | null,
          emailStoriesDailyEnabled?: boolean | null,
          trialStoriesCount?: number | null,
          bookCredits?: number | null,
          notificationSettings?:  {
            __typename: "NotificationSettings",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              cognitoUserId: string,
              email: string,
              activeChildId?: string | null,
              settings?: string | null,
              lastInteraction?: string | null,
              emailUpdatesEnabled?: boolean | null,
              emailPromotionsEnabled?: boolean | null,
              emailStoriesFridayEnabled?: boolean | null,
              emailStoriesDailyEnabled?: boolean | null,
              trialStoriesCount?: number | null,
              bookCredits?: number | null,
              createdAt: string,
              updatedAt: string,
              userNotificationSettingsId?: string | null,
              owner?: string | null,
            } | null,
            userId: string,
            notificationsEnabled: boolean,
            storyReminders:  {
              __typename: "StoryReminderSettings",
              enabled: boolean,
              frequency?: string | null,
            },
            promotionsEnabled?: boolean | null,
            updatesEnabled?: boolean | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          deviceTokens?:  {
            __typename: "ModelDeviceTokenConnection",
            items:  Array< {
              __typename: "DeviceToken",
              id: string,
              userId: string,
              token: string,
              platform: string,
              endpointArn?: string | null,
              createdAt: string,
              updatedAt: string,
              userDeviceTokensId?: string | null,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userNotificationSettingsId?: string | null,
          owner?: string | null,
        } | null,
        userId: string,
        notificationsEnabled: boolean,
        storyReminders:  {
          __typename: "StoryReminderSettings",
          enabled: boolean,
          frequency?: string | null,
        },
        promotionsEnabled?: boolean | null,
        updatesEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      deviceTokens?:  {
        __typename: "ModelDeviceTokenConnection",
        items:  Array< {
          __typename: "DeviceToken",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            cognitoUserId: string,
            email: string,
            children?:  {
              __typename: "ModelChildConnection",
              nextToken?: string | null,
            } | null,
            activeChildId?: string | null,
            settings?: string | null,
            lastInteraction?: string | null,
            emailUpdatesEnabled?: boolean | null,
            emailPromotionsEnabled?: boolean | null,
            emailStoriesFridayEnabled?: boolean | null,
            emailStoriesDailyEnabled?: boolean | null,
            trialStoriesCount?: number | null,
            bookCredits?: number | null,
            notificationSettings?:  {
              __typename: "NotificationSettings",
              id: string,
              userId: string,
              notificationsEnabled: boolean,
              promotionsEnabled?: boolean | null,
              updatesEnabled?: boolean | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            deviceTokens?:  {
              __typename: "ModelDeviceTokenConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            userNotificationSettingsId?: string | null,
            owner?: string | null,
          } | null,
          userId: string,
          token: string,
          platform: string,
          endpointArn?: string | null,
          createdAt: string,
          updatedAt: string,
          userDeviceTokensId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userNotificationSettingsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    token: string,
    platform: string,
    endpointArn?: string | null,
    createdAt: string,
    updatedAt: string,
    userDeviceTokensId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateWaitlistSubscriptionVariables = {
  filter?: ModelSubscriptionWaitlistFilterInput | null,
  cognitoUserId?: string | null,
};

export type OnCreateWaitlistSubscription = {
  onCreateWaitlist?:  {
    __typename: "Waitlist",
    cognitoUserId: string,
    email: string,
    accessGranted: boolean,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateWaitlistSubscriptionVariables = {
  filter?: ModelSubscriptionWaitlistFilterInput | null,
  cognitoUserId?: string | null,
};

export type OnUpdateWaitlistSubscription = {
  onUpdateWaitlist?:  {
    __typename: "Waitlist",
    cognitoUserId: string,
    email: string,
    accessGranted: boolean,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteWaitlistSubscriptionVariables = {
  filter?: ModelSubscriptionWaitlistFilterInput | null,
  cognitoUserId?: string | null,
};

export type OnDeleteWaitlistSubscription = {
  onDeleteWaitlist?:  {
    __typename: "Waitlist",
    cognitoUserId: string,
    email: string,
    accessGranted: boolean,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateGiftcardSubscriptionVariables = {
  filter?: ModelSubscriptionGiftcardFilterInput | null,
  recipientEmail?: string | null,
};

export type OnCreateGiftcardSubscription = {
  onCreateGiftcard?:  {
    __typename: "Giftcard",
    id: string,
    giftcode: string,
    recipientEmail: string,
    subscriptionProduct: string,
    storyCredits: number,
    bookCredits: number,
    giftingEmail?: string | null,
    redeemed: boolean,
    redeemedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGiftcardSubscriptionVariables = {
  filter?: ModelSubscriptionGiftcardFilterInput | null,
  recipientEmail?: string | null,
};

export type OnUpdateGiftcardSubscription = {
  onUpdateGiftcard?:  {
    __typename: "Giftcard",
    id: string,
    giftcode: string,
    recipientEmail: string,
    subscriptionProduct: string,
    storyCredits: number,
    bookCredits: number,
    giftingEmail?: string | null,
    redeemed: boolean,
    redeemedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGiftcardSubscriptionVariables = {
  filter?: ModelSubscriptionGiftcardFilterInput | null,
  recipientEmail?: string | null,
};

export type OnDeleteGiftcardSubscription = {
  onDeleteGiftcard?:  {
    __typename: "Giftcard",
    id: string,
    giftcode: string,
    recipientEmail: string,
    subscriptionProduct: string,
    storyCredits: number,
    bookCredits: number,
    giftingEmail?: string | null,
    redeemed: boolean,
    redeemedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMetadataSubscriptionVariables = {
  filter?: ModelSubscriptionMetadataFilterInput | null,
};

export type OnCreateMetadataSubscription = {
  onCreateMetadata?:  {
    __typename: "Metadata",
    id: string,
    isUnderMaintenance: boolean,
    startingTrialStoriesCount: number,
    waitlistEnabled: boolean,
    minimumAppVersion?: string | null,
    promotionString?: string | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type OnUpdateMetadataSubscriptionVariables = {
  filter?: ModelSubscriptionMetadataFilterInput | null,
};

export type OnUpdateMetadataSubscription = {
  onUpdateMetadata?:  {
    __typename: "Metadata",
    id: string,
    isUnderMaintenance: boolean,
    startingTrialStoriesCount: number,
    waitlistEnabled: boolean,
    minimumAppVersion?: string | null,
    promotionString?: string | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type OnDeleteMetadataSubscriptionVariables = {
  filter?: ModelSubscriptionMetadataFilterInput | null,
};

export type OnDeleteMetadataSubscription = {
  onDeleteMetadata?:  {
    __typename: "Metadata",
    id: string,
    isUnderMaintenance: boolean,
    startingTrialStoriesCount: number,
    waitlistEnabled: boolean,
    minimumAppVersion?: string | null,
    promotionString?: string | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type OnCreateUserPaymentSubscriptionVariables = {
  filter?: ModelSubscriptionUserPaymentFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserPaymentSubscription = {
  onCreateUserPayment?:  {
    __typename: "UserPayment",
    stripeSubscriptionId: string,
    cognitoUserId: string,
    stripeCustomerId?: string | null,
    currentTransactionId?: string | null,
    status: SubscriptionStatus,
    currentPeriodStart?: string | null,
    currentPeriodEnd?: string | null,
    cancelAtPeriodEnd?: boolean | null,
    price:  {
      __typename: "StripePrice",
      id: string,
      amount: number,
      interval?: BillingInterval | null,
      interval_count?: number | null,
      currency: string,
    },
    product:  {
      __typename: "StripeProduct",
      id: string,
      name: string,
      description?: string | null,
    },
    provider?: PaymentProvider | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserPaymentSubscriptionVariables = {
  filter?: ModelSubscriptionUserPaymentFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserPaymentSubscription = {
  onUpdateUserPayment?:  {
    __typename: "UserPayment",
    stripeSubscriptionId: string,
    cognitoUserId: string,
    stripeCustomerId?: string | null,
    currentTransactionId?: string | null,
    status: SubscriptionStatus,
    currentPeriodStart?: string | null,
    currentPeriodEnd?: string | null,
    cancelAtPeriodEnd?: boolean | null,
    price:  {
      __typename: "StripePrice",
      id: string,
      amount: number,
      interval?: BillingInterval | null,
      interval_count?: number | null,
      currency: string,
    },
    product:  {
      __typename: "StripeProduct",
      id: string,
      name: string,
      description?: string | null,
    },
    provider?: PaymentProvider | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserPaymentSubscriptionVariables = {
  filter?: ModelSubscriptionUserPaymentFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserPaymentSubscription = {
  onDeleteUserPayment?:  {
    __typename: "UserPayment",
    stripeSubscriptionId: string,
    cognitoUserId: string,
    stripeCustomerId?: string | null,
    currentTransactionId?: string | null,
    status: SubscriptionStatus,
    currentPeriodStart?: string | null,
    currentPeriodEnd?: string | null,
    cancelAtPeriodEnd?: boolean | null,
    price:  {
      __typename: "StripePrice",
      id: string,
      amount: number,
      interval?: BillingInterval | null,
      interval_count?: number | null,
      currency: string,
    },
    product:  {
      __typename: "StripeProduct",
      id: string,
      name: string,
      description?: string | null,
    },
    provider?: PaymentProvider | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateTransactionsSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionsFilterInput | null,
  owner?: string | null,
};

export type OnCreateTransactionsSubscription = {
  onCreateTransactions?:  {
    __typename: "Transactions",
    id: string,
    cognitoUserId: string,
    transactionId: string,
    originalTransactionId?: string | null,
    product:  {
      __typename: "StripeProduct",
      id: string,
      name: string,
      description?: string | null,
    },
    price:  {
      __typename: "StripePrice",
      id: string,
      amount: number,
      interval?: BillingInterval | null,
      interval_count?: number | null,
      currency: string,
    },
    receipt?: string | null,
    provider?: PaymentProvider | null,
    discounts?: string | null,
    transactionDate?: string | null,
    errorCode?: string | null,
    errorMessage?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateTransactionsSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateTransactionsSubscription = {
  onUpdateTransactions?:  {
    __typename: "Transactions",
    id: string,
    cognitoUserId: string,
    transactionId: string,
    originalTransactionId?: string | null,
    product:  {
      __typename: "StripeProduct",
      id: string,
      name: string,
      description?: string | null,
    },
    price:  {
      __typename: "StripePrice",
      id: string,
      amount: number,
      interval?: BillingInterval | null,
      interval_count?: number | null,
      currency: string,
    },
    receipt?: string | null,
    provider?: PaymentProvider | null,
    discounts?: string | null,
    transactionDate?: string | null,
    errorCode?: string | null,
    errorMessage?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteTransactionsSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteTransactionsSubscription = {
  onDeleteTransactions?:  {
    __typename: "Transactions",
    id: string,
    cognitoUserId: string,
    transactionId: string,
    originalTransactionId?: string | null,
    product:  {
      __typename: "StripeProduct",
      id: string,
      name: string,
      description?: string | null,
    },
    price:  {
      __typename: "StripePrice",
      id: string,
      amount: number,
      interval?: BillingInterval | null,
      interval_count?: number | null,
      currency: string,
    },
    receipt?: string | null,
    provider?: PaymentProvider | null,
    discounts?: string | null,
    transactionDate?: string | null,
    errorCode?: string | null,
    errorMessage?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateChildSubscriptionVariables = {
  filter?: ModelSubscriptionChildFilterInput | null,
  owner?: string | null,
};

export type OnCreateChildSubscription = {
  onCreateChild?:  {
    __typename: "Child",
    id: string,
    name: string,
    age: string,
    commonNoun: string,
    readingLevel?: string | null,
    avatar?: string | null,
    storyPages?:  {
      __typename: "ModelStoryPagesConnection",
      items:  Array< {
        __typename: "StoryPages",
        id: string,
        pageIDs?: Array< string | null > | null,
        storyType?: string | null,
        storyStatus?: string | null,
        rating?: number | null,
        childStoryPagesId: string,
        lastViewed?: string | null,
        expositionImageName?: string | null,
        title?: string | null,
        initiationMethod?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userChildrenId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateChildSubscriptionVariables = {
  filter?: ModelSubscriptionChildFilterInput | null,
  owner?: string | null,
};

export type OnUpdateChildSubscription = {
  onUpdateChild?:  {
    __typename: "Child",
    id: string,
    name: string,
    age: string,
    commonNoun: string,
    readingLevel?: string | null,
    avatar?: string | null,
    storyPages?:  {
      __typename: "ModelStoryPagesConnection",
      items:  Array< {
        __typename: "StoryPages",
        id: string,
        pageIDs?: Array< string | null > | null,
        storyType?: string | null,
        storyStatus?: string | null,
        rating?: number | null,
        childStoryPagesId: string,
        lastViewed?: string | null,
        expositionImageName?: string | null,
        title?: string | null,
        initiationMethod?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userChildrenId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteChildSubscriptionVariables = {
  filter?: ModelSubscriptionChildFilterInput | null,
  owner?: string | null,
};

export type OnDeleteChildSubscription = {
  onDeleteChild?:  {
    __typename: "Child",
    id: string,
    name: string,
    age: string,
    commonNoun: string,
    readingLevel?: string | null,
    avatar?: string | null,
    storyPages?:  {
      __typename: "ModelStoryPagesConnection",
      items:  Array< {
        __typename: "StoryPages",
        id: string,
        pageIDs?: Array< string | null > | null,
        storyType?: string | null,
        storyStatus?: string | null,
        rating?: number | null,
        childStoryPagesId: string,
        lastViewed?: string | null,
        expositionImageName?: string | null,
        title?: string | null,
        initiationMethod?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userChildrenId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreatePageSubscriptionVariables = {
  filter?: ModelSubscriptionPageFilterInput | null,
  cognitoUserId?: string | null,
};

export type OnCreatePageSubscription = {
  onCreatePage?:  {
    __typename: "Page",
    id: string,
    cognitoUserId: string,
    pageText: string,
    pageOptions: Array< string | null >,
    imageName?: string | null,
    minorImageName?: string | null,
    characterImageNames?: string | null,
    primaryImage?: string | null,
    protagonist?: string | null,
    storyType?: string | null,
    location?: string | null,
    readingLevel?: string | null,
    preceededBy?: string | null,
    followedBy?: Array< string | null > | null,
    followedByEnding?: Array< string | null > | null,
    isExposition?: boolean | null,
    isEnding?: boolean | null,
    isAnonymized?: boolean | null,
    status?: PageStatus | null,
    isAccepted?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title?: string | null,
  } | null,
};

export type OnUpdatePageSubscriptionVariables = {
  filter?: ModelSubscriptionPageFilterInput | null,
  cognitoUserId?: string | null,
};

export type OnUpdatePageSubscription = {
  onUpdatePage?:  {
    __typename: "Page",
    id: string,
    cognitoUserId: string,
    pageText: string,
    pageOptions: Array< string | null >,
    imageName?: string | null,
    minorImageName?: string | null,
    characterImageNames?: string | null,
    primaryImage?: string | null,
    protagonist?: string | null,
    storyType?: string | null,
    location?: string | null,
    readingLevel?: string | null,
    preceededBy?: string | null,
    followedBy?: Array< string | null > | null,
    followedByEnding?: Array< string | null > | null,
    isExposition?: boolean | null,
    isEnding?: boolean | null,
    isAnonymized?: boolean | null,
    status?: PageStatus | null,
    isAccepted?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title?: string | null,
  } | null,
};

export type OnDeletePageSubscriptionVariables = {
  filter?: ModelSubscriptionPageFilterInput | null,
  cognitoUserId?: string | null,
};

export type OnDeletePageSubscription = {
  onDeletePage?:  {
    __typename: "Page",
    id: string,
    cognitoUserId: string,
    pageText: string,
    pageOptions: Array< string | null >,
    imageName?: string | null,
    minorImageName?: string | null,
    characterImageNames?: string | null,
    primaryImage?: string | null,
    protagonist?: string | null,
    storyType?: string | null,
    location?: string | null,
    readingLevel?: string | null,
    preceededBy?: string | null,
    followedBy?: Array< string | null > | null,
    followedByEnding?: Array< string | null > | null,
    isExposition?: boolean | null,
    isEnding?: boolean | null,
    isAnonymized?: boolean | null,
    status?: PageStatus | null,
    isAccepted?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title?: string | null,
  } | null,
};

export type OnCreateSharedPageSubscriptionVariables = {
  filter?: ModelSubscriptionSharedPageFilterInput | null,
};

export type OnCreateSharedPageSubscription = {
  onCreateSharedPage?:  {
    __typename: "SharedPage",
    id: string,
    pageText: string,
    pageOptions?: Array< string | null > | null,
    imageName?: string | null,
    protagonist?: string | null,
    storyType?: string | null,
    location?: string | null,
    readingLevel?: string | null,
    preceededBy?: string | null,
    followedBy?: Array< string | null > | null,
    followedByEnding?: Array< string | null > | null,
    status?: PageStatus | null,
    isExposition?: boolean | null,
    isEnding?: boolean | null,
    isAnonymized?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title?: string | null,
  } | null,
};

export type OnUpdateSharedPageSubscriptionVariables = {
  filter?: ModelSubscriptionSharedPageFilterInput | null,
};

export type OnUpdateSharedPageSubscription = {
  onUpdateSharedPage?:  {
    __typename: "SharedPage",
    id: string,
    pageText: string,
    pageOptions?: Array< string | null > | null,
    imageName?: string | null,
    protagonist?: string | null,
    storyType?: string | null,
    location?: string | null,
    readingLevel?: string | null,
    preceededBy?: string | null,
    followedBy?: Array< string | null > | null,
    followedByEnding?: Array< string | null > | null,
    status?: PageStatus | null,
    isExposition?: boolean | null,
    isEnding?: boolean | null,
    isAnonymized?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title?: string | null,
  } | null,
};

export type OnDeleteSharedPageSubscriptionVariables = {
  filter?: ModelSubscriptionSharedPageFilterInput | null,
};

export type OnDeleteSharedPageSubscription = {
  onDeleteSharedPage?:  {
    __typename: "SharedPage",
    id: string,
    pageText: string,
    pageOptions?: Array< string | null > | null,
    imageName?: string | null,
    protagonist?: string | null,
    storyType?: string | null,
    location?: string | null,
    readingLevel?: string | null,
    preceededBy?: string | null,
    followedBy?: Array< string | null > | null,
    followedByEnding?: Array< string | null > | null,
    status?: PageStatus | null,
    isExposition?: boolean | null,
    isEnding?: boolean | null,
    isAnonymized?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title?: string | null,
  } | null,
};

export type OnCreateStoryPagesSubscriptionVariables = {
  filter?: ModelSubscriptionStoryPagesFilterInput | null,
  owner?: string | null,
};

export type OnCreateStoryPagesSubscription = {
  onCreateStoryPages?:  {
    __typename: "StoryPages",
    id: string,
    pageIDs?: Array< string | null > | null,
    storyType?: string | null,
    storyStatus?: string | null,
    rating?: number | null,
    childStoryPagesId: string,
    lastViewed?: string | null,
    expositionImageName?: string | null,
    title?: string | null,
    initiationMethod?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateStoryPagesSubscriptionVariables = {
  filter?: ModelSubscriptionStoryPagesFilterInput | null,
  owner?: string | null,
};

export type OnUpdateStoryPagesSubscription = {
  onUpdateStoryPages?:  {
    __typename: "StoryPages",
    id: string,
    pageIDs?: Array< string | null > | null,
    storyType?: string | null,
    storyStatus?: string | null,
    rating?: number | null,
    childStoryPagesId: string,
    lastViewed?: string | null,
    expositionImageName?: string | null,
    title?: string | null,
    initiationMethod?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteStoryPagesSubscriptionVariables = {
  filter?: ModelSubscriptionStoryPagesFilterInput | null,
  owner?: string | null,
};

export type OnDeleteStoryPagesSubscription = {
  onDeleteStoryPages?:  {
    __typename: "StoryPages",
    id: string,
    pageIDs?: Array< string | null > | null,
    storyType?: string | null,
    storyStatus?: string | null,
    rating?: number | null,
    childStoryPagesId: string,
    lastViewed?: string | null,
    expositionImageName?: string | null,
    title?: string | null,
    initiationMethod?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateDailyStorySubscriptionVariables = {
  filter?: ModelSubscriptionDailyStoryFilterInput | null,
};

export type OnCreateDailyStorySubscription = {
  onCreateDailyStory?:  {
    __typename: "DailyStory",
    id: string,
    expositionPageID: string,
    storyType?: string | null,
    pageDepth?: string | null,
    status?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    approvedAt?: string | null,
    storyTitle?: string | null,
    displayStart?: string | null,
  } | null,
};

export type OnUpdateDailyStorySubscriptionVariables = {
  filter?: ModelSubscriptionDailyStoryFilterInput | null,
};

export type OnUpdateDailyStorySubscription = {
  onUpdateDailyStory?:  {
    __typename: "DailyStory",
    id: string,
    expositionPageID: string,
    storyType?: string | null,
    pageDepth?: string | null,
    status?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    approvedAt?: string | null,
    storyTitle?: string | null,
    displayStart?: string | null,
  } | null,
};

export type OnDeleteDailyStorySubscriptionVariables = {
  filter?: ModelSubscriptionDailyStoryFilterInput | null,
};

export type OnDeleteDailyStorySubscription = {
  onDeleteDailyStory?:  {
    __typename: "DailyStory",
    id: string,
    expositionPageID: string,
    storyType?: string | null,
    pageDepth?: string | null,
    status?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    approvedAt?: string | null,
    storyTitle?: string | null,
    displayStart?: string | null,
  } | null,
};

export type OnCreateImageSubjectsLogSubscriptionVariables = {
  filter?: ModelSubscriptionImageSubjectsLogFilterInput | null,
  owner?: string | null,
};

export type OnCreateImageSubjectsLogSubscription = {
  onCreateImageSubjectsLog?:  {
    __typename: "imageSubjectsLog",
    id: string,
    subjects?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateImageSubjectsLogSubscriptionVariables = {
  filter?: ModelSubscriptionImageSubjectsLogFilterInput | null,
  owner?: string | null,
};

export type OnUpdateImageSubjectsLogSubscription = {
  onUpdateImageSubjectsLog?:  {
    __typename: "imageSubjectsLog",
    id: string,
    subjects?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteImageSubjectsLogSubscriptionVariables = {
  filter?: ModelSubscriptionImageSubjectsLogFilterInput | null,
  owner?: string | null,
};

export type OnDeleteImageSubjectsLogSubscription = {
  onDeleteImageSubjectsLog?:  {
    __typename: "imageSubjectsLog",
    id: string,
    subjects?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateJobStatusSubscriptionVariables = {
  filter?: ModelSubscriptionJobStatusFilterInput | null,
  cognitoUserId?: string | null,
};

export type OnCreateJobStatusSubscription = {
  onCreateJobStatus?:  {
    __typename: "JobStatus",
    id: string,
    jobStatus: string,
    jobResult?:  {
      __typename: "JobResult",
      success?: boolean | null,
      pageIDs?: Array< string | null > | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    cognitoUserId?: string | null,
  } | null,
};

export type OnUpdateJobStatusSubscriptionVariables = {
  filter?: ModelSubscriptionJobStatusFilterInput | null,
  cognitoUserId?: string | null,
};

export type OnUpdateJobStatusSubscription = {
  onUpdateJobStatus?:  {
    __typename: "JobStatus",
    id: string,
    jobStatus: string,
    jobResult?:  {
      __typename: "JobResult",
      success?: boolean | null,
      pageIDs?: Array< string | null > | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    cognitoUserId?: string | null,
  } | null,
};

export type OnDeleteJobStatusSubscriptionVariables = {
  filter?: ModelSubscriptionJobStatusFilterInput | null,
  cognitoUserId?: string | null,
};

export type OnDeleteJobStatusSubscription = {
  onDeleteJobStatus?:  {
    __typename: "JobStatus",
    id: string,
    jobStatus: string,
    jobResult?:  {
      __typename: "JobResult",
      success?: boolean | null,
      pageIDs?: Array< string | null > | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    cognitoUserId?: string | null,
  } | null,
};

export type OnCreateImageSubscriptionVariables = {
  filter?: ModelSubscriptionImageFilterInput | null,
};

export type OnCreateImageSubscription = {
  onCreateImage?:  {
    __typename: "Image",
    id: string,
    name: string,
    type: ImageType,
    genres?:  {
      __typename: "ModelImageGenreRelationConnection",
      items:  Array< {
        __typename: "ImageGenreRelation",
        id: string,
        imageId: string,
        genreId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        genre:  {
          __typename: "Genre",
          id: string,
          name: string,
          images?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    expressions?:  {
      __typename: "ModelExpressionConnection",
      items:  Array< {
        __typename: "Expression",
        id: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        name: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        imageExpressionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    traits?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    categories?:  {
      __typename: "ModelImageCategoryRelationConnection",
      items:  Array< {
        __typename: "ImageCategoryRelation",
        id: string,
        imageId: string,
        imageCategoryId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        imageCategory:  {
          __typename: "ImageCategory",
          id: string,
          category: string,
          images?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    characterSet?: string | null,
    characterSetName?: string | null,
    location?: string | null,
  } | null,
};

export type OnUpdateImageSubscriptionVariables = {
  filter?: ModelSubscriptionImageFilterInput | null,
};

export type OnUpdateImageSubscription = {
  onUpdateImage?:  {
    __typename: "Image",
    id: string,
    name: string,
    type: ImageType,
    genres?:  {
      __typename: "ModelImageGenreRelationConnection",
      items:  Array< {
        __typename: "ImageGenreRelation",
        id: string,
        imageId: string,
        genreId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        genre:  {
          __typename: "Genre",
          id: string,
          name: string,
          images?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    expressions?:  {
      __typename: "ModelExpressionConnection",
      items:  Array< {
        __typename: "Expression",
        id: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        name: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        imageExpressionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    traits?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    categories?:  {
      __typename: "ModelImageCategoryRelationConnection",
      items:  Array< {
        __typename: "ImageCategoryRelation",
        id: string,
        imageId: string,
        imageCategoryId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        imageCategory:  {
          __typename: "ImageCategory",
          id: string,
          category: string,
          images?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    characterSet?: string | null,
    characterSetName?: string | null,
    location?: string | null,
  } | null,
};

export type OnDeleteImageSubscriptionVariables = {
  filter?: ModelSubscriptionImageFilterInput | null,
};

export type OnDeleteImageSubscription = {
  onDeleteImage?:  {
    __typename: "Image",
    id: string,
    name: string,
    type: ImageType,
    genres?:  {
      __typename: "ModelImageGenreRelationConnection",
      items:  Array< {
        __typename: "ImageGenreRelation",
        id: string,
        imageId: string,
        genreId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        genre:  {
          __typename: "Genre",
          id: string,
          name: string,
          images?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    expressions?:  {
      __typename: "ModelExpressionConnection",
      items:  Array< {
        __typename: "Expression",
        id: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        name: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        imageExpressionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    traits?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    categories?:  {
      __typename: "ModelImageCategoryRelationConnection",
      items:  Array< {
        __typename: "ImageCategoryRelation",
        id: string,
        imageId: string,
        imageCategoryId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        imageCategory:  {
          __typename: "ImageCategory",
          id: string,
          category: string,
          images?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    characterSet?: string | null,
    characterSetName?: string | null,
    location?: string | null,
  } | null,
};

export type OnCreateExpressionSubscriptionVariables = {
  filter?: ModelSubscriptionExpressionFilterInput | null,
};

export type OnCreateExpressionSubscription = {
  onCreateExpression?:  {
    __typename: "Expression",
    id: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    name: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    imageExpressionsId?: string | null,
  } | null,
};

export type OnUpdateExpressionSubscriptionVariables = {
  filter?: ModelSubscriptionExpressionFilterInput | null,
};

export type OnUpdateExpressionSubscription = {
  onUpdateExpression?:  {
    __typename: "Expression",
    id: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    name: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    imageExpressionsId?: string | null,
  } | null,
};

export type OnDeleteExpressionSubscriptionVariables = {
  filter?: ModelSubscriptionExpressionFilterInput | null,
};

export type OnDeleteExpressionSubscription = {
  onDeleteExpression?:  {
    __typename: "Expression",
    id: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    name: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    imageExpressionsId?: string | null,
  } | null,
};

export type OnCreateGenreSubscriptionVariables = {
  filter?: ModelSubscriptionGenreFilterInput | null,
};

export type OnCreateGenreSubscription = {
  onCreateGenre?:  {
    __typename: "Genre",
    id: string,
    name: string,
    images?:  {
      __typename: "ModelImageGenreRelationConnection",
      items:  Array< {
        __typename: "ImageGenreRelation",
        id: string,
        imageId: string,
        genreId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        genre:  {
          __typename: "Genre",
          id: string,
          name: string,
          images?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateGenreSubscriptionVariables = {
  filter?: ModelSubscriptionGenreFilterInput | null,
};

export type OnUpdateGenreSubscription = {
  onUpdateGenre?:  {
    __typename: "Genre",
    id: string,
    name: string,
    images?:  {
      __typename: "ModelImageGenreRelationConnection",
      items:  Array< {
        __typename: "ImageGenreRelation",
        id: string,
        imageId: string,
        genreId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        genre:  {
          __typename: "Genre",
          id: string,
          name: string,
          images?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteGenreSubscriptionVariables = {
  filter?: ModelSubscriptionGenreFilterInput | null,
};

export type OnDeleteGenreSubscription = {
  onDeleteGenre?:  {
    __typename: "Genre",
    id: string,
    name: string,
    images?:  {
      __typename: "ModelImageGenreRelationConnection",
      items:  Array< {
        __typename: "ImageGenreRelation",
        id: string,
        imageId: string,
        genreId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        genre:  {
          __typename: "Genre",
          id: string,
          name: string,
          images?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateImageCategorySubscriptionVariables = {
  filter?: ModelSubscriptionImageCategoryFilterInput | null,
};

export type OnCreateImageCategorySubscription = {
  onCreateImageCategory?:  {
    __typename: "ImageCategory",
    id: string,
    category: string,
    images?:  {
      __typename: "ModelImageCategoryRelationConnection",
      items:  Array< {
        __typename: "ImageCategoryRelation",
        id: string,
        imageId: string,
        imageCategoryId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        imageCategory:  {
          __typename: "ImageCategory",
          id: string,
          category: string,
          images?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateImageCategorySubscriptionVariables = {
  filter?: ModelSubscriptionImageCategoryFilterInput | null,
};

export type OnUpdateImageCategorySubscription = {
  onUpdateImageCategory?:  {
    __typename: "ImageCategory",
    id: string,
    category: string,
    images?:  {
      __typename: "ModelImageCategoryRelationConnection",
      items:  Array< {
        __typename: "ImageCategoryRelation",
        id: string,
        imageId: string,
        imageCategoryId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        imageCategory:  {
          __typename: "ImageCategory",
          id: string,
          category: string,
          images?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteImageCategorySubscriptionVariables = {
  filter?: ModelSubscriptionImageCategoryFilterInput | null,
};

export type OnDeleteImageCategorySubscription = {
  onDeleteImageCategory?:  {
    __typename: "ImageCategory",
    id: string,
    category: string,
    images?:  {
      __typename: "ModelImageCategoryRelationConnection",
      items:  Array< {
        __typename: "ImageCategoryRelation",
        id: string,
        imageId: string,
        imageCategoryId: string,
        image:  {
          __typename: "Image",
          id: string,
          name: string,
          type: ImageType,
          genres?:  {
            __typename: "ModelImageGenreRelationConnection",
            items:  Array< {
              __typename: "ImageGenreRelation",
              id: string,
              imageId: string,
              genreId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          expressions?:  {
            __typename: "ModelExpressionConnection",
            items:  Array< {
              __typename: "Expression",
              id: string,
              name: string,
              createdAt?: string | null,
              updatedAt?: string | null,
              imageExpressionsId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          traits?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          categories?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          characterSet?: string | null,
          characterSetName?: string | null,
          location?: string | null,
        },
        imageCategory:  {
          __typename: "ImageCategory",
          id: string,
          category: string,
          images?:  {
            __typename: "ModelImageCategoryRelationConnection",
            items:  Array< {
              __typename: "ImageCategoryRelation",
              id: string,
              imageId: string,
              imageCategoryId: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateBookOrderSubscriptionVariables = {
  filter?: ModelSubscriptionBookOrderFilterInput | null,
  owner?: string | null,
};

export type OnCreateBookOrderSubscription = {
  onCreateBookOrder?:  {
    __typename: "BookOrder",
    id: string,
    checkoutId: string,
    storyId: string,
    title: string,
    bookplateStyle?: string | null,
    bookplateName?: string | null,
    byline?: string | null,
    dedication?: string | null,
    lineItems:  Array< {
      __typename: "OrderLineItem",
      description: string,
      quantity: number,
      amount: number,
      priceId?: string | null,
    } >,
    shippingName: string,
    shippingAddressLine1: string,
    shippingAddressLine2?: string | null,
    shippingCity: string,
    shippingState: string,
    shippingPostalCode: string,
    shippingCountry: string,
    status?: string | null,
    statusTimeline?: string | null,
    customerEmail: string,
    totalAmount: number,
    currency: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateBookOrderSubscriptionVariables = {
  filter?: ModelSubscriptionBookOrderFilterInput | null,
  owner?: string | null,
};

export type OnUpdateBookOrderSubscription = {
  onUpdateBookOrder?:  {
    __typename: "BookOrder",
    id: string,
    checkoutId: string,
    storyId: string,
    title: string,
    bookplateStyle?: string | null,
    bookplateName?: string | null,
    byline?: string | null,
    dedication?: string | null,
    lineItems:  Array< {
      __typename: "OrderLineItem",
      description: string,
      quantity: number,
      amount: number,
      priceId?: string | null,
    } >,
    shippingName: string,
    shippingAddressLine1: string,
    shippingAddressLine2?: string | null,
    shippingCity: string,
    shippingState: string,
    shippingPostalCode: string,
    shippingCountry: string,
    status?: string | null,
    statusTimeline?: string | null,
    customerEmail: string,
    totalAmount: number,
    currency: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteBookOrderSubscriptionVariables = {
  filter?: ModelSubscriptionBookOrderFilterInput | null,
  owner?: string | null,
};

export type OnDeleteBookOrderSubscription = {
  onDeleteBookOrder?:  {
    __typename: "BookOrder",
    id: string,
    checkoutId: string,
    storyId: string,
    title: string,
    bookplateStyle?: string | null,
    bookplateName?: string | null,
    byline?: string | null,
    dedication?: string | null,
    lineItems:  Array< {
      __typename: "OrderLineItem",
      description: string,
      quantity: number,
      amount: number,
      priceId?: string | null,
    } >,
    shippingName: string,
    shippingAddressLine1: string,
    shippingAddressLine2?: string | null,
    shippingCity: string,
    shippingState: string,
    shippingPostalCode: string,
    shippingCountry: string,
    status?: string | null,
    statusTimeline?: string | null,
    customerEmail: string,
    totalAmount: number,
    currency: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCheckoutMetadataSubscriptionVariables = {
  filter?: ModelSubscriptionCheckoutMetadataFilterInput | null,
  owner?: string | null,
};

export type OnCreateCheckoutMetadataSubscription = {
  onCreateCheckoutMetadata?:  {
    __typename: "CheckoutMetadata",
    id: string,
    stripeSessionId: string,
    customerEmail: string,
    purchaseType: string,
    lineItems:  Array< {
      __typename: "OrderLineItem",
      description: string,
      quantity: number,
      amount: number,
      priceId?: string | null,
    } >,
    totalAmount: number,
    currency: string,
    discountTotal?: number | null,
    discounts?:  Array< {
      __typename: "Discount",
      amount: number,
      promoCode?: string | null,
      couponId?: string | null,
      couponName?: string | null,
    } > | null,
    recipientName?: string | null,
    recipientEmail?: string | null,
    gifterName?: string | null,
    gifterEmail?: string | null,
    giftMessage?: string | null,
    deliveryDate?: string | null,
    giftCardStyle?: string | null,
    bookOrderId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCheckoutMetadataSubscriptionVariables = {
  filter?: ModelSubscriptionCheckoutMetadataFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCheckoutMetadataSubscription = {
  onUpdateCheckoutMetadata?:  {
    __typename: "CheckoutMetadata",
    id: string,
    stripeSessionId: string,
    customerEmail: string,
    purchaseType: string,
    lineItems:  Array< {
      __typename: "OrderLineItem",
      description: string,
      quantity: number,
      amount: number,
      priceId?: string | null,
    } >,
    totalAmount: number,
    currency: string,
    discountTotal?: number | null,
    discounts?:  Array< {
      __typename: "Discount",
      amount: number,
      promoCode?: string | null,
      couponId?: string | null,
      couponName?: string | null,
    } > | null,
    recipientName?: string | null,
    recipientEmail?: string | null,
    gifterName?: string | null,
    gifterEmail?: string | null,
    giftMessage?: string | null,
    deliveryDate?: string | null,
    giftCardStyle?: string | null,
    bookOrderId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCheckoutMetadataSubscriptionVariables = {
  filter?: ModelSubscriptionCheckoutMetadataFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCheckoutMetadataSubscription = {
  onDeleteCheckoutMetadata?:  {
    __typename: "CheckoutMetadata",
    id: string,
    stripeSessionId: string,
    customerEmail: string,
    purchaseType: string,
    lineItems:  Array< {
      __typename: "OrderLineItem",
      description: string,
      quantity: number,
      amount: number,
      priceId?: string | null,
    } >,
    totalAmount: number,
    currency: string,
    discountTotal?: number | null,
    discounts?:  Array< {
      __typename: "Discount",
      amount: number,
      promoCode?: string | null,
      couponId?: string | null,
      couponName?: string | null,
    } > | null,
    recipientName?: string | null,
    recipientEmail?: string | null,
    gifterName?: string | null,
    gifterEmail?: string | null,
    giftMessage?: string | null,
    deliveryDate?: string | null,
    giftCardStyle?: string | null,
    bookOrderId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateImageGenreRelationSubscriptionVariables = {
  filter?: ModelSubscriptionImageGenreRelationFilterInput | null,
};

export type OnCreateImageGenreRelationSubscription = {
  onCreateImageGenreRelation?:  {
    __typename: "ImageGenreRelation",
    id: string,
    imageId: string,
    genreId: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    genre:  {
      __typename: "Genre",
      id: string,
      name: string,
      images?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateImageGenreRelationSubscriptionVariables = {
  filter?: ModelSubscriptionImageGenreRelationFilterInput | null,
};

export type OnUpdateImageGenreRelationSubscription = {
  onUpdateImageGenreRelation?:  {
    __typename: "ImageGenreRelation",
    id: string,
    imageId: string,
    genreId: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    genre:  {
      __typename: "Genre",
      id: string,
      name: string,
      images?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteImageGenreRelationSubscriptionVariables = {
  filter?: ModelSubscriptionImageGenreRelationFilterInput | null,
};

export type OnDeleteImageGenreRelationSubscription = {
  onDeleteImageGenreRelation?:  {
    __typename: "ImageGenreRelation",
    id: string,
    imageId: string,
    genreId: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    genre:  {
      __typename: "Genre",
      id: string,
      name: string,
      images?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateImageCategoryRelationSubscriptionVariables = {
  filter?: ModelSubscriptionImageCategoryRelationFilterInput | null,
};

export type OnCreateImageCategoryRelationSubscription = {
  onCreateImageCategoryRelation?:  {
    __typename: "ImageCategoryRelation",
    id: string,
    imageId: string,
    imageCategoryId: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    imageCategory:  {
      __typename: "ImageCategory",
      id: string,
      category: string,
      images?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateImageCategoryRelationSubscriptionVariables = {
  filter?: ModelSubscriptionImageCategoryRelationFilterInput | null,
};

export type OnUpdateImageCategoryRelationSubscription = {
  onUpdateImageCategoryRelation?:  {
    __typename: "ImageCategoryRelation",
    id: string,
    imageId: string,
    imageCategoryId: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    imageCategory:  {
      __typename: "ImageCategory",
      id: string,
      category: string,
      images?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteImageCategoryRelationSubscriptionVariables = {
  filter?: ModelSubscriptionImageCategoryRelationFilterInput | null,
};

export type OnDeleteImageCategoryRelationSubscription = {
  onDeleteImageCategoryRelation?:  {
    __typename: "ImageCategoryRelation",
    id: string,
    imageId: string,
    imageCategoryId: string,
    image:  {
      __typename: "Image",
      id: string,
      name: string,
      type: ImageType,
      genres?:  {
        __typename: "ModelImageGenreRelationConnection",
        items:  Array< {
          __typename: "ImageGenreRelation",
          id: string,
          imageId: string,
          genreId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          genre:  {
            __typename: "Genre",
            id: string,
            name: string,
            images?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      expressions?:  {
        __typename: "ModelExpressionConnection",
        items:  Array< {
          __typename: "Expression",
          id: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          name: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          imageExpressionsId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      traits?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      categories?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      characterSet?: string | null,
      characterSetName?: string | null,
      location?: string | null,
    },
    imageCategory:  {
      __typename: "ImageCategory",
      id: string,
      category: string,
      images?:  {
        __typename: "ModelImageCategoryRelationConnection",
        items:  Array< {
          __typename: "ImageCategoryRelation",
          id: string,
          imageId: string,
          imageCategoryId: string,
          image:  {
            __typename: "Image",
            id: string,
            name: string,
            type: ImageType,
            genres?:  {
              __typename: "ModelImageGenreRelationConnection",
              nextToken?: string | null,
            } | null,
            expressions?:  {
              __typename: "ModelExpressionConnection",
              nextToken?: string | null,
            } | null,
            traits?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            categories?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            characterSet?: string | null,
            characterSetName?: string | null,
            location?: string | null,
          },
          imageCategory:  {
            __typename: "ImageCategory",
            id: string,
            category: string,
            images?:  {
              __typename: "ModelImageCategoryRelationConnection",
              nextToken?: string | null,
            } | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};
