import {
  UpdateUserInput,
  UpdateUserMutation,
} from 'src/graphql/API';
import { GraphQLResult, API } from '@aws-amplify/api';
import { updateUser } from 'src/graphql/mutations';

export default async function putUser(user: UpdateUserInput) {
  const response: GraphQLResult<UpdateUserMutation> = await API.graphql({
    query: updateUser,
    variables: {
      input: user,
    },
  }) as GraphQLResult<UpdateUserMutation>;
  return response.data?.updateUser;
}
