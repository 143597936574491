import React from 'react';
import { StoreButtons } from './StoreButtons';

interface TryTheAppProps {
  isDesktop: boolean;
}

export function TryTheApp({ isDesktop }: TryTheAppProps) {
  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className={`p-8 ${isDesktop ? 'flex items-center justify-between' : 'text-center'}`}>
          <div className={`${isDesktop ? 'max-w-xl' : 'mb-8'}`}>
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4">
              Create a story today
            </h2>
            <p className="text-base sm:text-lg">
              Download the app and get
              {' '}
              <span className="font-bold">5 FREE trial stories</span>
              .
              {' '}
              Turn any story into a hardcover, custom illustrated book featuring your child for $49.99.
            </p>
          </div>
          <div className={`${isDesktop ? 'ml-8' : ''}`}>
            <StoreButtons isDesktop={isDesktop} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TryTheApp;
