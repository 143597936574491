import { API, graphqlOperation } from 'aws-amplify';
import { deleteExpression, deleteImage, deleteImageGenreRelation } from 'src/graphql/mutations';
import { listImageGenreRelations } from 'src/graphql/queries';
import { ImageGenreRelation, Image } from 'src/graphql/API';

async function deleteImageInDB(image: Image) {
  try {
    console.log('image within DeleteImageById', image);
    console.log('expressions within DeleteImageById', image.expressions?.items);

    // Pull out the expression IDs and then delete them
    const expressionIDs = image.expressions?.items.map((expression) => expression?.id);
    if (expressionIDs) {
      await Promise.all(expressionIDs.map(async (expressionId: string | undefined) => {
        if (expressionId) {
          await API.graphql(graphqlOperation(deleteExpression, { input: { id: expressionId } }));
        }
      }));
    }

    console.log('before imageGenreRelations');

    const imageGenreRelationsData: any = await API.graphql(graphqlOperation(listImageGenreRelations, {
      filter: { imageId: { eq: image.id } },
    }));
    const imageGenreRelations = imageGenreRelationsData.data.listImageGenreRelations.items;
    console.log('imageGenreRelations within deleteImageById', imageGenreRelations);

    if (imageGenreRelations) {
      // Delete the ImageGenreRelations
      await Promise.all(imageGenreRelations.map(async (relation: ImageGenreRelation) => {
        await API.graphql(graphqlOperation(deleteImageGenreRelation, { input: { id: relation.id } }));
      }));
    }

    console.log('after imageGenreRelations');

    // Delete the Image
    await API.graphql(graphqlOperation(deleteImage, { input: { id: image.id } }));

    console.log('Image and associated data deleted successfully');
  } catch (error) {
    console.error('Error deleting Image:', error);
  }
}

export default deleteImageInDB;
