import _ from 'underscore';
import { UserPayment, UserPaymentByCognitoUserIdQuery } from 'src/graphql/API';
import { userPaymentByCognitoUserId } from 'src/graphql/queries';
import { GraphQLResult, API } from '@aws-amplify/api';
import getCognitoUserId from '@lib/getCognitoUserId';

export default async function getUserPayment() {
  const cognitoUserId = await getCognitoUserId();
  const response: GraphQLResult<UserPaymentByCognitoUserIdQuery> = await API.graphql({
    query: userPaymentByCognitoUserId,
    variables: { cognitoUserId },
  }) as GraphQLResult<UserPaymentByCognitoUserIdQuery>;
  const payments = response.data?.userPaymentByCognitoUserId?.items || [];
  return _.sortBy<UserPayment[]>(payments as UserPayment[], 'createdAt').reverse()[0];
}
