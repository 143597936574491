/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://i5zpuiegsfh3hpkpfml6eh7qyq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "anansiRestApi",
            "endpoint": "https://32q5ht2b1e.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        },
        {
            "name": "wanderlynoauth",
            "endpoint": "https://8xroml9np8.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:8942065e-bf5f-4894-8367-b3ee4744f23c",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_TcV9Lu1yO",
    "aws_user_pools_web_client_id": "7is4u83eqbifuk05sk43tn8vr",
    "oauth": {
        "domain": "anansi-social-auth-main.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://laura-daily.d3795v2ejnmd0z.amplifyapp.com/,https://stagingnew.d3795v2ejnmd0z.amplifyapp.com/,https://app.storiesbyanansi.com/,https://app.wander.ly/,wanderly://,https://masterimages.d3795v2ejnmd0z.amplifyapp.com/",
        "redirectSignOut": "http://localhost:3000/,https://laura-daily.d3795v2ejnmd0z.amplifyapp.com/,https://stagingnew.d3795v2ejnmd0z.amplifyapp.com/,https://app.storiesbyanansi.com/,https://app.wander.ly/,wanderly://,https://masterimages.d3795v2ejnmd0z.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "testimages00314-main",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
