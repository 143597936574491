import { Storage } from 'aws-amplify';

export default async function getNoAuthMetadata() {
  try {
    const result = await Storage.get('app-metadata-no-auth.json', {
      download: true,
      level: 'public',
      cacheControl: 'max-age=3600',
    });

    if (result.Body) {
      const text = await (result.Body as Blob).text();
      const data = JSON.parse(text);
      return data;
    }
    throw new Error('No data found');
  } catch (error) {
    console.error('Error fetching promotion string:', error);
    return null;
  }
}
