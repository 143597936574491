/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateExpressionInput,
  CreateImageInput,
  ImageType,
  Image,
  Expression,
} from 'src/graphql/API';
import createNewImage from '@features/Admin/api/createNewImage';
import { ANANSI_REST_API_NAME } from '@lib/constants';
import { API } from '@aws-amplify/api';
import { RootState, AppDispatch } from '@store/store';
import { v4 as uuidv4 } from 'uuid';
import { verifyImageExists } from '@lib/imageUtils';
import createNewGenreAPI from '@features/Admin/api/createNewGenre'; // Import the API function with a different name
import createImageGenreRelations from '@features/Admin/api/createImageGenreRelations';
import createNewExpression from '@features/Admin/api/createNewExpression';
import deleteImageInDB from '@features/Admin/api/deleteImageInDB';
import deleteExpressionInDB from '@features/Admin/api/deleteExpressionInDB';

// ####################
// HELPERS
// ####################

// Define the return type for convertToBase64
function convertToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        resolve((reader.result as string).split(',')[1]);
      } else {
        reject(new Error('File reading failed'));
      }
    };
    reader.onerror = (error) => reject(error);
  });
}

// Define the return type for handleImageUpload
async function handleImageUpload(file: File, newImage: CreateImageInput, expressionName: string): Promise<{ success: boolean; locations?: string[]; error?: string }> {
  const apiName = ANANSI_REST_API_NAME;
  const path = '/addImage';
  const base64 = await convertToBase64(file);

  try {
    const response = await API.post(apiName, path, {
      body: {
        file: base64,
        imageName: newImage.name,
        imageType: newImage.type,
        expressionName,
      },
    });

    if (response.locations && Array.isArray(response.locations)) {
      return { success: true, locations: response.locations };
    }
    return { success: false, error: 'Failed to upload image: No locations returned' };
  } catch (error) {
    console.error('Error:', error);
    return { success: false, error: `Failed to upload image: ${(error as Error).message}` };
  }
}

// delete the image from master and resized buckets in S3
async function deleteImageFromS3(imageName: string, imageType: ImageType, expressionName: string) {
  const apiName = ANANSI_REST_API_NAME;
  const path = '/deleteImage';

  try {
    const response = await API.post(apiName, path, {
      body: {
        imageName,
        imageType,
        expressionName,
      },
    });
    console.log('response', response);
    if (response.statusCode >= 400) {
      throw new Error(`Server returned error: ${response.statusCode}`);
    }
    return { success: true, message: `Image ${imageName} deleted successfully` };
  } catch (error) {
    console.error('Error:', error);
    throw new Error(`Failed to delete image: ${(error as Error).message}`);
  }
}

// ####################
// TYPES
// ####################

export interface AdminAction {
  id: string;
  message: string;
  status: 'pending' | 'success' | 'error';
}

interface AdminState {
  actions: AdminAction[];
}

const initialState: AdminState = {
  actions: [],
};

// ####################
// THUNKS
// ####################

export const uploadImageAndCreateEntry = createAsyncThunk<
  { success: boolean; message: string },
  {
    file: File;
    newImage: CreateImageInput;
    imageType: ImageType;
    genreId: string;
    expressionName: string;
  },
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: string;
  }
>(
  'admin/uploadImageAndCreateEntry',
  async (
    {
      file,
      newImage,
      imageType,
      genreId,
      expressionName,
    },
    { rejectWithValue },
  ) => {
    try {
      // First, validate that this image is not already in the database
      const existingImage = await verifyImageExists(newImage.name);
      if (existingImage) {
        return rejectWithValue(`Image already exists: ${newImage.name}`);
      }

      // Upload the image to S3
      const res = await handleImageUpload(file, newImage, expressionName);

      if (!res.success) {
        return rejectWithValue(res.error || 'Unknown error occurred during handleImageUpload');
      }

      console.log('before createNewImage');
      const imageId = await createNewImage(newImage);

      console.log('after createNewImage');

      await createImageGenreRelations(imageId, [genreId]);

      console.log('after createImageGenreRelations');

      // only create an expression if the image is not a background
      if (imageType !== ImageType.BACKGROUND && expressionName) {
        const newExpressionInput = {
          name: expressionName,
          imageExpressionsId: imageId,
        } as CreateExpressionInput;
        await createNewExpression(newExpressionInput);
      }

      console.log('after createNewExpression');

      // Return a success message or relevant data
      return { success: true, message: `${newImage.name}${expressionName ? ` - ${expressionName}` : ''}` };
    } catch (error) {
      return rejectWithValue((error as Error).message || 'Unknown error occurred');
    }
  },
);

export const createNewGenre = createAsyncThunk<
  { genreId: string; name: string },
  { name: string },
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>('admin/createNewGenre', async ({ name }, { rejectWithValue }) => {
  try {
    const newGenreInput = {
      name,
    };

    const genreId = await createNewGenreAPI(newGenreInput); // Use the imported API function
    if (typeof genreId !== 'string') {
      throw new Error('Invalid genre ID returned');
    }
    return { genreId, name };
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('Unknown error occurred');
  }
});

export const uploadAndCreateNewExpression = createAsyncThunk<
  { success: boolean; message: string },
  {
    file: File;
    newExpressionName: string;
    parentImage: Image;
  },
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: string;
  }
>(
  'admin/createAndUploadNewExpression',
  async ({ file, newExpressionName, parentImage }, { rejectWithValue }) => {
    try {
      const uploadResult = await handleImageUpload(file, parentImage, newExpressionName);
      if (!uploadResult.success) {
        return rejectWithValue(uploadResult.error || 'Failed to upload image');
      }

      const newExpressionInput = {
        name: newExpressionName,
        imageExpressionsId: parentImage.id,
      } as CreateExpressionInput;

      await createNewExpression(newExpressionInput);

      return { success: true, message: `New expression "${newExpressionName}" created successfully` };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Unknown error occurred');
    }
  },
);

export const deleteImage = createAsyncThunk<
  { success: boolean; message: string },
  { image: Image },
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>('admin/deleteImage', async ({ image }, { rejectWithValue }) => {
  try {
    await deleteImageFromS3(image.name, image.type, '');
    await deleteImageInDB(image);
    return { success: true, message: `Image ${image.name} deleted successfully` };
  } catch (error) {
    return rejectWithValue(`Error deleting image ${image.name}: ${(error as Error).message}`);
  }
});

export const deleteExpression = createAsyncThunk<
  { success: boolean; message: string },
  { image: Image; expression: Expression },
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>('admin/deleteExpression', async ({ image, expression }, { rejectWithValue }) => {
  try {
    await deleteImageFromS3(image.name, image.type, expression.name);
    await deleteExpressionInDB(expression);
    return { success: true, message: `Expression ${expression.name} deleted successfully` };
  } catch (error) {
    return rejectWithValue(`Error deleting expression ${expression.name}: ${(error as Error).message}`);
  }
});

// ####################
// SLICE
// ####################

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    addAdminAction: (state, action: PayloadAction<Omit<AdminAction, 'id'>>) => {
      state.actions.push({
        ...action.payload,
        id: uuidv4(),
      });
    },
    updateAdminAction: (state, action: PayloadAction<{ id: string; status: 'success' | 'error'; message?: string }>) => {
      const index = state.actions.findIndex((a) => a.id === action.payload.id);
      if (index !== -1) {
        state.actions[index] = {
          ...state.actions[index],
          status: action.payload.status,
          message: action.payload.message || state.actions[index].message,
        };
      }
    },
    removeAdminAction: (state, action: PayloadAction<string>) => {
      state.actions = state.actions.filter(
        (adminAction) => adminAction.id !== action.payload,
      );
    },
    clearAdminActions: (state) => {
      state.actions = [];
    },
    resetAdminState: (state) => {
      state.actions = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadImageAndCreateEntry.pending, (state) => {
        const id = uuidv4();
        state.actions.push({
          id,
          message: 'Uploading image and creating entry...',
          status: 'pending',
        });
      })
      .addCase(uploadImageAndCreateEntry.fulfilled, (state, action) => {
        const index = state.actions.findIndex((a) => a.status === 'pending');
        console.log('action', action);
        if (index !== -1) {
          state.actions[index] = {
            ...state.actions[index],
            status: 'success',
            message: `Image uploaded and entry created successfully: ${action.payload.message}`,
          };
        }
      })
      .addCase(uploadImageAndCreateEntry.rejected, (state, action) => {
        const index = state.actions.findIndex((a) => a.status === 'pending');
        if (index !== -1) {
          state.actions[index] = {
            ...state.actions[index],
            status: 'error',
            message: `Error: ${action.payload || 'Unknown error occurred'}`,
          };
        }
      })
      .addCase(uploadAndCreateNewExpression.pending, (state) => {
        const id = uuidv4();
        state.actions.push({
          id,
          message: 'Uploading image and creating entry...',
          status: 'pending',
        });
      })
      .addCase(uploadAndCreateNewExpression.fulfilled, (state, action) => {
        const index = state.actions.findIndex((a) => a.status === 'pending');
        if (index !== -1) {
          state.actions[index] = {
            ...state.actions[index],
            status: 'success',
            message: `Image uploaded and entry created successfully: ${action.payload.message}`,
          };
        }
      })
      .addCase(uploadAndCreateNewExpression.rejected, (state, action) => {
        const index = state.actions.findIndex((a) => a.status === 'pending');
        if (index !== -1) {
          state.actions[index] = {
            ...state.actions[index],
            status: 'error',
            message: `Error: ${action.payload || 'Unknown error occurred'}`,
          };
        }
      })
      .addCase(createNewGenre.pending, (state) => {
        const id = uuidv4();
        state.actions.push({
          id,
          message: 'Creating new genre...',
          status: 'pending',
        });
      })
      .addCase(createNewGenre.fulfilled, (state, action) => {
        const index = state.actions.findIndex((a) => a.status === 'pending');
        if (index !== -1) {
          state.actions[index] = {
            ...state.actions[index],
            status: 'success',
            message: `Genre created successfully: ${action.payload.name}`,
          };
        }
      })
      .addCase(createNewGenre.rejected, (state, action) => {
        const index = state.actions.findIndex((a) => a.status === 'pending');
        if (index !== -1) {
          state.actions[index] = {
            ...state.actions[index],
            status: 'error',
            message: `Error: ${action.payload || 'Unknown error occurred'}`,
          };
        }
      })
      .addCase(deleteImage.pending, (state) => {
        const id = uuidv4();
        state.actions.push({
          id,
          message: 'Deleting image...',
          status: 'pending',
        });
      })
      .addCase(deleteImage.fulfilled, (state, action) => {
        const index = state.actions.findIndex((a) => a.status === 'pending');
        if (index !== -1) {
          state.actions[index] = {
            ...state.actions[index],
            status: 'success',
            message: `Image deleted successfully: ${action.payload.message}`,
          };
        }
      })
      .addCase(deleteImage.rejected, (state, action) => {
        const index = state.actions.findIndex((a) => a.status === 'pending');
        if (index !== -1) {
          state.actions[index] = {
            ...state.actions[index],
            status: 'error',
            message: `Error: ${action.payload || 'Unknown error occurred'}`,
          };
        }
      })
      .addCase(deleteExpression.pending, (state) => {
        const id = uuidv4();
        state.actions.push({
          id,
          message: 'Deleting expression...',
          status: 'pending',
        });
      })
      .addCase(deleteExpression.fulfilled, (state, action) => {
        const index = state.actions.findIndex((a) => a.status === 'pending');
        if (index !== -1) {
          state.actions[index] = {
            ...state.actions[index],
            status: 'success',
            message: `Expression deleted successfully: ${action.payload.message}`,
          };
        }
      })
      .addCase(deleteExpression.rejected, (state, action) => {
        const index = state.actions.findIndex((a) => a.status === 'pending');
        if (index !== -1) {
          state.actions[index] = {
            ...state.actions[index],
            status: 'error',
            message: `Error: ${action.payload || 'Unknown error occurred'}`,
          };
        }
      });
  },
});

// ####################
// EXPORTS
// ####################

export const {
  addAdminAction,
  updateAdminAction,
  removeAdminAction,
  clearAdminActions,
  resetAdminState,
} = adminSlice.actions;

export const selectAdminActions = (state: RootState) => state.admin.actions;

export default adminSlice.reducer;
