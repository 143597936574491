import React from 'react';
import { useSelector } from 'react-redux';
import { selectPromotionString } from '@store/checkoutSlice';

export default function PromotionBanner() {
  const promotionString = useSelector(selectPromotionString);

  if (!promotionString) return null;

  const renderFormattedText = (text: string) => {
    const parts = text.split(/({{.*?}})/);
    return parts.map((part) => {
      if (part.startsWith('{{') && part.endsWith('}}')) {
        const boldText = part.slice(2, -2);
        return <strong key={`bold-${boldText}`}>{boldText}</strong>;
      }
      return <span key={`text-${part}`}>{part}</span>;
    });
  };

  return (
    <div className="bg-primary text-white text-center py-2 px-4 relative z-20">
      <p className="text-sm font-medium">{renderFormattedText(promotionString)}</p>
    </div>
  );
}
