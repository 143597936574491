import { ReactElement } from 'react';
import { Dialog } from '@headlessui/react';
import AnButton, { AnButtonProps } from './AnButton';

interface Props {
  title?: string;
  subTitle?: string;
  children?: ReactElement;
  buttons?: AnButtonProps[];
  isOpen: boolean;
  confirmBtn?: ReactElement<AnButtonProps>;
  cancelBtn?: ReactElement<AnButtonProps>;
  onClose?: () => void;
}

export default function AnModal({
  title,
  subTitle,
  children,
  buttons = [],
  isOpen,
  onClose = () => {},
  confirmBtn,
  cancelBtn,
}: Props) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/50 z-40" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-10 z-50 text-center">
        <Dialog.Panel className="w-full max-w-sm max-h-[90vh] rounded bg-white p-4 overflow-auto">

          <div className="mb-4">
            {title && <Dialog.Title className="font-sans">{title}</Dialog.Title>}
            {subTitle && (
            <Dialog.Description className="text-light">
              {subTitle}
            </Dialog.Description>
            )}
          </div>

          <div className="my-10">
            {children}
          </div>

          <div className="flex flex-row justify-end gap-2">
            {cancelBtn}
            {
            buttons.map((buttonProps, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <AnButton key={index} {...buttonProps} />
            ))
          }
            {confirmBtn}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
