import React from 'react';
import { Dialog } from '@headlessui/react';
import AnButton from '@components/AnButton';
import AnCrossIcon from '@components/AnIcon/icons/AnCrossIcon';
import DiscountPromoContent from './DiscountPromoContent';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function DiscountPromoModal({ isOpen, onClose }: Props) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="fixed inset-0 bg-black/50 z-40" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-10 z-50">
        <Dialog.Panel className="w-full max-w-md rounded-xl bg-white pt-10 pb-8 px-8 relative">
          <AnButton
            onClick={onClose}
            className="absolute right-3 top-3"
            iconRight={AnCrossIcon}
            variant="text"
            color="text-dark"
            iconProps={{
              size: 'xl',
            }}
          />

          <DiscountPromoContent onClose={onClose} />
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
