import { GraphQLResult, API } from '@aws-amplify/api';
import { GetStoryPagesQuery } from 'src/graphql/API';
import { getStoryPages as getStoryPagesQuery } from 'src/graphql/queries';

async function getStoryPages(storyId: string) {
  const res = await API.graphql({
    query: getStoryPagesQuery,
    variables: {
      id: storyId,
    },
  }) as GraphQLResult<GetStoryPagesQuery>;

  return res.data?.getStoryPages;
}

export default getStoryPages;
