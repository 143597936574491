import AnIcon from '../AnIcon';
import IconProps from '../IconProps.interface';

export default function AnChevronLeftIcon({
  fillColorClass,
  strokeColorClass,
  size,
  strokeWidth,
}: IconProps) {
  return (
    <AnIcon
      size={size}
      strokeWidth={strokeWidth}
      fillColorClass={fillColorClass}
      strokeColorClass={strokeColorClass}
      viewBoxWidth={22}
      viewBoxHeight={22}
      svgPath={(
        <g>
          <path d="M8.293 12.707a1 1 0 0 1 0-1.414l5.657-5.657a1 1 0 1 1 1.414 1.414L10.414 12l4.95 4.95a1 1 0 0 1-1.414 1.414l-5.657-5.657Z" />
        </g>
      )}
    />
  );
}
