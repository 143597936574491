import AnIcon from '../AnIcon';
import IconProps from '../IconProps.interface';

export default function AnCrossIcon({
  fillColorClass,
  strokeColorClass,
  size,
  strokeWidth,
}: IconProps) {
  return (
    <AnIcon
      size={size}
      strokeWidth={strokeWidth}
      fillColorClass={fillColorClass}
      strokeColorClass={strokeColorClass}
      viewBoxWidth={40}
      viewBoxHeight={40}
      svgPath={(
        <path d="M18.8211 20L11.077 12.2558C10.7517 11.9305 10.7517 11.4029 11.077 11.0772C11.4023 10.7519 11.93 10.7519 12.2556 11.0772L19.9997 18.8214L27.7439 11.0772C28.0692 10.7519 28.5968 10.7519 28.9225 11.0772C29.2478 11.4026 29.2478 11.9302 28.9225 12.2558L21.1783 20L28.9225 27.7441C29.2478 28.0694 29.2478 28.5971 28.9225 28.9227C28.5971 29.248 28.0695 29.248 27.7439 28.9227L19.9997 21.1786L12.2556 28.9227C11.9303 29.248 11.4026 29.248 11.077 28.9227C10.7517 28.5974 10.7517 28.0697 11.077 27.7441L18.8211 20Z" />
            )}
    />
  );
}
