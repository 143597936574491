import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AnTextField from '@components/AnTextField';
import AnCheckoutImageLineItem from '@components/AnCheckoutImageLineItem';
import isEmail from 'validator/lib/isEmail';
import { useDispatch, useSelector } from 'react-redux';
import { selectPurchaseDetails, setPackagePurchaseDetails } from '@store/checkoutSlice';
import { imageBaseURL } from '@lib/imageUtils';
import { LineItem, PackagePurchase, PRODUCT_NAMES } from '../types';

interface CustomizeGiftProps {
  onIsFormValid: (isValid: boolean) => void;
}

// Constants for validation
const MAX_LINES = 4;
const MAX_TOTAL_CHARS = 210;

// Utility function to count lines
const countLines = (text: string): number => text.split('\n').length;

const giftCardStyleOptions: LineItem[] = [
  {
    priceId: '',
    productId: '',
    name: PRODUCT_NAMES.GENERAL_GC_STYLE,
    description: PRODUCT_NAMES.GENERAL_GC_STYLE,
    currency: '',
    amount: 0,
    imageSrc: `${imageBaseURL}misc/checkout/default-giftcard_320w.webp`,
  },
  {
    priceId: '',
    productId: '',
    name: PRODUCT_NAMES.HOLIDAY_GC_STYLE,
    description: PRODUCT_NAMES.HOLIDAY_GC_STYLE,
    currency: '',
    amount: 0,
    imageSrc: `${imageBaseURL}misc/checkout/holiday-giftcard_320w.webp`,
  },
  {
    priceId: '',
    productId: '',
    name: PRODUCT_NAMES.BIRTHDAY_GC_STYLE,
    description: PRODUCT_NAMES.BIRTHDAY_GC_STYLE,
    currency: '',
    amount: 0,
    imageSrc: `${imageBaseURL}misc/checkout/birthday-giftcard_320w.webp`,
  },
];

// Update the validation schema
const validationSchema = yup.object().shape({
  gifterName: yup.string().nullable().required('Please enter your name'),
  gifterEmail: yup
    .string()
    .test('is-valid-email', 'Please enter a valid email address', (value) => isEmail(value || ''))
    .required('Please enter your email'),
  giftMessage: yup.string().nullable().required('Please enter a message')
    .test(
      'max-characters',
      `Message must be at most ${MAX_TOTAL_CHARS} characters`,
      (value) => (value ? value.length <= MAX_TOTAL_CHARS : false),
    )
    .test(
      'max-lines',
      `Message must be at most ${MAX_LINES} lines`,
      (value) => (value ? countLines(value) <= MAX_LINES : false),
    ),
});

export default function CustomizeGift({
  onIsFormValid,
}: CustomizeGiftProps) {
  const purchaseDetails = useSelector(selectPurchaseDetails) as PackagePurchase;
  const dispatch = useDispatch();

  const [giftMessageFeedback, setGiftMessageFeedback] = useState({
    remainingChars: MAX_TOTAL_CHARS,
    currentLines: 1,
    isValid: true,
  });

  const prevGifterNameRef = useRef(purchaseDetails?.gifterName);
  const prevGifterEmailRef = useRef(purchaseDetails?.gifterEmail);
  const prevGiftMessageRef = useRef(purchaseDetails?.giftMessage);

  const {
    control,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      gifterName: purchaseDetails?.gifterName || '',
      gifterEmail: purchaseDetails?.gifterEmail || '',
      giftMessage: purchaseDetails?.giftMessage || 'Hi, I’m giving you a Wanderly gift card, so you and your family can create and publish personalized, interactive stories. ✨',
    },
  });

  const watchedGifterName = watch('gifterName');
  const watchedGifterEmail = watch('gifterEmail');
  const watchedGiftMessage = watch('giftMessage');

  const [selectedStyle, setSelectedStyle] = useState<LineItem | null>(null);

  // Initialize gift card style once
  useEffect(() => {
    if (giftCardStyleOptions.length > 0 && !selectedStyle) {
      const defaultStyle = giftCardStyleOptions[0];
      setSelectedStyle(defaultStyle);
      dispatch(setPackagePurchaseDetails({
        giftCardStyle: defaultStyle.name,
      }));
    }
  }, []);

  const handleGiftCardStyleSelection = useCallback((newSelectedStyle: LineItem | null) => {
    if (!newSelectedStyle) return;

    setSelectedStyle(newSelectedStyle);
    dispatch(setPackagePurchaseDetails({
      giftCardStyle: newSelectedStyle.name,
    }));
  }, [dispatch]);

  useEffect(() => {
    onIsFormValid(isValid);
  }, [isValid, onIsFormValid]);

  useEffect(() => {
    if (watchedGifterName !== prevGifterNameRef.current
      || watchedGifterEmail !== prevGifterEmailRef.current
      || watchedGiftMessage !== prevGiftMessageRef.current) {
      dispatch(setPackagePurchaseDetails({
        gifterName: watchedGifterName,
        gifterEmail: watchedGifterEmail,
        giftMessage: watchedGiftMessage,
      }));
      prevGifterNameRef.current = watchedGifterName;
      prevGifterEmailRef.current = watchedGifterEmail;
      prevGiftMessageRef.current = watchedGiftMessage;
    }
  }, [watchedGifterName, watchedGifterEmail, watchedGiftMessage, dispatch]);

  // Update this effect to handle null values
  useEffect(() => {
    const remainingChars = MAX_TOTAL_CHARS - (watchedGiftMessage?.length || 0);
    const currentLines = watchedGiftMessage ? countLines(watchedGiftMessage) : 0;

    const isValidFeedback = remainingChars >= 0 && currentLines <= MAX_LINES;

    setGiftMessageFeedback({
      remainingChars,
      currentLines,
      isValid: isValidFeedback,
    });
  }, [watchedGiftMessage]);

  if (!purchaseDetails) {
    return null;
  }

  return (
    <form className="my-4 max-w-sm">
      <div>
        <p className="text-sm text-gray-600 pb-1">Your Message</p>
        <AnTextField
          name="giftMessage"
          placeholder=""
          control={control}
          error={!!errors.giftMessage}
          errorMessage={errors.giftMessage?.message}
          textFieldStyle="fully-enclosed"
          multiline
          autoComplete="off"
        />
        <p
          className={`text-xs text-right ${
            giftMessageFeedback.isValid ? 'text-gray-500' : 'text-red-500'
          }`}
        >
          Characters remaining:
          {' '}
          {giftMessageFeedback.remainingChars}
        </p>
      </div>
      <div className="mb-4">
        <p className="text-sm text-gray-600 pb-1">From</p>
        <AnTextField
          name="gifterName"
          placeholder="Your Name"
          control={control}
          error={!!errors.gifterName}
          errorMessage={errors.gifterName?.message}
          textFieldStyle="fully-enclosed"
          autoComplete="name"
        />
      </div>
      <div className="mb-4">
        <p className="text-sm text-gray-600 pb-1">Your Email</p>
        <AnTextField
          name="gifterEmail"
          placeholder="first.last@example.com"
          control={control}
          error={!!errors.gifterEmail}
          errorMessage={errors.gifterEmail?.message}
          textFieldStyle="fully-enclosed"
          autoComplete="email"
        />
      </div>
      <AnCheckoutImageLineItem
        lineItemOptions={giftCardStyleOptions}
        onChange={handleGiftCardStyleSelection}
        selectedOption={selectedStyle}
        title="Gift Card Style"
        showDescription
        showPrice={false}
      />
    </form>
  );
}
