import { useEffect, useState, useCallback } from 'react';
import AnCheckoutImageLineItem from '@components/AnCheckoutImageLineItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCart,
  removeFromCart,
  selectCart,
  selectPackageOptions,
} from '@store/checkoutSlice';
import { LineItem, PRODUCT_NAMES } from '../types';

export default function SelectPackage() {
  const [selectedPackage, setSelectedPackage] = useState<LineItem | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const cart = useSelector(selectCart);
  const packageOptions = useSelector(selectPackageOptions);
  const dispatch = useDispatch();

  useEffect(() => {
    if (packageOptions.length > 0 && !isInitialized) {
      const cartPackage = cart.items.find((item) => packageOptions.some((pkg) => pkg.priceId === item.priceId));

      if (cartPackage) {
        const matchingPackage = packageOptions.find((pkg) => pkg.priceId === cartPackage.priceId);
        if (matchingPackage) {
          setSelectedPackage(matchingPackage);
        }
      } else {
        const standardPackage = packageOptions.find((pkg) => pkg.name === PRODUCT_NAMES.STANDARD_PACKAGE);
        if (standardPackage) {
          setSelectedPackage(standardPackage);
          dispatch(addToCart({ ...standardPackage, quantity: 1 }));
        }
      }
      setIsInitialized(true);
    }
  }, [packageOptions, cart, dispatch]);

  const handlePackageSelection = useCallback((newSelectedPackage: LineItem | null) => {
    if (!newSelectedPackage) return;

    if (selectedPackage) {
      dispatch(removeFromCart(selectedPackage.priceId));
    }

    setSelectedPackage(newSelectedPackage);
    dispatch(addToCart({ ...newSelectedPackage, quantity: 1 }));
  }, [selectedPackage, dispatch]);

  return (
    <>
      {isInitialized && packageOptions.length > 0 && (
        <AnCheckoutImageLineItem
          lineItemOptions={packageOptions}
          onChange={handlePackageSelection}
          selectedOption={selectedPackage}
        />
      )}
      <p className="text-sm text-gray-600 mt-4">All book packages include shipping.</p>
    </>
  );
}
