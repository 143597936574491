import IconProps from '../IconProps.interface';
import AnIcon from '../AnIcon';

export default function AnEditPencilIcon({
  fillColorClass,
  strokeColorClass,
  size,
  strokeWidth,
}: IconProps) {
  return (
    <AnIcon
      size={size}
      strokeWidth={strokeWidth}
      fillColorClass={fillColorClass}
      strokeColorClass={strokeColorClass}
      viewBoxWidth={22}
      viewBoxHeight={22}
      svgPath={(
        <g>
          <path d="M13.896 3.03a2 2 0 0 1 2.701-.117l.127.117 4.243 4.243a2 2 0 0 1 .117 2.7l-.117.128-10.314 10.314a2 2 0 0 1-1.238.578L9.239 21H4.006a1.01 1.01 0 0 1-1.004-.9l-.006-.11v-5.233a2 2 0 0 1 .467-1.284l.12-.13L13.895 3.03ZM12.17 7.584l-7.174 7.174V19H9.24l7.174-7.174-4.243-4.243Zm3.14-3.14L13.584 6.17l4.243 4.243 1.726-1.726z" />
        </g>
      )}
    />
  );
}
