// const defaultColors = require('tailwindcss/colors');

const colors = {
  primary: {
    900: '#17ACA1',
    700: '#3DB4B0',
    500: '#68C5C3',
    200: '#97D7D5',
    DEFAULT: '#17ACA1',
  },
  secondary: {
    900: '#F9CB4C',
    700: '#F9D56C',
    500: '#FADE8D',
    200: '#FAE8B1',
    DEFAULT: '#F9CB4C',
  },
  success: {
    950: '#64A076',
    900: '#64D776',
    700: '#80DE8F',
    500: '#9DE6A9',
    200: '#BCECC4',
    DEFAULT: '#64D776',
  },
  error: {
    950: '#D35A3C',
    900: '#F85A3C',
    700: '#F9785E',
    500: '#F99783',
    200: '#FAB8A9',
    DEFAULT: '#F85A3C',
  },
  text: {
    900: '#181818',
    700: '3D3D3D',
    500: '686868',
    200: '979797',
    dark: '#181818',
    light: '#979797',
  },
  smoky: { // dark
    900: '#15445A',
    700: '#3B6578',
    500: '#668897',
    200: '#96ADB8',
    DEFAULT: '#15445A',
  },
  accent1: {
    900: '#FDC591',
    700: '#FDD0A5',
    500: '#FDDABA',
    200: '#FCE6D0',
    DEFAULT: '#FDC591',
  },
  accent2: {
    900: '#F58269',
    700: '#F58F7B',
    500: '#F7AA9A',
    200: '#F8C4B9',
    DEFAULT: '#F58269',
  },
  blur: '#FFFFFFB5',
};

export default colors;
