import useEmblaCarousel from 'embla-carousel-react';
import { useState, useCallback, useEffect } from 'react';

interface ImageCarouselProps {
  images: Array<{
    src: string;
    srcSet?: string;
    thumbnail?: string;
  }>;
  showDots?: boolean;
  showThumbnails?: boolean;
  className?: string;
}

export default function ImageCarousel({
  images,
  showDots = false,
  showThumbnails = false,
  className = '',
}: ImageCarouselProps) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    containScroll: 'keepSnaps',
    align: 'start',
  });

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return undefined;
    onSelect();
    emblaApi.on('select', onSelect);
    return () => {
      emblaApi.off('select', onSelect);
    };
  }, [emblaApi, onSelect]);

  const scrollTo = useCallback((index: number) => {
    emblaApi?.scrollTo(index);
  }, [emblaApi]);

  return (
    <div className={`space-y-4 ${className}`}>
      <div className="embla overflow-hidden relative" ref={emblaRef}>
        <div className="embla__container flex px-4">
          {images.map((image) => (
            <div
              key={image.src}
              className="embla__slide flex-[0_0_90%] pl-4"
            >
              <img
                src={image.src}
                srcSet={image.srcSet}
                sizes="(max-width: 640px) 320px, (max-width: 1280px) 640px, 1280px"
                alt=""
                className="w-full h-full object-cover rounded-lg"
                loading="lazy"
              />
            </div>
          ))}
        </div>

        {showDots && (
          <div className="absolute bottom-4 left-0 right-0 flex justify-center gap-2">
            {images.map((image, index) => (
              <button
                key={image.src}
                className={`w-2 h-2 rounded-full transition-colors ${
                  index === selectedIndex
                    ? 'bg-accent1'
                    : 'bg-gray-300'
                }`}
                onClick={() => scrollTo(index)}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        )}
      </div>

      {showThumbnails && (
        <div className="flex justify-center gap-2 overflow-x-auto px-4 py-1">
          {images.map((image, index) => (
            <button
              aria-label={`Go to slide ${index + 1}`}
              key={image.src}
              onClick={() => scrollTo(index)}
              className={`flex-shrink-0 w-16 h-16 rounded-md p-0.5 ${
                index === selectedIndex
                  ? 'ring-2 ring-accent1'
                  : ''
              }`}
            >
              <img
                src={image.thumbnail || image.src}
                alt=""
                className="w-full h-full object-cover rounded-md"
                loading="lazy"
              />
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
