/* eslint-disable no-param-reassign */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Child, User, UserPayment } from 'src/graphql/API';
import getUserPayment from '@lib/api/getUserPayment';
import getTrialStoriesCount from '@lib/api/getTrialStoriesCount';
import updateTrialStoriesCount from '@lib/api/updateTrialStoriesCount';
import redeemGiftCards from '@lib/api/redeemGiftCards';
import { RootState } from './store';

export interface Giftcard {
  giftingEmail: string;
  storyCredits: number;
}

interface AppState {
  user?: Omit<User, 'children'>;
  children: Omit<Child, 'storyPages'>[];
  isFocusMode: boolean;
  isMobileNavOpen: boolean;
  userPayment?: UserPayment;
  loading: boolean;
  loadingPaymentData: boolean;
  giftcardsRedeemed: Giftcard[];
  showGiftcardRedeemedModal: boolean;
}

interface FetchPaymentDataResult {
  payment: UserPayment | undefined;
  remainingTrialStories: number;
}

interface RedeemGiftcardsDataResult {
  redeemedGiftcards: Giftcard[];
}

const initialState: AppState = {
  user: undefined,
  children: [],
  isFocusMode: false,
  isMobileNavOpen: false,
  userPayment: undefined,
  loading: false,
  loadingPaymentData: false,
  giftcardsRedeemed: [],
  showGiftcardRedeemedModal: false,
};

export const selectUser = (state: RootState) => state.app.user;
export const selectLoading = (state: RootState) => state.app.loading;
export const selectActiveChild = (state: RootState) => state.app.children.filter((child) => child.id === state.app.user?.activeChildId)[0];
export const selectChildren = (state: RootState) => state.app.children;
export const selectFocusMode = (state: RootState) => state.app.isFocusMode;
export const selectNavOpen = (state: RootState) => state.app.isMobileNavOpen;
export const selectUserPayment = (state: RootState) => state.app.userPayment;
export const selectPremium = (state: RootState) => state.app.userPayment?.status === 'active';
export const selectPremiumOrTrial = (state: RootState) => state.app.userPayment?.status === 'active' || (state.app.user?.trialStoriesCount ?? 0) > 0;
export const selectRemainingTrialStories = (state: RootState) => state.app.user?.trialStoriesCount;
export const selectLoadingPaymentData = (state: RootState) => state.app.loadingPaymentData;
export const selectGiftcardsRedeemed = (state: RootState) => state.app.giftcardsRedeemed;
export const selectShowGiftcardRedeemedModal = (state: RootState) => state.app.showGiftcardRedeemedModal;

const fetchUserPaymentData = createAsyncThunk<FetchPaymentDataResult, void>(
  'app/fetchUserPaymentData',
  async (_, thunkAPI) => {
    try {
      const payment = await getUserPayment();
      const remainingTrialStories = await getTrialStoriesCount();
      const result: FetchPaymentDataResult = { payment, remainingTrialStories };
      return result;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error ? error.message : 'Unknown error');
    }
  },
);

const decrementTrialStoriesCount = createAsyncThunk<number>(
  'app/decrementTrialStoriesCount',
  async (_, thunkAPI) => {
    try {
      const userTrialCount = (thunkAPI.getState() as RootState).app.user?.trialStoriesCount;
      if (!userTrialCount) {
        return 1;
      }
      const newTrialCount = userTrialCount - 1;
      const userID = (thunkAPI.getState() as RootState).app.user?.id as string;
      const res = await updateTrialStoriesCount(newTrialCount, userID);
      if (res) { // check if res is not null or undefined
        return newTrialCount;
      }
      return 1;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error ? error.message : 'Unknown error');
    }
  },
);

const redeemGiftcardsThunk = createAsyncThunk<RedeemGiftcardsDataResult>(
  'app/redeemGiftcards',
  async (_, thunkAPI) => {
    try {
      const redeemedGiftcardsResult = await redeemGiftCards();

      // Check if the array is not empty before returning
      if (redeemedGiftcardsResult.length === 0) {
        return { redeemedGiftcards: [] }; // Return an explicitly empty array
      }

      return { redeemedGiftcards: redeemedGiftcardsResult }; // Return the result as is
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error ? error.message : 'Unknown error');
    }
  },
);

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetUser: (state) => {
      state.user = undefined;
      state.children = [];
      state.giftcardsRedeemed = [];
      state.showGiftcardRedeemedModal = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { children, ...rest } = action.payload || {};
      state.user = rest;
    },
    setChildren: (state, action: PayloadAction<Child[]>) => {
      state.children = action.payload.map((childItem) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { storyPages, ...rest } = childItem;
        return rest;
      });
    },
    addNewChild: (state, action: PayloadAction<Child>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { storyPages, ...rest } = action.payload;
      state.children = [...state.children, rest];
    },
    removeChild: (state, action: PayloadAction<string>) => {
      const newChildrenArr = state.children.filter((child) => child.id !== action.payload);
      state.children = newChildrenArr;
    },
    updateChild: (state, action: PayloadAction<Child>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { storyPages, ...rest } = action.payload;
      state.children = state.children.map((child) => {
        if (child.id === rest.id) {
          return { child, ...rest };
        }
        return child;
      });
    },
    toggleFocusMode: (state) => {
      state.isFocusMode = !state.isFocusMode;
    },
    toggleFocusModeOff: (state) => {
      state.isFocusMode = true;
    },
    toggleFocusModeOn: (state) => {
      state.isFocusMode = false;
    },
    toggleNav: (state) => {
      state.isMobileNavOpen = !state.isMobileNavOpen;
    },
    toggleNavOpen: (state) => {
      state.isMobileNavOpen = true;
    },
    toggleNavClose: (state) => {
      state.isMobileNavOpen = false;
    },
    setUserPayment: (state, action: PayloadAction<UserPayment | undefined>) => {
      state.userPayment = action.payload;
    },
    setRemainingTrialStories: (state, action: PayloadAction<number>) => {
      state.user!.trialStoriesCount = action.payload;
    },
    setShowGiftcardRedeemedModal: (state, action: PayloadAction<boolean>) => {
      state.showGiftcardRedeemedModal = action.payload;
    },
    resetAppState: (state) => {
      (Object.keys(state) as [keyof AppState]).forEach((key) => {
        (state as any)[key] = initialState[key];
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserPaymentData.pending, (state) => {
        state.loadingPaymentData = true;
      })
      .addCase(fetchUserPaymentData.fulfilled, (state, action) => {
        state.userPayment = action.payload.payment;
        state.user!.trialStoriesCount = action.payload.remainingTrialStories;
        state.loadingPaymentData = false;
      })
      .addCase(decrementTrialStoriesCount.fulfilled, (state, action) => {
        state.user!.trialStoriesCount = action.payload;
      })
      .addCase(redeemGiftcardsThunk.fulfilled, (state, action) => {
        // Ensure the user exists and trialStoriesCount is a number
        if (state.user && typeof state.user.trialStoriesCount === 'number') {
          const { redeemedGiftcards } = action.payload;

          // Calculate the total credits, it will be 0 if the array is empty
          const redeemedGiftcardCredits = redeemedGiftcards.reduce((acc, curr) => acc + curr.storyCredits, 0);

          // Update the trialStoriesCount with the new credits
          state.user.trialStoriesCount += redeemedGiftcardCredits;

          // Update the giftcardsRedeemed with the new giftcards
          state.giftcardsRedeemed = redeemedGiftcards;

          // Decide to show the modal based on whether there are new giftcards
          state.showGiftcardRedeemedModal = redeemedGiftcards.length > 0;
        }
      });
  },
});

export const {
  setUser,
  addNewChild,
  setChildren,
  removeChild,
  updateChild,
  resetUser,
  toggleFocusMode,
  toggleFocusModeOff,
  toggleFocusModeOn,
  toggleNav,
  toggleNavOpen,
  toggleNavClose,
  setRemainingTrialStories,
  setUserPayment,
  resetAppState,
  setLoading,
  setShowGiftcardRedeemedModal,
} = appSlice.actions;

export {
  decrementTrialStoriesCount,
  fetchUserPaymentData,
  redeemGiftcardsThunk,
};

export default appSlice.reducer;
