import { ReactNode } from 'react';
import IconProps from './IconProps.interface';

interface Props extends IconProps {
    svgPath: ReactNode;
    viewBoxWidth?: number;
    viewBoxHeight?: number;
    containerClasses?: string;
}

export default function AnIcon({
  fillColorClass,
  strokeColorClass,
  size,
  strokeWidth = 0,
  svgPath,
  viewBoxWidth,
  viewBoxHeight,
  containerClasses,
}: Props) {
  let sizeClasses = '';

  switch (size) {
    case 'xs':
      sizeClasses += 'h-3 w-3';
      break;
    case 'sm':
      sizeClasses += 'h-4 w-4';
      break;
    case 'md':
      sizeClasses += 'h-5 w-5';
      break;
    case 'lg':
      sizeClasses += 'h-6 w-6';
      break;
    case 'xl':
      sizeClasses += 'h-8 w-8';
      break;
    case '2xl':
      sizeClasses += 'h-10 w-10';
      break;
    case '3xl':
      sizeClasses += 'h-11 w-11';
      break;
    case '4xl':
      sizeClasses += 'h-12 w-12';
      break;
    case '5xl':
      sizeClasses += 'h-14 w-14';
      break;
    case '6xl':
      sizeClasses += 'h-16 w-16';
      break;
    case '7xl':
      sizeClasses += 'h-20 w-20';
      break;
    default:
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={`${sizeClasses} ${strokeColorClass} ${fillColorClass} ${containerClasses}`} fill="none" viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`} stroke="currentColor" strokeWidth={strokeWidth}>
      {svgPath}
    </svg>
  );
}

AnIcon.defaultProps = {
  viewBoxWidth: 24,
  viewBoxHeight: 24,
  containerClasses: '',
};
