/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Cart,
  CartItem,
  CheckoutData,
  CustomBookPurchase,
  LineItem,
  PackageFlowType,
  PackagePurchase,
  PRODUCT_NAMES,
  PurchaseDetails,
  ShippingOption,
  PackageProductName,
  FacebookConversionData,
} from '@features/Checkout/types';
import getPricesEtc from '@features/Checkout/api/getPricesEtc';
import getShippingOptions from '@features/Checkout/api/getShippingOptions';
import getStripeCheckoutSessionNoAuth from '@features/Checkout/api/getStripeCheckoutSessionNoAuth';
import getNoAuthMetadata from '@lib/api/getNoAuthMetadata';
import { imageBaseURL } from '@lib/imageUtils';
import { MarketingSignupProperties, MarketingSignupResponse } from '@lib/marketingTypes';
import submitMarketingSignup from '@lib/api/postMarketingSignupNoAuth';
import { RootState, AppDispatch } from './store';

interface CoverOption extends LineItem {
  imageSrc: string;
}

interface CheckoutState {
  promotionString: string | null;
  isCheckoutInitialized: boolean;
  isCheckoutSessionLoading: boolean;
  checkoutType: string | null;
  cart: Cart;
  selectedShippingOption?: ShippingOption;
  purchaseDetails?: PurchaseDetails;
  packageFlow: PackageFlowType;
  loading: boolean;
  error: Record<string, string | undefined>;
  checkoutData?: CheckoutData;
  availableLineItems: LineItem[];
  shippingOptions: ShippingOption[];
  coverOptions: CoverOption[];
  landingPageActionsLoading: boolean;
  landingPageActionsError: Record<string, string | undefined>;
}

const initialState: CheckoutState = {
  promotionString: null,
  isCheckoutInitialized: false,
  isCheckoutSessionLoading: false,
  checkoutType: null,
  cart: {
    items: [],
    total: 0,
  },
  packageFlow: {
    buyingFor: '',
    whoWillCreate: '',
  },
  loading: false,
  error: {},
  availableLineItems: [],
  shippingOptions: [],
  coverOptions: [],
  landingPageActionsLoading: false,
  landingPageActionsError: {},
};

const packageImageMap: Record<PackageProductName, string> = {
  // [PRODUCT_NAMES.THREE_MONTH_SUBSCRIPTION]: 'https://wanderlyimagesmasterresized.s3.amazonaws.com/character_set/0k70vx/default_488w.png',
  // [PRODUCT_NAMES.YEAR_SUBSCRIPTION]: 'https://wanderlyimagesmasterresized.s3.amazonaws.com/character_set/0k70vx/default_488w.png',
  [PRODUCT_NAMES.CUSTOM_BOOK_PLUS_10_CREDITS]: 'https://static.wixstatic.com/media/af1b7c_6c9207d48b6e4f9699b9b932b5caa0bc~mv2.png/v1/fill/w_1278,h_1278,al_c,usm_0.66_1.00_0.01/af1b7c_6c9207d48b6e4f9699b9b932b5caa0bc~mv2.png',
  [PRODUCT_NAMES.STANDARD_PACKAGE]: `${imageBaseURL}misc/checkout/1-book-package_320w.webp`,
  [PRODUCT_NAMES.YEAR_WITH_BOOKS]: `${imageBaseURL}misc/checkout/4-book-package_320w.webp`,
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setPromotionString: (state, action: PayloadAction<string | null>) => {
      state.promotionString = action.payload;
    },
    addToCart: (state, action: PayloadAction<CartItem>) => {
      const existingItem = state.cart.items.find(
        (item) => item.priceId === action.payload.priceId,
      );

      if (existingItem) {
        existingItem.quantity += action.payload.quantity;
      } else {
        state.cart.items.push(action.payload);
      }

      // Recalculate total
      state.cart.total = state.cart.items.reduce(
        (sum, item) => sum + item.amount * item.quantity,
        0,
      );
    },

    removeFromCart: (state, action: PayloadAction<string>) => {
      state.cart.items = state.cart.items.filter(
        (item) => item.priceId !== action.payload,
      );

      // Recalculate total
      state.cart.total = state.cart.items.reduce(
        (sum, item) => sum + item.amount * item.quantity,
        0,
      );
    },

    updateCartItemQuantity: (
      state,
      action: PayloadAction<{ priceId: string; quantity: number }>,
    ) => {
      const item = state.cart.items.find(
        (i) => i.priceId === action.payload.priceId,
      );
      if (item) {
        item.quantity = action.payload.quantity;
        // Recalculate total
        state.cart.total = state.cart.items.reduce(
          (sum, cartItem) => sum + cartItem.amount * cartItem.quantity,
          0,
        );
      }
    },

    setShippingOption: (state, action: PayloadAction<ShippingOption>) => {
      state.selectedShippingOption = action.payload;
    },

    setCustomBookPurchaseDetails: (state, action: PayloadAction<Partial<CustomBookPurchase>>) => {
      state.purchaseDetails = {
        ...state.purchaseDetails as CustomBookPurchase,
        ...action.payload,
      };
    },

    setPackagePurchaseDetails: (state, action: PayloadAction<Partial<PackagePurchase>>) => {
      const payload = {
        ...action.payload,
        // Only process deliveryDate if it's included in the payload
        ...(action.payload.deliveryDate !== undefined && {
          deliveryDate: action.payload.deliveryDate instanceof Date
            ? action.payload.deliveryDate.toISOString()
            : action.payload.deliveryDate,
        }),
      };

      state.purchaseDetails = {
        ...state.purchaseDetails as PackagePurchase,
        ...payload,
      } as PackagePurchase;
    },

    setPackageFlow: (state, action: PayloadAction<PackageFlowType>) => {
      state.packageFlow = action.payload;
    },

    setCheckoutData: (state, action: PayloadAction<CheckoutData>) => {
      state.checkoutData = action.payload;
    },

    setCheckoutInitialized: (state, action: PayloadAction<boolean>) => {
      state.isCheckoutInitialized = action.payload;
    },

    setIsCheckoutSessionLoading: (state, action: PayloadAction<boolean>) => {
      state.isCheckoutSessionLoading = action.payload;
    },

    resetCheckout: () => initialState,

    resetLandingPageError: (state) => {
      state.landingPageActionsError = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeCheckout.pending, (state) => {
        state.loading = true;
        state.error = {};
      })
      .addCase(initializeCheckout.fulfilled, (state, action) => {
        state.loading = false;
        state.isCheckoutInitialized = true;
        state.availableLineItems = action.payload.lineItems;
        state.shippingOptions = action.payload.shippingOptions;
        state.checkoutType = action.payload.type;

        // Initialize cart if we have an initial item
        if (action.payload.initialCartItem) {
          state.cart.items = [action.payload.initialCartItem];
          state.cart.total = action.payload.initialCartItem.amount * action.payload.initialCartItem.quantity;
        }

        // Set up cover options
        const standardCover: CoverOption = {
          priceId: '',
          productId: '',
          name: PRODUCT_NAMES.STANDARD_COVER,
          description: "Your child's avatar on top of a background illustration.",
          currency: 'usd',
          amount: 0,
          imageSrc: `${imageBaseURL}misc/checkout/standard-cover-art_320w.webp`,
        };

        const premiumCover = action.payload.lineItems.find(
          (item) => item.name === PRODUCT_NAMES.PREMIUM_COVER,
        );

        const premiumCoverArtImageSrc = `${imageBaseURL}misc/checkout/premium-cover-art_320w.webp`;

        state.coverOptions = premiumCover
          ? [standardCover, { ...premiumCover, imageSrc: premiumCoverArtImageSrc }]
          : [standardCover];
      })
      .addCase(initializeCheckout.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          init: action.payload as string,
        };
      })
      .addCase(submitCheckout.pending, (state) => {
        state.isCheckoutSessionLoading = true;
      })
      .addCase(submitCheckout.fulfilled, (state) => {
        state.isCheckoutSessionLoading = false;
      })
      .addCase(submitCheckout.rejected, (state) => {
        state.isCheckoutSessionLoading = false;
      })
      .addCase(submitMarketingSignupThunk.pending, (state) => {
        state.landingPageActionsLoading = true;
        state.landingPageActionsError = {};
      })
      .addCase(submitMarketingSignupThunk.fulfilled, (state) => {
        state.landingPageActionsLoading = false;
        // You could potentially set other state here, like hasSignedUpForMarketing: true
      })
      .addCase(submitMarketingSignupThunk.rejected, (state, action) => {
        state.landingPageActionsLoading = false;
        state.landingPageActionsError = {
          marketingSignup: action.payload as string,
        };
      });
  },
});

// Actions
export const {
  setPromotionString,
  addToCart,
  removeFromCart,
  updateCartItemQuantity,
  setShippingOption,
  setCustomBookPurchaseDetails,
  setPackagePurchaseDetails,
  setPackageFlow,
  setCheckoutData,
  setCheckoutInitialized,
  resetCheckout,
  resetLandingPageError,
} = checkoutSlice.actions;

// Selectors
export const selectPromotionString = (state: RootState) => state.checkout.promotionString;
export const selectCheckoutType = (state: RootState) => state.checkout.checkoutType;
export const selectCart = (state: RootState) => state.checkout.cart;
export const selectCartTotal = (state: RootState) => state.checkout.cart.total;
export const selectCartItems = (state: RootState) => state.checkout.cart.items;
export const selectShippingOption = (state: RootState) => state.checkout.selectedShippingOption;
export const selectPurchaseDetails = (state: RootState) => state.checkout.purchaseDetails;
export const selectPackageFlow = (state: RootState) => state.checkout.packageFlow;
export const selectCheckoutData = (state: RootState) => state.checkout.checkoutData;
export const selectLoading = (state: RootState) => state.checkout.loading;
export const selectIsCheckoutInitialized = (state: RootState) => state.checkout.isCheckoutInitialized;
export const selectAvailableLineItems = (state: RootState) => state.checkout.availableLineItems;
export const selectShippingOptions = (state: RootState) => state.checkout.shippingOptions;
export const selectCoverOptions = (state: RootState) => state.checkout.coverOptions;
export const selectIsCheckoutSessionLoading = (state: RootState) => state.checkout.isCheckoutSessionLoading;
export const selectLandingPageActionsLoading = (state: RootState) => state.checkout.landingPageActionsLoading;
export const selectLandingPageActionsError = (state: RootState) => state.checkout.landingPageActionsError;

export const selectParsedLineItems = (state: RootState) => {
  const { availableLineItems } = state.checkout;

  const customBook = availableLineItems.find((item) => item.name === PRODUCT_NAMES.CUSTOM_BOOK) || null;
  const premiumCover = availableLineItems.find((item) => item.name === PRODUCT_NAMES.PREMIUM_COVER) || null;
  const packageProductNames: PackageProductName[] = [
    // PRODUCT_NAMES.THREE_MONTH_SUBSCRIPTION,
    // PRODUCT_NAMES.YEAR_SUBSCRIPTION,
    PRODUCT_NAMES.CUSTOM_BOOK_PLUS_10_CREDITS,
    PRODUCT_NAMES.STANDARD_PACKAGE,
    PRODUCT_NAMES.YEAR_WITH_BOOKS,
  ];

  const packageOptions = availableLineItems
    .filter((item): item is LineItem => packageProductNames.includes(item.name as PackageProductName))
    .sort((a, b) => a.amount - b.amount)
    .map((item) => ({
      ...item,
      imageSrc: packageImageMap[item.name as PackageProductName],
    }));

  return {
    customBookLineItem: customBook ? {
      ...customBook,
      imageSrc: 'https://wanderlyimagesmasterresized.s3.amazonaws.com/character_set/0k70vx/default_488w.png',
    } : null,
    premiumArtCoverLineItem: premiumCover ? {
      ...premiumCover,
      imageSrc: 'https://wanderlyimagesmasterresized.s3.amazonaws.com/character_set/0k70vx/default_488w.png',
    } : null,
    packageOptions,
  };
};

export const selectPackageOptions = (state: RootState) => selectParsedLineItems(state).packageOptions;

// If you need any async thunks, you can add them here
// Example:
/*
export const submitCheckout = createAsyncThunk(
  'checkout/submit',
  async (_, thunkAPI) => {
    // Implementation
  }
);
*/

export const fetchNoAuthMetadata = createAsyncThunk(
  'checkout/fetchNoAuthMetadata',
  async (_, { dispatch }) => {
    try {
      const metadata = await getNoAuthMetadata();
      if (metadata?.promotionString) {
        dispatch(setPromotionString(metadata.promotionString));
      }
    } catch (error) {
      // Silently fail for promotion string - it's not critical
      console.error('Failed to fetch promotion string:', error);
    }
  },
);

export const initializeCheckout = createAsyncThunk<
  {
    lineItems: LineItem[];
    shippingOptions: ShippingOption[];
    initialCartItem?: CartItem;
    type: string | null;
    initDuration?: number;
  },
  { type: string | null },
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: string;
  }
>(
  'checkout/initializeCheckout',
  async ({ type }, { rejectWithValue }) => {
    try {
      const startTime = performance.now();

      // Fetch checkout-specific APIs in parallel
      const [lineItems, fetchedShippingOptions] = await Promise.all([
        getPricesEtc(),
        getShippingOptions(),
      ]);

      const endTime = performance.now();
      const initDuration = endTime - startTime;

      if (!lineItems) {
        throw new Error('No response from getPricesEtc');
      }

      // Sort shipping options from cheapest to most expensive
      const sortedShippingOptions = fetchedShippingOptions.sort((a: { fixedAmount: { amount: number; }; }, b: { fixedAmount: { amount: number; }; }) => a.fixedAmount.amount - b.fixedAmount.amount);

      // Initialize cart based on type
      let initialCartItem: CartItem | undefined;

      if (type === 'custom-book') {
        const customBookLineItem = lineItems.find(
          (item: LineItem) => item.name === PRODUCT_NAMES.CUSTOM_BOOK,
        );
        if (customBookLineItem) {
          initialCartItem = { ...customBookLineItem, quantity: 1 };
        }
      } else if (type === 'buy-package') {
        const giftCardItem = lineItems.find(
          (item: LineItem) => item.name === PRODUCT_NAMES.STANDARD_PACKAGE,
        );
        if (giftCardItem) {
          initialCartItem = { ...giftCardItem, quantity: 1 };
        }
      }

      return {
        lineItems,
        shippingOptions: sortedShippingOptions,
        initialCartItem,
        type,
        initDuration,
      };
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'Failed to initialize checkout');
    }
  },
);

export const submitCheckout = createAsyncThunk(
  'checkout/submit',
  async ({
    email, cart, shippingOptions, fbConversionData,
  }: {
    email: string;
    cart: Cart;
    shippingOptions: ShippingOption[];
    fbConversionData: FacebookConversionData;
  }, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const checkoutType = selectCheckoutType(state) as 'custom-book' | 'buy-package';

      const sessionUrl = await getStripeCheckoutSessionNoAuth({
        email,
        lineItems: cart.items,
        shippingOptions,
        type: checkoutType,
        purchaseDetails: state.checkout.purchaseDetails,
        fbConversionData,
      });

      if (sessionUrl) {
        window.location.href = sessionUrl;
        return sessionUrl;
      }
      throw new Error('No session URL returned');
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'Failed to create checkout session');
    }
  },
);

export const submitMarketingSignupThunk = createAsyncThunk(
  'checkout/submitMarketingSignup',
  async (signupData: MarketingSignupProperties, { rejectWithValue }) => {
    try {
      const response: MarketingSignupResponse = await submitMarketingSignup(signupData);

      if (response.success) {
        return response;
      }

      // Handle the already subscribed case
      if (response.code === 'ALREADY_SUBSCRIBED') {
        return rejectWithValue(response.message);
      }

      return rejectWithValue(response.message || 'Failed to sign up');
    } catch (error) {
      return rejectWithValue('Something went wrong. Please try again later.');
    }
  },
);

export default checkoutSlice.reducer;
