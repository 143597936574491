import { imageBaseURL } from '@lib/imageUtils';
import AppleStore from '@assets/app_store_badge.png';
import GooglePlay from '@assets/play_store_badge.png';
import trackCheckoutEvent from '@lib/analytics';
import InlineImageGallery from '../InlineImageGallery';

interface DownloadAppPromptProps {
  isDesktop: boolean;
}

export default function DownloadAppPrompt({ isDesktop }: DownloadAppPromptProps) {
  const printStoryDetailImageURL = `${imageBaseURL}misc/checkout/write-it-myself_${isDesktop ? '640' : '320'}w.webp`;

  const images = [
    {
      src: printStoryDetailImageURL,
      thumbnail: printStoryDetailImageURL,
    },
  ];

  const handleAppStoreClick = () => {
    trackCheckoutEvent('app_download_click', {
      path: 'gift_with_self_writing',
      action: 'download_app',
      platform: 'ios',
    });
  };

  const handlePlayStoreClick = () => {
    trackCheckoutEvent('app_download_click', {
      path: 'gift_with_self_writing',
      action: 'download_app',
      platform: 'android',
    });
  };

  return (
    <div className="space-y-8 max-w-sm my-4">
      {/* Step 1 */}
      <div>
        <div className="flex items-center gap-2 mb-4">
          <p className="text-sm text-gray-600">Download Wanderly on iOS or Android. Then customize a profile for the gift recipient, and request a print at the end of the story or from the Library.</p>
        </div>
        <div className="flex flex-col sm:flex-row gap-4">
          <a
            href="https://apps.apple.com/us/app/wanderly-stories/id6475022201"
            className="w-40"
            onClick={handleAppStoreClick}
          >
            <img src={AppleStore} alt="Download on the App Store" className="w-full h-full object-contain" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.app.wanderly"
            className="w-40"
            onClick={handlePlayStoreClick}
          >
            <img src={GooglePlay} alt="Get it on Google Play" className="w-full h-full object-contain" />
          </a>
        </div>
        <div className="mt-8">
          {/* {!isDesktop && <InlineImageGallery images={images} />} */}
          <InlineImageGallery images={images} />
        </div>
      </div>
    </div>
  );
}
