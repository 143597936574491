import { pronounMap, possessivePronounMap, commonNounMap } from '@lib/api/stringPersonalizationUtils';
import { selectActiveChild } from '@store/appSlice';
import store from '@store/store';
import { Child } from 'src/graphql/API';

function getPronoun(commonNoun: string, capitalized: boolean): string {
  const pronoun = pronounMap[commonNoun];
  return capitalized ? pronoun.charAt(0).toUpperCase() + pronoun.slice(1) : pronoun;
}

function getPossessivePronoun(commonNoun: string, capitalized: boolean): string {
  const pronoun = possessivePronounMap[commonNoun];
  return capitalized ? pronoun.charAt(0).toUpperCase() + pronoun.slice(1) : pronoun;
}

function getCommonNoun(commonNoun: string): string {
  const commonNounString = commonNounMap[commonNoun];
  return commonNounString;
}

function parseDailyStory(rawDailyStory: string) {
  const appState = store.getState();
  const child = selectActiveChild(appState) as Child;

  return rawDailyStory
    .replaceAll('[[NAME]]', child.name)
    .replaceAll('[[COMMON_NOUN]]', getCommonNoun(child.commonNoun))
    .replaceAll('[[PRONOUN]]', getPronoun(child.commonNoun, false))
    .replaceAll('[[POSSESSIVE]]', getPossessivePronoun(child.commonNoun, false))
    .replaceAll('[[PRONOUN_CAP]]', getPronoun(child.commonNoun, true))
    .replaceAll('[[POSSESSIVE_CAP]]', getPossessivePronoun(child.commonNoun, true))
    .replaceAll('[[AGE]]', `${child.age}`);
}

export default parseDailyStory;
