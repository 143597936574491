import { GraphQLResult, API } from '@aws-amplify/api';
import add from 'date-fns/add';
import isAfter from 'date-fns/isAfter';
import {
  GetPageQuery, Page,
} from 'src/graphql/API';
import { getPage } from 'src/graphql/queries';
import { ANANSI_REST_API_NAME, MAX_RESPONSE_TIME_SECONDS, POLL_INTERVAL_MS } from '@lib/constants';
import ERROR from '@lib/error.enum';

async function getJobStatus(jobID: string) {
  const apiName = ANANSI_REST_API_NAME;
  const path = '/jobStatus';

  const myInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      jobID,
    },
  };

  return API.get(apiName, path, myInit)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error.response);
    });
}

function pollJobStatusForPageID(
  jobID: string,
  interval: number,
  maxEndTime = add(new Date(), { seconds: MAX_RESPONSE_TIME_SECONDS }),
): Promise<any> {
  return new Promise((resolve, reject) => {
    getJobStatus(jobID).then((data) => {
      const jobStatusData = JSON.parse(data.message);
      const { jobResult } = jobStatusData;

      if (jobResult && jobResult.pageIDs?.length > 0) {
        resolve(jobResult);
      } else {
        if (isAfter(new Date(), maxEndTime)) {
          reject(new Error(ERROR.TIME_OUT));
          return;
        }
        setTimeout(() => {
          pollJobStatusForPageID(jobID, interval, maxEndTime).then(resolve).catch(reject);
        }, interval);
      }
    }).catch((error) => {
      reject(error);
    });
  });
}

async function getPageByIDfromDB(pageID: string) {
  const pageIDParams = {
    id: pageID,
  };

  const getPageByIDResponse = await API.graphql({
    query: getPage,
    variables: pageIDParams,
  }) as GraphQLResult<GetPageQuery>;

  return getPageByIDResponse.data?.getPage;
}

async function getNextPage(chosenOptionIndex: number, nextJobId: string): Promise<Page> {
  // assumes that nextJobId will be there...
  if (!nextJobId) {
    throw new Error('next job id not found!');
  }
  const res = await pollJobStatusForPageID(nextJobId, POLL_INTERVAL_MS); // @LAURA Do we need to poll anymore? Or does it always return the page id on first response
  const nextPageId = res.pageIDs[chosenOptionIndex];
  const nextPage = await getPageByIDfromDB(nextPageId);
  return nextPage as Page;
}

export { getPageByIDfromDB };

export default getNextPage;
