import { API, graphqlOperation } from 'aws-amplify';
import storyLocationMap from 'src/app/data/story-locations.json';
import objectImageMap from 'src/app/data/object-image-map.json';
import {
  Page,
  SharedPage,
  ImageType,
} from 'src/graphql/API';
import type { Image } from 'src/graphql/API';
import {
  imagesByCharacterSetAndName,
  listImageCategories,
  listImages,
  imageByName,
  getImage,
} from 'src/graphql/queries';
import { StoryLocation, StoryLocationsData } from './types/StoryLocation';

export const imageBaseURL = process.env.REACT_APP_ASSETS_BASE_URL;
export const expositionImageBaseURL = `${imageBaseURL}exposition/`;
export const magicBookImageBaseURL = `${imageBaseURL}magic-book/`;
export const backgroundBaseURL = `${imageBaseURL}background/`;
export const characterSetBaseURL = `${imageBaseURL}character_set/`;
export const loadingImageBaseURL = `${imageBaseURL}loading/`;
export const websterImageBaseURL = `${imageBaseURL}webster/`;
export const adminImageBaseURL = 'https://wanderlyimagesmasterresized.s3.amazonaws.com/';

export function getExpositionImagePrefix(
  locationFromDB?: string,
  keyType?: keyof StoryLocationsData,
): string {
  if (locationFromDB && keyType && storyLocationMap[keyType]) {
    const locations = storyLocationMap[keyType] as Record<string, StoryLocation>;
    const locationKey = Object.keys(locations).find((locKey) => locations[locKey].location === locationFromDB);
    return locationKey || '';
  }
  return '';
}

export function getObjectImagePrefix(keyword: string) {
  const count = (objectImageMap as any)[keyword];
  return `${keyword}_${Math.floor(Math.random() * count)}`;
}

export function selectObjectImagev2(keyword: string) {
  const objectImages = Object.keys(objectImageMap);

  if (objectImages.includes(keyword)) {
    return keyword;
  }

  const splitKeyword = keyword.split(' ');

  for (let i = 0; i < splitKeyword.length; i += 1) {
    if (objectImages.includes(splitKeyword[i].toLowerCase())) {
      return splitKeyword[i];
    }
  }

  return '';
}

export function getPageImage(pageData: Page | SharedPage): string {
  // const storyLocationFromDB = pageData.location || undefined;

  if (pageData.imageName) {
    return pageData.imageName;
  }

  // const isExposition = !pageData.preceededBy;
  // if (isExposition) {
  //   return getExpositionImagePrefix(storyLocationFromDB);
  // } - as of 4/24, no longer displaying exposition images in story

  return '';
}

export function getImageBaseUrl(isExposition: boolean) {
  if (isExposition) return expositionImageBaseURL;
  return backgroundBaseURL;
}

export async function verifyCharacterSetImageExists(characterSet: string, imageName: string): Promise<boolean> {
  const characterSetName = `${characterSet}_${imageName}`; // Format to match the composite key format

  try {
    const query = graphqlOperation(imagesByCharacterSetAndName, {
      characterSetName,
    });

    const data: any = await API.graphql(query);

    return data.data.imagesByCharacterSetAndName.items.length > 0;
  } catch (error) {
    console.error('Error querying Image:', error);
    return false;
  }
}

export async function verifyBackgroundImageExists(imageName: string): Promise<boolean> {
  try {
    // Step 1: Get the category ID for 'background'
    const categoryQuery = graphqlOperation(listImageCategories, {
      filter: { category: { eq: 'background' } },
    });
    const categoryData: any = await API.graphql(categoryQuery);
    const backgroundCategoryId = categoryData.data.listImageCategories.items[0]?.id;

    if (!backgroundCategoryId) {
      console.error('Background category not found');
      return false;
    }

    // Step 2: Query images with the background category and specific name
    const imageQuery = graphqlOperation(listImages, {
      filter: {
        and: [
          { categories: { contains: backgroundCategoryId } },
          { name: { eq: imageName } },
        ],
      },
    });
    const imageData: any = await API.graphql(imageQuery);

    // Return true if any matching images are found
    return imageData.data.listImages.items.length > 0;
  } catch (error) {
    console.error('Error verifying background image:', error);
    return false;
  }
}

export async function verifyImageExists(imageName: string): Promise<boolean> {
  try {
    const imageQuery = graphqlOperation(imageByName, {
      name: imageName,
    });
    const imageData: any = await API.graphql(imageQuery);

    return imageData.data.imageByName.items.length > 0;
  } catch (error) {
    console.error('Error verifying image:', error);
    return false;
  }
}

export async function getImageById(imageId: string): Promise<Image | null> {
  try {
    const imageQuery = graphqlOperation(getImage, {
      id: imageId,
    });
    const imageData: any = await API.graphql(imageQuery);
    return imageData.data.getImage;
  } catch (error) {
    console.error('Error querying Image:', error);
    return null;
  }
}

// Update this function to check specific image dimensions and type
export const validateImage = (file: File, imageType: ImageType): Promise<boolean> => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const img = new Image();
    img.onload = () => {
      console.log('Image loaded, width:', img.width, 'height:', img.height);
      let isValidDimensions = false;
      if (imageType === ImageType.BACKGROUND) {
        isValidDimensions = img.width === 1344 && img.height === 896;
      } else {
        isValidDimensions = img.width === 1024 && img.height === 1024;
      }
      const isValidType = file.type === 'image/png';
      resolve(isValidDimensions && isValidType);
    };
    img.src = e.target?.result as string;
  };
  reader.readAsDataURL(file);
});
