import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import isEmail from 'validator/lib/isEmail';
import AnTextField from '@components/AnTextField';
import AnButton from '@components/AnButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLandingPageActionsError,
  selectLandingPageActionsLoading,
  submitMarketingSignupThunk,
  resetLandingPageError,
} from '@store/checkoutSlice';
import type { AppDispatch } from '@store/store';
import { MarketingSignupProperties } from '@lib/marketingTypes';
import yayWebster from '@assets/webster-yay-transparent_640w.png';
import Cookies from 'js-cookie';
import { trackStaticPageEvent } from '@lib/analytics';

const PROMO_SUBMISSION_BLOCK_DAYS = 365;

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .test('is-valid-email', 'Please enter a valid email address', (value) => isEmail(value || ''))
    .required('Please enter your email'),
});

interface Props {
  onClose?: () => void;
  className?: string;
}

export default function DiscountPromoContent({ onClose, className = '' }: Props) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isAlreadySubscribed, setIsAlreadySubscribed] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(selectLandingPageActionsLoading);
  const error = useSelector(selectLandingPageActionsError);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const onFormSubmit = handleSubmit(async (data) => {
    const signupData: MarketingSignupProperties = {
      email: data.email,
      source: 'landing_page',
      customProperties: {
        formLocation: 'discount_promo',
      },
    };

    // Clear any existing errors before submitting
    dispatch(resetLandingPageError());

    const resultAction = await dispatch(submitMarketingSignupThunk(signupData));

    if (submitMarketingSignupThunk.fulfilled.match(resultAction)) {
      setIsSuccess(true);
      // track successful new subscriber
      trackStaticPageEvent('email_signup_success', {
        path: 'landing_page',
        form_location: 'discount_promo',
        signup_status: 'new_subscriber',
      });

      // Set 'hasSubmittedPromo' cookie to prevent showing the promo for a year
      Cookies.set('hasSubmittedPromo', 'true', {
        expires: PROMO_SUBMISSION_BLOCK_DAYS,
      });
    } else if (resultAction.payload === "You're already signed up! Check your email for the discount code.") {
      setIsAlreadySubscribed(true);
      // Clear the error state since this is actually a success case
      dispatch(resetLandingPageError());

      // track successful existing subscriber
      trackStaticPageEvent('email_signup_success', {
        path: 'landing_page',
        form_location: 'discount_promo',
        signup_status: 'existing_subscriber',
      });

      // Optionally set 'hasSubmittedPromo' if already subscribed should also block promo
      Cookies.set('hasSubmittedPromo', 'true', {
        expires: PROMO_SUBMISSION_BLOCK_DAYS,
      });
    }
  });

  if (error.marketingSignup && !isAlreadySubscribed) {
    return (
      <div className={`text-center space-y-6 ${className}`}>
        <h3 className="text-2xl font-bold text-red-600">
          Uh-oh!
        </h3>
        <p className="text-gray-600">
          {error.marketingSignup}
        </p>
        <div className="space-y-4">
          <AnButton
            text="Try Again"
            variant="contained"
            color="primary"
            size="lg"
            block
            onClick={() => {
              dispatch(resetLandingPageError());
              setIsAlreadySubscribed(false);
            }}
          />
          {onClose && (
            <AnButton
              text="Cancel"
              variant="text"
              color="text-dark"
              block
              onClick={onClose}
            />
          )}
        </div>
      </div>
    );
  }

  if (isSuccess || isAlreadySubscribed) {
    return (
      <div className={`text-center space-y-6 ${className}`}>
        <div className="relative mb-2">
          <img
            src={yayWebster}
            alt=""
            className="w-36 h-36 mx-auto"
          />
        </div>
        <h3 className="text-2xl font-bold text-gray-800">
          {isAlreadySubscribed ? 'Welcome Back!' : 'Thank you!'}
        </h3>
        <div className="space-y-2">
          <p className="text-gray-600">
            {isAlreadySubscribed
              ? "You're already part of our community!"
              : "Here's your 10% off promo code:"}
          </p>
          <p className="text-3xl font-bold text-primary">NEWADVENTURE10</p>
        </div>
        <p className="text-sm text-gray-500">
          {isAlreadySubscribed
            ? "If you haven't yet, you can use this code for your first purchase."
            : 'This code has been sent to your email, too.'}
        </p>
        {onClose && (
          <AnButton
            text="Close"
            variant="contained"
            color="primary"
            size="lg"
            block
            onClick={onClose}
          />
        )}
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="text-center mb-6">
        <h3 className="text-2xl font-bold mb-2">
          Get 10% Off Your First Purchase
        </h3>
        <p className="text-gray-600">
          Sign up for special offers and updates
        </p>
      </div>

      <form onSubmit={onFormSubmit}>
        <AnTextField
          name="email"
          placeholder="Enter your email"
          control={control}
          error={!!errors.email}
          errorMessage={errors.email?.message}
          textFieldStyle="fully-enclosed"
          autoComplete="email"
        />

        <AnButton
          text="Unlock Offer"
          variant="contained"
          color="primary"
          size="lg"
          block
          type="submit"
          loading={loading}
        />

        <p className="text-xs text-gray-500 mt-2 mb-6 text-center">
          By signing up, you agree to receive email marketing
        </p>
      </form>

      {onClose && (
        <AnButton
          className="mt-4"
          text="No thanks, I'll pay full price"
          variant="text"
          color="text-dark"
          block
          onClick={onClose}
        />
      )}
    </div>
  );
}
