import {
  useCallback, useEffect, useMemo, useState, lazy, Suspense,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import yellowOrganicClosedLine from '@assets/yellow_organic_closed_line.svg';
import yellowOrganicBlob from '@assets/yellow_organic_blob.svg';
import yayWebster from '@assets/webster-yay-transparent_640w.png';
import logo512 from '@assets/logo512.png';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import {
  setCustomBookPurchaseDetails,
  setPackagePurchaseDetails,
  setPackageFlow,
  resetCheckout,
  selectCart,
  selectPackageFlow,
  selectIsCheckoutInitialized,
  initializeCheckout,
  selectShippingOptions,
  submitCheckout,
  selectPurchaseDetails,
  setCheckoutInitialized,
} from '@store/checkoutSlice';
import { Loader } from '@aws-amplify/ui-react';
import colors from 'src/colors';
import { imageBaseURL } from '@lib/imageUtils';
import trackCheckoutEvent, { CheckoutPath } from '@lib/analytics';
import GALLERY_BOOK_IMAGES from '@features/LandingPage/data/book-carousel';
import { SeoMeta, PAGE_META } from '@components/SEO';
import CheckoutHeader from './CheckoutHeader';
import {
  CheckoutStep as CheckoutStepType, FacebookConversionData, isCustomBookPurchase, PackageFlowType, PackagePurchase, PRODUCT_NAMES,
} from './types';
import CheckoutWizard from './CheckoutWizard';
import CheckoutQuickSummary from './CheckoutQuickSummary';
import FinalizeCover from './OrderBookSteps/FinalizeCover';
import PersonalizeInside from './OrderBookSteps/PersonalizeInside';
import OrderSummary from './OrderSummary';
import SelectPackage from './BuyPackage/SelectPackage';
import CustomizeGift from './BuyPackage/CustomizeGift';
import DownloadAppPrompt from './BuyPackage/DownloadAppPrompt';
import WhoWillCreate from './BuyPackage/WhoWillCreate';
import WhoAreYouBuyingFor from './BuyPackage/WhoAreYouBuyingFor';
import GiftDeliveryInfo from './BuyPackage/GiftDeliveryInfo';
import useMediaQuery from './hooks/useMediaQuery';
// Helper functions to get Facebook cookies
function getFacebookPixelCookie() {
  const cookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith('_fbp='))
    ?.split('=')[1];
  return cookie;
}

function getFacebookClickCookie() {
  const cookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith('_fbc='))
    ?.split('=')[1];
  return cookie;
}

const CUSTOM_BOOK_PARAMS = {
  email: {
    entry: 'entry.833608329',
    direct: 'customer_email',
  },
  bookTitle: {
    entry: 'entry.492077904',
    direct: 'book_title',
  },
  byLineName: {
    entry: 'entry.905712201',
    direct: 'byline_name',
  },
  namePlateName: {
    entry: 'entry.1620812457',
    direct: 'nameplate_name',
  },
  message: {
    entry: 'entry.575637385',
    direct: 'dedication_message',
  },
};

type CheckoutLayout = 'single-pane' | 'two-pane';

function getCheckoutPath(type: string, giftCardFlow: PackageFlowType): CheckoutPath {
  if (type === 'custom-book') return 'book_order';
  if (giftCardFlow.buyingFor === 'me') return 'self_purchase';
  if (giftCardFlow.whoWillCreate === 'me') return 'gift_with_self_writing';
  return 'gift_package';
}

// Add lazy loaded ImageCarousel
const ImageCarousel = lazy(() => import('@components/AnImageCarousel'));

export default function Checkout() {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type') || 'buy-package';
  const isSuccess = searchParams.get('success') === 'true';

  const [scrollToStep, setScrollToStep] = useState<string | null>(null);

  const dispatch = useDispatch<AppDispatch>();

  // checkoutSlice selectors
  const isCheckoutInitialized = useSelector(selectIsCheckoutInitialized);
  const cart = useSelector(selectCart);
  const giftCardFlow = useSelector(selectPackageFlow);
  const shippingOptions = useSelector(selectShippingOptions);
  const purchaseDetails = useSelector(selectPurchaseDetails);

  const isWideScreen = useMediaQuery('(min-width: 910px)');
  const [layout, setLayout] = useState<CheckoutLayout>('single-pane');

  useEffect(() => {
    setLayout(isWideScreen ? 'two-pane' : 'single-pane');
  }, [isWideScreen]);

  /*
  // INIT
  */

  const customBookProps = useMemo(() => Object.entries(CUSTOM_BOOK_PARAMS).reduce((acc, [key, paramNames]) => {
    // Try entry format first, then direct format
    const value = searchParams.get(paramNames.entry) || searchParams.get(paramNames.direct) || '';
    acc[key] = value;
    return acc;
  }, {} as Record<string, string>), [searchParams]);

  const {
    email,
    byLineName,
    namePlateName,
    message,
    bookTitle: rawBookTitle,
  } = customBookProps;

  const { bookTitle, storyId } = useMemo(() => {
    if (!rawBookTitle) {
      return { bookTitle: '', storyId: '' };
    }

    const [title, id] = rawBookTitle.split(' - ');
    return {
      bookTitle: title || '',
      storyId: id || '',
    };
  }, [rawBookTitle]);

  useEffect(() => {
    async function init() {
      // Initialize purchaseDetails with URL params
      if (type === 'custom-book') {
        dispatch(setCustomBookPurchaseDetails({
          title: bookTitle,
          byline: byLineName,
          bookplateName: namePlateName,
          dedication: message,
          bookplateStyle: PRODUCT_NAMES.MODERN_BOOKPLATE,
          storyId,
        }));
      } else if (type === 'buy-package') {
        dispatch(setPackagePurchaseDetails({
          recipientFirstName: '',
          recipientLastName: '',
          recipientEmail: '',
          giftMessage: '',
          deliveryDate: new Date(),
          giftCardStyle: '',
          gifterName: '',
          gifterEmail: '',
        }));
      }
      if (!isCheckoutInitialized && !isSuccess) {
        dispatch(initializeCheckout({ type }));
      } else if (isSuccess) {
        dispatch(setCheckoutInitialized(true));

        const params = new URLSearchParams(window.location.search);
        const orderType = params.get('type');
        // const orderId = params.get('order_id');
        const itemCount = params.get('items');

        if (isSuccess && orderType) {
          // Track purchase conversion
          trackCheckoutEvent('purchase_complete', {
            type: orderType as 'buy-package' | 'custom-book',
            item_count: parseInt(itemCount || '0', 10),
            // Safe to include total here as it's only in analytics, not URL
            // TODO: pass through total
          });
        }
      }

      // Track checkout initialized
    }
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetPreQuestions = useCallback(() => {
    trackCheckoutEvent('checkout_package_reset', {
    });
    dispatch(setPackageFlow({
      buyingFor: '',
      whoWillCreate: '',
    }));
    dispatch(resetCheckout());
    dispatch(initializeCheckout({ type }));
    // no need to check for type since this only gets called on package purchase
    dispatch(setPackagePurchaseDetails({
      recipientFirstName: '',
      recipientLastName: '',
      recipientEmail: '',
      giftMessage: '',
      deliveryDate: new Date(),
    }));
  }, [dispatch, type]);

  const handleCheckoutSubmit = useCallback(() => {
    trackCheckoutEvent('checkout_complete', {
      path: getCheckoutPath(type, giftCardFlow),
      cart_value: cart.total,
      items: cart.items.map((item) => item.name),
    });

    let prefilledEmail;
    if (purchaseDetails && isCustomBookPurchase(purchaseDetails)) {
      prefilledEmail = email;
    } else if (purchaseDetails && !isCustomBookPurchase(purchaseDetails)) {
      prefilledEmail = (purchaseDetails as PackagePurchase).gifterEmail;
    }

    const fbData = {
      fbc: getFacebookClickCookie(),
      fbp: getFacebookPixelCookie(),
    };

    dispatch(submitCheckout({
      email: prefilledEmail || '',
      cart,
      shippingOptions,
      fbConversionData: fbData as FacebookConversionData,
    }));
  }, [type, giftCardFlow, cart, purchaseDetails, dispatch, shippingOptions, email]);

  const [isCheckoutFormValid, setIsCheckoutFormValid] = useState(false);

  /*
//   ///////////////////////
//   All the steps
//   ///////////////////////
//   */

  const [isFinalizeCoverFormValid, setIsFinalizeCoverFormValid] = useState(false);

  const memoizedFinalizeCoverStep: CheckoutStepType = useMemo(() => ({
    title: 'Finalize Cover',
    children: (
      <FinalizeCover
        {...customBookProps}
        bookTitle={bookTitle}
        byLineName={byLineName}
        onIsFormValid={setIsFinalizeCoverFormValid}
      />
    ),
    isNextDisabled: !isFinalizeCoverFormValid,
    onNext: () => {},
    isLastStep: false,
  }), [customBookProps, bookTitle, byLineName, isFinalizeCoverFormValid]);

  const [isPersonalizeInsideFormValid, setIsPersonalizeInsideFormValid] = useState(false);

  const memoizedPersonalizeInsideStep: CheckoutStepType = useMemo(() => ({
    title: 'Personalize Inside',
    children: (
      <PersonalizeInside
        bookplateName={namePlateName}
        dedicationMessage={message}
        onIsFormValid={setIsPersonalizeInsideFormValid}
      />
    ),
    onNext: () => {},
    isNextDisabled: !isPersonalizeInsideFormValid,
    isLastStep: false,
  }), [namePlateName, message, isPersonalizeInsideFormValid]);

  const memoizedWhoAreBuyingForStep: CheckoutStepType = useMemo(() => ({
    title: 'Who are you buying for?',
    children: (
      <WhoAreYouBuyingFor
        onChange={(value: PackageFlowType) => {
          dispatch(setPackageFlow(value));
        }}
        onStepComplete={() => {}}
      />
    ),
    onNext: () => {},
    isLastStep: false,
  }), [dispatch]);

  const memoizedWhoWillCreateStep: CheckoutStepType = useMemo(() => ({
    title: 'Who will create the personalized stories?',
    children: (
      <WhoWillCreate
        onChange={(value: PackageFlowType) => {
          dispatch(setPackageFlow(value));
        }}
        onStepComplete={() => {}}
      />
    ),
    onNext: () => {},
    isLastStep: false,
  }), [dispatch]);

  const memoizedDownloadAppStep: CheckoutStepType = useMemo(() => ({
    title: 'Create a book',
    children: (
      <DownloadAppPrompt isDesktop={isWideScreen} />
    ),
    onNext: () => {},
    isLastStep: true,
  }), [isWideScreen]);

  const [isRecipientAndGifterInfoFormValid, setIsRecipientAndGifterInfoFormValid] = useState(false);

  const memoizedRecipientAndGifterInfoStep: CheckoutStepType = useMemo(() => ({
    title: 'Gift Delivery Details',
    children: (
      <GiftDeliveryInfo
        onIsFormValid={setIsRecipientAndGifterInfoFormValid}
      />
    ),
    onNext: () => {},
    isNextDisabled: !isRecipientAndGifterInfoFormValid,
    isLastStep: false,
  }), [isRecipientAndGifterInfoFormValid]);

  const [isCustomizeGiftFormValid, setIsCustomizeGiftFormValid] = useState(false);

  const memoizedCustomizeGiftStep: CheckoutStepType = useMemo(() => ({
    title: 'Customize Gift Message',
    children: (
      <CustomizeGift
        onIsFormValid={setIsCustomizeGiftFormValid}
      />
    ),
    onNext: () => {},
    isNextDisabled: !isCustomizeGiftFormValid,
    isLastStep: false,
  }), [isCustomizeGiftFormValid]);

  const memoizedSelectPackageStep: CheckoutStepType = useMemo(() => ({
    title: 'Select Package',
    children: (
      <SelectPackage />
    ),
    onNext: () => {},
    isLastStep: false,
  }), []);

  const memoizedOrderSummaryStep: CheckoutStepType = useMemo(() => ({
    title: 'Order Summary',
    children: (
      <OrderSummary
        orderSummaryType={type as 'buy-package' | 'custom-book'}
        isGoToCheckoutDisabled={!isCheckoutFormValid}
        onConfirm={handleCheckoutSubmit}
        setScrollToStep={setScrollToStep}
        giftCardFlow={giftCardFlow}
      />
    ),
    onNext: () => {},
    isLastStep: true,
  }), [type, handleCheckoutSubmit, isCheckoutFormValid, giftCardFlow]);

  // for making sure checkout form is valid
  useEffect(() => {
    if (type === 'custom-book') {
      setIsCheckoutFormValid(isFinalizeCoverFormValid && isPersonalizeInsideFormValid);
    } else if (type === 'buy-package') {
      if (giftCardFlow.buyingFor === 'someoneElse') {
        setIsCheckoutFormValid(isRecipientAndGifterInfoFormValid && isCustomizeGiftFormValid);
      } else {
        setIsCheckoutFormValid(true);
      }
    }
  }, [isFinalizeCoverFormValid, isPersonalizeInsideFormValid, isRecipientAndGifterInfoFormValid, isCustomizeGiftFormValid, type, giftCardFlow.buyingFor]);

  const steps: CheckoutStepType[] = useMemo(() => {
    if (type === 'custom-book') {
      return [memoizedFinalizeCoverStep, memoizedPersonalizeInsideStep, memoizedOrderSummaryStep];
    }

    if (type === 'buy-package') {
      if (!giftCardFlow.buyingFor) {
        return [memoizedWhoAreBuyingForStep];
      }

      if (giftCardFlow.buyingFor === 'me') {
        return [memoizedSelectPackageStep, memoizedOrderSummaryStep];
      }

      if (giftCardFlow.buyingFor === 'someoneElse') {
        if (!giftCardFlow.whoWillCreate) {
          return [memoizedWhoWillCreateStep];
        }

        if (giftCardFlow.whoWillCreate === 'me') {
          return [memoizedDownloadAppStep];
        }

        // if (giftCardFlow.whoWillCreate === 'someoneElse') {
        return [
          memoizedRecipientAndGifterInfoStep,
          memoizedCustomizeGiftStep,
          memoizedSelectPackageStep,
          memoizedOrderSummaryStep,
        ];
      }
    }

    return []; // Default case, should never happen
  }, [type, giftCardFlow.buyingFor, giftCardFlow.whoWillCreate, memoizedFinalizeCoverStep, memoizedPersonalizeInsideStep, memoizedOrderSummaryStep, memoizedWhoAreBuyingForStep, memoizedSelectPackageStep, memoizedRecipientAndGifterInfoStep, memoizedCustomizeGiftStep, memoizedWhoWillCreateStep, memoizedDownloadAppStep]);

  // Track path changes
  useEffect(() => {
    if (type === 'custom-book') {
      trackCheckoutEvent('checkout_path_selected', {
        path: 'book_order',
      });
    } else if (giftCardFlow.buyingFor === 'me') {
      trackCheckoutEvent('checkout_path_selected', {
        path: 'self_purchase',
      });
    } else if (giftCardFlow.buyingFor === 'someoneElse' && giftCardFlow.whoWillCreate === 'me') {
      trackCheckoutEvent('checkout_path_selected', {
        path: 'gift_with_self_writing',
      });
    } else if (giftCardFlow.buyingFor === 'someoneElse' && giftCardFlow.whoWillCreate === 'someoneElse') {
      trackCheckoutEvent('checkout_path_selected', {
        path: 'gift_package',
      });
    }
  }, [type, giftCardFlow.buyingFor, giftCardFlow.whoWillCreate]);

  return (
    <>
      <SeoMeta
        {...PAGE_META.checkout}
        canonicalUrl="/checkout"
      />
      <div className="bg-white flex flex-col h-screen relative overflow-hidden">
        {/* Background container */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          {/* Yellow Organic Blob */}
          <img
            src={yellowOrganicBlob}
            alt=""
            className="absolute top-0 left-2/3 w-3/4 h-auto transform -translate-x-1/2 -translate-y-1/4"
          />

          {/* Background SVGs */}
          <img
            src={yellowOrganicClosedLine}
            alt=""
            className="absolute top-0 left-0 w-1/4 h-auto opacity-50 transform -translate-x-1/4 -translate-y-1/4"
          />
          <img
            src={yellowOrganicClosedLine}
            alt=""
            className="absolute bottom-0 right-0 w-1/4 h-auto opacity-50 transform translate-x-1/4 translate-y-1/4 rotate-180"
          />
          {/* New Color Splash Image */}
          <img
            src={`${imageBaseURL}misc/checkout/wanderly-color-splash-1_1024w.webp`}
            alt=""
            className="absolute left-0 top-1/2 h-1/2 w-auto opacity-40 transform -translate-x-1/4 -translate-y-1/2 max-w-full"
          />
        </div>

        <CheckoutHeader showLogo="" />
        <main className="flex-1 p-6 overflow-hidden z-10">
          <div className="h-full max-w-7xl mx-auto">
            <div className="flex flex-row gap-6 h-full">
              {isSuccess ? (
                <div className="flex-1 bg-white p-6 rounded-lg shadow flex items-center justify-center">
                  <div className="text-center md:text-left md:flex md:items-center md:gap-12 max-w-4xl w-full">
                    {/* Image container - added min-width to prevent squishing */}
                    <div className="order-1 md:order-1 relative mb-8 md:mb-0 md:min-w-[320px] flex justify-center md:justify-start">
                      <div className="relative">
                        <img
                          src={logo512}
                          alt="Wanderly Logo"
                          className="w-48 h-48 md:w-80 md:h-80 rounded-lg"
                        />
                        <img
                          src={yayWebster}
                          alt=""
                          className="absolute bottom-0 left-0 w-32 h-32 md:w-48 md:h-48 transform translate-y-1/4 -translate-x-1/4"
                        />
                      </div>
                    </div>

                    {/* Text content - below images for mobile, right side for desktop */}
                    <div className="order-2 md:order-2">
                      <h1 className="text-3xl mb-4">Thank you!</h1>
                      <p className="mb-6">
                        Your receipt will be sent to your email address in just a moment.
                      </p>
                      <button
                        onClick={() => {
                          window.location.href = 'https://wander.ly';
                        }}
                        className="bg-accent1 text-white px-6 py-2 rounded-lg hover:bg-accent1-dark"
                      >
                        Return Home
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {layout === 'two-pane' && (
                    <div className="flex-1 bg-white p-6 rounded-lg shadow">
                      <Suspense fallback={(
                        <div className="flex items-center justify-center h-full">
                          <Loader
                            filledColor={colors.accent1.DEFAULT}
                            width="4em"
                            height="4em"
                          />
                        </div>
                      )}
                      >
                        <ImageCarousel images={GALLERY_BOOK_IMAGES} showThumbnails />
                      </Suspense>
                    </div>
                  )}
                  <div className={`flex-1 bg-white p-6 rounded-lg shadow flex flex-col ${layout === 'single-pane' ? 'w-full' : ''}`}>
                    <div className="flex-shrink-0">
                      <CheckoutQuickSummary
                        resetPreQuestions={resetPreQuestions}
                      />
                    </div>
                    <div className="flex-grow overflow-hidden">
                      <CheckoutWizard
                        steps={steps}
                        scrollToStep={scrollToStep ?? undefined}
                        setScrollToStep={setScrollToStep}
                        checkoutPath={getCheckoutPath(type, giftCardFlow)}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </main>

        {/* Loading Overlay */}
        {!isCheckoutInitialized && !isSuccess && (
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="absolute inset-0 bg-white opacity-70" />
            <div className="z-10 bg-white p-8 rounded-lg shadow-lg flex flex-col items-center">
              <Loader
                filledColor={colors.accent1.DEFAULT}
                width="4em"
                height="4em"
              />
              <p className="mt-4 text-lg">Loading checkout...</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
