import React from 'react';

interface AssurancesBannerProps {
  isDesktop: boolean;
}

const assurances = [
  {
    icon: '💯',
    title: '100% Refund Guarantee',
  },
  {
    icon: '⭐',
    title: '5-Star Reviews',
  },
  {
    icon: '📚',
    title: '3000+ Stories Created',
  },
  {
    icon: '🚚',
    title: 'Shipping Included in All Packages',
  },
];

export default function AssurancesBanner({ isDesktop }: AssurancesBannerProps) {
  return (
    <div className="py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className={`grid ${isDesktop ? 'grid-cols-4' : 'grid-cols-2'} gap-6`}>
          {assurances.map((item) => (
            <div key={item.title} className="text-center flex items-center justify-center gap-2">
              <div className="text-2xl sm:text-3xl">
                {item.icon}
              </div>
              <h3 className="font-bold text-xs sm:text-sm">
                {item.title}
              </h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
