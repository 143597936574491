import { API, graphqlOperation } from 'aws-amplify';
import { Expression } from 'src/graphql/API';
import { deleteExpression } from 'src/graphql/mutations';

async function deleteExpressionInDB(expression: Expression) {
  try {
    await API.graphql(graphqlOperation(deleteExpression, { input: { id: expression.id } }));
    console.log('Expression deleted successfully');
  } catch (error) {
    console.error('Error deleting Expression:', error);
  }
}

export default deleteExpressionInDB;
