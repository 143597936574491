import { GraphQLResult, API } from '@aws-amplify/api';
import {
  CreateImageInput,
  CreateImageMutation,
} from 'src/graphql/API';
import { createImage } from 'src/graphql/mutations';

export default async function createNewImage(
  newImageInput: CreateImageInput,
) {
  try {
    console.log('newImageInput', newImageInput);
    console.log('newImageInput.traits', newImageInput.traits);
    console.log('type of newImageInput.traits', typeof newImageInput.traits);

    const inputWithStringifiedTraits = {
      ...newImageInput,
      traits: JSON.stringify(newImageInput.traits),
    };

    const imageRes = await API.graphql({
      query: createImage,
      variables: { input: inputWithStringifiedTraits },
    }) as GraphQLResult<CreateImageMutation>;

    const imageId = imageRes.data?.createImage?.id;

    if (!imageId) {
      throw new Error('Failed to create image');
    }

    return imageId;
  } catch (error) {
    console.error('Error creating new image', error);
    throw error;
  }
}
