import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectActiveChild } from '@store/appSlice';

interface Props {
  children: ReactElement;
}

/**
 * Wrap any component that requires a child user with this hook.
 * @param children The component that requires child user.
 * @returns The component if a child user exists, otherwise navigates to a child route
 */
export default function RequireChild({ children }: Props) {
  const child = useSelector(selectActiveChild);
  if (child) return children;
  return <Navigate to="/welcome" replace />;
}
