export type CheckoutPath = 'book_order' | 'self_purchase' | 'gift_with_self_writing' | 'gift_package';
export type CheckoutStep = 'who_buying_for' | 'who_will_create' | 'finalize_cover' | 'personalize_inside' | 'recipient_info' | 'customize_gift' | 'select_package' | 'order_summary';
export type CheckoutAction = 'view' | 'complete' | 'back' | 'download_app' | 'proceed_to_checkout';

export interface CheckoutEventProperties {
  path?: CheckoutPath;
  step?: CheckoutStep;
  action?: CheckoutAction;
  type?: 'buy-package' | 'custom-book';
  item_count?: number;
  [key: string]: any;
}

export default function trackCheckoutEvent(
  eventName: string,
  properties: CheckoutEventProperties,
) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    ...properties,
  });
}

export type StaticPagePath = 'landing_page' | 'terms' | 'privacy';

export interface StaticPageEventProperties {
  path?: StaticPagePath;
  platform?: 'ios' | 'android';
  button_name?: string;
  [key: string]: any;
}

export function trackStaticPageEvent(eventName: string, properties: StaticPageEventProperties) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    ...properties,
  });
}
