export function PrivacyContent() {
  return (
    <div className="prose prose-lg max-w-none">
      {/* Introduction Section */}
      <section>
        <p className="text-gray-700 text-justify">
          This Privacy Policy describes how Running Towards XYZ, LLC. (&ldquo;Running&rdquo;, &ldquo;we&rdquo;, or &ldquo;us&rdquo;) handles personal information that we collect through our website –
          {' '}
          <a href="http://www.wander.ly" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
            http://www.wander.ly
          </a>
          {' '}
          – and any other sites or services that link to this Privacy Policy (collectively, the &ldquo;Services&rdquo;), as well as any correspondence with you.
        </p>
      </section>

      {/* Personal Information Collection Section */}
      <section className="mt-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Personal information we collect
        </h2>

        <h3 className="text-xl font-bold text-gray-900 mb-4">
          Information you provide to us:
        </h3>
        <ul className="list-disc pl-6 space-y-2">
          <li className="text-gray-700">
            <strong>Contact and account information</strong>
            , such as your first and last name, email address, username and password.
          </li>
          <li className="text-gray-700">
            <strong>Usage information</strong>
            , such as information about how you, or your child, uses the Services and interacts with us through use of the Services.
          </li>
          <li className="text-gray-700">
            <strong>Feedback or correspondence</strong>
            , such as information you provide when you contact us with questions, feedback, product reviews, or otherwise correspond with us online.
          </li>
          <li className="text-gray-700">
            <strong>Marketing information</strong>
            , such as your preferences for receiving communications about our activities, events, and publications, and details about how you engage with our communications.
          </li>
          <li className="text-gray-700">
            <strong>Other information</strong>
            {' '}
            that we may collect which is not specifically listed here, but which we will use in accordance with this Privacy Policy or as otherwise disclosed at the time of collection.
          </li>
        </ul>
      </section>

      {/* Third Party Information Section */}
      <section className="mt-8">
        <h3 className="text-xl font-bold text-gray-900 mb-4">
          Information we obtain from third parties:
        </h3>
        <ul className="list-disc pl-6 space-y-2">
          <li className="text-gray-700">
            <strong>Social media information. </strong>
            We may maintain pages on social media platforms, such as Facebook, LinkedIn, Instagram, and other third-party platforms. When you visit or interact with our pages on those platforms, the platform provider&rsquo;s privacy policy will apply to your interactions and their collection, use and processing of your personal information. You or the platforms may provide us with information through the platform, and we will treat such information in accordance with this Privacy Policy.
          </li>
          <li className="text-gray-700">
            <strong>Third-party login information. </strong>
            When you link, connect, or login to the Services with a third party service (e.g. Google, Facebook, or Apple), you direct the service to send us information such as your registration, friends list, and profile information as controlled by that service or as authorized by you via your privacy settings at that service.
          </li>
        </ul>
      </section>

      {/* Automatic Data Collection Section */}
      <section className="mt-8">
        <h3 className="text-xl font-bold text-gray-900 mb-4">
          Automatic data collection
        </h3>
        <p className="text-gray-700 text-justify">
          We and our service providers may automatically log information about you, your computer or mobile device, and your interaction over time with our Services, our communications and other online services, such as:
        </p>

        <ul className="list-disc pl-6 mt-4 space-y-2">
          <li className="text-gray-700">
            <strong>Device data</strong>
            , such as your computer&rsquo;s or mobile device&rsquo;s operating system type and version, manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP address, language settings, mobile device carrier, radio/network information (e.g., WiFi, LTE, 4G), and general location information such as city, state or geographic area.
          </li>
          <li className="text-gray-700">
            <strong>Online activity data</strong>
            , such as pages or screens you viewed, how long you spent on a page or screen, browsing history, navigation paths between pages or screens, information about your activity on a page or screen, access times, and duration of access, and whether you have opened our marketing emails or clicked links within them.
          </li>
        </ul>

        <h3 className="text-xl font-bold text-gray-900 mt-6 mb-4">
          We use the following tools for automatic data collection:
        </h3>
        <ul className="list-disc pl-6 space-y-2">
          <li className="text-gray-700">
            <strong>Cookies</strong>
            , which are text files that websites store on a visitor&rsquo;s device to uniquely identify the visitor&rsquo;s browser or to store information or settings in the browser for the purpose of helping you navigate between pages efficiently, remembering your preferences, enabling functionality, helping us understand user activity and patterns, and facilitating online advertising.
          </li>
          <li className="text-gray-700">
            <strong>Local storage technologies</strong>
            , like HTML5, that provide cookie-equivalent functionality but can store larger amounts of data, including on your device outside of your browser in connection with specific applications.
          </li>
          <li className="text-gray-700">
            <strong>Web beacons</strong>
            , also known as pixel tags or clear GIFs, which are used to demonstrate that a webpage or email was accessed or opened, or that certain content was viewed or clicked.
          </li>
        </ul>
      </section>

      {/* How We Use Information Section */}
      <section className="mt-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          How we use your personal information
        </h2>

        <h3 className="text-xl font-bold text-gray-900 mb-4">
          To operate our Services:
        </h3>
        <ul className="list-disc pl-6 space-y-2">
          <li className="text-gray-700">Provide, operate, maintain, secure and improve our Services</li>
          <li className="text-gray-700">Provide information about our Services</li>
          <li className="text-gray-700">Communicate with you about our Services, including by sending you announcements, updates, security alerts, and support and administrative messages</li>
          <li className="text-gray-700">Understand your needs and interests, and personalize your experience with our Services and our communications</li>
          <li className="text-gray-700">Respond to your requests, questions and feedback</li>
        </ul>
      </section>

      {/* Research and Development Section */}
      <section className="mt-8">
        <h3 className="text-xl font-bold text-gray-900 mb-4">
          For research and development
        </h3>
        <p className="text-gray-700 text-justify">
          To analyze and improve the Services and to develop new products and Services, including by studying use of our Services.
        </p>
      </section>

      {/* Marketing and Advertising Section */}
      <section className="mt-8">
        <h3 className="text-xl font-bold text-gray-900 mb-4">
          Marketing and advertising
        </h3>
        <p className="text-gray-700 text-justify">
          We and our advertising partners may collect and use your personal information for marketing and advertising purposes, including:
        </p>
        <ul className="list-disc pl-6 space-y-2 mt-4">
          <li className="text-gray-700">
            <strong>Direct marketing. </strong>
            We or our advertising partners may from time-to-time send you direct marketing communications as permitted by law, including, but not limited to, notifying you of special promotions, offers and events via email. You may opt out of marketing-related communications by following the opt out or unsubscribe instructions contained in the marketing communication we send you. You may continue to receive service-related and other non-marketing communications.
          </li>
          <li className="text-gray-700">
            <strong>To comply with law. </strong>
            As we believe necessary or appropriate to comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities.
          </li>
        </ul>
      </section>

      {/* Compliance and Safety Section */}
      <section className="mt-8">
        <h3 className="text-xl font-bold text-gray-900 mb-4">
          For compliance, fraud prevention, and safety
        </h3>
        <p className="text-gray-700 text-justify">
          To: (a) protect our, your or others&rsquo; rights, privacy, safety or property (including by making and defending legal claims); (b) enforce the terms and conditions that govern our Services; and (c) protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.
        </p>
      </section>

      {/* Anonymous Data Section */}
      <section className="mt-8">
        <h3 className="text-xl font-bold text-gray-900 mb-4">
          To create anonymous data
        </h3>
        <p className="text-gray-700 text-justify">
          To create anonymous data from your personal information and other individuals whose personal information we collect. We make personal information into anonymous data by removing information that makes the data personally identifiable to you. We may use this anonymous data and share it with third parties for our lawful business purposes, including to analyze and improve our Services and promote our business.
        </p>
      </section>

      {/* How We Share Information Section */}
      <section className="mt-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          How we share your personal information
        </h2>

        <h3 className="text-xl font-bold text-gray-900 mb-4">
          Service providers
        </h3>
        <p className="text-gray-700 text-justify">
          We may share your personal information with third party companies and individuals that provide services on our behalf or help us operate our Services (such as customer support, hosting, analytics, email delivery, marketing, identity verification, and database management services).
        </p>

        <h3 className="text-xl font-bold text-gray-900 mt-6 mb-4">
          Professional advisors
        </h3>
        <p className="text-gray-700 text-justify">
          We may disclose your personal information to professional advisors, such as lawyers, bankers, auditors and insurers, where necessary in the course of the professional services that they render to us.
        </p>

        <h3 className="text-xl font-bold text-gray-900 mt-6 mb-4">
          For compliance, fraud prevention and safety
        </h3>
        <p className="text-gray-700 text-justify">
          We may share your personal information for the compliance, fraud prevention and safety purposes described above.
        </p>

        <h3 className="text-xl font-bold text-gray-900 mt-6 mb-4">
          Business transfers
        </h3>
        <p className="text-gray-700 text-justify">
          We may sell, transfer or otherwise share some or all of our business or assets, including your personal information, in connection with a business transaction (or potential business transaction) such as a corporate divestiture, merger, consolidation, acquisition, reorganization or sale of assets, or in the event of bankruptcy or dissolution. In such a case, we will make reasonable efforts to require the recipient to honor this Privacy Policy.
        </p>
      </section>

      {/* User Choices Section */}
      <section className="mt-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Your choices
        </h2>
        <p className="text-gray-700 text-justify">
          In certain circumstances providing personal information is optional. However, if you choose not to provide personal information that is needed to use some features of our Service, you may be unable to use those features. You can also contact us to request updates or corrections to your personal information.
        </p>
      </section>

      {/* Data Retention Section */}
      <section className="mt-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Data Retention and Account Deletion
        </h2>
        <p className="text-gray-700 text-justify">
          Your personal data will be processed and stored for as long as required to provide the service and may be retained for longer due to applicable legal obligation.
        </p>
        <p className="text-gray-700 text-justify mt-4">
          You have the right to delete your account with our Services at any time. Here are the ways you can do this:
        </p>
        <ul className="list-disc pl-6 space-y-2 mt-4">
          <li className="text-gray-700">
            <strong>Email Request:</strong>
            {' '}
            Send an email to
            {' '}
            <a href="mailto:hello@wander.ly" className="text-blue-600 hover:text-blue-800">
              hello@wander.ly
            </a>
            {' '}
            with your account details and a deletion request. We will process your request and confirm once your account and associated personal information have been deleted.
          </li>
          <li className="text-gray-700">
            <strong>Direct Deletion Through Services:</strong>
            {' '}
            Log into your account, navigate to the &quot;Manage Account&quot; page, and select the &quot;Delete Account&quot; option. Follow the on-screen instructions to complete the process.
          </li>
        </ul>
        <p className="text-gray-700 text-justify mt-4">
          If a user deletes their account, their account data, beyond any payment data required to be retained by law, will be removed from our system within 60 days after the request for account deletion has been acknowledged.
        </p>
      </section>

      {/* Payment Information Section */}
      <section className="mt-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Handling of Payment Information Post-Deletion
        </h2>
        <p className="text-gray-700 text-justify">
          While we do not store your credit card or bank account information, as payments are processed by Stripe, the Google Play Store and the Apple App Store, we retain limited transactional data for legal, tax, and auditing purposes. This includes transaction identifiers, amounts, dates, and general transaction types. This information is retained in accordance with financial and legal obligations, particularly under California law, and is not used for any other purpose.
        </p>
        <p className="text-gray-700 text-justify mt-4">
          After your account is deleted, this payment-related information will remain in our records for the period required by law. It will be protected and accessed only when necessary for legal, tax, or auditing purposes.
        </p>
        <p className="text-gray-700 text-justify mt-4">
          Please note that Stripe, Google and Apple, as our payment processors, maintain their own records of transactions. For details on how these companies handle data upon account deletion, please refer to
          {' '}
          <a href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
            Stripe&apos;s Privacy Policy
          </a>
          , the
          {' '}
          <a href="https://play.google.com/intl/en-US_us/about/play-terms/index.html" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
            Google Play Terms of Service
          </a>
          {' '}
          or
          {' '}
          <a href="https://www.apple.com/legal/privacy/data/en/app-store/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
            Apple&apos;s App Store Privacy Policy
          </a>
          .
        </p>
      </section>

      {/* Other Sites Section */}
      <section className="mt-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Other sites, mobile applications and services
        </h2>
        <p className="text-gray-700 text-justify">
          Our Services may contain links to other websites, mobile applications, and other online services operated by third parties. These links are not an endorsement of, or representation that we are affiliated with, any third party. In addition, our content may be included on web pages or in mobile applications or online services that are not associated with us. We do not control third party websites, mobile applications or online services, and we are not responsible for their actions. Other websites and services follow different rules regarding the collection, use and sharing of your personal information. We encourage you to read the privacy policies of the other websites and mobile applications and online services you use.
        </p>
      </section>

      {/* Security Practices Section */}
      <section className="mt-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Security practices
        </h2>
        <p className="text-gray-700 text-justify">
          We use reasonable organizational, technical and administrative measures designed to protect against unauthorized access, misuse, loss, disclosure, alteration and destruction of personal information we maintain. Unfortunately, data transmission over the Internet cannot be guaranteed as completely secure. Therefore, while we strive to protect your personal information, we cannot guarantee the security of personal information.
        </p>
        <p className="text-gray-700 text-justify mt-4">
          Measures we take to ensure secure data handling include but are not limited to:
        </p>
        <ul className="list-disc pl-6 space-y-2 mt-4">
          <li className="text-gray-700">Utilization of secure cloud infrastructure provided by Amazon Web Services.</li>
          <li className="text-gray-700">Implementation of Google and Facebook account signin to leverage their security measures.</li>
          <li className="text-gray-700">Restricting access to user data to authorized personnel only.</li>
          <li className="text-gray-700">Regularly updating our systems to apply security best practices.</li>
        </ul>
      </section>

      {/* Children's Privacy Section */}
      <section className="mt-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Children
        </h2>
        <p className="text-gray-700 text-justify">
          While a parent or guardian may create an account and allow its child to use our Services, if we learn that we have collected personal information through the Services from a child under 13 without the consent of the child&rsquo;s parent or guardian as required by law, we will delete it. We do not collect the personal information of children through use of your Services. The personal information we collect, described above, relates to the personal information of the parent or guardian who has signed up for our Services.
        </p>
      </section>

      {/* Changes to Privacy Policy Section */}
      <section className="mt-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Changes to this Privacy Policy
        </h2>
        <p className="text-gray-700 text-justify">
          We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on our Services. We may also provide notification of changes in another way that we believe is reasonably likely to reach you, such as via e-mail (if you have an account where we have your contact information) or another manner through our Services.
        </p>
        <p className="text-gray-700 text-justify mt-4">
          Any modifications to this Privacy Policy will be effective upon our posting the new terms and/or upon implementation of the new changes on our Services (or as otherwise indicated at the time of posting).
        </p>
      </section>

      {/* Contact Information Section */}
      <section className="mt-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          How to contact us
        </h2>
        <p className="text-gray-700 text-justify">
          Please direct any questions or comments about this policy or privacy practices to
          {' '}
          <a href="mailto:hello@wander.ly" className="text-blue-600 hover:text-blue-800">
            hello@wander.ly
          </a>
          .
        </p>
      </section>
    </div>
  );
}

export default PrivacyContent;
