import { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

type AnswerPart = string | { text: string, href?: string, action?: string };

interface Props {
  question: string;
  answer: AnswerPart[];
  onSpecialAction: (action: string) => void;
}

function AnFAQItem({
  question,
  answer,
  onSpecialAction,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200">
      <div
        className="flex justify-between items-center p-5 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setIsOpen(!isOpen);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <h2 className="text-lg">{question}</h2>
        <div>
          {isOpen ? <FiChevronUp size={24} /> : <FiChevronDown size={24} />}
        </div>
      </div>
      {isOpen && (
        <div className="p-5">
          {answer.map((part) => {
            if (typeof part === 'string') {
              if (part === '\n') {
                return <br key={`${question}-new-line`} />;
              }
              return <span key={`span-${part}`}>{part}</span>;
            } if (part.action) {
              return <button key={`btn-${part.action}-${part.text}`} className="text-blue-500" onClick={() => part.action && onSpecialAction(part.action)}>{part.text}</button>;
            }
            return <a key={`a-${part.href}-${part.text}`} href={part.href} className="text-blue-500">{part.text}</a>;
          })}
        </div>
      )}
    </div>
  );
}

export default AnFAQItem;
