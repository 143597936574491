import AnButton from '@components/AnButton';
import { PackageFlowType } from '../types';

interface WhoWillCreateProps {
  onChange: (value: PackageFlowType) => void;
  onStepComplete: () => void;
}

export default function WhoWillCreate({
  onChange,
  onStepComplete,
}: WhoWillCreateProps) {
  const handleSelection = (value: PackageFlowType) => {
    onChange(value);
    onStepComplete();
  };

  return (
    <div className="flex justify-between flex-col space-y-4 my-4 pr-1">
      <AnButton
        color="primary"
        variant="outlined"
        className="bg-white !text-black !font-[Urbanist]"
        onClick={() => handleSelection({ buyingFor: 'someoneElse', whoWillCreate: 'someoneElse' })}
      >
        🎁 My gift recipient
      </AnButton>
      <AnButton
        color="primary"
        variant="outlined"
        className="bg-white !text-black !font-[Urbanist]"
        onClick={() => handleSelection({ buyingFor: 'someoneElse', whoWillCreate: 'me' })}
      >
        ✍️ I&apos;ll write it for them
      </AnButton>
    </div>
  );
}
