import AnIcon from '../AnIcon';
import IconProps from '../IconProps.interface';

export default function AnTagOutlineIcon({
  fillColorClass,
  strokeColorClass,
  size,
  strokeWidth,
}: IconProps) {
  return (
    <AnIcon
      size={size}
      strokeWidth={strokeWidth}
      fillColorClass={fillColorClass}
      strokeColorClass={strokeColorClass}
      viewBoxWidth={24}
      viewBoxHeight={24}
      svgPath={(
        <path d="M10.537 2.164a3 3 0 0 1 2.244.727l.15.14 7.822 7.823a3 3 0 0 1 .135 4.098l-.135.144-5.657 5.657a3 3 0 0 1-4.098.135l-.144-.135L3.03 12.93a3 3 0 0 1-.878-2.188l.011-.205.472-5.185a3 3 0 0 1 2.537-2.695l.179-.021zm.308 1.989-.127.003-5.185.472a1 1 0 0 0-.888.787l-.017.118-.472 5.185a1 1 0 0 0 .206.703l.083.095 7.823 7.823a1 1 0 0 0 1.32.083l.094-.083 5.657-5.657a1 1 0 0 0 .083-1.32l-.083-.094-7.823-7.823a1 1 0 0 0-.671-.292M7.317 7.318a3 3 0 1 1 4.243 4.243 3 3 0 0 1-4.243-4.243m2.829 1.414a1 1 0 1 0-1.415 1.414 1 1 0 0 0 1.415-1.414" />
            )}
    />
  );
}
