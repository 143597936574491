import { API } from '@aws-amplify/api';
import { ANANSI_REST_API_NAME } from '@lib/constants';
import getCognitoUserId from '@lib/getCognitoUserId';
import getCognitoUserSubId from '@lib/getCognitoUserSubId';
import { StoryData } from '@lib/types/StoryData';

async function startExpositionJob(storyData: StoryData) {
  const {
    mainCharacter, storyType, companions, storySpecificMetadata,
  } = storyData;

  const apiName = ANANSI_REST_API_NAME;
  const path = '/exposition';

  const cognitoUserID = await getCognitoUserId();
  const cognitoUserSubID = await getCognitoUserSubId();

  // Mini function to apply transformations to storyType.name
  function transformStoryType(storyTypeName: string | undefined): string | undefined {
    switch (storyTypeName) {
      case 'First Day of School':
        return 'Scripted';
      case 'Help an Animal':
        return 'v3HelpAnAnimal';
      // Add more cases as needed
      default:
        return storyTypeName;
    }
  }

  const transformedStoryType = transformStoryType(storyType?.name);

  // Check if companions is defined and not empty, then map, otherwise set to an empty string
  const companionsString = companions && companions.length > 0 ? JSON.stringify(companions.map((companion) => ({
    name: companion.name,
    relation: companion.relation,
    gender: companion.gender,
    type: companion.type,
    otherRelation: companion.otherRelation,
  }))) : '[]'; // Use an empty array string if companions is undefined or empty

  const myInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      age: mainCharacter.age,
      name: mainCharacter.name,
      gender: mainCharacter.commonNoun,
      readingLevel: mainCharacter.readingLevel ? mainCharacter.readingLevel : 'practicing',
      cognitoUserID,
      cognitoUserSubID,
      storyType: transformedStoryType,
      companions: companionsString,
      storySpecificMetadata: JSON.stringify(storySpecificMetadata),
    },
  };

  return API.get(apiName, path, myInit)
    .then((response) => response.data.job_id)
    .catch((error) => {
      console.error(error.response);
      return error;
    });
}

export default startExpositionJob;
