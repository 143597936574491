import React, {
  useRef, useEffect, ReactElement,
} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import trackCheckoutEvent, { CheckoutPath, CheckoutStep as CheckoutStepFromAnalytics } from '@lib/analytics';
import { CheckoutStep as CheckoutStepType } from './types';
import CheckoutStep from './CheckoutStep';

export default function CheckoutWizard({
  steps,
  scrollToStep,
  setScrollToStep,
  checkoutPath,
}: {
  steps: CheckoutStepType[],
  scrollToStep?: string,
  setScrollToStep?: (step: string | null) => void,
  checkoutPath: CheckoutPath,
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const stepsRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleNext = (currentIndex: number) => {
    const currentStep = steps[currentIndex];

    trackCheckoutEvent('checkout_step_complete', {
      path: checkoutPath,
      step: currentStep.title.toLowerCase().replace(/\s+/g, '_') as CheckoutStepFromAnalytics,
      action: 'complete',
    });

    if (currentIndex < steps.length - 1) {
      const nextStep = steps[currentIndex + 1];
      trackCheckoutEvent('checkout_step_view', {
        path: checkoutPath,
        step: nextStep.title.toLowerCase().replace(/\s+/g, '_') as CheckoutStepFromAnalytics,
        action: 'view',
      });

      const nextElement = stepsRefs.current[currentIndex + 1];
      if (nextElement) {
        nextElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  useEffect(() => {
    if (scrollToStep) {
      const stepIndex = steps.findIndex((step) => step.title === scrollToStep);
      if (stepIndex !== -1) {
        const stepElement = stepsRefs.current[stepIndex];
        if (stepElement) {
          stepElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          if (setScrollToStep) {
            setScrollToStep(null);
          }
        }
      }
    }
  }, [scrollToStep, steps]);

  // Track initial step view
  useEffect(() => {
    if (steps.length > 0) {
      trackCheckoutEvent('checkout_step_view', {
        path: checkoutPath,
        step: steps[0].title.toLowerCase().replace(/\s+/g, '_') as CheckoutStepFromAnalytics,
        action: 'view',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={containerRef}
      className="flex flex-col h-full overflow-hidden"
    >
      <div className="flex-grow overflow-y-auto">
        <AnimatePresence initial={false} mode="wait">
          {steps.map((step, index) => (
            <motion.div
              key={step.title}
              ref={(el) => {
                stepsRefs.current[index] = el;
              }}
              className="min-h-full"
              initial={{ opacity: 0, y: '50%' }}
              animate={{ opacity: 1, y: '0%' }}
              exit={{ opacity: 0, y: '-50%' }}
              transition={{
                duration: 0.5,
                opacity: { duration: 0.3 },
                y: { type: 'spring', stiffness: 300, damping: 30 },
              }}
            >
              <CheckoutStep
                title={step.title}
                onNext={() => handleNext(index)}
                isNextDisabled={step.isNextDisabled}
                isLastStep={index === steps.length - 1}
              >
                {React.isValidElement(step.children)
                  ? React.cloneElement(step.children as ReactElement, { onStepComplete: () => handleNext(index) })
                  : step.children}
              </CheckoutStep>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
}
