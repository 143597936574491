import NumberedPage from '@lib/types/NumberedPage';
import StoryStatus from '@lib/types/StoryStatus.enum';
import { StoryPages, UpdateStoryPagesInput } from 'src/graphql/API';

function constructStoryPagesWithNextPage(nextPage: NumberedPage, oldStoryPages: StoryPages, isNextPageLast: boolean): UpdateStoryPagesInput {
  const { id } = oldStoryPages;
  // for updating the StoryPages object in the DB,
  const data = {
    id,
    pageIDs: [...(oldStoryPages.pageIDs || []), nextPage.pageData.id],
    storyStatus: isNextPageLast ? StoryStatus.ENDED : StoryStatus.IN_PROGRESS,
    expositionImageName: oldStoryPages.expositionImageName,
    lastViewed: new Date().toISOString(),
  };

  if (nextPage.pageNo === 1) {
    data.expositionImageName = nextPage.pageImage;
  }
  return data;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  constructStoryPagesWithNextPage,
};
