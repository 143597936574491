import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import './index.scss';
import { Amplify } from 'aws-amplify';
import { Provider } from 'react-redux';
import store from '@store/store';
import { RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from './reportWebVitals';
import awsConfig from './aws-exports';
import 'react-step-progress-bar/styles.css';
import router from './app/routes';
import '@aws-amplify/ui-react/styles.css';

Sentry.init({
  dsn: 'https://8f32f6c31d164be5964feb4b22f5d68f@o4505386591453184.ingest.sentry.io/4505386593484800',
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
  ],
  // Defined via aws UI in amplify -> environment variables
  environment: process.env.REACT_APP_SENTRY_ENV,
  // tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === '[::1]'
    // network created by my router. E.g. when I run the npm started app on a mobile connected to the same wifi as my dev laptop
    || window.location.hostname.startsWith('192.168')
    // 127.0.0.1/8 is considered localhost for IPv4.
    || window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignInUri, ...redirectSigninUris] = awsConfig.oauth.redirectSignIn.split(',');
const [localRedirectSignOutUri, ...redirectSignOutUris] = awsConfig.oauth.redirectSignOut.split(',');

// const redirectSignIn = isLocalhost ? `${window.location.hostname}:${window.location.port}` : redirectSigninUris.filter((uri: string) => uri.includes(window.location.hostname))[0];
const redirectSignIn = isLocalhost ? localRedirectSignInUri : redirectSigninUris.filter((uri: string) => uri.includes(window.location.hostname))[0];
const redirectSignOut = isLocalhost ? localRedirectSignOutUri : redirectSignOutUris.filter((uri: string) => uri.includes(window.location.hostname))[0];

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn,
    redirectSignOut,
  },
};

Amplify.configure(updatedAwsConfig);

const tagManagerArgs = {
  gtmId: 'GTM-NGNZQDV',
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <HelmetProvider>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </HelmetProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
