import AnIcon from '../AnIcon';
import IconProps from '../IconProps.interface';

export default function AnTruckOutlineIcon({
  fillColorClass,
  strokeColorClass,
  size,
  strokeWidth,
}: IconProps) {
  return (
    <AnIcon
      size={size}
      strokeWidth={strokeWidth}
      fillColorClass={fillColorClass}
      strokeColorClass={strokeColorClass}
      viewBoxWidth={24}
      viewBoxHeight={24}
      svgPath={(
        <path d="M15 4a2 2 0 0 1 2 2v1h1.52a2 2 0 0 1 1.561.75l1.48 1.851a2 2 0 0 1 .439 1.25V15a2 2 0 0 1-2 2 3 3 0 1 1-6 0h-4a3 3 0 1 1-6 0 2 2 0 0 1-2-2V6a2 2 0 0 1 2-2zM7 16a1 1 0 1 0 0 2 1 1 0 0 0 0-2m10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2M15 6H4v9h.764a2.997 2.997 0 0 1 4.341-.138l.131.138h5.528l.115-.121.121-.115zm3.52 3H17v5c.82 0 1.563.33 2.105.862l.131.138H20v-4.15z" />
            )}
    />
  );
}
