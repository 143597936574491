import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectPremium } from '@store/appSlice';

interface Props {
  children: ReactElement;
}

/**
 * Wrap any component that does NOT require a premium subscription with this hook.
 * @param children The component that does NOT requires a premium subscription.
 * @returns The component if a premium subscription does NOT exists, otherwise navigates to a premium route
 */
export default function RequireNoPremium({ children }: Props) {
  const premium = useSelector(selectPremium);
  if (!premium) return children;
  return <Navigate to="/stories/current" replace />;
}
