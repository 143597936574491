import { UserByCognitoUserIdQuery } from 'src/graphql/API';
import { userByCognitoUserId } from 'src/graphql/queries';
import getCognitoUserId from '@lib/getCognitoUserId';
import { GraphQLResult, API } from '@aws-amplify/api';

export default async function getUser() {
  const cognitoUserId = await getCognitoUserId();
  const response: GraphQLResult<UserByCognitoUserIdQuery> = await API.graphql({
    query: userByCognitoUserId,
    variables: { cognitoUserId },
  }) as GraphQLResult<UserByCognitoUserIdQuery>;
  return response.data?.userByCognitoUserId?.items[0] || undefined;
}
