import {
  ButtonHTMLAttributes,
  createElement, FunctionComponent, ReactNode, useMemo,
} from 'react';
import clsx from 'clsx';
import IconProps from './AnIcon/IconProps.interface';
import { SizeType } from '../lib/types';

export type Variant = 'contained' | 'outlined' | 'text' | 'text-contained' |'link'

export interface AnButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  iconLeft?: FunctionComponent<IconProps>;
  iconRight?: FunctionComponent<IconProps>;
  iconProps?: IconProps;
  variant?: Variant;
  size?: SizeType;
  color?: 'primary' | 'secondary' | 'success' | 'text-dark' | 'text-light' | 'white' | 'error';
  block?: boolean;
  className?: string;
  children?: ReactNode;
  centered?: boolean;
  loading?: boolean;
}

export default function AnButton({
  text,
  iconLeft,
  iconRight,
  iconProps = {},
  variant = 'contained',
  color = 'primary',
  size = 'sm',
  block = false,
  className,
  children,
  centered = true,
  loading = false,
  ...rest
}: AnButtonProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const colorClasses = [
    'border-primary',
    'bg-primary',
    'text-primary',
    'stroke-primary',
    'fill-primary',
    'border-secondary',
    'bg-secondary',
    'text-secondary',
    'stroke-secondary',
    'fill-secondary',
    'border-success',
    'bg-success',
    'text-success',
    'stroke-success',
    'fill-success',
    'stroke-text-dark',
    'fill-text-dark',
    'stroke-text-light',
    'fill-text-light',
    'bg-white',
    'border-white',
  ];

  const textClass = useMemo(() => clsx({
    'text-white': color !== 'white',
    'text-dark': color === 'white',
  }), [color]);

  const colorWithoutTextPrefix = useMemo(() => color.replace('text-', ''), [color]);
  const variantClasses = useMemo(() => clsx({
    'px-6 py-2 rounded-lg': variant === 'outlined' || variant === 'contained' || variant === 'text-contained',
    [`border-${color} bg-${color} font-serif ${textClass} enabled:hover:bg-${color}-800 enabled:hover:border-${color}-800`]: variant === 'contained',
    [`bg-transparent border-${color} text-dark font-serif`]: variant === 'outlined',
    [`bg-transparent text-${colorWithoutTextPrefix} border-0 font-sans`]: variant === 'text' || variant === 'text-contained',
    [`bg-transparent text-${colorWithoutTextPrefix} border-0 font-sans underline`]: variant === 'link',
  }), [variant, color]);

  const iconStroke = useMemo(() => clsx({
    'stroke-white': variant === 'contained',
    [`stroke-${color}`]: variant === 'text' || variant === 'outlined',
  }), [variant, color]);
  const blockClasses = useMemo(() => clsx({
    'w-full': block,
  }), [block]);

  const iconFill = useMemo(() => clsx({
    'fill-white': variant === 'contained',
    [`fill-${color}`]: variant === 'text' || variant === 'outlined',
  }), [variant, color]);

  const sizeClasses = useMemo(() => clsx({
    'text-sm': ['xs', 'sm'].includes(size),
    'text-md': size === 'md',
    'text-lg': ['lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl', '7xl', '8xl', '9xl', '10xl'].includes(size),
  }), [size]);

  const iconEl = useMemo(() => {
    if (!iconRight && !iconLeft) return null;
    return createElement(iconRight || iconLeft as FunctionComponent<IconProps>, {
      strokeColorClass: iconStroke,
      fillColorClass: iconFill,
      size,
      ...iconProps,
    });
  }, [iconRight, iconLeft, iconStroke, iconFill, size, iconProps]);

  return (
    <button
      type={rest.type as any}
      className={`
        flex flex-row ${centered ? 'justify-center' : 'justify-start'} items-center gap-1
        ${variantClasses} ${sizeClasses} ${color} ${blockClasses}
        border font-medium transition duration-500 ease select-none focus:outline-none focus:shadow-outline
        disabled:cursor-not-allowed
        disabled:bg-gray-100 disabled:border-gray-100
        ${className}
        `}
      {...rest}
      disabled={loading || rest.disabled}
    >
      {loading ? (
        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
      ) : (
        <>
          {iconLeft && iconEl}
          {text && <p className="">{text}</p>}
          {iconRight && iconEl}
          {children}
        </>
      )}
    </button>
  );
}
