import { Loader } from '@aws-amplify/ui-react';
import AnModal from './AnModal';

interface Props {
  message?: string;
}

export default function AnLoadingModal({
  message = 'Wanderly is spinning up. Please wait...',
}: Props) {
  return (
    <AnModal
      isOpen
    >
      <div>
        <Loader size="large" />
        <p>{message}</p>
      </div>
    </AnModal>
  );
}
