import { ReactNode } from 'react';

interface TwoPaneSectionProps {
  leftContent: ReactNode;
  rightContent: ReactNode;
  reversed?: boolean;
  isDesktop: boolean;
}

export function TwoPaneSection({
  leftContent,
  rightContent,
  reversed = false,
  isDesktop,
}: TwoPaneSectionProps) {
  const paneWidth = isDesktop ? 'w-1/2' : 'w-full';

  return (
    <div className={`flex ${isDesktop ? 'flex-row' : 'flex-col'} ${reversed ? 'flex-row-reverse' : ''} gap-16 p-8 max-w-7xl mx-auto bg-white`}>
      <div className={paneWidth}>{leftContent}</div>
      <div className={paneWidth}>{rightContent}</div>
    </div>
  );
}

export default TwoPaneSection;
