import { API, Storage } from 'aws-amplify';
import { WANDERLY_NO_AUTH_NAME } from '@lib/constants';

export default async function getPricesEtc() {
  try {
    const result = await Storage.get('prices.json', {
      download: true,
      level: 'public',
      cacheControl: 'max-age=3600',
    });

    if (result.Body) {
      const text = await (result.Body as Blob).text();
      const data = JSON.parse(text);
      return data.plans;
    }
    throw new Error('No data found');
  } catch (error) {
    console.error('Error fetching prices:', error);
    // Fallback to API if needed
    const apiName = WANDERLY_NO_AUTH_NAME;
    const path = '/getPricesEtc';
    const res = await API.get(apiName, path, {});
    return res.plans;
  }
}
