import { GraphQLResult, API } from '@aws-amplify/api';
import { GetSharedPageQuery } from 'src/graphql/API';
import { getSharedPage } from 'src/graphql/queries';

async function getSharedPageById(sharedPageId: string) {
  const res = await API.graphql({
    query: getSharedPage,
    variables: {
      id: sharedPageId,
    },
  }) as GraphQLResult<GetSharedPageQuery>;

  return res.data?.getSharedPage;
}

export default getSharedPageById;
