import CheckoutHeader from '@features/Checkout/CheckoutHeader';
import yellowOrganicClosedLine from '@assets/yellow_organic_closed_line.svg';
import yellowOrganicBlob from '@assets/yellow_organic_blob.svg';
import { imageBaseURL } from '@lib/imageUtils';
import { Footer } from '@components/AnStaticPageFooter';
import { SeoMeta, PAGE_META } from '@components/SEO';
import { TermsContent } from './TermsContent';

function Terms() {
  return (
    <>
      <SeoMeta
        {...PAGE_META.terms}
        canonicalUrl="/terms"
      />
      <div className="min-h-screen bg-white relative">
        <div className="fixed inset-0 pointer-events-none">
          <img
            src={`${imageBaseURL}misc/checkout/wanderly-color-splash-1_1024w.webp`}
            alt=""
            className="fixed left-0 top-1/2 w-[800px] aspect-square opacity-40 transform -translate-x-1/4 -translate-y-1/2"
          />
          <img
            src={yellowOrganicBlob}
            alt=""
            className="fixed top-0 left-2/3 w-[800px] h-auto transform -translate-x-1/2 -translate-y-1/4"
          />
          <img
            src={yellowOrganicClosedLine}
            alt=""
            className="fixed top-0 left-0 w-[400px] h-auto opacity-50 transform -translate-x-1/4 -translate-y-1/4"
          />
          <img
            src={yellowOrganicClosedLine}
            alt=""
            className="fixed bottom-0 right-0 w-[400px] h-auto opacity-50 transform translate-x-1/4 translate-y-1/4 rotate-180"
          />
        </div>

        <CheckoutHeader showLogo="block" />

        <main className="relative z-10 pt-8 pb-16 px-4 sm:px-6 lg:pt-12 lg:pb-24 lg:px-8">
          <div className="relative max-w-4xl mx-auto">
            <div className="text-center mb-12">
              <h1 className="text-4xl sm:text-5xl font-bold text-gray-900 mb-4">
                Terms of Service
              </h1>
              <p className="text-lg text-gray-600">
                Last updated: June 26, 2024
              </p>
            </div>

            <div className="bg-white shadow-xl rounded-lg overflow-hidden">
              <div className="px-4 py-6 sm:p-8">
                <TermsContent />
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
}

export default Terms;
