import AnLogo from '@components/AnLogo';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  showLogo: string;
}

export default function Header({
  showLogo,
}: Props) {
  const navigate = useNavigate();
  const handleLogoClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <header className="bg-white px-4 sm:px-6 lg:px-8 py-4 sm:py-3 h-16 sm:h-14 flex items-center justify-between relative z-10 shadow-sm">
      <div
        onClick={handleLogoClick}
        aria-label="Toggle"
        role="button"
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleLogoClick!();
          }
        }}
        className={`mb-1 ${showLogo}`}
      >
        <AnLogo />
      </div>
    </header>
  );
}
