import putUser from '@lib/api/putUser';

async function updateTrialStoriesCount(newTrialStoriesCount: number, userID: string) {
  if (!userID) {
    throw new Error('User not found');
  }
  const resPutUser = await putUser({
    id: userID!,
    trialStoriesCount: newTrialStoriesCount,
  });

  return resPutUser;
}

export default updateTrialStoriesCount;
