/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    cognitoUserId
    email
    children {
      items {
        id
        name
        age
        commonNoun
        readingLevel
        avatar
        storyPages {
          items {
            id
            pageIDs
            storyType
            storyStatus
            rating
            childStoryPagesId
            lastViewed
            expositionImageName
            title
            initiationMethod
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userChildrenId
        owner
        __typename
      }
      nextToken
      __typename
    }
    activeChildId
    settings
    lastInteraction
    emailUpdatesEnabled
    emailPromotionsEnabled
    emailStoriesFridayEnabled
    emailStoriesDailyEnabled
    trialStoriesCount
    bookCredits
    notificationSettings {
      id
      user {
        id
        cognitoUserId
        email
        children {
          items {
            id
            name
            age
            commonNoun
            readingLevel
            avatar
            storyPages {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userChildrenId
            owner
            __typename
          }
          nextToken
          __typename
        }
        activeChildId
        settings
        lastInteraction
        emailUpdatesEnabled
        emailPromotionsEnabled
        emailStoriesFridayEnabled
        emailStoriesDailyEnabled
        trialStoriesCount
        bookCredits
        notificationSettings {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          notificationsEnabled
          storyReminders {
            enabled
            frequency
            __typename
          }
          promotionsEnabled
          updatesEnabled
          createdAt
          updatedAt
          owner
          __typename
        }
        deviceTokens {
          items {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            token
            platform
            endpointArn
            createdAt
            updatedAt
            userDeviceTokensId
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userNotificationSettingsId
        owner
        __typename
      }
      userId
      notificationsEnabled
      storyReminders {
        enabled
        frequency
        __typename
      }
      promotionsEnabled
      updatesEnabled
      createdAt
      updatedAt
      owner
      __typename
    }
    deviceTokens {
      items {
        id
        user {
          id
          cognitoUserId
          email
          children {
            items {
              id
              name
              age
              commonNoun
              readingLevel
              avatar
              createdAt
              updatedAt
              userChildrenId
              owner
              __typename
            }
            nextToken
            __typename
          }
          activeChildId
          settings
          lastInteraction
          emailUpdatesEnabled
          emailPromotionsEnabled
          emailStoriesFridayEnabled
          emailStoriesDailyEnabled
          trialStoriesCount
          bookCredits
          notificationSettings {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            notificationsEnabled
            storyReminders {
              enabled
              frequency
              __typename
            }
            promotionsEnabled
            updatesEnabled
            createdAt
            updatedAt
            owner
            __typename
          }
          deviceTokens {
            items {
              id
              userId
              token
              platform
              endpointArn
              createdAt
              updatedAt
              userDeviceTokensId
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userNotificationSettingsId
          owner
          __typename
        }
        userId
        token
        platform
        endpointArn
        createdAt
        updatedAt
        userDeviceTokensId
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    userNotificationSettingsId
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoUserId
      email
      children {
        items {
          id
          name
          age
          commonNoun
          readingLevel
          avatar
          storyPages {
            items {
              id
              pageIDs
              storyType
              storyStatus
              rating
              childStoryPagesId
              lastViewed
              expositionImageName
              title
              initiationMethod
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userChildrenId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activeChildId
      settings
      lastInteraction
      emailUpdatesEnabled
      emailPromotionsEnabled
      emailStoriesFridayEnabled
      emailStoriesDailyEnabled
      trialStoriesCount
      bookCredits
      notificationSettings {
        id
        user {
          id
          cognitoUserId
          email
          children {
            items {
              id
              name
              age
              commonNoun
              readingLevel
              avatar
              createdAt
              updatedAt
              userChildrenId
              owner
              __typename
            }
            nextToken
            __typename
          }
          activeChildId
          settings
          lastInteraction
          emailUpdatesEnabled
          emailPromotionsEnabled
          emailStoriesFridayEnabled
          emailStoriesDailyEnabled
          trialStoriesCount
          bookCredits
          notificationSettings {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            notificationsEnabled
            storyReminders {
              enabled
              frequency
              __typename
            }
            promotionsEnabled
            updatesEnabled
            createdAt
            updatedAt
            owner
            __typename
          }
          deviceTokens {
            items {
              id
              userId
              token
              platform
              endpointArn
              createdAt
              updatedAt
              userDeviceTokensId
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userNotificationSettingsId
          owner
          __typename
        }
        userId
        notificationsEnabled
        storyReminders {
          enabled
          frequency
          __typename
        }
        promotionsEnabled
        updatesEnabled
        createdAt
        updatedAt
        owner
        __typename
      }
      deviceTokens {
        items {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          token
          platform
          endpointArn
          createdAt
          updatedAt
          userDeviceTokensId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userNotificationSettingsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const userByCognitoUserId = /* GraphQL */ `query UserByCognitoUserId(
  $cognitoUserId: String!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  userByCognitoUserId(
    cognitoUserId: $cognitoUserId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoUserId
      email
      children {
        items {
          id
          name
          age
          commonNoun
          readingLevel
          avatar
          storyPages {
            items {
              id
              pageIDs
              storyType
              storyStatus
              rating
              childStoryPagesId
              lastViewed
              expositionImageName
              title
              initiationMethod
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userChildrenId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activeChildId
      settings
      lastInteraction
      emailUpdatesEnabled
      emailPromotionsEnabled
      emailStoriesFridayEnabled
      emailStoriesDailyEnabled
      trialStoriesCount
      bookCredits
      notificationSettings {
        id
        user {
          id
          cognitoUserId
          email
          children {
            items {
              id
              name
              age
              commonNoun
              readingLevel
              avatar
              createdAt
              updatedAt
              userChildrenId
              owner
              __typename
            }
            nextToken
            __typename
          }
          activeChildId
          settings
          lastInteraction
          emailUpdatesEnabled
          emailPromotionsEnabled
          emailStoriesFridayEnabled
          emailStoriesDailyEnabled
          trialStoriesCount
          bookCredits
          notificationSettings {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            notificationsEnabled
            storyReminders {
              enabled
              frequency
              __typename
            }
            promotionsEnabled
            updatesEnabled
            createdAt
            updatedAt
            owner
            __typename
          }
          deviceTokens {
            items {
              id
              userId
              token
              platform
              endpointArn
              createdAt
              updatedAt
              userDeviceTokensId
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userNotificationSettingsId
          owner
          __typename
        }
        userId
        notificationsEnabled
        storyReminders {
          enabled
          frequency
          __typename
        }
        promotionsEnabled
        updatesEnabled
        createdAt
        updatedAt
        owner
        __typename
      }
      deviceTokens {
        items {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          token
          platform
          endpointArn
          createdAt
          updatedAt
          userDeviceTokensId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userNotificationSettingsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByCognitoUserIdQueryVariables,
  APITypes.UserByCognitoUserIdQuery
>;
export const userByEmail = /* GraphQL */ `query UserByEmail(
  $email: String!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  userByEmail(
    email: $email
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoUserId
      email
      children {
        items {
          id
          name
          age
          commonNoun
          readingLevel
          avatar
          storyPages {
            items {
              id
              pageIDs
              storyType
              storyStatus
              rating
              childStoryPagesId
              lastViewed
              expositionImageName
              title
              initiationMethod
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userChildrenId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activeChildId
      settings
      lastInteraction
      emailUpdatesEnabled
      emailPromotionsEnabled
      emailStoriesFridayEnabled
      emailStoriesDailyEnabled
      trialStoriesCount
      bookCredits
      notificationSettings {
        id
        user {
          id
          cognitoUserId
          email
          children {
            items {
              id
              name
              age
              commonNoun
              readingLevel
              avatar
              createdAt
              updatedAt
              userChildrenId
              owner
              __typename
            }
            nextToken
            __typename
          }
          activeChildId
          settings
          lastInteraction
          emailUpdatesEnabled
          emailPromotionsEnabled
          emailStoriesFridayEnabled
          emailStoriesDailyEnabled
          trialStoriesCount
          bookCredits
          notificationSettings {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            notificationsEnabled
            storyReminders {
              enabled
              frequency
              __typename
            }
            promotionsEnabled
            updatesEnabled
            createdAt
            updatedAt
            owner
            __typename
          }
          deviceTokens {
            items {
              id
              userId
              token
              platform
              endpointArn
              createdAt
              updatedAt
              userDeviceTokensId
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userNotificationSettingsId
          owner
          __typename
        }
        userId
        notificationsEnabled
        storyReminders {
          enabled
          frequency
          __typename
        }
        promotionsEnabled
        updatesEnabled
        createdAt
        updatedAt
        owner
        __typename
      }
      deviceTokens {
        items {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          token
          platform
          endpointArn
          createdAt
          updatedAt
          userDeviceTokensId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userNotificationSettingsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByEmailQueryVariables,
  APITypes.UserByEmailQuery
>;
export const getNotificationSettings = /* GraphQL */ `query GetNotificationSettings($id: ID!) {
  getNotificationSettings(id: $id) {
    id
    user {
      id
      cognitoUserId
      email
      children {
        items {
          id
          name
          age
          commonNoun
          readingLevel
          avatar
          storyPages {
            items {
              id
              pageIDs
              storyType
              storyStatus
              rating
              childStoryPagesId
              lastViewed
              expositionImageName
              title
              initiationMethod
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userChildrenId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activeChildId
      settings
      lastInteraction
      emailUpdatesEnabled
      emailPromotionsEnabled
      emailStoriesFridayEnabled
      emailStoriesDailyEnabled
      trialStoriesCount
      bookCredits
      notificationSettings {
        id
        user {
          id
          cognitoUserId
          email
          children {
            items {
              id
              name
              age
              commonNoun
              readingLevel
              avatar
              createdAt
              updatedAt
              userChildrenId
              owner
              __typename
            }
            nextToken
            __typename
          }
          activeChildId
          settings
          lastInteraction
          emailUpdatesEnabled
          emailPromotionsEnabled
          emailStoriesFridayEnabled
          emailStoriesDailyEnabled
          trialStoriesCount
          bookCredits
          notificationSettings {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            notificationsEnabled
            storyReminders {
              enabled
              frequency
              __typename
            }
            promotionsEnabled
            updatesEnabled
            createdAt
            updatedAt
            owner
            __typename
          }
          deviceTokens {
            items {
              id
              userId
              token
              platform
              endpointArn
              createdAt
              updatedAt
              userDeviceTokensId
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userNotificationSettingsId
          owner
          __typename
        }
        userId
        notificationsEnabled
        storyReminders {
          enabled
          frequency
          __typename
        }
        promotionsEnabled
        updatesEnabled
        createdAt
        updatedAt
        owner
        __typename
      }
      deviceTokens {
        items {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          token
          platform
          endpointArn
          createdAt
          updatedAt
          userDeviceTokensId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userNotificationSettingsId
      owner
      __typename
    }
    userId
    notificationsEnabled
    storyReminders {
      enabled
      frequency
      __typename
    }
    promotionsEnabled
    updatesEnabled
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationSettingsQueryVariables,
  APITypes.GetNotificationSettingsQuery
>;
export const listNotificationSettings = /* GraphQL */ `query ListNotificationSettings(
  $id: ID
  $filter: ModelNotificationSettingsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listNotificationSettings(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      user {
        id
        cognitoUserId
        email
        children {
          items {
            id
            name
            age
            commonNoun
            readingLevel
            avatar
            storyPages {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userChildrenId
            owner
            __typename
          }
          nextToken
          __typename
        }
        activeChildId
        settings
        lastInteraction
        emailUpdatesEnabled
        emailPromotionsEnabled
        emailStoriesFridayEnabled
        emailStoriesDailyEnabled
        trialStoriesCount
        bookCredits
        notificationSettings {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          notificationsEnabled
          storyReminders {
            enabled
            frequency
            __typename
          }
          promotionsEnabled
          updatesEnabled
          createdAt
          updatedAt
          owner
          __typename
        }
        deviceTokens {
          items {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            token
            platform
            endpointArn
            createdAt
            updatedAt
            userDeviceTokensId
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userNotificationSettingsId
        owner
        __typename
      }
      userId
      notificationsEnabled
      storyReminders {
        enabled
        frequency
        __typename
      }
      promotionsEnabled
      updatesEnabled
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationSettingsQueryVariables,
  APITypes.ListNotificationSettingsQuery
>;
export const notificationSettingsByUserId = /* GraphQL */ `query NotificationSettingsByUserId(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationSettingsByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user {
        id
        cognitoUserId
        email
        children {
          items {
            id
            name
            age
            commonNoun
            readingLevel
            avatar
            storyPages {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userChildrenId
            owner
            __typename
          }
          nextToken
          __typename
        }
        activeChildId
        settings
        lastInteraction
        emailUpdatesEnabled
        emailPromotionsEnabled
        emailStoriesFridayEnabled
        emailStoriesDailyEnabled
        trialStoriesCount
        bookCredits
        notificationSettings {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          notificationsEnabled
          storyReminders {
            enabled
            frequency
            __typename
          }
          promotionsEnabled
          updatesEnabled
          createdAt
          updatedAt
          owner
          __typename
        }
        deviceTokens {
          items {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            token
            platform
            endpointArn
            createdAt
            updatedAt
            userDeviceTokensId
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userNotificationSettingsId
        owner
        __typename
      }
      userId
      notificationsEnabled
      storyReminders {
        enabled
        frequency
        __typename
      }
      promotionsEnabled
      updatesEnabled
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotificationSettingsByUserIdQueryVariables,
  APITypes.NotificationSettingsByUserIdQuery
>;
export const getDeviceToken = /* GraphQL */ `query GetDeviceToken($id: ID!) {
  getDeviceToken(id: $id) {
    id
    user {
      id
      cognitoUserId
      email
      children {
        items {
          id
          name
          age
          commonNoun
          readingLevel
          avatar
          storyPages {
            items {
              id
              pageIDs
              storyType
              storyStatus
              rating
              childStoryPagesId
              lastViewed
              expositionImageName
              title
              initiationMethod
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userChildrenId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activeChildId
      settings
      lastInteraction
      emailUpdatesEnabled
      emailPromotionsEnabled
      emailStoriesFridayEnabled
      emailStoriesDailyEnabled
      trialStoriesCount
      bookCredits
      notificationSettings {
        id
        user {
          id
          cognitoUserId
          email
          children {
            items {
              id
              name
              age
              commonNoun
              readingLevel
              avatar
              createdAt
              updatedAt
              userChildrenId
              owner
              __typename
            }
            nextToken
            __typename
          }
          activeChildId
          settings
          lastInteraction
          emailUpdatesEnabled
          emailPromotionsEnabled
          emailStoriesFridayEnabled
          emailStoriesDailyEnabled
          trialStoriesCount
          bookCredits
          notificationSettings {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            notificationsEnabled
            storyReminders {
              enabled
              frequency
              __typename
            }
            promotionsEnabled
            updatesEnabled
            createdAt
            updatedAt
            owner
            __typename
          }
          deviceTokens {
            items {
              id
              userId
              token
              platform
              endpointArn
              createdAt
              updatedAt
              userDeviceTokensId
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userNotificationSettingsId
          owner
          __typename
        }
        userId
        notificationsEnabled
        storyReminders {
          enabled
          frequency
          __typename
        }
        promotionsEnabled
        updatesEnabled
        createdAt
        updatedAt
        owner
        __typename
      }
      deviceTokens {
        items {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          token
          platform
          endpointArn
          createdAt
          updatedAt
          userDeviceTokensId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userNotificationSettingsId
      owner
      __typename
    }
    userId
    token
    platform
    endpointArn
    createdAt
    updatedAt
    userDeviceTokensId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceTokenQueryVariables,
  APITypes.GetDeviceTokenQuery
>;
export const listDeviceTokens = /* GraphQL */ `query ListDeviceTokens(
  $id: ID
  $filter: ModelDeviceTokenFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listDeviceTokens(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      user {
        id
        cognitoUserId
        email
        children {
          items {
            id
            name
            age
            commonNoun
            readingLevel
            avatar
            storyPages {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userChildrenId
            owner
            __typename
          }
          nextToken
          __typename
        }
        activeChildId
        settings
        lastInteraction
        emailUpdatesEnabled
        emailPromotionsEnabled
        emailStoriesFridayEnabled
        emailStoriesDailyEnabled
        trialStoriesCount
        bookCredits
        notificationSettings {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          notificationsEnabled
          storyReminders {
            enabled
            frequency
            __typename
          }
          promotionsEnabled
          updatesEnabled
          createdAt
          updatedAt
          owner
          __typename
        }
        deviceTokens {
          items {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            token
            platform
            endpointArn
            createdAt
            updatedAt
            userDeviceTokensId
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userNotificationSettingsId
        owner
        __typename
      }
      userId
      token
      platform
      endpointArn
      createdAt
      updatedAt
      userDeviceTokensId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceTokensQueryVariables,
  APITypes.ListDeviceTokensQuery
>;
export const deviceTokensByUserId = /* GraphQL */ `query DeviceTokensByUserId(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDeviceTokenFilterInput
  $limit: Int
  $nextToken: String
) {
  deviceTokensByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user {
        id
        cognitoUserId
        email
        children {
          items {
            id
            name
            age
            commonNoun
            readingLevel
            avatar
            storyPages {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userChildrenId
            owner
            __typename
          }
          nextToken
          __typename
        }
        activeChildId
        settings
        lastInteraction
        emailUpdatesEnabled
        emailPromotionsEnabled
        emailStoriesFridayEnabled
        emailStoriesDailyEnabled
        trialStoriesCount
        bookCredits
        notificationSettings {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          notificationsEnabled
          storyReminders {
            enabled
            frequency
            __typename
          }
          promotionsEnabled
          updatesEnabled
          createdAt
          updatedAt
          owner
          __typename
        }
        deviceTokens {
          items {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            token
            platform
            endpointArn
            createdAt
            updatedAt
            userDeviceTokensId
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userNotificationSettingsId
        owner
        __typename
      }
      userId
      token
      platform
      endpointArn
      createdAt
      updatedAt
      userDeviceTokensId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DeviceTokensByUserIdQueryVariables,
  APITypes.DeviceTokensByUserIdQuery
>;
export const deviceTokenByUserIdAndToken = /* GraphQL */ `query DeviceTokenByUserIdAndToken(
  $token: String!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDeviceTokenFilterInput
  $limit: Int
  $nextToken: String
) {
  deviceTokenByUserIdAndToken(
    token: $token
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user {
        id
        cognitoUserId
        email
        children {
          items {
            id
            name
            age
            commonNoun
            readingLevel
            avatar
            storyPages {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userChildrenId
            owner
            __typename
          }
          nextToken
          __typename
        }
        activeChildId
        settings
        lastInteraction
        emailUpdatesEnabled
        emailPromotionsEnabled
        emailStoriesFridayEnabled
        emailStoriesDailyEnabled
        trialStoriesCount
        bookCredits
        notificationSettings {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          notificationsEnabled
          storyReminders {
            enabled
            frequency
            __typename
          }
          promotionsEnabled
          updatesEnabled
          createdAt
          updatedAt
          owner
          __typename
        }
        deviceTokens {
          items {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            token
            platform
            endpointArn
            createdAt
            updatedAt
            userDeviceTokensId
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userNotificationSettingsId
        owner
        __typename
      }
      userId
      token
      platform
      endpointArn
      createdAt
      updatedAt
      userDeviceTokensId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DeviceTokenByUserIdAndTokenQueryVariables,
  APITypes.DeviceTokenByUserIdAndTokenQuery
>;
export const getWaitlist = /* GraphQL */ `query GetWaitlist($cognitoUserId: String!) {
  getWaitlist(cognitoUserId: $cognitoUserId) {
    cognitoUserId
    email
    accessGranted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWaitlistQueryVariables,
  APITypes.GetWaitlistQuery
>;
export const listWaitlists = /* GraphQL */ `query ListWaitlists(
  $cognitoUserId: String
  $filter: ModelWaitlistFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listWaitlists(
    cognitoUserId: $cognitoUserId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      cognitoUserId
      email
      accessGranted
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWaitlistsQueryVariables,
  APITypes.ListWaitlistsQuery
>;
export const getGiftcard = /* GraphQL */ `query GetGiftcard($id: ID!) {
  getGiftcard(id: $id) {
    id
    giftcode
    recipientEmail
    subscriptionProduct
    storyCredits
    bookCredits
    giftingEmail
    redeemed
    redeemedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGiftcardQueryVariables,
  APITypes.GetGiftcardQuery
>;
export const listGiftcards = /* GraphQL */ `query ListGiftcards(
  $filter: ModelGiftcardFilterInput
  $limit: Int
  $nextToken: String
) {
  listGiftcards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      giftcode
      recipientEmail
      subscriptionProduct
      storyCredits
      bookCredits
      giftingEmail
      redeemed
      redeemedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGiftcardsQueryVariables,
  APITypes.ListGiftcardsQuery
>;
export const giftcardByGiftcode = /* GraphQL */ `query GiftcardByGiftcode(
  $giftcode: String!
  $sortDirection: ModelSortDirection
  $filter: ModelGiftcardFilterInput
  $limit: Int
  $nextToken: String
) {
  giftcardByGiftcode(
    giftcode: $giftcode
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      giftcode
      recipientEmail
      subscriptionProduct
      storyCredits
      bookCredits
      giftingEmail
      redeemed
      redeemedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GiftcardByGiftcodeQueryVariables,
  APITypes.GiftcardByGiftcodeQuery
>;
export const giftcardsByRecipientEmail = /* GraphQL */ `query GiftcardsByRecipientEmail(
  $recipientEmail: String!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGiftcardFilterInput
  $limit: Int
  $nextToken: String
) {
  giftcardsByRecipientEmail(
    recipientEmail: $recipientEmail
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      giftcode
      recipientEmail
      subscriptionProduct
      storyCredits
      bookCredits
      giftingEmail
      redeemed
      redeemedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GiftcardsByRecipientEmailQueryVariables,
  APITypes.GiftcardsByRecipientEmailQuery
>;
export const getMetadata = /* GraphQL */ `query GetMetadata($id: ID!) {
  getMetadata(id: $id) {
    id
    isUnderMaintenance
    startingTrialStoriesCount
    waitlistEnabled
    minimumAppVersion
    promotionString
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMetadataQueryVariables,
  APITypes.GetMetadataQuery
>;
export const listMetadata = /* GraphQL */ `query ListMetadata(
  $filter: ModelMetadataFilterInput
  $limit: Int
  $nextToken: String
) {
  listMetadata(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      isUnderMaintenance
      startingTrialStoriesCount
      waitlistEnabled
      minimumAppVersion
      promotionString
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMetadataQueryVariables,
  APITypes.ListMetadataQuery
>;
export const getUserPayment = /* GraphQL */ `query GetUserPayment($stripeSubscriptionId: String!) {
  getUserPayment(stripeSubscriptionId: $stripeSubscriptionId) {
    stripeSubscriptionId
    cognitoUserId
    stripeCustomerId
    currentTransactionId
    status
    currentPeriodStart
    currentPeriodEnd
    cancelAtPeriodEnd
    price {
      id
      amount
      interval
      interval_count
      currency
      __typename
    }
    product {
      id
      name
      description
      __typename
    }
    provider
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserPaymentQueryVariables,
  APITypes.GetUserPaymentQuery
>;
export const listUserPayments = /* GraphQL */ `query ListUserPayments(
  $stripeSubscriptionId: String
  $filter: ModelUserPaymentFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserPayments(
    stripeSubscriptionId: $stripeSubscriptionId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      stripeSubscriptionId
      cognitoUserId
      stripeCustomerId
      currentTransactionId
      status
      currentPeriodStart
      currentPeriodEnd
      cancelAtPeriodEnd
      price {
        id
        amount
        interval
        interval_count
        currency
        __typename
      }
      product {
        id
        name
        description
        __typename
      }
      provider
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserPaymentsQueryVariables,
  APITypes.ListUserPaymentsQuery
>;
export const userPaymentByCognitoUserId = /* GraphQL */ `query UserPaymentByCognitoUserId(
  $cognitoUserId: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserPaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  userPaymentByCognitoUserId(
    cognitoUserId: $cognitoUserId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      stripeSubscriptionId
      cognitoUserId
      stripeCustomerId
      currentTransactionId
      status
      currentPeriodStart
      currentPeriodEnd
      cancelAtPeriodEnd
      price {
        id
        amount
        interval
        interval_count
        currency
        __typename
      }
      product {
        id
        name
        description
        __typename
      }
      provider
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserPaymentByCognitoUserIdQueryVariables,
  APITypes.UserPaymentByCognitoUserIdQuery
>;
export const getTransactions = /* GraphQL */ `query GetTransactions($id: ID!) {
  getTransactions(id: $id) {
    id
    cognitoUserId
    transactionId
    originalTransactionId
    product {
      id
      name
      description
      __typename
    }
    price {
      id
      amount
      interval
      interval_count
      currency
      __typename
    }
    receipt
    provider
    discounts
    transactionDate
    errorCode
    errorMessage
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTransactionsQueryVariables,
  APITypes.GetTransactionsQuery
>;
export const listTransactions = /* GraphQL */ `query ListTransactions(
  $filter: ModelTransactionsFilterInput
  $limit: Int
  $nextToken: String
) {
  listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoUserId
      transactionId
      originalTransactionId
      product {
        id
        name
        description
        __typename
      }
      price {
        id
        amount
        interval
        interval_count
        currency
        __typename
      }
      receipt
      provider
      discounts
      transactionDate
      errorCode
      errorMessage
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTransactionsQueryVariables,
  APITypes.ListTransactionsQuery
>;
export const transactionsByCognitoUserId = /* GraphQL */ `query TransactionsByCognitoUserId(
  $cognitoUserId: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTransactionsFilterInput
  $limit: Int
  $nextToken: String
) {
  transactionsByCognitoUserId(
    cognitoUserId: $cognitoUserId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoUserId
      transactionId
      originalTransactionId
      product {
        id
        name
        description
        __typename
      }
      price {
        id
        amount
        interval
        interval_count
        currency
        __typename
      }
      receipt
      provider
      discounts
      transactionDate
      errorCode
      errorMessage
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsByCognitoUserIdQueryVariables,
  APITypes.TransactionsByCognitoUserIdQuery
>;
export const transactionsByTransactionID = /* GraphQL */ `query TransactionsByTransactionID(
  $transactionId: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTransactionsFilterInput
  $limit: Int
  $nextToken: String
) {
  transactionsByTransactionID(
    transactionId: $transactionId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoUserId
      transactionId
      originalTransactionId
      product {
        id
        name
        description
        __typename
      }
      price {
        id
        amount
        interval
        interval_count
        currency
        __typename
      }
      receipt
      provider
      discounts
      transactionDate
      errorCode
      errorMessage
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsByTransactionIDQueryVariables,
  APITypes.TransactionsByTransactionIDQuery
>;
export const transactionsByOriginalTransactionId = /* GraphQL */ `query TransactionsByOriginalTransactionId(
  $originalTransactionId: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTransactionsFilterInput
  $limit: Int
  $nextToken: String
) {
  transactionsByOriginalTransactionId(
    originalTransactionId: $originalTransactionId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoUserId
      transactionId
      originalTransactionId
      product {
        id
        name
        description
        __typename
      }
      price {
        id
        amount
        interval
        interval_count
        currency
        __typename
      }
      receipt
      provider
      discounts
      transactionDate
      errorCode
      errorMessage
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsByOriginalTransactionIdQueryVariables,
  APITypes.TransactionsByOriginalTransactionIdQuery
>;
export const getChild = /* GraphQL */ `query GetChild($id: ID!) {
  getChild(id: $id) {
    id
    name
    age
    commonNoun
    readingLevel
    avatar
    storyPages {
      items {
        id
        pageIDs
        storyType
        storyStatus
        rating
        childStoryPagesId
        lastViewed
        expositionImageName
        title
        initiationMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    userChildrenId
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetChildQueryVariables, APITypes.GetChildQuery>;
export const listChildren = /* GraphQL */ `query ListChildren(
  $filter: ModelChildFilterInput
  $limit: Int
  $nextToken: String
) {
  listChildren(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      age
      commonNoun
      readingLevel
      avatar
      storyPages {
        items {
          id
          pageIDs
          storyType
          storyStatus
          rating
          childStoryPagesId
          lastViewed
          expositionImageName
          title
          initiationMethod
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userChildrenId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChildrenQueryVariables,
  APITypes.ListChildrenQuery
>;
export const getPage = /* GraphQL */ `query GetPage($id: ID!) {
  getPage(id: $id) {
    id
    cognitoUserId
    pageText
    pageOptions
    imageName
    minorImageName
    characterImageNames
    primaryImage
    protagonist
    storyType
    location
    readingLevel
    preceededBy
    followedBy
    followedByEnding
    isExposition
    isEnding
    isAnonymized
    status
    isAccepted
    createdAt
    updatedAt
    title
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPageQueryVariables, APITypes.GetPageQuery>;
export const listPages = /* GraphQL */ `query ListPages(
  $filter: ModelPageFilterInput
  $limit: Int
  $nextToken: String
) {
  listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoUserId
      pageText
      pageOptions
      imageName
      minorImageName
      characterImageNames
      primaryImage
      protagonist
      storyType
      location
      readingLevel
      preceededBy
      followedBy
      followedByEnding
      isExposition
      isEnding
      isAnonymized
      status
      isAccepted
      createdAt
      updatedAt
      title
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListPagesQueryVariables, APITypes.ListPagesQuery>;
export const getSharedPage = /* GraphQL */ `query GetSharedPage($id: ID!) {
  getSharedPage(id: $id) {
    id
    pageText
    pageOptions
    imageName
    protagonist
    storyType
    location
    readingLevel
    preceededBy
    followedBy
    followedByEnding
    status
    isExposition
    isEnding
    isAnonymized
    createdAt
    updatedAt
    title
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSharedPageQueryVariables,
  APITypes.GetSharedPageQuery
>;
export const listSharedPages = /* GraphQL */ `query ListSharedPages(
  $filter: ModelSharedPageFilterInput
  $limit: Int
  $nextToken: String
) {
  listSharedPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      pageText
      pageOptions
      imageName
      protagonist
      storyType
      location
      readingLevel
      preceededBy
      followedBy
      followedByEnding
      status
      isExposition
      isEnding
      isAnonymized
      createdAt
      updatedAt
      title
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSharedPagesQueryVariables,
  APITypes.ListSharedPagesQuery
>;
export const getStoryPages = /* GraphQL */ `query GetStoryPages($id: ID!) {
  getStoryPages(id: $id) {
    id
    pageIDs
    storyType
    storyStatus
    rating
    childStoryPagesId
    lastViewed
    expositionImageName
    title
    initiationMethod
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStoryPagesQueryVariables,
  APITypes.GetStoryPagesQuery
>;
export const listStoryPages = /* GraphQL */ `query ListStoryPages(
  $filter: ModelStoryPagesFilterInput
  $limit: Int
  $nextToken: String
) {
  listStoryPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      pageIDs
      storyType
      storyStatus
      rating
      childStoryPagesId
      lastViewed
      expositionImageName
      title
      initiationMethod
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStoryPagesQueryVariables,
  APITypes.ListStoryPagesQuery
>;
export const storyPagesByChildId = /* GraphQL */ `query StoryPagesByChildId(
  $childStoryPagesId: ID!
  $lastViewed: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStoryPagesFilterInput
  $limit: Int
  $nextToken: String
) {
  storyPagesByChildId(
    childStoryPagesId: $childStoryPagesId
    lastViewed: $lastViewed
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      pageIDs
      storyType
      storyStatus
      rating
      childStoryPagesId
      lastViewed
      expositionImageName
      title
      initiationMethod
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.StoryPagesByChildIdQueryVariables,
  APITypes.StoryPagesByChildIdQuery
>;
export const getDailyStory = /* GraphQL */ `query GetDailyStory($id: ID!) {
  getDailyStory(id: $id) {
    id
    expositionPageID
    storyType
    pageDepth
    status
    createdAt
    updatedAt
    approvedAt
    storyTitle
    displayStart
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDailyStoryQueryVariables,
  APITypes.GetDailyStoryQuery
>;
export const listDailyStories = /* GraphQL */ `query ListDailyStories(
  $filter: ModelDailyStoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listDailyStories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      expositionPageID
      storyType
      pageDepth
      status
      createdAt
      updatedAt
      approvedAt
      storyTitle
      displayStart
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDailyStoriesQueryVariables,
  APITypes.ListDailyStoriesQuery
>;
export const dailyStoryByDisplayStart = /* GraphQL */ `query DailyStoryByDisplayStart(
  $displayStart: String!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDailyStoryFilterInput
  $limit: Int
  $nextToken: String
) {
  dailyStoryByDisplayStart(
    displayStart: $displayStart
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      expositionPageID
      storyType
      pageDepth
      status
      createdAt
      updatedAt
      approvedAt
      storyTitle
      displayStart
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DailyStoryByDisplayStartQueryVariables,
  APITypes.DailyStoryByDisplayStartQuery
>;
export const getImageSubjectsLog = /* GraphQL */ `query GetImageSubjectsLog($id: ID!) {
  getImageSubjectsLog(id: $id) {
    id
    subjects
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetImageSubjectsLogQueryVariables,
  APITypes.GetImageSubjectsLogQuery
>;
export const listImageSubjectsLogs = /* GraphQL */ `query ListImageSubjectsLogs(
  $filter: ModelImageSubjectsLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listImageSubjectsLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      subjects
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListImageSubjectsLogsQueryVariables,
  APITypes.ListImageSubjectsLogsQuery
>;
export const getJobStatus = /* GraphQL */ `query GetJobStatus($id: ID!) {
  getJobStatus(id: $id) {
    id
    jobStatus
    jobResult {
      success
      pageIDs
      __typename
    }
    createdAt
    updatedAt
    cognitoUserId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetJobStatusQueryVariables,
  APITypes.GetJobStatusQuery
>;
export const listJobStatuses = /* GraphQL */ `query ListJobStatuses(
  $filter: ModelJobStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listJobStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      jobStatus
      jobResult {
        success
        pageIDs
        __typename
      }
      createdAt
      updatedAt
      cognitoUserId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListJobStatusesQueryVariables,
  APITypes.ListJobStatusesQuery
>;
export const getImage = /* GraphQL */ `query GetImage($id: ID!) {
  getImage(id: $id) {
    id
    name
    type
    genres {
      items {
        id
        imageId
        genreId
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        genre {
          id
          name
          images {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    expressions {
      items {
        id
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        name
        createdAt
        updatedAt
        imageExpressionsId
        __typename
      }
      nextToken
      __typename
    }
    traits
    createdAt
    updatedAt
    categories {
      items {
        id
        imageId
        imageCategoryId
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        imageCategory {
          id
          category
          images {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    characterSet
    characterSetName
    location
    __typename
  }
}
` as GeneratedQuery<APITypes.GetImageQueryVariables, APITypes.GetImageQuery>;
export const listImages = /* GraphQL */ `query ListImages(
  $filter: ModelImageFilterInput
  $limit: Int
  $nextToken: String
) {
  listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      type
      genres {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      expressions {
        items {
          id
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          name
          createdAt
          updatedAt
          imageExpressionsId
          __typename
        }
        nextToken
        __typename
      }
      traits
      createdAt
      updatedAt
      categories {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      characterSet
      characterSetName
      location
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListImagesQueryVariables,
  APITypes.ListImagesQuery
>;
export const imageByName = /* GraphQL */ `query ImageByName(
  $name: String!
  $sortDirection: ModelSortDirection
  $filter: ModelImageFilterInput
  $limit: Int
  $nextToken: String
) {
  imageByName(
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      type
      genres {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      expressions {
        items {
          id
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          name
          createdAt
          updatedAt
          imageExpressionsId
          __typename
        }
        nextToken
        __typename
      }
      traits
      createdAt
      updatedAt
      categories {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      characterSet
      characterSetName
      location
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ImageByNameQueryVariables,
  APITypes.ImageByNameQuery
>;
export const imagesByType = /* GraphQL */ `query ImagesByType(
  $type: ImageType!
  $sortDirection: ModelSortDirection
  $filter: ModelImageFilterInput
  $limit: Int
  $nextToken: String
) {
  imagesByType(
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      type
      genres {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      expressions {
        items {
          id
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          name
          createdAt
          updatedAt
          imageExpressionsId
          __typename
        }
        nextToken
        __typename
      }
      traits
      createdAt
      updatedAt
      categories {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      characterSet
      characterSetName
      location
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ImagesByTypeQueryVariables,
  APITypes.ImagesByTypeQuery
>;
export const imagesByCharacterSetAndName = /* GraphQL */ `query ImagesByCharacterSetAndName(
  $characterSetName: String!
  $sortDirection: ModelSortDirection
  $filter: ModelImageFilterInput
  $limit: Int
  $nextToken: String
) {
  imagesByCharacterSetAndName(
    characterSetName: $characterSetName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      type
      genres {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      expressions {
        items {
          id
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          name
          createdAt
          updatedAt
          imageExpressionsId
          __typename
        }
        nextToken
        __typename
      }
      traits
      createdAt
      updatedAt
      categories {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      characterSet
      characterSetName
      location
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ImagesByCharacterSetAndNameQueryVariables,
  APITypes.ImagesByCharacterSetAndNameQuery
>;
export const getExpression = /* GraphQL */ `query GetExpression($id: ID!) {
  getExpression(id: $id) {
    id
    image {
      id
      name
      type
      genres {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      expressions {
        items {
          id
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          name
          createdAt
          updatedAt
          imageExpressionsId
          __typename
        }
        nextToken
        __typename
      }
      traits
      createdAt
      updatedAt
      categories {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      characterSet
      characterSetName
      location
      __typename
    }
    name
    createdAt
    updatedAt
    imageExpressionsId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExpressionQueryVariables,
  APITypes.GetExpressionQuery
>;
export const listExpressions = /* GraphQL */ `query ListExpressions(
  $filter: ModelExpressionFilterInput
  $limit: Int
  $nextToken: String
) {
  listExpressions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      image {
        id
        name
        type
        genres {
          items {
            id
            imageId
            genreId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            genre {
              id
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        expressions {
          items {
            id
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            name
            createdAt
            updatedAt
            imageExpressionsId
            __typename
          }
          nextToken
          __typename
        }
        traits
        createdAt
        updatedAt
        categories {
          items {
            id
            imageId
            imageCategoryId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            imageCategory {
              id
              category
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        characterSet
        characterSetName
        location
        __typename
      }
      name
      createdAt
      updatedAt
      imageExpressionsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListExpressionsQueryVariables,
  APITypes.ListExpressionsQuery
>;
export const getGenre = /* GraphQL */ `query GetGenre($id: ID!) {
  getGenre(id: $id) {
    id
    name
    images {
      items {
        id
        imageId
        genreId
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        genre {
          id
          name
          images {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetGenreQueryVariables, APITypes.GetGenreQuery>;
export const listGenres = /* GraphQL */ `query ListGenres(
  $filter: ModelGenreFilterInput
  $limit: Int
  $nextToken: String
) {
  listGenres(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      images {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGenresQueryVariables,
  APITypes.ListGenresQuery
>;
export const genreByName = /* GraphQL */ `query GenreByName(
  $name: String!
  $sortDirection: ModelSortDirection
  $filter: ModelGenreFilterInput
  $limit: Int
  $nextToken: String
) {
  genreByName(
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      images {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GenreByNameQueryVariables,
  APITypes.GenreByNameQuery
>;
export const getImageCategory = /* GraphQL */ `query GetImageCategory($id: ID!) {
  getImageCategory(id: $id) {
    id
    category
    images {
      items {
        id
        imageId
        imageCategoryId
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        imageCategory {
          id
          category
          images {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetImageCategoryQueryVariables,
  APITypes.GetImageCategoryQuery
>;
export const listImageCategories = /* GraphQL */ `query ListImageCategories(
  $filter: ModelImageCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listImageCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      category
      images {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListImageCategoriesQueryVariables,
  APITypes.ListImageCategoriesQuery
>;
export const getBookOrder = /* GraphQL */ `query GetBookOrder($id: ID!) {
  getBookOrder(id: $id) {
    id
    checkoutId
    storyId
    title
    bookplateStyle
    bookplateName
    byline
    dedication
    lineItems {
      description
      quantity
      amount
      priceId
      __typename
    }
    shippingName
    shippingAddressLine1
    shippingAddressLine2
    shippingCity
    shippingState
    shippingPostalCode
    shippingCountry
    status
    statusTimeline
    customerEmail
    totalAmount
    currency
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookOrderQueryVariables,
  APITypes.GetBookOrderQuery
>;
export const listBookOrders = /* GraphQL */ `query ListBookOrders(
  $filter: ModelBookOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listBookOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      checkoutId
      storyId
      title
      bookplateStyle
      bookplateName
      byline
      dedication
      lineItems {
        description
        quantity
        amount
        priceId
        __typename
      }
      shippingName
      shippingAddressLine1
      shippingAddressLine2
      shippingCity
      shippingState
      shippingPostalCode
      shippingCountry
      status
      statusTimeline
      customerEmail
      totalAmount
      currency
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookOrdersQueryVariables,
  APITypes.ListBookOrdersQuery
>;
export const bookOrdersByCheckoutId = /* GraphQL */ `query BookOrdersByCheckoutId(
  $checkoutId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelBookOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  bookOrdersByCheckoutId(
    checkoutId: $checkoutId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      checkoutId
      storyId
      title
      bookplateStyle
      bookplateName
      byline
      dedication
      lineItems {
        description
        quantity
        amount
        priceId
        __typename
      }
      shippingName
      shippingAddressLine1
      shippingAddressLine2
      shippingCity
      shippingState
      shippingPostalCode
      shippingCountry
      status
      statusTimeline
      customerEmail
      totalAmount
      currency
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookOrdersByCheckoutIdQueryVariables,
  APITypes.BookOrdersByCheckoutIdQuery
>;
export const getCheckoutMetadata = /* GraphQL */ `query GetCheckoutMetadata($id: ID!) {
  getCheckoutMetadata(id: $id) {
    id
    stripeSessionId
    customerEmail
    purchaseType
    lineItems {
      description
      quantity
      amount
      priceId
      __typename
    }
    totalAmount
    currency
    discountTotal
    discounts {
      amount
      promoCode
      couponId
      couponName
      __typename
    }
    recipientName
    recipientEmail
    gifterName
    gifterEmail
    giftMessage
    deliveryDate
    giftCardStyle
    bookOrderId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCheckoutMetadataQueryVariables,
  APITypes.GetCheckoutMetadataQuery
>;
export const listCheckoutMetadata = /* GraphQL */ `query ListCheckoutMetadata(
  $filter: ModelCheckoutMetadataFilterInput
  $limit: Int
  $nextToken: String
) {
  listCheckoutMetadata(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      stripeSessionId
      customerEmail
      purchaseType
      lineItems {
        description
        quantity
        amount
        priceId
        __typename
      }
      totalAmount
      currency
      discountTotal
      discounts {
        amount
        promoCode
        couponId
        couponName
        __typename
      }
      recipientName
      recipientEmail
      gifterName
      gifterEmail
      giftMessage
      deliveryDate
      giftCardStyle
      bookOrderId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCheckoutMetadataQueryVariables,
  APITypes.ListCheckoutMetadataQuery
>;
export const checkoutMetadataByStripeSessionId = /* GraphQL */ `query CheckoutMetadataByStripeSessionId(
  $stripeSessionId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelCheckoutMetadataFilterInput
  $limit: Int
  $nextToken: String
) {
  checkoutMetadataByStripeSessionId(
    stripeSessionId: $stripeSessionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      stripeSessionId
      customerEmail
      purchaseType
      lineItems {
        description
        quantity
        amount
        priceId
        __typename
      }
      totalAmount
      currency
      discountTotal
      discounts {
        amount
        promoCode
        couponId
        couponName
        __typename
      }
      recipientName
      recipientEmail
      gifterName
      gifterEmail
      giftMessage
      deliveryDate
      giftCardStyle
      bookOrderId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CheckoutMetadataByStripeSessionIdQueryVariables,
  APITypes.CheckoutMetadataByStripeSessionIdQuery
>;
export const getImageGenreRelation = /* GraphQL */ `query GetImageGenreRelation($id: ID!) {
  getImageGenreRelation(id: $id) {
    id
    imageId
    genreId
    image {
      id
      name
      type
      genres {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      expressions {
        items {
          id
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          name
          createdAt
          updatedAt
          imageExpressionsId
          __typename
        }
        nextToken
        __typename
      }
      traits
      createdAt
      updatedAt
      categories {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      characterSet
      characterSetName
      location
      __typename
    }
    genre {
      id
      name
      images {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetImageGenreRelationQueryVariables,
  APITypes.GetImageGenreRelationQuery
>;
export const listImageGenreRelations = /* GraphQL */ `query ListImageGenreRelations(
  $filter: ModelImageGenreRelationFilterInput
  $limit: Int
  $nextToken: String
) {
  listImageGenreRelations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      imageId
      genreId
      image {
        id
        name
        type
        genres {
          items {
            id
            imageId
            genreId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            genre {
              id
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        expressions {
          items {
            id
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            name
            createdAt
            updatedAt
            imageExpressionsId
            __typename
          }
          nextToken
          __typename
        }
        traits
        createdAt
        updatedAt
        categories {
          items {
            id
            imageId
            imageCategoryId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            imageCategory {
              id
              category
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        characterSet
        characterSetName
        location
        __typename
      }
      genre {
        id
        name
        images {
          items {
            id
            imageId
            genreId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            genre {
              id
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListImageGenreRelationsQueryVariables,
  APITypes.ListImageGenreRelationsQuery
>;
export const imageGenreRelationsByImageId = /* GraphQL */ `query ImageGenreRelationsByImageId(
  $imageId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelImageGenreRelationFilterInput
  $limit: Int
  $nextToken: String
) {
  imageGenreRelationsByImageId(
    imageId: $imageId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      imageId
      genreId
      image {
        id
        name
        type
        genres {
          items {
            id
            imageId
            genreId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            genre {
              id
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        expressions {
          items {
            id
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            name
            createdAt
            updatedAt
            imageExpressionsId
            __typename
          }
          nextToken
          __typename
        }
        traits
        createdAt
        updatedAt
        categories {
          items {
            id
            imageId
            imageCategoryId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            imageCategory {
              id
              category
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        characterSet
        characterSetName
        location
        __typename
      }
      genre {
        id
        name
        images {
          items {
            id
            imageId
            genreId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            genre {
              id
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ImageGenreRelationsByImageIdQueryVariables,
  APITypes.ImageGenreRelationsByImageIdQuery
>;
export const imageGenreRelationsByGenreId = /* GraphQL */ `query ImageGenreRelationsByGenreId(
  $genreId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelImageGenreRelationFilterInput
  $limit: Int
  $nextToken: String
) {
  imageGenreRelationsByGenreId(
    genreId: $genreId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      imageId
      genreId
      image {
        id
        name
        type
        genres {
          items {
            id
            imageId
            genreId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            genre {
              id
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        expressions {
          items {
            id
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            name
            createdAt
            updatedAt
            imageExpressionsId
            __typename
          }
          nextToken
          __typename
        }
        traits
        createdAt
        updatedAt
        categories {
          items {
            id
            imageId
            imageCategoryId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            imageCategory {
              id
              category
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        characterSet
        characterSetName
        location
        __typename
      }
      genre {
        id
        name
        images {
          items {
            id
            imageId
            genreId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            genre {
              id
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ImageGenreRelationsByGenreIdQueryVariables,
  APITypes.ImageGenreRelationsByGenreIdQuery
>;
export const getImageCategoryRelation = /* GraphQL */ `query GetImageCategoryRelation($id: ID!) {
  getImageCategoryRelation(id: $id) {
    id
    imageId
    imageCategoryId
    image {
      id
      name
      type
      genres {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      expressions {
        items {
          id
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          name
          createdAt
          updatedAt
          imageExpressionsId
          __typename
        }
        nextToken
        __typename
      }
      traits
      createdAt
      updatedAt
      categories {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      characterSet
      characterSetName
      location
      __typename
    }
    imageCategory {
      id
      category
      images {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetImageCategoryRelationQueryVariables,
  APITypes.GetImageCategoryRelationQuery
>;
export const listImageCategoryRelations = /* GraphQL */ `query ListImageCategoryRelations(
  $filter: ModelImageCategoryRelationFilterInput
  $limit: Int
  $nextToken: String
) {
  listImageCategoryRelations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      imageId
      imageCategoryId
      image {
        id
        name
        type
        genres {
          items {
            id
            imageId
            genreId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            genre {
              id
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        expressions {
          items {
            id
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            name
            createdAt
            updatedAt
            imageExpressionsId
            __typename
          }
          nextToken
          __typename
        }
        traits
        createdAt
        updatedAt
        categories {
          items {
            id
            imageId
            imageCategoryId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            imageCategory {
              id
              category
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        characterSet
        characterSetName
        location
        __typename
      }
      imageCategory {
        id
        category
        images {
          items {
            id
            imageId
            imageCategoryId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            imageCategory {
              id
              category
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListImageCategoryRelationsQueryVariables,
  APITypes.ListImageCategoryRelationsQuery
>;
export const imageCategoryRelationsByImageId = /* GraphQL */ `query ImageCategoryRelationsByImageId(
  $imageId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelImageCategoryRelationFilterInput
  $limit: Int
  $nextToken: String
) {
  imageCategoryRelationsByImageId(
    imageId: $imageId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      imageId
      imageCategoryId
      image {
        id
        name
        type
        genres {
          items {
            id
            imageId
            genreId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            genre {
              id
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        expressions {
          items {
            id
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            name
            createdAt
            updatedAt
            imageExpressionsId
            __typename
          }
          nextToken
          __typename
        }
        traits
        createdAt
        updatedAt
        categories {
          items {
            id
            imageId
            imageCategoryId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            imageCategory {
              id
              category
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        characterSet
        characterSetName
        location
        __typename
      }
      imageCategory {
        id
        category
        images {
          items {
            id
            imageId
            imageCategoryId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            imageCategory {
              id
              category
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ImageCategoryRelationsByImageIdQueryVariables,
  APITypes.ImageCategoryRelationsByImageIdQuery
>;
export const imageCategoryRelationsByImageCategoryId = /* GraphQL */ `query ImageCategoryRelationsByImageCategoryId(
  $imageCategoryId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelImageCategoryRelationFilterInput
  $limit: Int
  $nextToken: String
) {
  imageCategoryRelationsByImageCategoryId(
    imageCategoryId: $imageCategoryId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      imageId
      imageCategoryId
      image {
        id
        name
        type
        genres {
          items {
            id
            imageId
            genreId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            genre {
              id
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        expressions {
          items {
            id
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            name
            createdAt
            updatedAt
            imageExpressionsId
            __typename
          }
          nextToken
          __typename
        }
        traits
        createdAt
        updatedAt
        categories {
          items {
            id
            imageId
            imageCategoryId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            imageCategory {
              id
              category
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        characterSet
        characterSetName
        location
        __typename
      }
      imageCategory {
        id
        category
        images {
          items {
            id
            imageId
            imageCategoryId
            image {
              id
              name
              type
              traits
              createdAt
              updatedAt
              characterSet
              characterSetName
              location
              __typename
            }
            imageCategory {
              id
              category
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ImageCategoryRelationsByImageCategoryIdQueryVariables,
  APITypes.ImageCategoryRelationsByImageCategoryIdQuery
>;
