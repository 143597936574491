import colors from 'src/colors';

export function HowItWorks() {
  const steps = [
    {
      icon: '🎁',
      title: '1. Buy a Package',
      description: 'For yourself or gift it to a loved one. Packages start at $69. You can also buy books separately for $49.99.',
    },
    {
      icon: '📱',
      title: '2. Create Stories in the App',
      description: 'Your child is the hero, and they choose what happens next. Choose from 100+ different story locations.',
    },
    {
      icon: '📚',
      title: '3. Print a Favorite',
      description: 'For that extra special story, turn it into a custom illustrated and personalized hardcover book',
    },
  ];

  function getStepColor(title: string) {
    if (title.includes('1')) return colors.primary[200];
    if (title.includes('2')) return colors.secondary[200];
    return colors.accent1[200];
  }

  return (
    <div className="py-12">
      <h2 className="text-3xl sm:text-4xl font-bold text-center mb-12">
        How It Works
      </h2>
      <div className="grid md:grid-cols-3 gap-8 md:gap-12">
        {steps.map((step) => (
          <div
            key={step.title}
            className="flex flex-col items-center text-center space-y-4"
          >
            <div
              className="text-4xl sm:text-5xl mb-2 flex items-center justify-center w-24 h-24 rounded-full"
              style={{
                backgroundColor: getStepColor(step.title),
              }}
            >
              {step.icon}
            </div>
            <div className="relative">
              <div className="text-xl sm:text-2xl font-bold mb-2">
                {step.title}
              </div>
            </div>
            <p className="text-gray-600">
              {step.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HowItWorks;
