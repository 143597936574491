/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NumberedPage from '@lib/types/NumberedPage';
import { SharedPage, StoryPages, UpdateStoryPagesInput } from 'src/graphql/API';
import putStoryPages from '@lib/api/putStoryPages';
import getStoryPages from '@lib/api/getStoryPages';
import StoryStatus from '@lib/types/StoryStatus.enum';
import { getNextSharedPage } from '@features/DailyStory/api/api';
import { getDailyStoryExposition } from '@features/DailyStory/lib/getDailyStoryExposition';
import parseDailyStory from '@features/DailyStory/lib/daily-story-parser';
import { getPageImage } from '@lib/imageUtils';
import { constructStoryPagesWithNextPage } from '@features/Story/lib/utils';
import { isDefined } from '@lib/utils';
import getSharedPageById from '@features/DailyStory/api/getSharedPageById';
import PreviousRenderedImage from '@lib/types/PreviousRenderedImage';
import { RootState } from './store';

interface DailyStoryState {
  lastPageNumber?: number;
  storyType?: string;
  currentPage?: NumberedPage;
  storyPages?: StoryPages;
  loading: boolean;
  error: Record<string, string | undefined>;
  previousRenderedImage?: PreviousRenderedImage
}

const initialState: DailyStoryState = {
  currentPage: undefined,
  storyPages: undefined,
  loading: false,
  error: {},
  lastPageNumber: undefined,
  storyType: undefined,
  previousRenderedImage: undefined,
};

export const dailyStorySlice = createSlice({
  name: 'dailyStory',
  initialState,
  reducers: {
    setStoryType: (state, action: PayloadAction<string>) => {
      state.storyType = action.payload;
    },
    setLastPageNumber: (state, action: PayloadAction<number>) => {
      state.lastPageNumber = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetDailyStoryState: (state) => {
      (Object.keys(state) as [keyof DailyStoryState]).forEach((key) => {
        (state as any)[key] = initialState[key];
      });
    },
    setPreviousRenderedImage: (state) => {
      // If there's a new image, set that as the latest previously rendered image.
      if (state.currentPage?.pageImage) {
        state.previousRenderedImage = {
          isExposition: state.currentPage.pageNo === 1,
          name: state.currentPage.pageImage,
        };
        return;
      }
      // if the current page does not have its own image, use the one that was previously rendered
      if (state.previousRenderedImage) return;
      // If no image was previously rendered, use the exposition image.
      if (state.storyPages?.expositionImageName) {
        state.previousRenderedImage = {
          isExposition: true,
          name: state.storyPages.expositionImageName,
        };
        return;
      }

      state.previousRenderedImage = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExistingPage.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchExistingPage.fulfilled, (state, action) => {
        state.loading = false;
        state.currentPage = action.payload;
      })
      .addCase(fetchExistingPage.rejected, (state, action) => {
        state.loading = false;
        state.error.fetchExistingPage = action.error.message;
      })
      .addCase(fetchNewPage.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNewPage.fulfilled, (state, action) => {
        state.loading = false;
        const newPage = action.payload;
        state.currentPage = newPage;
      })
      .addCase(fetchNewPage.rejected, (state, action) => {
        state.loading = false;
        state.error.fetchNewPage = action.error.message;
      })
      .addCase(updateStoryPages.fulfilled, (state, action) => {
        state.storyPages = action.payload;
        if (action.payload.storyStatus === StoryStatus.ENDED) {
          state.lastPageNumber = state.currentPage?.pageNo;
        }
        if (state.currentPage?.pageNo === 1) {
          state.currentPage!.pageImage = action.payload.expositionImageName || undefined;
          state.currentPage!.pageData.title = action.payload.title || undefined;
        }
        state.loading = false;
      })
      .addCase(updateStoryPages.rejected, (state, action) => {
        state.loading = false;
        state.error.updateStoryPages = action.error.message;
      })
      .addCase(fetchStoryPages.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStoryPages.fulfilled, (state, action) => {
        state.storyPages = action.payload;
        if (action.payload.storyType !== 'dailyAdventure') {
          console.error(`${action.payload.id} is not a daily story`);
          return;
        }
        if (action.payload.storyStatus === StoryStatus.ENDED) {
          state.lastPageNumber = action.payload.pageIDs?.length || 0;
        }
      })
      .addCase(fetchStoryPages.rejected, (state, action) => {
        state.error.fetchStoryPages = action.error.message;
      });
  },
});

export const {
  setLastPageNumber,
  setStoryType,
  resetDailyStoryState,
  setPreviousRenderedImage,
} = dailyStorySlice.actions;

export const selectIsNextPageLast = ({ dailyStory }: RootState) => (
  dailyStory.currentPage && dailyStory.lastPageNumber
    ? dailyStory.currentPage.pageNo === dailyStory.lastPageNumber - 1
    : false);
export const selectIsLastPage = ({ dailyStory }:RootState) => (
  dailyStory.currentPage ? dailyStory.currentPage.pageNo === dailyStory.lastPageNumber : false
);
export const selectIsFirstPage = ({ dailyStory }:RootState) => (
  dailyStory.currentPage ? dailyStory.currentPage.pageNo <= 1 : false
);
export const selectLastPageNumber = ({ dailyStory }: RootState) => dailyStory.lastPageNumber;
export const selectPreviousRenderedImage = ({ dailyStory }: RootState) => dailyStory.previousRenderedImage;
export const selectCurrentPage = ({ dailyStory }: RootState) => dailyStory.currentPage;
export const selectCurrentPageNumber = ({ dailyStory }: RootState) => dailyStory.currentPage?.pageNo;
export const selectStoryType = (state: RootState) => state.dailyStory.storyType;
export const selectStoryPages = ({ dailyStory }: RootState) => dailyStory.storyPages;
export const selectLoading = ({ dailyStory }: RootState) => dailyStory.loading;

export const selectIsReadOnly = ({ dailyStory }: RootState) => {
  const { storyPages, currentPage } = dailyStory;
  if (storyPages?.rating) { // implies that the story has ended AND the last page has already been read
    return true;
  }

  // if this page is not the last one in an in-progress / ended story. In other words, if an option has already been selected, its no longer editable
  if ((currentPage?.pageNo || 0) < (storyPages?.pageIDs?.length || 0)) {
    return true;
  }

  return false;
};

const fetchExistingPage = createAsyncThunk<NumberedPage, number>(
  'dailyStory/fetchExistingPage',
  async (pageNo: number, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const storyPages = selectStoryPages(appState);
    if (!storyPages) {
      throw new Error('No Story exists');
    }
    const pageId = storyPages.pageIDs![pageNo - 1];
    if (!isDefined(pageId)) {
      throw new Error('page no. does not exist');
    }
    const pageData = await getSharedPageById(pageId);
    if (!pageData) {
      throw new Error('no page data found...');
    }
    const page = {
      pageNo,
      pageData: {
        ...pageData,
        pageText: parseDailyStory(pageData.pageText),
        title: pageData.title ? parseDailyStory(pageData.title) : undefined,
      },
      pageImage: pageNo === 1 ? storyPages?.expositionImageName! : getPageImage(pageData),
    };
    return page;
  },
);

const fetchNewPage = createAsyncThunk<NumberedPage, {
  chosenOptionIndex: number;
  dailyStoryId: string;
}>(
  'dailyStory/fetchNewPage',
  async ({ chosenOptionIndex, dailyStoryId }, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const currentPage = selectCurrentPage(appState);
    const storyPages = selectStoryPages(appState);

    let pageData: SharedPage | undefined | null;
    if (!currentPage) {
      if (!dailyStoryId) throw new Error('dailyStoryId was not found!');
      pageData = await getDailyStoryExposition(dailyStoryId as string); // returns SharedPage object
    } else {
      pageData = await getNextSharedPage(chosenOptionIndex, currentPage?.pageData as SharedPage);
    }

    if (!pageData) {
      throw new Error('no page data found...');
    }

    const pageNo = (currentPage?.pageNo || 0) + 1;

    const newPage = {
      pageNo,
      pageData: {
        ...pageData,
        pageText: parseDailyStory(pageData.pageText),
        title: pageData.title ? parseDailyStory(pageData.title) : undefined,
      },
      pageImage: getPageImage(pageData),
    };
    const putData = constructStoryPagesWithNextPage(newPage, storyPages!, !newPage.pageData.pageOptions?.length);
    thunkAPI.dispatch(updateStoryPages(putData));
    return newPage;
  },
);

const fetchStoryPages = createAsyncThunk<StoryPages, string>(
  'dailyStory/fetchStoryPages',
  async (storyId: string) => {
    const res = await getStoryPages(storyId);
    if (!res) {
      throw new Error('Story Pages could not be fetched!');
    }
    return res;
  },
);

const updateStoryPages = createAsyncThunk<StoryPages, UpdateStoryPagesInput>(
  'dailyStory/updateStoryPages',
  async (putData: UpdateStoryPagesInput) => {
    const res = await putStoryPages(putData);
    if (!res) {
      throw new Error('Failed to update story pages');
    }
    return res;
  },
);

export {
  updateStoryPages,
  fetchStoryPages,
  fetchNewPage,
  fetchExistingPage,
};

export default dailyStorySlice.reducer;
