import { imageBaseURL } from '@lib/imageUtils';

const GALLERY_IMAGES_APP = [
  {
    src: `${imageBaseURL}misc/landing/page_illustration_1280w.webp`,
    srcSet: `${imageBaseURL}misc/landing/page_illustration_320w.webp 320w, ${imageBaseURL}misc/landing/page_illustration_640w.webp 640w, ${imageBaseURL}misc/landing/page_illustration_1280w.webp 1280w`,
    thumbnail: `${imageBaseURL}misc/landing/page_illustration_320w.webp`,
  },
  {
    src: `${imageBaseURL}misc/landing/make_choices_1280w.webp`,
    srcSet: `${imageBaseURL}misc/landing/make_choices_320w.webp 320w, ${imageBaseURL}misc/landing/make_choices_640w.webp 640w, ${imageBaseURL}misc/landing/make_choices_1280w.webp 1280w`,
    thumbnail: `${imageBaseURL}misc/landing/make_choices_320w.webp`,
  },
  {
    src: `${imageBaseURL}misc/landing/choose_avatar_1280w.webp`,
    srcSet: `${imageBaseURL}misc/landing/choose_avatar_320w.webp 320w, ${imageBaseURL}misc/landing/choose_avatar_640w.webp 640w, ${imageBaseURL}misc/landing/choose_avatar_1280w.webp 1280w`,
    thumbnail: `${imageBaseURL}misc/landing/choose_avatar_320w.webp`,
  },
  {
    src: `${imageBaseURL}misc/landing/add_companions_1280w.webp`,
    srcSet: `${imageBaseURL}misc/landing/add_companions_320w.webp 320w, ${imageBaseURL}misc/landing/add_companions_640w.webp 640w, ${imageBaseURL}misc/landing/add_companions_1280w.webp 1280w`,
    thumbnail: `${imageBaseURL}misc/landing/add_companions_320w.webp`,
  },
  {
    src: `${imageBaseURL}misc/landing/choose_location_1280w.webp`,
    srcSet: `${imageBaseURL}misc/landing/choose_location_320w.webp 320w, ${imageBaseURL}misc/landing/choose_location_640w.webp 640w, ${imageBaseURL}misc/landing/choose_location_1280w.webp 1280w`,
    thumbnail: `${imageBaseURL}misc/landing/choose_location_320w.webp`,
  },
  {
    src: `${imageBaseURL}misc/landing/reading_level_1280w.webp`,
    srcSet: `${imageBaseURL}misc/landing/reading_level_320w.webp 320w, ${imageBaseURL}misc/landing/reading_level_640w.webp 640w, ${imageBaseURL}misc/landing/reading_level_1280w.webp 1280w`,
    thumbnail: `${imageBaseURL}misc/landing/reading_level_320w.webp`,
  },
];

export default GALLERY_IMAGES_APP;
