export const pronounMap: Record<string, string> = {
  Girl: 'she',
  Boy: 'he',
  Child: 'they',
  female: 'she',
  male: 'he',
  'non-binary': 'they',
  plural: 'they',
};

export const possessivePronounMap: Record<string, string> = {
  Girl: 'her',
  Boy: 'his',
  Child: 'their',
  female: 'her',
  male: 'his',
  'non-binary': 'their',
  plural: 'their',
};

export const commonNounMap: Record<string, string> = {
  Girl: 'girl',
  Boy: 'boy',
  Child: 'child',
};

export const reflexivePronounMap: Record<string, string> = {
  Girl: 'herself',
  Boy: 'himself',
  Child: 'themself',
  female: 'herself',
  male: 'himself',
  'non-binary': 'themself',
  plural: 'themselves',
};

export const wasOrWereMap: Record<string, string> = {
  Girl: 'was',
  Boy: 'was',
  Child: 'were',
  female: 'was',
  male: 'was',
  'non-binary': 'were',
  plural: 'were',
};
