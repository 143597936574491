import WebsterWaving from '@assets/webster-waving.png';

export function Footer() {
  return (
    <footer className="bg-gray-50 bg-opacity-70 py-8 px-6 relative z-10">
      <div className="max-w-7xl mx-auto flex flex-col items-center gap-6">
        <div className="flex items-center gap-4">
          <img src={WebsterWaving} alt="Webster" className="h-12 w-auto" />
          <p className="text-sm text-gray-600">© 2024 Wanderly. All rights reserved.</p>
        </div>
        <div className="flex gap-4">
          <a href="https://www.facebook.com/profile.php?id=61550218496361" className="text-gray-600 hover:text-gray-900">
            <img src="https://static.wixstatic.com/media/11062b_e1117f9a7a1741ef955a84836121c4ad~mv2.png" alt="Facebook" className="w-6 h-6" />
          </a>
          <a href="https://www.twitter.com/wanderlystories" className="text-gray-600 hover:text-gray-900">
            <img src="https://static.wixstatic.com/media/11062b_8d4bd2538e784265832a8fe7c23fb1bd~mv2.png" alt="Twitter" className="w-6 h-6" />
          </a>
          <a href="https://www.instagram.com/wanderlystories" className="text-gray-600 hover:text-gray-900">
            <img src="https://static.wixstatic.com/media/11062b_94a6580a67244d17a4dc923b54b99fb4~mv2.png" alt="Instagram" className="w-6 h-6" />
          </a>
        </div>
        <div className="flex flex-col items-center gap-2">
          <div className="flex gap-6">
            <a href="/privacy" className="text-sm text-gray-600 hover:text-gray-900">Privacy Policy</a>
            <a href="/terms" className="text-sm text-gray-600 hover:text-gray-900">Terms of Service</a>
          </div>
          <p className="text-sm text-gray-600 text-center">
            P.O. Box 3136
            <br />
            Oakland, CA 94609, United States
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
