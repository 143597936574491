import { ReactElement, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

interface Props {
  children: ReactElement;
}

async function isUserAdmin() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
    console.log('groups', groups);
    return groups && groups.includes('Admin');
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
}

/**
 * Wrap any component that requires admin access with this hook.
 * @param children The component that requires admin access.
 * @returns The component if admin access exists, otherwise navigates to a landing page
 */
export default function RequireAdmin({ children }: Props) {
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);

  useEffect(() => {
    isUserAdmin().then((result) => setIsAdmin(result));
  }, []);

  if (isAdmin === null) {
    // Still loading, you can return a loading indicator here
    return <div>Loading...</div>;
  }

  if (!isAdmin) return <Navigate to="/settings/account" replace />;
  return children;
}
