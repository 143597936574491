import store from '@store/store';
import {
  SharedPage,
} from 'src/graphql/API';
import { selectActiveChild } from '@store/appSlice';
import {
  getSharedPageByIDfromDB,
  getExpositionPageIDFromDailyStory,
} from '../api/api';

async function getDailyStoryExposition(dailyStoryId: string) {
  const child = selectActiveChild(store.getState());

  if (!child) {
    throw new Error('No child was found...');
  }

  const expositionPageID = await getExpositionPageIDFromDailyStory(dailyStoryId);
  const expositionPage = await getSharedPageByIDfromDB(expositionPageID!);

  return expositionPage as SharedPage;
}

// eslint-disable-next-line import/prefer-default-export
export { getDailyStoryExposition };
