import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectPremiumOrTrial } from '@store/appSlice';

interface Props {
  children: ReactElement;
}

/**
 * Wrap any component that requires a premium subscription with this hook.
 * @param children The component that requires a premium subscription.
 * @returns The component if a premium subscription exists, otherwise navigates to a non-premium route
 */
export default function RequirePremiumOrTrial({ children }: Props) {
  const premiumOrTrial = useSelector(selectPremiumOrTrial);
  if (premiumOrTrial) return children;
  return <Navigate to="/upgrade" replace />;
}
