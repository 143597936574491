/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    cognitoUserId
    email
    children {
      items {
        id
        name
        age
        commonNoun
        readingLevel
        avatar
        storyPages {
          items {
            id
            pageIDs
            storyType
            storyStatus
            rating
            childStoryPagesId
            lastViewed
            expositionImageName
            title
            initiationMethod
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userChildrenId
        owner
        __typename
      }
      nextToken
      __typename
    }
    activeChildId
    settings
    lastInteraction
    emailUpdatesEnabled
    emailPromotionsEnabled
    emailStoriesFridayEnabled
    emailStoriesDailyEnabled
    trialStoriesCount
    bookCredits
    notificationSettings {
      id
      user {
        id
        cognitoUserId
        email
        children {
          items {
            id
            name
            age
            commonNoun
            readingLevel
            avatar
            storyPages {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userChildrenId
            owner
            __typename
          }
          nextToken
          __typename
        }
        activeChildId
        settings
        lastInteraction
        emailUpdatesEnabled
        emailPromotionsEnabled
        emailStoriesFridayEnabled
        emailStoriesDailyEnabled
        trialStoriesCount
        bookCredits
        notificationSettings {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          notificationsEnabled
          storyReminders {
            enabled
            frequency
            __typename
          }
          promotionsEnabled
          updatesEnabled
          createdAt
          updatedAt
          owner
          __typename
        }
        deviceTokens {
          items {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            token
            platform
            endpointArn
            createdAt
            updatedAt
            userDeviceTokensId
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userNotificationSettingsId
        owner
        __typename
      }
      userId
      notificationsEnabled
      storyReminders {
        enabled
        frequency
        __typename
      }
      promotionsEnabled
      updatesEnabled
      createdAt
      updatedAt
      owner
      __typename
    }
    deviceTokens {
      items {
        id
        user {
          id
          cognitoUserId
          email
          children {
            items {
              id
              name
              age
              commonNoun
              readingLevel
              avatar
              createdAt
              updatedAt
              userChildrenId
              owner
              __typename
            }
            nextToken
            __typename
          }
          activeChildId
          settings
          lastInteraction
          emailUpdatesEnabled
          emailPromotionsEnabled
          emailStoriesFridayEnabled
          emailStoriesDailyEnabled
          trialStoriesCount
          bookCredits
          notificationSettings {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            notificationsEnabled
            storyReminders {
              enabled
              frequency
              __typename
            }
            promotionsEnabled
            updatesEnabled
            createdAt
            updatedAt
            owner
            __typename
          }
          deviceTokens {
            items {
              id
              userId
              token
              platform
              endpointArn
              createdAt
              updatedAt
              userDeviceTokensId
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userNotificationSettingsId
          owner
          __typename
        }
        userId
        token
        platform
        endpointArn
        createdAt
        updatedAt
        userDeviceTokensId
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    userNotificationSettingsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    cognitoUserId
    email
    children {
      items {
        id
        name
        age
        commonNoun
        readingLevel
        avatar
        storyPages {
          items {
            id
            pageIDs
            storyType
            storyStatus
            rating
            childStoryPagesId
            lastViewed
            expositionImageName
            title
            initiationMethod
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userChildrenId
        owner
        __typename
      }
      nextToken
      __typename
    }
    activeChildId
    settings
    lastInteraction
    emailUpdatesEnabled
    emailPromotionsEnabled
    emailStoriesFridayEnabled
    emailStoriesDailyEnabled
    trialStoriesCount
    bookCredits
    notificationSettings {
      id
      user {
        id
        cognitoUserId
        email
        children {
          items {
            id
            name
            age
            commonNoun
            readingLevel
            avatar
            storyPages {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userChildrenId
            owner
            __typename
          }
          nextToken
          __typename
        }
        activeChildId
        settings
        lastInteraction
        emailUpdatesEnabled
        emailPromotionsEnabled
        emailStoriesFridayEnabled
        emailStoriesDailyEnabled
        trialStoriesCount
        bookCredits
        notificationSettings {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          notificationsEnabled
          storyReminders {
            enabled
            frequency
            __typename
          }
          promotionsEnabled
          updatesEnabled
          createdAt
          updatedAt
          owner
          __typename
        }
        deviceTokens {
          items {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            token
            platform
            endpointArn
            createdAt
            updatedAt
            userDeviceTokensId
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userNotificationSettingsId
        owner
        __typename
      }
      userId
      notificationsEnabled
      storyReminders {
        enabled
        frequency
        __typename
      }
      promotionsEnabled
      updatesEnabled
      createdAt
      updatedAt
      owner
      __typename
    }
    deviceTokens {
      items {
        id
        user {
          id
          cognitoUserId
          email
          children {
            items {
              id
              name
              age
              commonNoun
              readingLevel
              avatar
              createdAt
              updatedAt
              userChildrenId
              owner
              __typename
            }
            nextToken
            __typename
          }
          activeChildId
          settings
          lastInteraction
          emailUpdatesEnabled
          emailPromotionsEnabled
          emailStoriesFridayEnabled
          emailStoriesDailyEnabled
          trialStoriesCount
          bookCredits
          notificationSettings {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            notificationsEnabled
            storyReminders {
              enabled
              frequency
              __typename
            }
            promotionsEnabled
            updatesEnabled
            createdAt
            updatedAt
            owner
            __typename
          }
          deviceTokens {
            items {
              id
              userId
              token
              platform
              endpointArn
              createdAt
              updatedAt
              userDeviceTokensId
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userNotificationSettingsId
          owner
          __typename
        }
        userId
        token
        platform
        endpointArn
        createdAt
        updatedAt
        userDeviceTokensId
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    userNotificationSettingsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    cognitoUserId
    email
    children {
      items {
        id
        name
        age
        commonNoun
        readingLevel
        avatar
        storyPages {
          items {
            id
            pageIDs
            storyType
            storyStatus
            rating
            childStoryPagesId
            lastViewed
            expositionImageName
            title
            initiationMethod
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userChildrenId
        owner
        __typename
      }
      nextToken
      __typename
    }
    activeChildId
    settings
    lastInteraction
    emailUpdatesEnabled
    emailPromotionsEnabled
    emailStoriesFridayEnabled
    emailStoriesDailyEnabled
    trialStoriesCount
    bookCredits
    notificationSettings {
      id
      user {
        id
        cognitoUserId
        email
        children {
          items {
            id
            name
            age
            commonNoun
            readingLevel
            avatar
            storyPages {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userChildrenId
            owner
            __typename
          }
          nextToken
          __typename
        }
        activeChildId
        settings
        lastInteraction
        emailUpdatesEnabled
        emailPromotionsEnabled
        emailStoriesFridayEnabled
        emailStoriesDailyEnabled
        trialStoriesCount
        bookCredits
        notificationSettings {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          notificationsEnabled
          storyReminders {
            enabled
            frequency
            __typename
          }
          promotionsEnabled
          updatesEnabled
          createdAt
          updatedAt
          owner
          __typename
        }
        deviceTokens {
          items {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            token
            platform
            endpointArn
            createdAt
            updatedAt
            userDeviceTokensId
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userNotificationSettingsId
        owner
        __typename
      }
      userId
      notificationsEnabled
      storyReminders {
        enabled
        frequency
        __typename
      }
      promotionsEnabled
      updatesEnabled
      createdAt
      updatedAt
      owner
      __typename
    }
    deviceTokens {
      items {
        id
        user {
          id
          cognitoUserId
          email
          children {
            items {
              id
              name
              age
              commonNoun
              readingLevel
              avatar
              createdAt
              updatedAt
              userChildrenId
              owner
              __typename
            }
            nextToken
            __typename
          }
          activeChildId
          settings
          lastInteraction
          emailUpdatesEnabled
          emailPromotionsEnabled
          emailStoriesFridayEnabled
          emailStoriesDailyEnabled
          trialStoriesCount
          bookCredits
          notificationSettings {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            notificationsEnabled
            storyReminders {
              enabled
              frequency
              __typename
            }
            promotionsEnabled
            updatesEnabled
            createdAt
            updatedAt
            owner
            __typename
          }
          deviceTokens {
            items {
              id
              userId
              token
              platform
              endpointArn
              createdAt
              updatedAt
              userDeviceTokensId
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userNotificationSettingsId
          owner
          __typename
        }
        userId
        token
        platform
        endpointArn
        createdAt
        updatedAt
        userDeviceTokensId
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    userNotificationSettingsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createNotificationSettings = /* GraphQL */ `mutation CreateNotificationSettings(
  $input: CreateNotificationSettingsInput!
  $condition: ModelNotificationSettingsConditionInput
) {
  createNotificationSettings(input: $input, condition: $condition) {
    id
    user {
      id
      cognitoUserId
      email
      children {
        items {
          id
          name
          age
          commonNoun
          readingLevel
          avatar
          storyPages {
            items {
              id
              pageIDs
              storyType
              storyStatus
              rating
              childStoryPagesId
              lastViewed
              expositionImageName
              title
              initiationMethod
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userChildrenId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activeChildId
      settings
      lastInteraction
      emailUpdatesEnabled
      emailPromotionsEnabled
      emailStoriesFridayEnabled
      emailStoriesDailyEnabled
      trialStoriesCount
      bookCredits
      notificationSettings {
        id
        user {
          id
          cognitoUserId
          email
          children {
            items {
              id
              name
              age
              commonNoun
              readingLevel
              avatar
              createdAt
              updatedAt
              userChildrenId
              owner
              __typename
            }
            nextToken
            __typename
          }
          activeChildId
          settings
          lastInteraction
          emailUpdatesEnabled
          emailPromotionsEnabled
          emailStoriesFridayEnabled
          emailStoriesDailyEnabled
          trialStoriesCount
          bookCredits
          notificationSettings {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            notificationsEnabled
            storyReminders {
              enabled
              frequency
              __typename
            }
            promotionsEnabled
            updatesEnabled
            createdAt
            updatedAt
            owner
            __typename
          }
          deviceTokens {
            items {
              id
              userId
              token
              platform
              endpointArn
              createdAt
              updatedAt
              userDeviceTokensId
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userNotificationSettingsId
          owner
          __typename
        }
        userId
        notificationsEnabled
        storyReminders {
          enabled
          frequency
          __typename
        }
        promotionsEnabled
        updatesEnabled
        createdAt
        updatedAt
        owner
        __typename
      }
      deviceTokens {
        items {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          token
          platform
          endpointArn
          createdAt
          updatedAt
          userDeviceTokensId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userNotificationSettingsId
      owner
      __typename
    }
    userId
    notificationsEnabled
    storyReminders {
      enabled
      frequency
      __typename
    }
    promotionsEnabled
    updatesEnabled
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationSettingsMutationVariables,
  APITypes.CreateNotificationSettingsMutation
>;
export const updateNotificationSettings = /* GraphQL */ `mutation UpdateNotificationSettings(
  $input: UpdateNotificationSettingsInput!
  $condition: ModelNotificationSettingsConditionInput
) {
  updateNotificationSettings(input: $input, condition: $condition) {
    id
    user {
      id
      cognitoUserId
      email
      children {
        items {
          id
          name
          age
          commonNoun
          readingLevel
          avatar
          storyPages {
            items {
              id
              pageIDs
              storyType
              storyStatus
              rating
              childStoryPagesId
              lastViewed
              expositionImageName
              title
              initiationMethod
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userChildrenId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activeChildId
      settings
      lastInteraction
      emailUpdatesEnabled
      emailPromotionsEnabled
      emailStoriesFridayEnabled
      emailStoriesDailyEnabled
      trialStoriesCount
      bookCredits
      notificationSettings {
        id
        user {
          id
          cognitoUserId
          email
          children {
            items {
              id
              name
              age
              commonNoun
              readingLevel
              avatar
              createdAt
              updatedAt
              userChildrenId
              owner
              __typename
            }
            nextToken
            __typename
          }
          activeChildId
          settings
          lastInteraction
          emailUpdatesEnabled
          emailPromotionsEnabled
          emailStoriesFridayEnabled
          emailStoriesDailyEnabled
          trialStoriesCount
          bookCredits
          notificationSettings {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            notificationsEnabled
            storyReminders {
              enabled
              frequency
              __typename
            }
            promotionsEnabled
            updatesEnabled
            createdAt
            updatedAt
            owner
            __typename
          }
          deviceTokens {
            items {
              id
              userId
              token
              platform
              endpointArn
              createdAt
              updatedAt
              userDeviceTokensId
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userNotificationSettingsId
          owner
          __typename
        }
        userId
        notificationsEnabled
        storyReminders {
          enabled
          frequency
          __typename
        }
        promotionsEnabled
        updatesEnabled
        createdAt
        updatedAt
        owner
        __typename
      }
      deviceTokens {
        items {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          token
          platform
          endpointArn
          createdAt
          updatedAt
          userDeviceTokensId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userNotificationSettingsId
      owner
      __typename
    }
    userId
    notificationsEnabled
    storyReminders {
      enabled
      frequency
      __typename
    }
    promotionsEnabled
    updatesEnabled
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationSettingsMutationVariables,
  APITypes.UpdateNotificationSettingsMutation
>;
export const deleteNotificationSettings = /* GraphQL */ `mutation DeleteNotificationSettings(
  $input: DeleteNotificationSettingsInput!
  $condition: ModelNotificationSettingsConditionInput
) {
  deleteNotificationSettings(input: $input, condition: $condition) {
    id
    user {
      id
      cognitoUserId
      email
      children {
        items {
          id
          name
          age
          commonNoun
          readingLevel
          avatar
          storyPages {
            items {
              id
              pageIDs
              storyType
              storyStatus
              rating
              childStoryPagesId
              lastViewed
              expositionImageName
              title
              initiationMethod
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userChildrenId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activeChildId
      settings
      lastInteraction
      emailUpdatesEnabled
      emailPromotionsEnabled
      emailStoriesFridayEnabled
      emailStoriesDailyEnabled
      trialStoriesCount
      bookCredits
      notificationSettings {
        id
        user {
          id
          cognitoUserId
          email
          children {
            items {
              id
              name
              age
              commonNoun
              readingLevel
              avatar
              createdAt
              updatedAt
              userChildrenId
              owner
              __typename
            }
            nextToken
            __typename
          }
          activeChildId
          settings
          lastInteraction
          emailUpdatesEnabled
          emailPromotionsEnabled
          emailStoriesFridayEnabled
          emailStoriesDailyEnabled
          trialStoriesCount
          bookCredits
          notificationSettings {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            notificationsEnabled
            storyReminders {
              enabled
              frequency
              __typename
            }
            promotionsEnabled
            updatesEnabled
            createdAt
            updatedAt
            owner
            __typename
          }
          deviceTokens {
            items {
              id
              userId
              token
              platform
              endpointArn
              createdAt
              updatedAt
              userDeviceTokensId
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userNotificationSettingsId
          owner
          __typename
        }
        userId
        notificationsEnabled
        storyReminders {
          enabled
          frequency
          __typename
        }
        promotionsEnabled
        updatesEnabled
        createdAt
        updatedAt
        owner
        __typename
      }
      deviceTokens {
        items {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          token
          platform
          endpointArn
          createdAt
          updatedAt
          userDeviceTokensId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userNotificationSettingsId
      owner
      __typename
    }
    userId
    notificationsEnabled
    storyReminders {
      enabled
      frequency
      __typename
    }
    promotionsEnabled
    updatesEnabled
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationSettingsMutationVariables,
  APITypes.DeleteNotificationSettingsMutation
>;
export const createDeviceToken = /* GraphQL */ `mutation CreateDeviceToken(
  $input: CreateDeviceTokenInput!
  $condition: ModelDeviceTokenConditionInput
) {
  createDeviceToken(input: $input, condition: $condition) {
    id
    user {
      id
      cognitoUserId
      email
      children {
        items {
          id
          name
          age
          commonNoun
          readingLevel
          avatar
          storyPages {
            items {
              id
              pageIDs
              storyType
              storyStatus
              rating
              childStoryPagesId
              lastViewed
              expositionImageName
              title
              initiationMethod
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userChildrenId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activeChildId
      settings
      lastInteraction
      emailUpdatesEnabled
      emailPromotionsEnabled
      emailStoriesFridayEnabled
      emailStoriesDailyEnabled
      trialStoriesCount
      bookCredits
      notificationSettings {
        id
        user {
          id
          cognitoUserId
          email
          children {
            items {
              id
              name
              age
              commonNoun
              readingLevel
              avatar
              createdAt
              updatedAt
              userChildrenId
              owner
              __typename
            }
            nextToken
            __typename
          }
          activeChildId
          settings
          lastInteraction
          emailUpdatesEnabled
          emailPromotionsEnabled
          emailStoriesFridayEnabled
          emailStoriesDailyEnabled
          trialStoriesCount
          bookCredits
          notificationSettings {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            notificationsEnabled
            storyReminders {
              enabled
              frequency
              __typename
            }
            promotionsEnabled
            updatesEnabled
            createdAt
            updatedAt
            owner
            __typename
          }
          deviceTokens {
            items {
              id
              userId
              token
              platform
              endpointArn
              createdAt
              updatedAt
              userDeviceTokensId
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userNotificationSettingsId
          owner
          __typename
        }
        userId
        notificationsEnabled
        storyReminders {
          enabled
          frequency
          __typename
        }
        promotionsEnabled
        updatesEnabled
        createdAt
        updatedAt
        owner
        __typename
      }
      deviceTokens {
        items {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          token
          platform
          endpointArn
          createdAt
          updatedAt
          userDeviceTokensId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userNotificationSettingsId
      owner
      __typename
    }
    userId
    token
    platform
    endpointArn
    createdAt
    updatedAt
    userDeviceTokensId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDeviceTokenMutationVariables,
  APITypes.CreateDeviceTokenMutation
>;
export const updateDeviceToken = /* GraphQL */ `mutation UpdateDeviceToken(
  $input: UpdateDeviceTokenInput!
  $condition: ModelDeviceTokenConditionInput
) {
  updateDeviceToken(input: $input, condition: $condition) {
    id
    user {
      id
      cognitoUserId
      email
      children {
        items {
          id
          name
          age
          commonNoun
          readingLevel
          avatar
          storyPages {
            items {
              id
              pageIDs
              storyType
              storyStatus
              rating
              childStoryPagesId
              lastViewed
              expositionImageName
              title
              initiationMethod
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userChildrenId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activeChildId
      settings
      lastInteraction
      emailUpdatesEnabled
      emailPromotionsEnabled
      emailStoriesFridayEnabled
      emailStoriesDailyEnabled
      trialStoriesCount
      bookCredits
      notificationSettings {
        id
        user {
          id
          cognitoUserId
          email
          children {
            items {
              id
              name
              age
              commonNoun
              readingLevel
              avatar
              createdAt
              updatedAt
              userChildrenId
              owner
              __typename
            }
            nextToken
            __typename
          }
          activeChildId
          settings
          lastInteraction
          emailUpdatesEnabled
          emailPromotionsEnabled
          emailStoriesFridayEnabled
          emailStoriesDailyEnabled
          trialStoriesCount
          bookCredits
          notificationSettings {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            notificationsEnabled
            storyReminders {
              enabled
              frequency
              __typename
            }
            promotionsEnabled
            updatesEnabled
            createdAt
            updatedAt
            owner
            __typename
          }
          deviceTokens {
            items {
              id
              userId
              token
              platform
              endpointArn
              createdAt
              updatedAt
              userDeviceTokensId
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userNotificationSettingsId
          owner
          __typename
        }
        userId
        notificationsEnabled
        storyReminders {
          enabled
          frequency
          __typename
        }
        promotionsEnabled
        updatesEnabled
        createdAt
        updatedAt
        owner
        __typename
      }
      deviceTokens {
        items {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          token
          platform
          endpointArn
          createdAt
          updatedAt
          userDeviceTokensId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userNotificationSettingsId
      owner
      __typename
    }
    userId
    token
    platform
    endpointArn
    createdAt
    updatedAt
    userDeviceTokensId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeviceTokenMutationVariables,
  APITypes.UpdateDeviceTokenMutation
>;
export const deleteDeviceToken = /* GraphQL */ `mutation DeleteDeviceToken(
  $input: DeleteDeviceTokenInput!
  $condition: ModelDeviceTokenConditionInput
) {
  deleteDeviceToken(input: $input, condition: $condition) {
    id
    user {
      id
      cognitoUserId
      email
      children {
        items {
          id
          name
          age
          commonNoun
          readingLevel
          avatar
          storyPages {
            items {
              id
              pageIDs
              storyType
              storyStatus
              rating
              childStoryPagesId
              lastViewed
              expositionImageName
              title
              initiationMethod
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userChildrenId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activeChildId
      settings
      lastInteraction
      emailUpdatesEnabled
      emailPromotionsEnabled
      emailStoriesFridayEnabled
      emailStoriesDailyEnabled
      trialStoriesCount
      bookCredits
      notificationSettings {
        id
        user {
          id
          cognitoUserId
          email
          children {
            items {
              id
              name
              age
              commonNoun
              readingLevel
              avatar
              createdAt
              updatedAt
              userChildrenId
              owner
              __typename
            }
            nextToken
            __typename
          }
          activeChildId
          settings
          lastInteraction
          emailUpdatesEnabled
          emailPromotionsEnabled
          emailStoriesFridayEnabled
          emailStoriesDailyEnabled
          trialStoriesCount
          bookCredits
          notificationSettings {
            id
            user {
              id
              cognitoUserId
              email
              activeChildId
              settings
              lastInteraction
              emailUpdatesEnabled
              emailPromotionsEnabled
              emailStoriesFridayEnabled
              emailStoriesDailyEnabled
              trialStoriesCount
              bookCredits
              createdAt
              updatedAt
              userNotificationSettingsId
              owner
              __typename
            }
            userId
            notificationsEnabled
            storyReminders {
              enabled
              frequency
              __typename
            }
            promotionsEnabled
            updatesEnabled
            createdAt
            updatedAt
            owner
            __typename
          }
          deviceTokens {
            items {
              id
              userId
              token
              platform
              endpointArn
              createdAt
              updatedAt
              userDeviceTokensId
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userNotificationSettingsId
          owner
          __typename
        }
        userId
        notificationsEnabled
        storyReminders {
          enabled
          frequency
          __typename
        }
        promotionsEnabled
        updatesEnabled
        createdAt
        updatedAt
        owner
        __typename
      }
      deviceTokens {
        items {
          id
          user {
            id
            cognitoUserId
            email
            children {
              nextToken
              __typename
            }
            activeChildId
            settings
            lastInteraction
            emailUpdatesEnabled
            emailPromotionsEnabled
            emailStoriesFridayEnabled
            emailStoriesDailyEnabled
            trialStoriesCount
            bookCredits
            notificationSettings {
              id
              userId
              notificationsEnabled
              promotionsEnabled
              updatesEnabled
              createdAt
              updatedAt
              owner
              __typename
            }
            deviceTokens {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            userNotificationSettingsId
            owner
            __typename
          }
          userId
          token
          platform
          endpointArn
          createdAt
          updatedAt
          userDeviceTokensId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userNotificationSettingsId
      owner
      __typename
    }
    userId
    token
    platform
    endpointArn
    createdAt
    updatedAt
    userDeviceTokensId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDeviceTokenMutationVariables,
  APITypes.DeleteDeviceTokenMutation
>;
export const createWaitlist = /* GraphQL */ `mutation CreateWaitlist(
  $input: CreateWaitlistInput!
  $condition: ModelWaitlistConditionInput
) {
  createWaitlist(input: $input, condition: $condition) {
    cognitoUserId
    email
    accessGranted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWaitlistMutationVariables,
  APITypes.CreateWaitlistMutation
>;
export const updateWaitlist = /* GraphQL */ `mutation UpdateWaitlist(
  $input: UpdateWaitlistInput!
  $condition: ModelWaitlistConditionInput
) {
  updateWaitlist(input: $input, condition: $condition) {
    cognitoUserId
    email
    accessGranted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWaitlistMutationVariables,
  APITypes.UpdateWaitlistMutation
>;
export const deleteWaitlist = /* GraphQL */ `mutation DeleteWaitlist(
  $input: DeleteWaitlistInput!
  $condition: ModelWaitlistConditionInput
) {
  deleteWaitlist(input: $input, condition: $condition) {
    cognitoUserId
    email
    accessGranted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWaitlistMutationVariables,
  APITypes.DeleteWaitlistMutation
>;
export const createGiftcard = /* GraphQL */ `mutation CreateGiftcard(
  $input: CreateGiftcardInput!
  $condition: ModelGiftcardConditionInput
) {
  createGiftcard(input: $input, condition: $condition) {
    id
    giftcode
    recipientEmail
    subscriptionProduct
    storyCredits
    bookCredits
    giftingEmail
    redeemed
    redeemedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGiftcardMutationVariables,
  APITypes.CreateGiftcardMutation
>;
export const updateGiftcard = /* GraphQL */ `mutation UpdateGiftcard(
  $input: UpdateGiftcardInput!
  $condition: ModelGiftcardConditionInput
) {
  updateGiftcard(input: $input, condition: $condition) {
    id
    giftcode
    recipientEmail
    subscriptionProduct
    storyCredits
    bookCredits
    giftingEmail
    redeemed
    redeemedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGiftcardMutationVariables,
  APITypes.UpdateGiftcardMutation
>;
export const deleteGiftcard = /* GraphQL */ `mutation DeleteGiftcard(
  $input: DeleteGiftcardInput!
  $condition: ModelGiftcardConditionInput
) {
  deleteGiftcard(input: $input, condition: $condition) {
    id
    giftcode
    recipientEmail
    subscriptionProduct
    storyCredits
    bookCredits
    giftingEmail
    redeemed
    redeemedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGiftcardMutationVariables,
  APITypes.DeleteGiftcardMutation
>;
export const createMetadata = /* GraphQL */ `mutation CreateMetadata(
  $input: CreateMetadataInput!
  $condition: ModelMetadataConditionInput
) {
  createMetadata(input: $input, condition: $condition) {
    id
    isUnderMaintenance
    startingTrialStoriesCount
    waitlistEnabled
    minimumAppVersion
    promotionString
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMetadataMutationVariables,
  APITypes.CreateMetadataMutation
>;
export const updateMetadata = /* GraphQL */ `mutation UpdateMetadata(
  $input: UpdateMetadataInput!
  $condition: ModelMetadataConditionInput
) {
  updateMetadata(input: $input, condition: $condition) {
    id
    isUnderMaintenance
    startingTrialStoriesCount
    waitlistEnabled
    minimumAppVersion
    promotionString
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMetadataMutationVariables,
  APITypes.UpdateMetadataMutation
>;
export const deleteMetadata = /* GraphQL */ `mutation DeleteMetadata(
  $input: DeleteMetadataInput!
  $condition: ModelMetadataConditionInput
) {
  deleteMetadata(input: $input, condition: $condition) {
    id
    isUnderMaintenance
    startingTrialStoriesCount
    waitlistEnabled
    minimumAppVersion
    promotionString
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMetadataMutationVariables,
  APITypes.DeleteMetadataMutation
>;
export const createUserPayment = /* GraphQL */ `mutation CreateUserPayment(
  $input: CreateUserPaymentInput!
  $condition: ModelUserPaymentConditionInput
) {
  createUserPayment(input: $input, condition: $condition) {
    stripeSubscriptionId
    cognitoUserId
    stripeCustomerId
    currentTransactionId
    status
    currentPeriodStart
    currentPeriodEnd
    cancelAtPeriodEnd
    price {
      id
      amount
      interval
      interval_count
      currency
      __typename
    }
    product {
      id
      name
      description
      __typename
    }
    provider
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserPaymentMutationVariables,
  APITypes.CreateUserPaymentMutation
>;
export const updateUserPayment = /* GraphQL */ `mutation UpdateUserPayment(
  $input: UpdateUserPaymentInput!
  $condition: ModelUserPaymentConditionInput
) {
  updateUserPayment(input: $input, condition: $condition) {
    stripeSubscriptionId
    cognitoUserId
    stripeCustomerId
    currentTransactionId
    status
    currentPeriodStart
    currentPeriodEnd
    cancelAtPeriodEnd
    price {
      id
      amount
      interval
      interval_count
      currency
      __typename
    }
    product {
      id
      name
      description
      __typename
    }
    provider
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPaymentMutationVariables,
  APITypes.UpdateUserPaymentMutation
>;
export const deleteUserPayment = /* GraphQL */ `mutation DeleteUserPayment(
  $input: DeleteUserPaymentInput!
  $condition: ModelUserPaymentConditionInput
) {
  deleteUserPayment(input: $input, condition: $condition) {
    stripeSubscriptionId
    cognitoUserId
    stripeCustomerId
    currentTransactionId
    status
    currentPeriodStart
    currentPeriodEnd
    cancelAtPeriodEnd
    price {
      id
      amount
      interval
      interval_count
      currency
      __typename
    }
    product {
      id
      name
      description
      __typename
    }
    provider
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserPaymentMutationVariables,
  APITypes.DeleteUserPaymentMutation
>;
export const createTransactions = /* GraphQL */ `mutation CreateTransactions(
  $input: CreateTransactionsInput!
  $condition: ModelTransactionsConditionInput
) {
  createTransactions(input: $input, condition: $condition) {
    id
    cognitoUserId
    transactionId
    originalTransactionId
    product {
      id
      name
      description
      __typename
    }
    price {
      id
      amount
      interval
      interval_count
      currency
      __typename
    }
    receipt
    provider
    discounts
    transactionDate
    errorCode
    errorMessage
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTransactionsMutationVariables,
  APITypes.CreateTransactionsMutation
>;
export const updateTransactions = /* GraphQL */ `mutation UpdateTransactions(
  $input: UpdateTransactionsInput!
  $condition: ModelTransactionsConditionInput
) {
  updateTransactions(input: $input, condition: $condition) {
    id
    cognitoUserId
    transactionId
    originalTransactionId
    product {
      id
      name
      description
      __typename
    }
    price {
      id
      amount
      interval
      interval_count
      currency
      __typename
    }
    receipt
    provider
    discounts
    transactionDate
    errorCode
    errorMessage
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTransactionsMutationVariables,
  APITypes.UpdateTransactionsMutation
>;
export const deleteTransactions = /* GraphQL */ `mutation DeleteTransactions(
  $input: DeleteTransactionsInput!
  $condition: ModelTransactionsConditionInput
) {
  deleteTransactions(input: $input, condition: $condition) {
    id
    cognitoUserId
    transactionId
    originalTransactionId
    product {
      id
      name
      description
      __typename
    }
    price {
      id
      amount
      interval
      interval_count
      currency
      __typename
    }
    receipt
    provider
    discounts
    transactionDate
    errorCode
    errorMessage
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTransactionsMutationVariables,
  APITypes.DeleteTransactionsMutation
>;
export const createChild = /* GraphQL */ `mutation CreateChild(
  $input: CreateChildInput!
  $condition: ModelChildConditionInput
) {
  createChild(input: $input, condition: $condition) {
    id
    name
    age
    commonNoun
    readingLevel
    avatar
    storyPages {
      items {
        id
        pageIDs
        storyType
        storyStatus
        rating
        childStoryPagesId
        lastViewed
        expositionImageName
        title
        initiationMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    userChildrenId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChildMutationVariables,
  APITypes.CreateChildMutation
>;
export const updateChild = /* GraphQL */ `mutation UpdateChild(
  $input: UpdateChildInput!
  $condition: ModelChildConditionInput
) {
  updateChild(input: $input, condition: $condition) {
    id
    name
    age
    commonNoun
    readingLevel
    avatar
    storyPages {
      items {
        id
        pageIDs
        storyType
        storyStatus
        rating
        childStoryPagesId
        lastViewed
        expositionImageName
        title
        initiationMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    userChildrenId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChildMutationVariables,
  APITypes.UpdateChildMutation
>;
export const deleteChild = /* GraphQL */ `mutation DeleteChild(
  $input: DeleteChildInput!
  $condition: ModelChildConditionInput
) {
  deleteChild(input: $input, condition: $condition) {
    id
    name
    age
    commonNoun
    readingLevel
    avatar
    storyPages {
      items {
        id
        pageIDs
        storyType
        storyStatus
        rating
        childStoryPagesId
        lastViewed
        expositionImageName
        title
        initiationMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    userChildrenId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChildMutationVariables,
  APITypes.DeleteChildMutation
>;
export const createPage = /* GraphQL */ `mutation CreatePage(
  $input: CreatePageInput!
  $condition: ModelPageConditionInput
) {
  createPage(input: $input, condition: $condition) {
    id
    cognitoUserId
    pageText
    pageOptions
    imageName
    minorImageName
    characterImageNames
    primaryImage
    protagonist
    storyType
    location
    readingLevel
    preceededBy
    followedBy
    followedByEnding
    isExposition
    isEnding
    isAnonymized
    status
    isAccepted
    createdAt
    updatedAt
    title
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePageMutationVariables,
  APITypes.CreatePageMutation
>;
export const updatePage = /* GraphQL */ `mutation UpdatePage(
  $input: UpdatePageInput!
  $condition: ModelPageConditionInput
) {
  updatePage(input: $input, condition: $condition) {
    id
    cognitoUserId
    pageText
    pageOptions
    imageName
    minorImageName
    characterImageNames
    primaryImage
    protagonist
    storyType
    location
    readingLevel
    preceededBy
    followedBy
    followedByEnding
    isExposition
    isEnding
    isAnonymized
    status
    isAccepted
    createdAt
    updatedAt
    title
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePageMutationVariables,
  APITypes.UpdatePageMutation
>;
export const deletePage = /* GraphQL */ `mutation DeletePage(
  $input: DeletePageInput!
  $condition: ModelPageConditionInput
) {
  deletePage(input: $input, condition: $condition) {
    id
    cognitoUserId
    pageText
    pageOptions
    imageName
    minorImageName
    characterImageNames
    primaryImage
    protagonist
    storyType
    location
    readingLevel
    preceededBy
    followedBy
    followedByEnding
    isExposition
    isEnding
    isAnonymized
    status
    isAccepted
    createdAt
    updatedAt
    title
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePageMutationVariables,
  APITypes.DeletePageMutation
>;
export const createSharedPage = /* GraphQL */ `mutation CreateSharedPage(
  $input: CreateSharedPageInput!
  $condition: ModelSharedPageConditionInput
) {
  createSharedPage(input: $input, condition: $condition) {
    id
    pageText
    pageOptions
    imageName
    protagonist
    storyType
    location
    readingLevel
    preceededBy
    followedBy
    followedByEnding
    status
    isExposition
    isEnding
    isAnonymized
    createdAt
    updatedAt
    title
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSharedPageMutationVariables,
  APITypes.CreateSharedPageMutation
>;
export const updateSharedPage = /* GraphQL */ `mutation UpdateSharedPage(
  $input: UpdateSharedPageInput!
  $condition: ModelSharedPageConditionInput
) {
  updateSharedPage(input: $input, condition: $condition) {
    id
    pageText
    pageOptions
    imageName
    protagonist
    storyType
    location
    readingLevel
    preceededBy
    followedBy
    followedByEnding
    status
    isExposition
    isEnding
    isAnonymized
    createdAt
    updatedAt
    title
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSharedPageMutationVariables,
  APITypes.UpdateSharedPageMutation
>;
export const deleteSharedPage = /* GraphQL */ `mutation DeleteSharedPage(
  $input: DeleteSharedPageInput!
  $condition: ModelSharedPageConditionInput
) {
  deleteSharedPage(input: $input, condition: $condition) {
    id
    pageText
    pageOptions
    imageName
    protagonist
    storyType
    location
    readingLevel
    preceededBy
    followedBy
    followedByEnding
    status
    isExposition
    isEnding
    isAnonymized
    createdAt
    updatedAt
    title
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSharedPageMutationVariables,
  APITypes.DeleteSharedPageMutation
>;
export const createStoryPages = /* GraphQL */ `mutation CreateStoryPages(
  $input: CreateStoryPagesInput!
  $condition: ModelStoryPagesConditionInput
) {
  createStoryPages(input: $input, condition: $condition) {
    id
    pageIDs
    storyType
    storyStatus
    rating
    childStoryPagesId
    lastViewed
    expositionImageName
    title
    initiationMethod
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStoryPagesMutationVariables,
  APITypes.CreateStoryPagesMutation
>;
export const updateStoryPages = /* GraphQL */ `mutation UpdateStoryPages(
  $input: UpdateStoryPagesInput!
  $condition: ModelStoryPagesConditionInput
) {
  updateStoryPages(input: $input, condition: $condition) {
    id
    pageIDs
    storyType
    storyStatus
    rating
    childStoryPagesId
    lastViewed
    expositionImageName
    title
    initiationMethod
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStoryPagesMutationVariables,
  APITypes.UpdateStoryPagesMutation
>;
export const deleteStoryPages = /* GraphQL */ `mutation DeleteStoryPages(
  $input: DeleteStoryPagesInput!
  $condition: ModelStoryPagesConditionInput
) {
  deleteStoryPages(input: $input, condition: $condition) {
    id
    pageIDs
    storyType
    storyStatus
    rating
    childStoryPagesId
    lastViewed
    expositionImageName
    title
    initiationMethod
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStoryPagesMutationVariables,
  APITypes.DeleteStoryPagesMutation
>;
export const createDailyStory = /* GraphQL */ `mutation CreateDailyStory(
  $input: CreateDailyStoryInput!
  $condition: ModelDailyStoryConditionInput
) {
  createDailyStory(input: $input, condition: $condition) {
    id
    expositionPageID
    storyType
    pageDepth
    status
    createdAt
    updatedAt
    approvedAt
    storyTitle
    displayStart
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDailyStoryMutationVariables,
  APITypes.CreateDailyStoryMutation
>;
export const updateDailyStory = /* GraphQL */ `mutation UpdateDailyStory(
  $input: UpdateDailyStoryInput!
  $condition: ModelDailyStoryConditionInput
) {
  updateDailyStory(input: $input, condition: $condition) {
    id
    expositionPageID
    storyType
    pageDepth
    status
    createdAt
    updatedAt
    approvedAt
    storyTitle
    displayStart
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDailyStoryMutationVariables,
  APITypes.UpdateDailyStoryMutation
>;
export const deleteDailyStory = /* GraphQL */ `mutation DeleteDailyStory(
  $input: DeleteDailyStoryInput!
  $condition: ModelDailyStoryConditionInput
) {
  deleteDailyStory(input: $input, condition: $condition) {
    id
    expositionPageID
    storyType
    pageDepth
    status
    createdAt
    updatedAt
    approvedAt
    storyTitle
    displayStart
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDailyStoryMutationVariables,
  APITypes.DeleteDailyStoryMutation
>;
export const createImageSubjectsLog = /* GraphQL */ `mutation CreateImageSubjectsLog(
  $input: CreateImageSubjectsLogInput!
  $condition: ModelImageSubjectsLogConditionInput
) {
  createImageSubjectsLog(input: $input, condition: $condition) {
    id
    subjects
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateImageSubjectsLogMutationVariables,
  APITypes.CreateImageSubjectsLogMutation
>;
export const updateImageSubjectsLog = /* GraphQL */ `mutation UpdateImageSubjectsLog(
  $input: UpdateImageSubjectsLogInput!
  $condition: ModelImageSubjectsLogConditionInput
) {
  updateImageSubjectsLog(input: $input, condition: $condition) {
    id
    subjects
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateImageSubjectsLogMutationVariables,
  APITypes.UpdateImageSubjectsLogMutation
>;
export const deleteImageSubjectsLog = /* GraphQL */ `mutation DeleteImageSubjectsLog(
  $input: DeleteImageSubjectsLogInput!
  $condition: ModelImageSubjectsLogConditionInput
) {
  deleteImageSubjectsLog(input: $input, condition: $condition) {
    id
    subjects
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteImageSubjectsLogMutationVariables,
  APITypes.DeleteImageSubjectsLogMutation
>;
export const createJobStatus = /* GraphQL */ `mutation CreateJobStatus(
  $input: CreateJobStatusInput!
  $condition: ModelJobStatusConditionInput
) {
  createJobStatus(input: $input, condition: $condition) {
    id
    jobStatus
    jobResult {
      success
      pageIDs
      __typename
    }
    createdAt
    updatedAt
    cognitoUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateJobStatusMutationVariables,
  APITypes.CreateJobStatusMutation
>;
export const updateJobStatus = /* GraphQL */ `mutation UpdateJobStatus(
  $input: UpdateJobStatusInput!
  $condition: ModelJobStatusConditionInput
) {
  updateJobStatus(input: $input, condition: $condition) {
    id
    jobStatus
    jobResult {
      success
      pageIDs
      __typename
    }
    createdAt
    updatedAt
    cognitoUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJobStatusMutationVariables,
  APITypes.UpdateJobStatusMutation
>;
export const deleteJobStatus = /* GraphQL */ `mutation DeleteJobStatus(
  $input: DeleteJobStatusInput!
  $condition: ModelJobStatusConditionInput
) {
  deleteJobStatus(input: $input, condition: $condition) {
    id
    jobStatus
    jobResult {
      success
      pageIDs
      __typename
    }
    createdAt
    updatedAt
    cognitoUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteJobStatusMutationVariables,
  APITypes.DeleteJobStatusMutation
>;
export const createImage = /* GraphQL */ `mutation CreateImage(
  $input: CreateImageInput!
  $condition: ModelImageConditionInput
) {
  createImage(input: $input, condition: $condition) {
    id
    name
    type
    genres {
      items {
        id
        imageId
        genreId
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        genre {
          id
          name
          images {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    expressions {
      items {
        id
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        name
        createdAt
        updatedAt
        imageExpressionsId
        __typename
      }
      nextToken
      __typename
    }
    traits
    createdAt
    updatedAt
    categories {
      items {
        id
        imageId
        imageCategoryId
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        imageCategory {
          id
          category
          images {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    characterSet
    characterSetName
    location
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateImageMutationVariables,
  APITypes.CreateImageMutation
>;
export const updateImage = /* GraphQL */ `mutation UpdateImage(
  $input: UpdateImageInput!
  $condition: ModelImageConditionInput
) {
  updateImage(input: $input, condition: $condition) {
    id
    name
    type
    genres {
      items {
        id
        imageId
        genreId
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        genre {
          id
          name
          images {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    expressions {
      items {
        id
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        name
        createdAt
        updatedAt
        imageExpressionsId
        __typename
      }
      nextToken
      __typename
    }
    traits
    createdAt
    updatedAt
    categories {
      items {
        id
        imageId
        imageCategoryId
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        imageCategory {
          id
          category
          images {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    characterSet
    characterSetName
    location
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateImageMutationVariables,
  APITypes.UpdateImageMutation
>;
export const deleteImage = /* GraphQL */ `mutation DeleteImage(
  $input: DeleteImageInput!
  $condition: ModelImageConditionInput
) {
  deleteImage(input: $input, condition: $condition) {
    id
    name
    type
    genres {
      items {
        id
        imageId
        genreId
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        genre {
          id
          name
          images {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    expressions {
      items {
        id
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        name
        createdAt
        updatedAt
        imageExpressionsId
        __typename
      }
      nextToken
      __typename
    }
    traits
    createdAt
    updatedAt
    categories {
      items {
        id
        imageId
        imageCategoryId
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        imageCategory {
          id
          category
          images {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    characterSet
    characterSetName
    location
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteImageMutationVariables,
  APITypes.DeleteImageMutation
>;
export const createExpression = /* GraphQL */ `mutation CreateExpression(
  $input: CreateExpressionInput!
  $condition: ModelExpressionConditionInput
) {
  createExpression(input: $input, condition: $condition) {
    id
    image {
      id
      name
      type
      genres {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      expressions {
        items {
          id
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          name
          createdAt
          updatedAt
          imageExpressionsId
          __typename
        }
        nextToken
        __typename
      }
      traits
      createdAt
      updatedAt
      categories {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      characterSet
      characterSetName
      location
      __typename
    }
    name
    createdAt
    updatedAt
    imageExpressionsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExpressionMutationVariables,
  APITypes.CreateExpressionMutation
>;
export const updateExpression = /* GraphQL */ `mutation UpdateExpression(
  $input: UpdateExpressionInput!
  $condition: ModelExpressionConditionInput
) {
  updateExpression(input: $input, condition: $condition) {
    id
    image {
      id
      name
      type
      genres {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      expressions {
        items {
          id
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          name
          createdAt
          updatedAt
          imageExpressionsId
          __typename
        }
        nextToken
        __typename
      }
      traits
      createdAt
      updatedAt
      categories {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      characterSet
      characterSetName
      location
      __typename
    }
    name
    createdAt
    updatedAt
    imageExpressionsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExpressionMutationVariables,
  APITypes.UpdateExpressionMutation
>;
export const deleteExpression = /* GraphQL */ `mutation DeleteExpression(
  $input: DeleteExpressionInput!
  $condition: ModelExpressionConditionInput
) {
  deleteExpression(input: $input, condition: $condition) {
    id
    image {
      id
      name
      type
      genres {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      expressions {
        items {
          id
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          name
          createdAt
          updatedAt
          imageExpressionsId
          __typename
        }
        nextToken
        __typename
      }
      traits
      createdAt
      updatedAt
      categories {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      characterSet
      characterSetName
      location
      __typename
    }
    name
    createdAt
    updatedAt
    imageExpressionsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteExpressionMutationVariables,
  APITypes.DeleteExpressionMutation
>;
export const createGenre = /* GraphQL */ `mutation CreateGenre(
  $input: CreateGenreInput!
  $condition: ModelGenreConditionInput
) {
  createGenre(input: $input, condition: $condition) {
    id
    name
    images {
      items {
        id
        imageId
        genreId
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        genre {
          id
          name
          images {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGenreMutationVariables,
  APITypes.CreateGenreMutation
>;
export const updateGenre = /* GraphQL */ `mutation UpdateGenre(
  $input: UpdateGenreInput!
  $condition: ModelGenreConditionInput
) {
  updateGenre(input: $input, condition: $condition) {
    id
    name
    images {
      items {
        id
        imageId
        genreId
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        genre {
          id
          name
          images {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGenreMutationVariables,
  APITypes.UpdateGenreMutation
>;
export const deleteGenre = /* GraphQL */ `mutation DeleteGenre(
  $input: DeleteGenreInput!
  $condition: ModelGenreConditionInput
) {
  deleteGenre(input: $input, condition: $condition) {
    id
    name
    images {
      items {
        id
        imageId
        genreId
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        genre {
          id
          name
          images {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGenreMutationVariables,
  APITypes.DeleteGenreMutation
>;
export const createImageCategory = /* GraphQL */ `mutation CreateImageCategory(
  $input: CreateImageCategoryInput!
  $condition: ModelImageCategoryConditionInput
) {
  createImageCategory(input: $input, condition: $condition) {
    id
    category
    images {
      items {
        id
        imageId
        imageCategoryId
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        imageCategory {
          id
          category
          images {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateImageCategoryMutationVariables,
  APITypes.CreateImageCategoryMutation
>;
export const updateImageCategory = /* GraphQL */ `mutation UpdateImageCategory(
  $input: UpdateImageCategoryInput!
  $condition: ModelImageCategoryConditionInput
) {
  updateImageCategory(input: $input, condition: $condition) {
    id
    category
    images {
      items {
        id
        imageId
        imageCategoryId
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        imageCategory {
          id
          category
          images {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateImageCategoryMutationVariables,
  APITypes.UpdateImageCategoryMutation
>;
export const deleteImageCategory = /* GraphQL */ `mutation DeleteImageCategory(
  $input: DeleteImageCategoryInput!
  $condition: ModelImageCategoryConditionInput
) {
  deleteImageCategory(input: $input, condition: $condition) {
    id
    category
    images {
      items {
        id
        imageId
        imageCategoryId
        image {
          id
          name
          type
          genres {
            items {
              id
              imageId
              genreId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          expressions {
            items {
              id
              name
              createdAt
              updatedAt
              imageExpressionsId
              __typename
            }
            nextToken
            __typename
          }
          traits
          createdAt
          updatedAt
          categories {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          characterSet
          characterSetName
          location
          __typename
        }
        imageCategory {
          id
          category
          images {
            items {
              id
              imageId
              imageCategoryId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteImageCategoryMutationVariables,
  APITypes.DeleteImageCategoryMutation
>;
export const createBookOrder = /* GraphQL */ `mutation CreateBookOrder(
  $input: CreateBookOrderInput!
  $condition: ModelBookOrderConditionInput
) {
  createBookOrder(input: $input, condition: $condition) {
    id
    checkoutId
    storyId
    title
    bookplateStyle
    bookplateName
    byline
    dedication
    lineItems {
      description
      quantity
      amount
      priceId
      __typename
    }
    shippingName
    shippingAddressLine1
    shippingAddressLine2
    shippingCity
    shippingState
    shippingPostalCode
    shippingCountry
    status
    statusTimeline
    customerEmail
    totalAmount
    currency
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBookOrderMutationVariables,
  APITypes.CreateBookOrderMutation
>;
export const updateBookOrder = /* GraphQL */ `mutation UpdateBookOrder(
  $input: UpdateBookOrderInput!
  $condition: ModelBookOrderConditionInput
) {
  updateBookOrder(input: $input, condition: $condition) {
    id
    checkoutId
    storyId
    title
    bookplateStyle
    bookplateName
    byline
    dedication
    lineItems {
      description
      quantity
      amount
      priceId
      __typename
    }
    shippingName
    shippingAddressLine1
    shippingAddressLine2
    shippingCity
    shippingState
    shippingPostalCode
    shippingCountry
    status
    statusTimeline
    customerEmail
    totalAmount
    currency
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBookOrderMutationVariables,
  APITypes.UpdateBookOrderMutation
>;
export const deleteBookOrder = /* GraphQL */ `mutation DeleteBookOrder(
  $input: DeleteBookOrderInput!
  $condition: ModelBookOrderConditionInput
) {
  deleteBookOrder(input: $input, condition: $condition) {
    id
    checkoutId
    storyId
    title
    bookplateStyle
    bookplateName
    byline
    dedication
    lineItems {
      description
      quantity
      amount
      priceId
      __typename
    }
    shippingName
    shippingAddressLine1
    shippingAddressLine2
    shippingCity
    shippingState
    shippingPostalCode
    shippingCountry
    status
    statusTimeline
    customerEmail
    totalAmount
    currency
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBookOrderMutationVariables,
  APITypes.DeleteBookOrderMutation
>;
export const createCheckoutMetadata = /* GraphQL */ `mutation CreateCheckoutMetadata(
  $input: CreateCheckoutMetadataInput!
  $condition: ModelCheckoutMetadataConditionInput
) {
  createCheckoutMetadata(input: $input, condition: $condition) {
    id
    stripeSessionId
    customerEmail
    purchaseType
    lineItems {
      description
      quantity
      amount
      priceId
      __typename
    }
    totalAmount
    currency
    discountTotal
    discounts {
      amount
      promoCode
      couponId
      couponName
      __typename
    }
    recipientName
    recipientEmail
    gifterName
    gifterEmail
    giftMessage
    deliveryDate
    giftCardStyle
    bookOrderId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCheckoutMetadataMutationVariables,
  APITypes.CreateCheckoutMetadataMutation
>;
export const updateCheckoutMetadata = /* GraphQL */ `mutation UpdateCheckoutMetadata(
  $input: UpdateCheckoutMetadataInput!
  $condition: ModelCheckoutMetadataConditionInput
) {
  updateCheckoutMetadata(input: $input, condition: $condition) {
    id
    stripeSessionId
    customerEmail
    purchaseType
    lineItems {
      description
      quantity
      amount
      priceId
      __typename
    }
    totalAmount
    currency
    discountTotal
    discounts {
      amount
      promoCode
      couponId
      couponName
      __typename
    }
    recipientName
    recipientEmail
    gifterName
    gifterEmail
    giftMessage
    deliveryDate
    giftCardStyle
    bookOrderId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCheckoutMetadataMutationVariables,
  APITypes.UpdateCheckoutMetadataMutation
>;
export const deleteCheckoutMetadata = /* GraphQL */ `mutation DeleteCheckoutMetadata(
  $input: DeleteCheckoutMetadataInput!
  $condition: ModelCheckoutMetadataConditionInput
) {
  deleteCheckoutMetadata(input: $input, condition: $condition) {
    id
    stripeSessionId
    customerEmail
    purchaseType
    lineItems {
      description
      quantity
      amount
      priceId
      __typename
    }
    totalAmount
    currency
    discountTotal
    discounts {
      amount
      promoCode
      couponId
      couponName
      __typename
    }
    recipientName
    recipientEmail
    gifterName
    gifterEmail
    giftMessage
    deliveryDate
    giftCardStyle
    bookOrderId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCheckoutMetadataMutationVariables,
  APITypes.DeleteCheckoutMetadataMutation
>;
export const createImageGenreRelation = /* GraphQL */ `mutation CreateImageGenreRelation(
  $input: CreateImageGenreRelationInput!
  $condition: ModelImageGenreRelationConditionInput
) {
  createImageGenreRelation(input: $input, condition: $condition) {
    id
    imageId
    genreId
    image {
      id
      name
      type
      genres {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      expressions {
        items {
          id
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          name
          createdAt
          updatedAt
          imageExpressionsId
          __typename
        }
        nextToken
        __typename
      }
      traits
      createdAt
      updatedAt
      categories {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      characterSet
      characterSetName
      location
      __typename
    }
    genre {
      id
      name
      images {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateImageGenreRelationMutationVariables,
  APITypes.CreateImageGenreRelationMutation
>;
export const updateImageGenreRelation = /* GraphQL */ `mutation UpdateImageGenreRelation(
  $input: UpdateImageGenreRelationInput!
  $condition: ModelImageGenreRelationConditionInput
) {
  updateImageGenreRelation(input: $input, condition: $condition) {
    id
    imageId
    genreId
    image {
      id
      name
      type
      genres {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      expressions {
        items {
          id
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          name
          createdAt
          updatedAt
          imageExpressionsId
          __typename
        }
        nextToken
        __typename
      }
      traits
      createdAt
      updatedAt
      categories {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      characterSet
      characterSetName
      location
      __typename
    }
    genre {
      id
      name
      images {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateImageGenreRelationMutationVariables,
  APITypes.UpdateImageGenreRelationMutation
>;
export const deleteImageGenreRelation = /* GraphQL */ `mutation DeleteImageGenreRelation(
  $input: DeleteImageGenreRelationInput!
  $condition: ModelImageGenreRelationConditionInput
) {
  deleteImageGenreRelation(input: $input, condition: $condition) {
    id
    imageId
    genreId
    image {
      id
      name
      type
      genres {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      expressions {
        items {
          id
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          name
          createdAt
          updatedAt
          imageExpressionsId
          __typename
        }
        nextToken
        __typename
      }
      traits
      createdAt
      updatedAt
      categories {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      characterSet
      characterSetName
      location
      __typename
    }
    genre {
      id
      name
      images {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteImageGenreRelationMutationVariables,
  APITypes.DeleteImageGenreRelationMutation
>;
export const createImageCategoryRelation = /* GraphQL */ `mutation CreateImageCategoryRelation(
  $input: CreateImageCategoryRelationInput!
  $condition: ModelImageCategoryRelationConditionInput
) {
  createImageCategoryRelation(input: $input, condition: $condition) {
    id
    imageId
    imageCategoryId
    image {
      id
      name
      type
      genres {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      expressions {
        items {
          id
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          name
          createdAt
          updatedAt
          imageExpressionsId
          __typename
        }
        nextToken
        __typename
      }
      traits
      createdAt
      updatedAt
      categories {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      characterSet
      characterSetName
      location
      __typename
    }
    imageCategory {
      id
      category
      images {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateImageCategoryRelationMutationVariables,
  APITypes.CreateImageCategoryRelationMutation
>;
export const updateImageCategoryRelation = /* GraphQL */ `mutation UpdateImageCategoryRelation(
  $input: UpdateImageCategoryRelationInput!
  $condition: ModelImageCategoryRelationConditionInput
) {
  updateImageCategoryRelation(input: $input, condition: $condition) {
    id
    imageId
    imageCategoryId
    image {
      id
      name
      type
      genres {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      expressions {
        items {
          id
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          name
          createdAt
          updatedAt
          imageExpressionsId
          __typename
        }
        nextToken
        __typename
      }
      traits
      createdAt
      updatedAt
      categories {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      characterSet
      characterSetName
      location
      __typename
    }
    imageCategory {
      id
      category
      images {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateImageCategoryRelationMutationVariables,
  APITypes.UpdateImageCategoryRelationMutation
>;
export const deleteImageCategoryRelation = /* GraphQL */ `mutation DeleteImageCategoryRelation(
  $input: DeleteImageCategoryRelationInput!
  $condition: ModelImageCategoryRelationConditionInput
) {
  deleteImageCategoryRelation(input: $input, condition: $condition) {
    id
    imageId
    imageCategoryId
    image {
      id
      name
      type
      genres {
        items {
          id
          imageId
          genreId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          genre {
            id
            name
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      expressions {
        items {
          id
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          name
          createdAt
          updatedAt
          imageExpressionsId
          __typename
        }
        nextToken
        __typename
      }
      traits
      createdAt
      updatedAt
      categories {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      characterSet
      characterSetName
      location
      __typename
    }
    imageCategory {
      id
      category
      images {
        items {
          id
          imageId
          imageCategoryId
          image {
            id
            name
            type
            genres {
              nextToken
              __typename
            }
            expressions {
              nextToken
              __typename
            }
            traits
            createdAt
            updatedAt
            categories {
              nextToken
              __typename
            }
            characterSet
            characterSetName
            location
            __typename
          }
          imageCategory {
            id
            category
            images {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteImageCategoryRelationMutationVariables,
  APITypes.DeleteImageCategoryRelationMutation
>;
