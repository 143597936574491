import { GraphQLResult, API } from '@aws-amplify/api';
import {
  CreateExpressionMutation,
  CreateExpressionInput,
} from 'src/graphql/API';
import { createExpression } from 'src/graphql/mutations';

export default async function createNewExpression(
  newExpressionInput: CreateExpressionInput,
) {
  console.log('newExpressionInput', newExpressionInput);

  try {
    const createExpressionRes = await API.graphql({
      query: createExpression,
      variables: { input: newExpressionInput },
    }) as GraphQLResult<CreateExpressionMutation>;

    const expressionId = createExpressionRes.data?.createExpression?.id;

    if (!expressionId) {
      throw new Error('Failed to create expression');
    }

    return expressionId;
  } catch (error) {
    console.error('Error creating expression', error);
    throw error;
  }
}
