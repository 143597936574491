import React from 'react';
import AnButton from '@components/AnButton';
import { CheckoutStep as CheckoutStepType } from './types';

export default function CheckoutStep({
  title,
  children,
  isNextDisabled,
  onNext,
  isLastStep,
}: CheckoutStepType) {
  return (
    <div className="flex flex-col min-h-full pt-16">
      <h2>{title}</h2>

      <div className="flex-grow">
        {children}
      </div>
      {!isLastStep && (
        <div className="mt-4">
          <AnButton
            onClick={onNext}
            disabled={isNextDisabled}
          >
            Next
          </AnButton>
        </div>
      )}
    </div>
  );
}

// import React from 'react';

// interface CheckoutStepWrapperProps {
//   title?: string;
//   children: React.ReactNode;
// }

// export default function CheckoutStepWrapper({ title, children }: CheckoutStepWrapperProps) {
//   return (
//     <div className="pt-16">
//       {title && <h2 className="mb-4">{title}</h2>}
//       {children}
//     </div>
//   );
// }
