import { GraphQLResult, API } from '@aws-amplify/api';
import { UpdateStoryPagesMutation, UpdateStoryPagesInput } from 'src/graphql/API';
import { updateStoryPages } from 'src/graphql/mutations';

async function putStoryPages(putData: UpdateStoryPagesInput) {
  const updateStoryPagesResponse: GraphQLResult<UpdateStoryPagesMutation> = await API.graphql({
    query: updateStoryPages,
    variables: {
      input: putData,
    },
  }) as GraphQLResult<UpdateStoryPagesMutation>;

  return updateStoryPagesResponse.data?.updateStoryPages;
}

export default putStoryPages;
