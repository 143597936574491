import AnButton from '@components/AnButton';
import { PackageFlowType } from '../types';

interface WhoAreYouBuyingForProps {
  onChange: (value: PackageFlowType) => void;
  onStepComplete: () => void;
}

export default function WhoAreYouBuyingFor({
  onChange,
  onStepComplete,
}: WhoAreYouBuyingForProps) {
  const handleSelection = (value: PackageFlowType) => {
    onChange(value);
    onStepComplete();
  };

  return (
    <div className="flex flex-col space-y-4 my-4 pr-1">
      <AnButton
        color="primary"
        variant="outlined"
        className="bg-white !text-black !font-[Urbanist] w-full"
        onClick={() => handleSelection({ buyingFor: 'me', whoWillCreate: '' })}
      >
        👋 Me and my child
      </AnButton>
      <AnButton
        color="primary"
        variant="contained"
        className="bg-white !text-black !font-[Urbanist] w-full"
        onClick={() => handleSelection({ buyingFor: 'someoneElse', whoWillCreate: '' })}
      >
        💌 Someone else
      </AnButton>
    </div>
  );
}
