import { Helmet } from 'react-helmet-async';
import { DEFAULT_META } from './DefaultMeta';

export interface SeoMetaProps {
  title?: string;
  description?: string;
  images?: {
    default?: string;
    openGraph?: string;
    twitter?: string;
  };
  type?: 'website' | 'article';
  canonicalUrl?: string;
  noindex?: boolean;
}

export default function SeoMeta({
  title = DEFAULT_META.title,
  description = DEFAULT_META.description,
  images = DEFAULT_META.images,
  type = DEFAULT_META.type,
  canonicalUrl,
  noindex = false,
}: SeoMetaProps) {
  const url = canonicalUrl ? `${DEFAULT_META.domain}${canonicalUrl}` : DEFAULT_META.domain;
  const getFullImageUrl = (imagePath: string) => (imagePath.startsWith('http') ? imagePath : `${DEFAULT_META.domain}${imagePath}`);

  return (
    <Helmet>
      {/* Basic */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {noindex && <meta name="robots" content="noindex,nofollow" />}
      {canonicalUrl && <link rel="canonical" href={url} />}

      {/* Open Graph */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={getFullImageUrl(images.openGraph ?? images.default ?? '')} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="Wanderly" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={DEFAULT_META.twitterHandle} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={getFullImageUrl(images.twitter ?? images.default ?? '')} />
      <meta name="twitter:image:width" content="1200" />
      <meta name="twitter:image:height" content="675" />
    </Helmet>
  );
}
