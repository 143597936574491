import { useNavigate } from 'react-router-dom';
import AnButton from '@components/AnButton';
import { StaticPagePath, trackStaticPageEvent } from '@lib/analytics';

interface BuyNowButtonProps {
  path: StaticPagePath;
  analyticsLabel: string;
}

export default function BuyNowButton({ path, analyticsLabel }: BuyNowButtonProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    // Track the click event
    trackStaticPageEvent('buy_now_clicked', {
      path,
      button_name: analyticsLabel,
    });

    // Navigate to checkout
    navigate('/checkout?type=buy-package');
  };

  return (
    <AnButton onClick={handleClick}>
      Buy Now
    </AnButton>
  );
}
