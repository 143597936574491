import { ANANSI_REST_API_NAME } from '@lib/constants';
import getCognitoUserId from '@lib/getCognitoUserId';
import getCognitoUserSubId from '@lib/getCognitoUserSubId';
import { API } from '@aws-amplify/api';

async function startNextPageJob(priorPageID: string, isNextPageLast: boolean = false) {
  const apiName = ANANSI_REST_API_NAME;
  const path = '/next';

  const cognitoUserID = await getCognitoUserId();
  const cognitoUserSubID = await getCognitoUserSubId();

  const myInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      cognitoUserID,
      cognitoUserSubID,
      priorPageID,
      ending: isNextPageLast,
    },
  };

  return API.get(apiName, path, myInit)
    .then((response) => response.data.job_id)
    .catch((error) => {
      console.error(error.response);
    });
}

export default startNextPageJob;
