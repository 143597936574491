import { GraphQLResult, API } from '@aws-amplify/api';
import {
  GetSharedPageQuery, GetDailyStoryQuery, SharedPage,
} from 'src/graphql/API';
import { getDailyStory, getSharedPage } from 'src/graphql/queries';

async function getSharedPageByIDfromDB(sharedPageID: string) {
  const sharedPageIDParams = {
    id: sharedPageID,
  };

  const getPageByIDResponse = await API.graphql({
    query: getSharedPage,
    variables: sharedPageIDParams,
  }) as GraphQLResult<GetSharedPageQuery>;

  return getPageByIDResponse.data?.getSharedPage;
}

async function getExpositionPageIDFromDailyStory(dailyStoryID: string) {
  const dailyStoryIDParams = {
    id: dailyStoryID,
  };

  const getDailyStoryResponse = await API.graphql({
    query: getDailyStory,
    variables: dailyStoryIDParams,
  }) as GraphQLResult<GetDailyStoryQuery>;

  return getDailyStoryResponse.data?.getDailyStory?.expositionPageID;
}

async function getNextSharedPage(chosenOptionIndex: number, currentPage: SharedPage): Promise<SharedPage> {
  // check to see if currentPage has a followedBy field, and set the nextPages variable accordingly

  let nextPages: (string | null)[] | null = null;
  if ('followedBy' in currentPage && currentPage.followedBy && currentPage.followedBy.length === 3) { // NOTE: 3 should be dynamically fetched instead (using pageDepth?)
    nextPages = currentPage.followedBy;
  } else if ('followedByEnding' in currentPage && currentPage.followedByEnding && currentPage.followedByEnding.length === 3) {
    nextPages = currentPage.followedByEnding;
  } else {
    throw new Error('no next pages found!');
  }

  if (!nextPages) {
    throw new Error('no next pages found!');
  }

  const nextPageId = nextPages[chosenOptionIndex];
  if (!nextPageId) {
    throw new Error('next page id is null or undefined');
  }

  const nextPage = await getSharedPageByIDfromDB(nextPageId);
  return nextPage as SharedPage;
}

export {
  getExpositionPageIDFromDailyStory,
  getNextSharedPage,
  getSharedPageByIDfromDB,
};
