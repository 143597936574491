import React from 'react';
import { imageBaseURL } from '@lib/imageUtils';

interface FoundersNoteProps {
  isDesktop: boolean;
}

export function FoundersNote({ isDesktop }: FoundersNoteProps) {
  const signaturePath = `${imageBaseURL}misc/landing/laura_signature_gray`;
  const familyPhotoPath = `${imageBaseURL}misc/landing/laura_and_girls`;

  return (
    <div className="py-8 bg-transparent">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className={`flex ${isDesktop ? 'flex-row' : 'flex-col'} gap-8`}>
          {/* Left side: Note content */}
          <div className={`${isDesktop ? 'w-2/3' : 'w-full'}`}>
            <div className="bg-white p-6 sm:p-8 rounded-lg shadow-md">
              <h2 className="text-2xl font-semibold mb-6">Founder&apos;s Note</h2>

              <div className="space-y-4 text-gray-700">
                <p>
                  Growing up, I loved the stories my mother and I made up together at night.
                  Now that I have two children of my own, I realize how hard that must have been for her to do!
                </p>

                <p>
                  Wanderly is an interactive storytelling app and publisher that creates never-before-told
                  stories based on your child&apos;s imagination. It works just like the stories I used to tell
                  with my mom: Wanderly provides the words, but it&apos;s your child&apos;s choices that shape the story!
                </p>

                <p>
                  Thanks for supporting my small, woman-owned business. I hope Wanderly gets to make some
                  magical stories with and for you and your family.
                </p>
              </div>

              <div className="mt-6">
                <picture className="block">
                  <source
                    media="(min-width: 1280px)"
                    srcSet={`${signaturePath}_640w.webp`}
                  />
                  <img
                    src={`${signaturePath}_320w.webp`}
                    alt="Laura's Signature"
                    className="h-20 object-contain"
                    width="320"
                    height="80"
                    loading="lazy"
                  />
                </picture>
              </div>
            </div>
          </div>

          {/* Right side: Photo */}
          <div className={`${isDesktop ? 'w-1/3' : 'w-full'}`}>
            <div className="bg-white p-4 rounded-lg shadow-md">
              <picture className="block">
                <source
                  media="(min-width: 768px)"
                  srcSet={`${familyPhotoPath}_640w.webp`}
                />
                <img
                  src={`${familyPhotoPath}_320w.webp`}
                  alt="Laura with Alanna and Selena"
                  className="w-full h-auto rounded-lg object-cover"
                  width="320"
                  height="400"
                  loading="lazy"
                />
              </picture>
              <p className="mt-4 text-sm text-gray-600 text-center italic">
                Making stories with my girls
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FoundersNote;
