import { ANANSI_REST_API_NAME } from '@lib/constants';
import { API } from '@aws-amplify/api';

async function redeemGiftCards() {
  const apiName = ANANSI_REST_API_NAME;
  const path = '/redeemGiftcard';

  const myInit = {
    headers: {
      // Include any required headers, like Authorization if needed
    },
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API.get(apiName, path, myInit)
    .then((response) => response.data.newCredits)
    .catch((error) => {
      console.error(error.response);
    });
}

export default redeemGiftCards;
