import { Navigate, createBrowserRouter } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import RequireChild from '@hooks/routing/RequireChild';
import RequireNoPremium from '@hooks/routing/RequireNoPremium';
import RequirePremiumOrTrial from '@hooks/routing/RequirePremiumOrTrial';
import RequireAdmin from '@hooks/routing/RequireAdmin';
import LandingPage from '@features/LandingPage/LandingPage';
import Checkout from '@features/Checkout/Checkout';
import Terms from '@features/StaticPublicPages/Terms';
import Privacy from '@features/StaticPublicPages/Privacy';
import AnLoadingModal from '@components/AnLoadingModal';

const Auth = lazy(() => import('src/Auth'));
const WaitlistedUserPage = lazy(() => import('@features/WaitlistedUserPage/WaitlistedUserPage'));
const CurrentStories = lazy(() => import('@features/CurrentStories/CurrentStories'));
const DailyStory = lazy(() => import('@features/DailyStory/DailyStory'));
const CustomStory = lazy(() => import('@features/CustomStory/CustomStory'));
const CreateScene = lazy(() => import('@features/CreateScene/CreateScene'));
const Admin = lazy(() => import('@features/Admin/Admin'));
const FirstChildInit = lazy(() => import('@features/FirstChildInit'));
const SettingsAccount = lazy(() => import('@features/Settings/SettingsAccount'));
const SettingsChild = lazy(() => import('@features/Settings/SettingsChild'));
const UpgradePremium = lazy(() => import('@features/UpgradePremium/UpgradePremium'));

function Fallback() {
  return (
    <AnLoadingModal />
  );
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: '/checkout',
    element: <Checkout />,
  },
  {
    path: '/terms',
    element: <Terms />,
  },
  {
    path: '/privacy',
    element: <Privacy />,
  },
  {
    path: '/auth',
    element: (
      <Suspense fallback={<Fallback />}>
        <Auth />
      </Suspense>
    ),
    children: [
      {
        path: 'welcome',
        element: <FirstChildInit />,
      },
      {
        path: 'stories/create-scene',
        element: (
          <RequireChild>
            <RequirePremiumOrTrial>
              <CreateScene />
            </RequirePremiumOrTrial>
          </RequireChild>
        ),
      },
      {
        path: 'stories/custom-story/:storyId',
        element: (
          <RequireChild>
            <CustomStory />
          </RequireChild>
        ),
      },
      {
        path: 'stories/daily-story/:storyId',
        element: <DailyStory />,
      },
      {
        path: 'stories/current',
        element: (
          <RequireChild>
            <CurrentStories />
          </RequireChild>
        ),
      },
      {
        path: 'upgrade',
        element: (
          <RequireNoPremium>
            <UpgradePremium />
          </RequireNoPremium>
        ),
      },
      {
        path: 'settings',
        element: (
          <Navigate to="/settings/child" />
        ),
      },
      {
        path: 'settings/child',
        element: <SettingsChild />,
      },
      {
        path: 'settings/account',
        element: <SettingsAccount />,
      },
      {
        path: '',
        element: <Navigate to="/auth/stories/current" />,
      },
      {
        path: '*',
        element: <Navigate to="/auth/stories/current" />,
      },
      {
        path: 'admin',
        element: (
          <RequireAdmin>
            <Admin />
          </RequireAdmin>
        ),
      },
    ],
  },
  {
    path: 'waitlist',
    element: <WaitlistedUserPage />,
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
]);

export default router;
