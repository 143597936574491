import AnImageCarousel from '@components/AnImageCarousel';

interface InlineImageGalleryProps {
  images: {
    src: string;
    thumbnail?: string;
  }[];
}

export default function InlineImageGallery({ images }: InlineImageGalleryProps) {
  return (
    <AnImageCarousel images={images} showDots />
  );
}
