import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AnTextField from '@components/AnTextField';
import AnCheckoutImageLineItem from '@components/AnCheckoutImageLineItem';
import { useDispatch } from 'react-redux';
import { setCustomBookPurchaseDetails } from '@store/checkoutSlice';
import { imageBaseURL } from '@lib/imageUtils';
import { LineItem, PRODUCT_NAMES } from '../types';

interface PersonalizeInsideProps {
  bookplateName: string;
  dedicationMessage: string;
  onIsFormValid: (isValid: boolean) => void;
}

// Constants for validation
const MAX_LINES = 4;
const MAX_TOTAL_CHARS = 210;

// Utility function to count lines
const countLines = (text: string): number => text.split('\n').length;

// Define the validation schema
const validationSchema = yup.object().shape({
  bookplateName: yup.string().required('Please enter a name for the bookplate'),
  dedicationMessage: yup
    .string()
    .optional()
    .test(
      'max-characters',
      `Message must be at most ${MAX_TOTAL_CHARS} characters`,
      (value) => !value || value.length <= MAX_TOTAL_CHARS,
    )
    .test(
      'max-lines',
      `Message must be at most ${MAX_LINES} lines`,
      (value) => !value || countLines(value) <= MAX_LINES,
    ),
});

const bookplateOptions: LineItem[] = [
  {
    name: PRODUCT_NAMES.MODERN_BOOKPLATE,
    priceId: '',
    productId: '',
    description: PRODUCT_NAMES.MODERN_BOOKPLATE,
    currency: 'usd',
    amount: 0,
    imageSrc: `${imageBaseURL}misc/checkout/modern-bookplate_320w.webp`,
  },
  {
    name: PRODUCT_NAMES.CLASSIC_BOOKPLATE,
    priceId: '',
    productId: '',
    description: PRODUCT_NAMES.CLASSIC_BOOKPLATE,
    currency: 'usd',
    amount: 0,
    imageSrc: `${imageBaseURL}misc/checkout/classic-bookplate_320w.webp`,
  },
];

export default function PersonalizeInside({
  bookplateName,
  dedicationMessage,
  onIsFormValid,
}: PersonalizeInsideProps) {
  const dispatch = useDispatch();

  const [dedicationFeedback, setDedicationFeedback] = useState({
    remainingChars: MAX_TOTAL_CHARS,
    currentLines: 1,
    isValid: true,
  });

  const prevBookplateNameRef = useRef<string>(bookplateName);
  const prevDedicationMessageRef = useRef<string>(dedicationMessage);

  const {
    control,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      bookplateName,
      dedicationMessage,
    },
  });

  const watchedBookplateName = watch('bookplateName');
  const watchedDedicationMessage = watch('dedicationMessage') || '';

  const handleBookplateSelection = (selectedBookplate: LineItem | null) => {
    if (selectedBookplate) {
      dispatch(setCustomBookPurchaseDetails({
        bookplateStyle: selectedBookplate.name,
      }));
    }
  };

  useEffect(() => {
    onIsFormValid(isValid);
  }, [isValid, onIsFormValid]);

  useEffect(() => {
    if (watchedBookplateName !== prevBookplateNameRef.current
        || watchedDedicationMessage !== prevDedicationMessageRef.current) {
      dispatch(setCustomBookPurchaseDetails({
        bookplateName: watchedBookplateName,
        dedication: watchedDedicationMessage,
      }));
      prevBookplateNameRef.current = watchedBookplateName;
      prevDedicationMessageRef.current = watchedDedicationMessage;
    }
  }, [watchedBookplateName, watchedDedicationMessage, dispatch]);

  useEffect(() => {
    const dedicationMsg = watchedDedicationMessage || '';
    const remainingChars = MAX_TOTAL_CHARS - dedicationMsg.length;
    const currentLines = countLines(dedicationMsg);

    const isValidFeedback = remainingChars >= 0 && currentLines <= MAX_LINES;

    setDedicationFeedback({
      remainingChars,
      currentLines,
      isValid: isValidFeedback,
    });
  }, [watchedDedicationMessage]);

  return (
    <form className="my-4 max-w-sm">
      <div className="mb-4">
        <p className="text-sm text-gray-600 pb-1">Name on Bookplate</p>
        <AnTextField
          name="bookplateName"
          placeholder="Bookplate Name"
          control={control}
          error={!!errors.bookplateName}
          errorMessage={errors.bookplateName?.message}
          textFieldStyle="fully-enclosed"
        />
      </div>
      <div className="mb-4">
        <p className="text-sm text-gray-600 pb-1">Dedication Message</p>
        <AnTextField
          name="dedicationMessage"
          placeholder="May your life be filled with stories that could only be told by you."
          control={control}
          error={!!errors.dedicationMessage}
          errorMessage={errors.dedicationMessage?.message}
          textFieldStyle="fully-enclosed"
          multiline
        />
        <p
          className={`text-xs text-right ${
            dedicationFeedback.isValid ? 'text-gray-500' : 'text-red-500'
          }`}
        >
          Characters remaining:
          {' '}
          {dedicationFeedback.remainingChars}
        </p>
      </div>
      <AnCheckoutImageLineItem
        lineItemOptions={bookplateOptions}
        onChange={handleBookplateSelection}
        title="Bookplate Style"
        showDescription
        showPrice={false}
      />
    </form>
  );
}
