import { API, graphqlOperation } from 'aws-amplify';
import { createImageGenreRelation } from 'src/graphql/mutations';

export default async function createCategoryRelations(imageId: string, genreIds: string[]) {
  console.log('genreIds', genreIds);
  console.log('imageId', imageId);

  const createRelationPromises = genreIds.map((genreId) => API.graphql(graphqlOperation(createImageGenreRelation, {
    input: {
      imageId,
      genreId,
    },
  })));

  try {
    await Promise.all(createRelationPromises);
    console.log('All image:genre relations created successfully');
  } catch (error) {
    console.error('Error creating image:genre relations:', error);
    throw error;
  }
}
