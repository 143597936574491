import { GraphQLResult, API } from '@aws-amplify/api';
import { GetPageQuery } from 'src/graphql/API';
import { getPage } from 'src/graphql/queries';

async function getPageById(pageId: string) {
  const res = await API.graphql({
    query: getPage,
    variables: {
      id: pageId,
    },
  }) as GraphQLResult<GetPageQuery>;

  return res.data?.getPage || undefined;
}

export default getPageById;
