import { imageBaseURL } from '@lib/imageUtils';

const GALLERY_BOOK_IMAGES = [
  {
    src: `${imageBaseURL}misc/checkout/book-cover-straight-on_1280w.webp`,
    srcSet: `
      ${imageBaseURL}misc/checkout/book-cover-straight-on_320w.webp 320w,
      ${imageBaseURL}misc/checkout/book-cover-straight-on_640w.webp 640w,
      ${imageBaseURL}misc/checkout/book-cover-straight-on_1280w.webp 1280w
    `,
    thumbnail: `${imageBaseURL}misc/checkout/book-cover-straight-on_320w.webp`,
  },
  {
    src: `${imageBaseURL}misc/checkout/two-page-spread-1_1280w.webp`,
    srcSet: `
      ${imageBaseURL}misc/checkout/two-page-spread-1_320w.webp 320w,
      ${imageBaseURL}misc/checkout/two-page-spread-1_640w.webp 640w,
      ${imageBaseURL}misc/checkout/two-page-spread-1_1280w.webp 1280w
    `,
    thumbnail: `${imageBaseURL}misc/checkout/two-page-spread-1_320w.webp`,
  },
  // {
  //   src: `${imageBaseURL}misc/checkout/bookplate-focus_1280w.jpeg`,
  //   srcSet: `
  //     ${imageBaseURL}misc/checkout/bookplate-focus_320w.jpeg 320w,
  //     ${imageBaseURL}misc/checkout/bookplate-focus_640w.jpeg 640w,
  //     ${imageBaseURL}misc/checkout/bookplate-focus_1280w.jpeg 1280w
  //   `,
  //   thumbnail: `${imageBaseURL}misc/checkout/bookplate-focus_320w.jpeg`,
  // },
  {
    src: `${imageBaseURL}misc/checkout/standard-layout-single-page_focus_1280w.webp`,
    srcSet: `
      ${imageBaseURL}misc/checkout/standard-layout-single-page-focus_320w.webp 320w,
      ${imageBaseURL}misc/checkout/standard-layout-single-page-focus_640w.webp 640w,
      ${imageBaseURL}misc/checkout/standard-layout-single-page-focus_1280w.webp 1280w
    `,
    thumbnail: `${imageBaseURL}misc/checkout/standard-layout-single-page-focus_320w.webp`,
  },
  {
    src: `${imageBaseURL}misc/checkout/two-page-spread-2_1280w.webp`,
    srcSet: `
      ${imageBaseURL}misc/checkout/two-page-spread-2_320w.webp 320w,
      ${imageBaseURL}misc/checkout/two-page-spread-2_640w.webp 640w,
      ${imageBaseURL}misc/checkout/two-page-spread-2_1280w.webp 1280w
    `,
    thumbnail: `${imageBaseURL}misc/checkout/two-page-spread-2_320w.webp`,
  },
  {
    src: `${imageBaseURL}misc/checkout/title-page-close-up_1280w.webp`,
    srcSet: `
      ${imageBaseURL}misc/checkout/title-page-close-up_320w.webp 320w,
      ${imageBaseURL}misc/checkout/title-page-close-up_640w.webp 640w,
      ${imageBaseURL}misc/checkout/title-page-close-up_1280w.webp 1280w
    `,
    thumbnail: `${imageBaseURL}misc/checkout/title-page-close-up_320w.webp`,
  },
];

export default GALLERY_BOOK_IMAGES;
