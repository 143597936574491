import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import appReducer, { resetAppState } from './appSlice';
import customStorySlice, { resetCustomStoryState } from './customStorySlice';
import dailyStorySlice, { resetDailyStoryState } from './dailyStorySlice';
import adminSlice, { resetAdminState } from './adminSlice';
import checkoutSlice, { resetCheckout } from './checkoutSlice';

const store = configureStore({
  reducer: {
    app: appReducer,
    customStory: customStorySlice,
    dailyStory: dailyStorySlice,
    admin: adminSlice,
    checkout: checkoutSlice,
  },
});

export const resetStore = () => {
  store.dispatch(resetAppState());
  store.dispatch(resetCustomStoryState());
  store.dispatch(resetDailyStoryState());
  store.dispatch(resetAdminState());
  store.dispatch(resetCheckout());
};

// RootState represents the entire Redux store state
export type RootState = ReturnType<typeof store.getState>;

// AppDispatch represents the dispatch function type for the Redux store
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
