import AppleStoreBadge from '@assets/app_store_badge.png';
import GooglePlayBadge from '@assets/play_store_badge.png';
import { trackStaticPageEvent } from '@lib/analytics';

interface StoreButtonsProps {
  isDesktop: boolean;
}

export function StoreButtons({ isDesktop }: StoreButtonsProps) {
  const handleAppStoreClick = () => {
    trackStaticPageEvent('app_download_click', {
      path: 'landing_page',
      action: 'download_app',
      platform: 'ios',
    });
  };

  const handlePlayStoreClick = () => {
    trackStaticPageEvent('app_download_click', {
      path: 'landing_page',
      action: 'download_app',
      platform: 'android',
    });
  };

  return (
    <div className={`flex ${isDesktop ? 'flex-row' : 'flex-col items-center'} gap-4 justify-center md:justify-start`}>
      <a
        href="https://apps.apple.com/us/app/wanderly-stories/id6475022201"
        target="_blank"
        rel="noopener noreferrer"
        className="transition-transform hover:scale-105"
        onClick={handleAppStoreClick}
      >
        <img
          src={AppleStoreBadge}
          alt="Download on the App Store"
          className="h-[40px] md:h-[50px] w-auto"
          loading="lazy"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.app.wanderly"
        target="_blank"
        rel="noopener noreferrer"
        className="transition-transform hover:scale-105"
        onClick={handlePlayStoreClick}
      >
        <img
          src={GooglePlayBadge}
          alt="Get it on Google Play"
          className="h-[40px] md:h-[50px] w-auto"
          loading="lazy"
        />
      </a>
    </div>
  );
}

export default StoreButtons;
