import React from 'react';
import AnStarFilledIcon from '@components/AnIcon/icons/AnStarFilledIcon';

export interface UserQuote {
  name: string;
  quote: string;
}

interface QuoteProps {
  quotes: UserQuote[];
  isDesktop: boolean;
  rows?: number;
}

export function UserQuotes({ quotes, isDesktop, rows = 2 }: QuoteProps) {
  const quotesPerRow = isDesktop ? 4 : 2;
  const maxQuotes = rows * quotesPerRow;
  const displayedQuotes = quotes.slice(0, maxQuotes);

  return (
    <div className="py-4 bg-transparent">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-wrap -mx-4">
          {displayedQuotes.map((user) => (
            <div
              key={user.name}
              className={`w-1/2 mb-4 ${
                isDesktop ? 'w-1/4 px-4 mb-8' : 'px-2'
              }`}
            >
              <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md h-full flex flex-col">
                <div className="flex items-center mb-4 gap-1">
                  {/* Display 5 Star Ratings */}
                  {Array.from({ length: 5 }, (_, i) => (
                    <AnStarFilledIcon
                      key={`star-${i}`}
                      fillColorClass="fill-secondary"
                      strokeColorClass="stroke-secondary"
                      size="lg"
                      strokeWidth={1}
                    />
                  ))}
                </div>
                <p className="text-gray-700 italic flex-grow">
                  &ldquo;
                  {user.quote}
                  &rdquo;
                </p>
                <p className="mt-4 text-right font-semibold">{user.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UserQuotes;
