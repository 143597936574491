import AnFAQItem from './AnFAQItem';

type AnswerPart = string | { text: string, href?: string, action?: string };

interface FAQ {
  question: string;
  answer: AnswerPart[];
}

interface AnFAQSectionProps {
  data?: FAQ[];
}

function AnFAQSection({ data }: AnFAQSectionProps) {
  const handleSpecialAction = (action: string) => {
    switch (action) {
      case 'upgrade_question':
        console.log('Get in touch action triggered!');
        if (window.Usersnap) {
          window.Usersnap.logEvent('upgrade_question');
        }
        // Add your own logic here
        break;
      default:
        console.error(`Unhandled action ${action}`);
    }
  };

  return (
    <div>
      <h3 className="mb-4">Frequently Asked Questions</h3>
      {data?.map((faq: FAQ) => (
        <AnFAQItem
          key={faq.question}
          question={faq.question}
          answer={faq.answer}
          onSpecialAction={handleSpecialAction}
        />
      ))}
    </div>
  );
}

export default AnFAQSection;
